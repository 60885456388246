var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import MicOffIcon from "../icons/MicOffIcon";
import MicOnIcon from "../icons/MicOnIcon";
import { TeamToColor, TeamToName } from "cresus-common/dist/utils/ColorsSwitch";
import ETeam from "cresus-common/dist/dto/ETeam";
import AvatarCompositionComponent from "../avatar_creation_component/AvatarCompositionComponent";
import useMedia from "../../contexts/useMedia";
import useSession from "../../contexts/useSession";
import useAuth from "../../contexts/useAuth";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
function PlayersDisplayComponent(props) {
    const { role } = useAuth();
    const { audioVideoClient } = useMedia();
    const { websocketPeer, sendRequest } = useSession();
    const [micOn, setMicOn] = useState(props.playerDTO.micOn);
    const [player, setPlayer] = useState(props.playerDTO);
    const [teamColor, setTeamColor] = useState();
    const [textColor, setTextColor] = useState("white");
    // const [playes, setPlayers] = useState(props.players);
    const [team, setTeam] = useState(props.playerDTO.team);
    useEffect(() => {
        setPlayer(props.playerDTO);
        setTeam(props.playerDTO.team);
        setTeamColor(TeamToColor(props.playerDTO.team));
        if (team === ETeam.GREEN || team === ETeam.YELLOW) {
            setTextColor("black");
        }
    }, [props.playerDTO]);
    useEffect(() => {
        const onMuteChangeCallback = (sessionPeerId, isMuted) => {
            console.log("Received onMuteChangeOthers", sessionPeerId, isMuted, props.playerDTO.sessionPeerId);
            if (sessionPeerId === props.playerDTO.sessionPeerId) {
                setMicOn(!isMuted);
                console.log("State changed");
            }
        };
        audioVideoClient.onMuteChangeOthers.push(onMuteChangeCallback);
        return () => {
            const indexCallback = audioVideoClient.onMuteChangeOthers.indexOf(onMuteChangeCallback);
            if (indexCallback !== -1)
                audioVideoClient.onMuteChangeOthers.splice(indexCallback, 1);
            else
                console.warn("PeopleWaitingRoomComponent unmount, cannot remove callback");
        };
    });
    function getDedefaultMuted() {
        return __awaiter(this, void 0, void 0, function* () {
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.playerDTO.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                const isMuted = audioVideoClient.getMuted(mediaAPIPeerId);
                setMicOn(!isMuted);
                console.log(`getDedefaultMuted ${isMuted} sessionPeerId:${props.playerDTO.sessionPeerId} name:${props.playerDTO.name} mediaPeerId:${mediaAPIPeerId}`);
            }
        });
    }
    useEffect(() => {
        getDedefaultMuted();
    }, []);
    function onClickMuteUnmuteButton() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!props.playerDTO.isConnected)
                return;
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.playerDTO.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                audioVideoClient.muteParticipant(mediaAPIPeerId, props.playerDTO.sessionPeerId);
            }
            else
                console.warn("Current player has no mediaAPIPeerId");
        });
    }
    return (_jsxs("div", Object.assign({ className: "flex justify-around w-full text-white font-roboto gap-[10px]", style: {
            background: "rgba(18, 30, 70, 1)",
            height: "40px",
            paddingTop: "5px",
            minHeight: "40px",
            paddingLeft: "10px",
            border: "1px solid #313D64",
            borderRadius: "3px",
            opacity: role === EPlayerRole.ANIMATOR && !props.playerDTO.isConnected ? 0.3 : 1,
        } }, { children: [_jsx("div", Object.assign({ className: "flex self-end ", style: {
                    height: "35px",
                    width: "49px",
                    // backgroundImage: "url('/assets/UI/avatarTestWaitingRoom.png')",
                    backgroundSize: "cover",
                } }, { children: _jsx("div", Object.assign({ className: "relative w-full h-full" }, { children: _jsx(AvatarCompositionComponent, { avatarComposition: props.playerDTO.avatarComposition, toRight: true }) })) })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-center gap-[2px]", style: {
                    width: "86.57%",
                    paddingTop: "2px",
                    paddingBottom: "5px",
                } }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center", style: {
                            height: "12px",
                            minHeight: "12px",
                            padding: "0px 5px",
                            backgroundColor: `${teamColor}`,
                            color: ` ${textColor}`,
                            borderRadius: "2px",
                            fontStyle: "normal",
                            lineHeight: "12px",
                            fontSize: "10px",
                            fontWeight: "400",
                            textTransform: "capitalize",
                        } }, { children: TeamToName(team) })), _jsx("div", Object.assign({ className: "font-roboto justify-start overflow-hidden flex flex-nowrap", style: {
                            height: "14px",
                            minHeight: "14px",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            fontSize: "12px",
                            fontWeight: "400",
                        } }, { children: props.playerDTO.name }))] })), _jsxs("button", Object.assign({ className: "flex relative items-center self-center", onClick: () => { onClickMuteUnmuteButton(); }, style: {
                    right: "0px",
                    height: "20px",
                    width: "30px",
                    top: "-2.5px",
                    paddingRight: "10px",
                } }, { children: [micOn && _jsxs("div", Object.assign({ className: "flex", style: {
                            borderRadius: "50%",
                            backgroundColor: "black"
                        } }, { children: [_jsx(MicOnIcon, {}), " "] })), !micOn &&
                        _jsxs("div", Object.assign({ className: "flex", style: {
                                borderRadius: "50%",
                                backgroundColor: "transparent"
                            } }, { children: [" ", _jsx(MicOffIcon, {})] }))] }))] })));
}
export default PlayersDisplayComponent;
