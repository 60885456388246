import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FunctionalButton from "../../FunctionalButton";
function InGamePopin(props) {
    return (_jsx("div", Object.assign({ className: "flex relative justify-center items-center self-center", style: {
            minHeight: 134,
            minWidth: 386,
            zIndex: 100,
        } }, { children: _jsx("div", Object.assign({ className: "flex absolute w-full h-full items-center justify-center", style: {
                // right: "50cqw",
                borderRadius: 15,
                border: "2px solid #000000",
                backgroundColor: "rgba(255, 255, 255, 1)",
                zIndex: 0,
                padding: "7px",
                boxShadow: "-10px 10px 0px 0px rgba(0, 0, 0, 0.25)",
                top: "-100%"
            } }, { children: _jsxs("div", Object.assign({ className: "flex justify-center relative w-full h-full flex-col gap-[20px]", style: {
                    padding: "35px",
                    borderRadius: 10,
                    backgroundColor: "rgba(111, 130, 192, 1)",
                } }, { children: [_jsx("div", Object.assign({ className: "flex justify-center items-center text-center flex-col text-white font-kalam gap-[7cqw]", style: {
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "normal",
                            // textTransform: "uppercase",
                        } }, { children: props.textInformation })), props.isItInformative &&
                        _jsx("div", Object.assign({ className: "flex items-center self-center" }, { children: _jsx(FunctionalButton, { fontSize: "14px", text: "ok", isRound: true, onClick: props.validateFunction, colorMode: 1 }) })), !props.isItInformative && props.textButtonCancel && props.textButtonValidate &&
                        _jsxs("div", Object.assign({ className: "flex items-center self-center gap-[10px]" }, { children: [_jsx(FunctionalButton, { fontSize: "14px", text: props.textButtonCancel, isRound: true, onClick: props.cancelFunction, colorMode: 1 }), _jsx(FunctionalButton, { fontSize: "14px", text: props.textButtonValidate, isRound: true, onClick: props.validateFunction, colorMode: 0 })] }))] })) })) }))
    // </div >
    );
}
export default InGamePopin;
