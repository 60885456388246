import { MeshPhysicalMaterial } from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
export function createBackgroundMaterialOverride(baseColor) {
    const baseMaterial = new MeshPhysicalMaterial();
    baseMaterial.envMapIntensity = 2;
    const material = new CustomShaderMaterial({
        baseMaterial: baseMaterial,
        fragmentShader: /* glsl */ `
            void main() {
                csm_DepthAlpha = 2.;
                ///csm_FragColor.a = 2.;
                vec4 csm_FogColor;
            }
        `,
        silent: true,
        uniforms: {
            uTime: {
                value: 0,
            },
        },
        fog: true,
        patchMap: {
            csm_FogColor: {
                '#include <fog_fragment>': /* glsl */ `
                #include <fog_fragment>
    
                vec4 fogColor = gl_FragColor;
                // Dirty uncommented hack from CustomShaderMaterial https://codesandbox.io/s/github/FarazzShaikh/THREE-CustomShaderMaterial/tree/main/examples/caustics
                // We use an alpha of 2.0 to indicate that the fragment should not be outlined in the postprocess pass
                gl_FragColor = vec4(fogColor.xyz*0.75, 2.); 
            `,
            },
        },
        flatShading: true,
        color: baseColor,
    });
    return material;
}
