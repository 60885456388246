var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import ChatComponent from "../../components/ChatComponent";
import { useEffect, useState } from "react";
import SetOfButtonsPlayerComponent from "../../components/player_game_element_component/SetOfButtonsPlayerComponent";
import useSessionContext from "../../tools/useSessionContext";
import useMedia from "../../contexts/useMedia";
import { useBoardGame } from "../../contexts/BoardGameProvider";
function IntroductionPlayerPage() {
    const { muteSound } = useBoardGame();
    const { setCameraMode, audioVideoClient } = useMedia();
    useEffect(() => {
        audioVideoClient.unmuteMic();
        setCameraMode(true, false, false);
    });
    const { gameState, sendRequest, websocketPeer } = useSessionContext();
    const [myPlayer, setMyPlayer] = useState();
    const [insideGameState, setInsideGameState] = useState(gameState);
    const [isChatShown, setIsChatShown] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    const navigate = useNavigate();
    function getMyPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getMyPlayer");
            const result = yield sendRequest("getMyPlayer", { gameState: gameState })
                .catch((error) => { console.error("getMyPlayer", error); });
            if (result !== undefined)
                setMyPlayer(result);
            else
                console.warn("problem with the data");
        });
    }
    function updateIfAvatarSelectionStarted(message) {
        console.log("test callback", message);
        const buffGameState = Object.assign({}, insideGameState);
        buffGameState.avatarSelectionOn = message.data.avatarSelectionOn;
        setInsideGameState(buffGameState);
    }
    // notifications to update the gamestate websocket //////////////////////////////
    useEffect(() => {
        getMyPlayer();
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "startAvatarSelection")
                updateIfAvatarSelectionStarted(notification);
        });
    }, []);
    //-----------------------------------------------------------------------------//
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.log("onConnectionOrReaload", error); });
            console.log("getGameState", result);
            if (result !== undefined)
                setInsideGameState(result);
        });
    }
    function toggleChat() {
        setIsChatShown(!isChatShown);
        if (!isChatShown)
            setNewMessageAlert(false);
    }
    useEffect(() => {
        muteSound("GlobalAmbiance");
        getGameState();
    }, []);
    useEffect(() => {
        if (insideGameState.avatarSelectionOn)
            navigate('/AvatarCompositionPage');
    }, [insideGameState]);
    return (_jsxs("div", Object.assign({ className: "flex fixed flex-col justify-start items-center h-screen w-screen", style: {
            paddingTop: "50px",
            paddingLeft: "40px",
            paddingRight: "40px",
        } }, { children: [_jsxs("div", Object.assign({ className: "flex gap-[50px] justify-between flex-col h-full relative pb-[10px]" }, { children: [_jsx("div", { className: "flex dilemeLogo", style: {
                            backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            minHeight: 166,
                            minWidth: 420,
                            maxHeight: 166 * 2,
                            maxWidth: 420 * 2,
                            zIndex: 0,
                        } }), _jsx(SetOfButtonsPlayerComponent, { alertNewMessage: newMessageAlert, toggleChat: toggleChat, isMapModifiable: false, isOnlyConfigButton: false })] })), myPlayer &&
                _jsx(ChatComponent, { newMessageAlert: setNewMessageAlert, isChatShown: isChatShown, toggleChat: toggleChat, myPlayer: myPlayer })] })));
}
export default IntroductionPlayerPage;
