import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import { useEffect, useState } from "react";
import QuestionPointIcon from "../icons/QuestionPointIcon";
import TextCardComponent from "../TextCardComponent";
function ShowCardCorrectionComponent(props) {
    const [pathOfCard, setPathOfCard] = useState("");
    useEffect(() => {
        switch (props.card.type) {
            case ETypeCards.EVENT:
                setPathOfCard("url(/assets/UI/game_elements_images/card_images/card_event_player.png)");
                break;
            case ETypeCards.PLAISIR:
                setPathOfCard("url(/assets/UI/game_elements_images/card_images/card_pleasure_player.png)");
                break;
            case ETypeCards.QUESTION:
                setPathOfCard("url(/assets/UI/game_elements_images/card_images/card_question_player.png)");
                break;
            default:
                setPathOfCard("problem");
        }
        console.log("pathOfCard ", pathOfCard);
    }, [props.card]);
    return (_jsx("div", Object.assign({ className: "flex fixed justify-center w-full h-full", style: {
            zIndex: 0,
            backgroundColor: "rgba(12, 21, 51, 0.80)",
            height: "100vh",
            top: 0,
        } }, { children: _jsx("div", Object.assign({ className: "flex absolute items-center self-center justify-center" }, { children: _jsxs("div", Object.assign({ className: "flex relative justify-center items-center self-center textCorrection", style: {
                    minWidth: 514,
                    minHeight: 400,
                    maxHeight: 400 * 2,
                    maxWidth: 514 * 2,
                } }, { children: [_jsx("div", Object.assign({ className: "flex absolute items-center self-center", style: {
                            width: "100%",
                            height: "100%",
                            top: "calc(-22%*1.29)",
                            left: "-6.5%",
                        } }, { children: _jsx("div", Object.assign({ className: "flex relative items-center self-center card", style: {
                                minWidth: 180,
                                minHeight: 285,
                                maxHeight: 285 * 2,
                                maxWidth: 180 * 2,
                                // top: "-22cqw",
                                // left: "-38.5cqw",
                            } }, { children: _jsx("div", { className: "flex w-full h-full relative", style: {
                                    transform: "rotate(3deg)",
                                    backgroundImage: pathOfCard,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    // top: "calc(-72%*0.63)",
                                    // left: "-128.5%",
                                } }) })) })), _jsx("div", Object.assign({ className: "flex absolute items-center textCorrection", style: {
                            minWidth: 514,
                            minHeight: 400,
                            maxHeight: 400 * 2,
                            maxWidth: 514 * 2,
                            borderRadius: 15,
                            boxShadow: "-10px 10px 0px 0px rgba(0, 0, 0, 0.25)",
                            border: "2px solid #000000",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            padding: "1.4%",
                            zIndex: 2,
                            top: "-4cqw",
                        } }, { children: _jsx("div", Object.assign({ className: "flex relative textCorrection", style: {
                                width: "100%",
                                // minHeight: 386,
                                height: "100%",
                            } }, { children: _jsxs("div", Object.assign({ className: "flex absolute justify-center items-center flex-col gap-[4cqw] ", style: {
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 10,
                                    padding: "4%",
                                    gap: "4%",
                                    backgroundColor: "#6F82C0",
                                } }, { children: [_jsx("div", Object.assign({ className: "flex relative w-[10.1cqw] h-[10.1cqw]", style: {
                                            width: "10.1%",
                                        } }, { children: _jsx(QuestionPointIcon, { color: "white" }) })), _jsx("div", Object.assign({ className: "flex relative font-kalam fontTitleCardCorrection", style: {
                                            // fontSize: "4cqw",
                                            fontWeight: 700,
                                            color: "rgba(255, 255, 255, 1)",
                                            // lineHeight: "4cqw",
                                        } }, { children: props.card.title })), _jsxs("div", Object.assign({ className: "flex relative font-kalam flex-col gap-[10px] fontTextCardCorrection", style: {
                                            // fontSize: "2.42cqw",
                                            fontWeight: 400,
                                            color: "rgba(255, 255, 255, 1)",
                                            // lineHeight: "2.83cqw",
                                        } }, { children: [_jsx(TextCardComponent, { text: props.card.textAnimator }, "TextCardComponent animator"), props.card.comment === "" ? _jsx("div", {}) : "Commentaires :", _jsx(TextCardComponent, { text: props.card.comment }, "TextCardComponent comment")] }))] })) })) }))] })) })) })));
}
export default ShowCardCorrectionComponent;
