var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import WebcamComponent from "../../components/animator_components/WebcamComponent";
import PeopleWaitingTeamComponent from "../../components/animator_components/PeopleWaitingTeamComponent";
import ETeam from "cresus-common/dist/dto/ETeam";
import InformationPreGameComponent from "../../components/animator_components/InformationPreGameComponent";
import TeamSelectedComponement from "../../components/animator_components/TeamSelectedComponent";
import FunctionalButton from "../../components/FunctionalButton";
import TeamDisplayComponent from "../../components/animator_components/TeamDisplayComponent";
import { useNavigate } from "react-router-dom";
import useSessionContext from "../../tools/useSessionContext";
import { useBoardGame } from "../../contexts/BoardGameProvider";
import ChatAnimatorComponent from "../../components/animator_components/ChatAnimatorComponent";
function TeamSelectionPage() {
    var _a, _b, _c, _d;
    console.log("RENDERING TeamSelectionPage");
    const [forceRender, setForceRender] = useState(false);
    const { muteSound } = useBoardGame();
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const navigate = useNavigate();
    const [insideGameState, setInsideGameState] = useState(gameState);
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);
    const [chatOn, setChatOn] = useState(false);
    const [paramOn, setParamOn] = useState(false);
    const [counter, setCounter] = useState(0); //15);
    const [counterEnded, setCounterEnded] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.log("onConnectionOrReaload", error); });
            console.log("getGameState", result);
            if (result !== undefined) {
                setInsideGameState(result);
            }
        });
    }
    // notifications to update the gamestate websocket //////////////////////////////
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            console.log("RECEIVED notification", notification.data);
            if (notification.method === "avatarComposition") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "updateGameState") {
                setInsideGameState(notification.data);
            }
        });
    }, []);
    function onTeamSelectionChange(player, team) {
        return __awaiter(this, void 0, void 0, function* () {
            const gameStateDTO = yield sendRequest("onTeamCompositionChange", { player: player, team: team });
            console.log("CLICKED ON TEAM", gameStateDTO);
            setInsideGameState(gameStateDTO);
            setForceRender(!forceRender);
        });
    }
    function onClickTakePlayerFromTeam(player) {
        return __awaiter(this, void 0, void 0, function* () {
            const gameStateDTO = yield sendRequest("onTeamCompositionChange", { player: player, team: ETeam.NONE });
            if (gameStateDTO !== undefined) {
                setInsideGameState(gameStateDTO);
            }
        });
    }
    function handleRandomTeamRepartition() {
        return __awaiter(this, void 0, void 0, function* () {
            const gameStateDTO = yield sendRequest("onRandomTeamRepartition", {});
            if (gameStateDTO !== undefined) {
                setInsideGameState(gameStateDTO);
                setForceRender(!forceRender);
            }
        });
    }
    function onStartingSession() {
        return __awaiter(this, void 0, void 0, function* () {
            const gameStateDTO = yield sendRequest("setProjectSelectionOn", {});
            if (gameState !== undefined) {
                setInsideGameState(gameStateDTO);
            }
        });
    }
    useEffect(() => {
        getGameState();
        muteSound("GlobalAmbiance");
    }, []);
    useEffect(() => {
        console.log("insideGameState", insideGameState);
        if (insideGameState.projectSelectionOn) {
            console.log("insideGameState project", insideGameState.projectSelectionOn);
            navigate('/DistributionProjectPage');
        }
    }, [insideGameState]);
    const foundRedTeam = insideGameState.teams.find(t => t.teamColor === ETeam.RED);
    const foundBlueTeam = insideGameState.teams.find(t => t.teamColor === ETeam.BLUE);
    const foundGreenTeam = insideGameState.teams.find(t => t.teamColor === ETeam.GREEN);
    const foundYellowTeam = insideGameState.teams.find(t => t.teamColor === ETeam.YELLOW);
    function handleTeamInactive(team) {
        return __awaiter(this, void 0, void 0, function* () {
            // TODO call socket (switch active state on server)
            const gameStateDTO = yield sendRequest("teamSelectionToggleTeamActive", { team: team });
            if (gameStateDTO !== undefined) {
                setInsideGameState(gameStateDTO);
            }
        });
    }
    const teamsActive = [
        (_a = foundRedTeam === null || foundRedTeam === void 0 ? void 0 : foundRedTeam.teamActive) !== null && _a !== void 0 ? _a : false,
        (_b = foundBlueTeam === null || foundBlueTeam === void 0 ? void 0 : foundBlueTeam.teamActive) !== null && _b !== void 0 ? _b : false,
        (_c = foundYellowTeam === null || foundYellowTeam === void 0 ? void 0 : foundYellowTeam.teamActive) !== null && _c !== void 0 ? _c : false,
        (_d = foundGreenTeam === null || foundGreenTeam === void 0 ? void 0 : foundGreenTeam.teamActive) !== null && _d !== void 0 ? _d : false
    ];
    const teamsFull = [
        (foundRedTeam === null || foundRedTeam === void 0 ? void 0 : foundRedTeam.players) ? (foundRedTeam === null || foundRedTeam === void 0 ? void 0 : foundRedTeam.players.length) >= 4 : false,
        (foundBlueTeam === null || foundBlueTeam === void 0 ? void 0 : foundBlueTeam.players) ? (foundBlueTeam === null || foundBlueTeam === void 0 ? void 0 : foundBlueTeam.players.length) >= 4 : false,
        (foundYellowTeam === null || foundYellowTeam === void 0 ? void 0 : foundYellowTeam.players) ? (foundYellowTeam === null || foundYellowTeam === void 0 ? void 0 : foundYellowTeam.players.length) >= 4 : false,
        (foundGreenTeam === null || foundGreenTeam === void 0 ? void 0 : foundGreenTeam.players) ? (foundGreenTeam === null || foundGreenTeam === void 0 ? void 0 : foundGreenTeam.players.length) >= 4 : false
    ];
    const currentActiveTeams = insideGameState.teams.filter(t => t.teamActive);
    const noEmptyTeamActive = currentActiveTeams.every(t => t.players.length > 0);
    const atLeastTwoTeams = currentActiveTeams.length > 1;
    const noPlayerInLobby = insideGameState.lobbyPlayers.length === 0;
    const isReadyToStart = noEmptyTeamActive && atLeastTwoTeams && noPlayerInLobby;
    return (_jsxs("div", Object.assign({ className: "absolute w-screen flex h-screen bg-dilemmeSimplePurple" }, { children: [_jsx("div", Object.assign({ className: "flex absolute", style: { zIndex: chatOn ? 1000 : -1 } }, { children: _jsx(ChatAnimatorComponent, { newMessageAlert: setNewMessageAlert, isChatShown: chatOn, toggleChat: () => { setChatOn(!chatOn); setNewMessageAlert(false); } }) })), _jsxs("div", Object.assign({ className: "flex h-full flex-col", style: {
                    backgroundColor: "rgba(0, 0, 0, 0.50)",
                    width: "25%",
                    minWidth: "256px",
                    zIndex: 2,
                } }, { children: [_jsxs("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                            paddingLeft: "20px",
                            paddingTop: "33px",
                            height: "91px",
                            minHeight: "91px",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        } }, { children: [insideGameState.lobbyPlayers.length, " joueurs dans ", _jsx("br", {}), " la salle d'attente"] })), _jsx("div", Object.assign({ className: "flex relative", style: {
                            paddingTop: "20px",
                            paddingRight: "10px",
                            height: "100%",
                            minHeight: "305px",
                            borderTop: "1px solid rgba(255, 255, 255, 0.20)",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                        } }, { children: _jsx("div", Object.assign({ className: "flex relative overflow-y-auto w-full flex-col", style: {
                                paddingLeft: "20px",
                                paddingRight: "8px",
                                height: "100%",
                                gap: "5px",
                            } }, { children: insideGameState.lobbyPlayers.map((person, index) => {
                                // const realPlayerDTO = insideGameState.lobbyPlayers.find((player) => player.name === person.name);
                                return _jsx(PeopleWaitingTeamComponent, { onTeamSelectionChange: onTeamSelectionChange, playerDTO: person, teamsActive: teamsActive, teamsFull: teamsFull }, "TeamSelectionPage" + person.name + index);
                            }) })) })), _jsx(WebcamComponent, { isCommunicationActive: true, isThereNotification: newMessageAlert, newMessageAlert: setNewMessageAlert, isChatActive: true, cameraOn: cameraOn, setCameraOn: setCameraOn, micOn: micOn, setMicOn: setMicOn, chatOn: chatOn, setChatOn: setChatOn, paramOn: paramOn, setParamOn: setParamOn })] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-start h-full w-full", style: {
                    paddingLeft: "50px",
                    paddingRight: "50px",
                } }, { children: [_jsx(InformationPreGameComponent, { buttonActive: isReadyToStart, textButton: "Lancer la session", onClickButton: onStartingSession, sessionName: insideGameState.animatorSessionName, counterEnded: counterEnded, counter: counter }), _jsx("div", Object.assign({ className: "flex flex-row w-full" }, { children: _jsxs("div", Object.assign({ className: " flex flex-row justify-between w-full h-auto", style: {
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                height: "68px", // maybe to change
                            } }, { children: [_jsxs("div", Object.assign({ className: "flex items-start", style: { zIndex: 100 } }, { children: [_jsx("div", Object.assign({ className: "flex flex-nowrap text-white font-roboto h-full", style: {
                                                fontSize: "24px",
                                                fontWeight: "700",
                                                lineHeight: "28px",
                                                paddingRight: "10px",
                                            } }, { children: "\u00C9quipe actives :" })), _jsx(TeamSelectedComponement, { handleTeamInactive: handleTeamInactive, teamsSelected: teamsActive })] })), _jsx("div", Object.assign({ style: { zIndex: 100 } }, { children: _jsx(FunctionalButton, { onClick: handleRandomTeamRepartition, text: "R\u00E9partir al\u00E9atoirement", colorMode: 1, isRound: true }) }))] })) })), _jsxs("div", Object.assign({ className: "flex flex-row ", style: {
                            paddingTop: "20px",
                            gap: "20px",
                        } }, { children: [foundRedTeam &&
                                _jsx(TeamDisplayComponent, { onClickTakePlayerFromTeam: onClickTakePlayerFromTeam, isActive: foundRedTeam.teamActive, team: foundRedTeam, insideGameState: insideGameState }, "team red"), foundBlueTeam &&
                                _jsx(TeamDisplayComponent, { onClickTakePlayerFromTeam: onClickTakePlayerFromTeam, isActive: foundBlueTeam.teamActive, team: foundBlueTeam, insideGameState: insideGameState }, "team blue"), foundYellowTeam &&
                                _jsx(TeamDisplayComponent, { onClickTakePlayerFromTeam: onClickTakePlayerFromTeam, isActive: foundYellowTeam.teamActive, team: foundYellowTeam, insideGameState: insideGameState }, "team Yellow"), foundGreenTeam &&
                                _jsx(TeamDisplayComponent, { onClickTakePlayerFromTeam: onClickTakePlayerFromTeam, isActive: foundGreenTeam.teamActive, team: foundGreenTeam, insideGameState: insideGameState }, "team Green")] }))] }))] })));
}
export default TeamSelectionPage;
