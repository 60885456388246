import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import { TeamToName } from "cresus-common/dist/utils/ColorsSwitch";
const getTeamColor = (teamColor) => {
    switch (teamColor) {
        case ETeam.BLUE:
            return "bleue";
        case ETeam.GREEN:
            return "verte";
        case ETeam.YELLOW:
            return "jaune";
        case ETeam.RED:
            return "rouge";
        default:
            console.log("error not a team");
            return "error";
    }
};
const getColor = (teamColor) => {
    switch (teamColor) {
        case ETeam.BLUE:
            return "#106EDD";
        case ETeam.GREEN:
            return "#24BD4F";
        case ETeam.YELLOW:
            return "#FFD53F";
        case ETeam.RED:
            return "#DD2810";
        default:
            console.log("error not a team");
            return "error";
    }
};
function chooseCigogne(teamColor) {
    switch (teamColor) {
        case ETeam.RED:
            return "url(./assets/UI/cigognes_teams_images/cigogne_with_team_RED.png)";
        case ETeam.BLUE:
            return "url(./assets/UI/cigognes_teams_images/cigogne_with_team_BLUE.png)";
        case ETeam.YELLOW:
            return "url(./assets/UI/cigognes_teams_images/cigogne_with_team_YELLOW.png)";
        case ETeam.GREEN:
            return "url(./assets/UI/cigognes_teams_images/cigogne_with_team_GREEN.png)";
        default:
            return "url(./assets/UI/cigognes_teams_images/cigogne_with_team_RED.png)";
    }
}
function TeamTurnComponement({ teamColor, capitain }) {
    return (_jsxs("div", Object.assign({ className: "fixed left-1/2 text-white right-1/2 teamTurn top-2.5 -translate-x-1/2 font-kalam text-xs flex ", style: {
            minWidth: 340,
            minHeight: 50,
            maxHeight: 50 * 2,
            maxWidth: 340 * 2,
            backgroundImage: "url(./assets/UI/bulle.png)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
            zIndex: 6
        } }, { children: [_jsx("div", { className: "flex relative", style: {
                    marginTop: "0.5%",
                    width: "37%",
                    height: "calc(37% * 6.8)",
                    backgroundImage: chooseCigogne(teamColor),
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: "calc(1% * -6.8)",
                    zIndex: 2,
                    transform: "scaleX(-1)",
                    // bottom: "14px"
                } }), _jsx("div", Object.assign({ className: "flex relative w-full flex-wrap fontTeamTurn", style: {
                    marginTop: "2.4%",
                    paddingRight: "4.7%",
                    zIndex: 3,
                    // fontSize: "3.5cqw",
                    // lineHeight: "4.1cqw",
                } }, { children: _jsxs("span", Object.assign({ className: "flex flex-wrap w-auto flex-row" }, { children: [_jsxs("div", Object.assign({ style: { color: getColor(teamColor) } }, { children: ["C\u2019est au tour de l\u2019\u00E9quipe  ", _jsxs("b", { children: [" ", TeamToName(teamColor), "."] })] })), _jsx("b", Object.assign({ className: "ml-1 mr-1" }, { children: capitain !== null && capitain !== void 0 ? capitain : "" })), " est le capitaine d\u2019\u00E9quipe pour le tour."] })) }))] })));
}
export default TeamTurnComponement;
