import ETeam from "cresus-common/dist/dto/ETeam";
function switchToTeamName(team) {
    switch (team) {
        case ETeam.BLUE:
            return "bleue";
        case ETeam.GREEN:
            return "verte";
        case ETeam.YELLOW:
            return "jaune";
        case ETeam.RED:
            return "rouge";
        default:
            console.log("error not a team");
            return "error";
    }
}
export default switchToTeamName;
