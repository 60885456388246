"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ECharge;
(function (ECharge) {
    ECharge[ECharge["LOGEMENT"] = 0] = "LOGEMENT";
    ECharge[ECharge["ELECTRICITE"] = 1] = "ELECTRICITE";
    ECharge[ECharge["TELEPHONE"] = 2] = "TELEPHONE";
    ECharge[ECharge["ASSURANCE_LOGEMENT"] = 3] = "ASSURANCE_LOGEMENT";
    ECharge[ECharge["ALIMENTATION_FRAIS"] = 4] = "ALIMENTATION_FRAIS";
    ECharge[ECharge["ALIMENTATION_AUTRE"] = 5] = "ALIMENTATION_AUTRE";
    ECharge[ECharge["ESSENCE"] = 6] = "ESSENCE";
    ECharge[ECharge["AUTRES"] = 7] = "AUTRES";
    ECharge[ECharge["MUTUELLE"] = 8] = "MUTUELLE";
    ECharge[ECharge["ASSURANCE_VOITURE"] = 9] = "ASSURANCE_VOITURE";
    ECharge[ECharge["OTHER_CREATED_CHARGE"] = 10] = "OTHER_CREATED_CHARGE";
})(ECharge || (ECharge = {}));
exports.default = ECharge;
