export function slerpAngle(startAngle, endAngle, t) {
    // calculate the shortest distance between the two angles
    let distance = endAngle - startAngle;
    if (Math.abs(distance) > Math.PI) {
        // wrap around
        if (distance > 0) {
            distance = distance - 2 * Math.PI;
        }
        else {
            distance = distance + 2 * Math.PI;
        }
    }
    // perform the slerp using the shortest distance
    let resultAngle = startAngle + distance * t;
    return resultAngle;
}
