"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ECardHandler;
(function (ECardHandler) {
    ECardHandler[ECardHandler["NO_ACTION_REQUIRED"] = 0] = "NO_ACTION_REQUIRED";
    ECardHandler[ECardHandler["MUST_CHOOSE_CARD"] = 1] = "MUST_CHOOSE_CARD";
    ECardHandler[ECardHandler["MUST_CORRECT_CARD"] = 2] = "MUST_CORRECT_CARD";
    ECardHandler[ECardHandler["CARD_HANDLED"] = 3] = "CARD_HANDLED";
})(ECardHandler || (ECardHandler = {}));
exports.default = ECardHandler;
