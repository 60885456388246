import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import FunctionalButton from "../FunctionalButton";
import AccessIcon from "../icons/AccessIcon";
function InformationPreGameComponent(props) {
    const [counter, setCounter] = useState(props.counter);
    const [counterEnded, setCounterEnded] = useState(props.counterEnded);
    let timer;
    function updateCounter() {
        if (counterEnded) {
            clearInterval(timer);
            return;
        }
        timer = setInterval(() => {
            setCounter(prevCounter => prevCounter - 1);
            console.log(counter);
            if (counter === 0) {
                setCounterEnded(true);
                clearInterval(timer);
            }
        }, 1000);
    }
    function secondsToHms(s) {
        let minuteString = "";
        let secondString = "";
        let m = Math.floor(s % 3600 / 60);
        let s1 = Math.floor(s % 3600 % 60);
        if (s1 < 10)
            secondString = `0${s1}`;
        else
            secondString = `${s1}`;
        if (m < 10)
            minuteString = `0${m}`;
        else
            minuteString = `${m}`;
        return `${minuteString}:${secondString}`;
    }
    useEffect(() => {
        updateCounter();
        console.log('counter', counter);
        return () => clearInterval(timer);
    }, [counter]);
    return (_jsxs("div", Object.assign({ className: "flex flex-col w-full", style: {
            minHeight: "91px",
            height: "91px",
        } }, { children: [_jsxs("div", Object.assign({ className: "flex h-[89px] w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-white", style: {
                            fontSize: "30px",
                            fontWeight: "900",
                            textTransform: "uppercase",
                            lineHeight: "35px",
                            fontStyle: "normal",
                        } }, { children: props.sessionName })), counterEnded && counterEnded !== undefined &&
                        _jsx("div", Object.assign({ style: {
                                border: "2px solid #000000",
                                zIndex: 100
                            } }, { children: _jsx(FunctionalButton, { onClick: props.onClickButton, enabled: props.buttonActive, text: props.textButton, colorMode: 0, isRound: false, icon: AccessIcon, showLeftIcon: true }) })), !counterEnded && counterEnded !== undefined &&
                        _jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-white self-center text-end", style: {
                                        width: "73px",
                                        minWidth: "73px",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        lineHeight: "14px",
                                    } }, { children: "D\u00E9but de la session dans" })), _jsx("div", Object.assign({ className: "flex font-roboto text-white", style: {
                                        fontSize: "36px",
                                        fontWeight: "700",
                                        lineHeight: "42px",
                                    } }, { children: secondsToHms(counter) }))] }))] })), _jsx("img", { src: "/assets/UI/line.png", alt: "", className: "flex w-full h-[2px]" })] })));
}
export default InformationPreGameComponent;
