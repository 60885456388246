var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ChargesNotebookComponent from "../../components/player_game_element_component/ChargesNotebookComponent";
import MoneyAndPleasureInfoComponent from "../../components/player_game_element_component/MoneyAndPleausreInfoComponent";
import MyTeamDisplayComponent from "../../components/player_game_element_component/MyTeamDisplayComponent";
import SetOfButtonsPlayerComponent from "../../components/player_game_element_component/SetOfButtonsPlayerComponent";
import ChatComponent from "../../components/ChatComponent";
import { useBoardGame } from "../../contexts/BoardGameProvider";
import useSessionContext from "../../tools/useSessionContext";
import { getDefaultGameStateDTO } from "cresus-common/dist/dto/GameStateDTO";
import ETeam from "cresus-common/dist/dto/ETeam";
import ThrowDiceComponent from "../../components/player_game_element_component/ThrowDiceComponent";
import ETurn from "cresus-common/dist/dto/ETurn";
import ShowCardComponent from "../../components/player_game_element_component/ShowCardComponent";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import ECategoriesCards from "cresus-common/dist/dto/ECategoriesCards";
import ECardStateGame from "cresus-common/dist/dto/ECardStateGame";
import ECardHandler from "cresus-common/dist/dto/ECardHandler";
import ShowCardCorrectionComponent from "../../components/player_game_element_component/ShownCardCorrection";
import { CameraMode } from "../../components/3D/CameraMode";
import TeamTurnComponement from "../../components/player_game_element_component/TeamTurnComponent";
import TeamsIngameComponent from "../../components/TeamsIngameComponent";
import ESlotType from "cresus-common/dist/dto/ESlotType";
import WaitingDrawCardComponent from "../../components/animator_components/game_components/WaitingDrawCardComponent";
import { deepCopy } from "cresus-common/dist/utils/CopyUtils";
import PedagogicContentPlayer from "../../components/player_game_element_component/PedagogicContentPlayer";
import RecapEndOfSessionPlayerPage from "./RecapEndOfSessionPlayerPage";
import FinalPage from "../FinalPage";
import useMedia from "../../contexts/useMedia";
const cardInfo1 = {
    cardName: "pneu à changer",
    effect1: {
        price: 70,
        mensual: false,
    },
};
const cardInfo2 = {
    cardName: "fuite d'eau",
    effect1: {
        price: 20,
        mensual: true,
    },
    effect2: {
        price: 100,
        mensual: false,
    }
};
const mockDataCardEvent = {
    id: 0,
    reference: "DECK-BASE",
    title: "Lave-linge en panne",
    type: ETypeCards.EVENT,
    category: ECategoriesCards.DEPENSE,
    difficulty: 0,
    comment: "test",
    short_description: "test",
    typeSpecificContent: "test",
    version: 1,
    textPlayer: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
    textAnimator: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
    is_kept_card: true,
    isAnswerCorrect: false,
    isSelected: false
};
const defaultPlayerDTO = {
    avatarComposition: {
        bonus: 0,
        eyes: 0,
        hair: 0,
        hair_color: 0,
        mouth: 0,
        nose: 0,
        skin: 0,
        teamColor: ETeam.NONE,
    },
    name: "",
    capitain: false,
    team: ETeam.NONE,
    micOn: false,
    sessionPeerId: "undefined",
    isConnected: true,
};
function GameInProgressPage() {
    var _a;
    const { moveCamera, movePawn, showDiceRoll, activatePawn, updateCameraTarget, playSound, muteSound } = useBoardGame();
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const { setCameraMode, audioVideoClient } = useMedia();
    const [isChatShown, setIsChatShown] = useState(false);
    const [isNotebookShown, setIsNotebookShown] = useState(false);
    const [myPlayer, setMyPlayer] = useState(deepCopy(defaultPlayerDTO));
    const [insideGameState, setInsideGameState] = useState(deepCopy(getDefaultGameStateDTO()));
    const [isThrowDiceComponentActive, setIsThrowDiceComponentActive] = useState(false);
    const [isCardTextShown, setIsCardTextShown] = useState(false);
    const [isCardCorrectionShown, setIsCardCorrectionShown] = useState(false);
    const [isCardBeingDrawn, setIsCardBeingDrawn] = useState(false);
    const [isPedagogicContentShown, setIsPedagogicContentShown] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    useEffect(() => {
        setCameraMode(true, false, false);
        muteSound("GlobalAmbiance");
    }, []);
    function updatePawnPositions(gameStateDTO) {
        gameStateDTO.teams.forEach((team) => {
            movePawn(team.teamColor, team.slotTeam.slotAbsoluteNumber, true);
        });
    }
    useEffect(() => {
        updateCameraTarget(ETeam.RED); // We update to any pawn as they are all at the same position in the beginning
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            try {
                console.log("GameInProgressnotification", notification);
                if (notification.method === "playSound") {
                    playSound(notification.data.sound);
                }
                if (notification.method === "applicationOfEffects" ||
                    notification.method === "nextTurn" ||
                    notification.method === "makeTeamThrowDiceTurn" ||
                    notification.method === "endGame" ||
                    notification.method === "payChargeByAnimator" ||
                    notification.method === "correctQuestionCard" ||
                    notification.method === "payChargeByAnimator" ||
                    notification.method === "pickNewCards" ||
                    notification.method === "chooseCard" ||
                    notification.method === "deleteCharge" ||
                    notification.method === "makeOneTeamsThrowDicesEvent" ||
                    notification.method === "makeNewEventThrow" ||
                    notification.method === "payChargeByPlayer" ||
                    notification.method === "deleteCard") {
                    const gameStateDTO = notification.data;
                    if (gameStateDTO !== undefined) {
                        setInsideGameState(gameStateDTO);
                        updatePawnPositions(gameStateDTO);
                    }
                }
                if (notification.method === "makeTeamsThrowDicesEvent") {
                    if (Boolean(notification.data)) {
                        setInsideGameState(notification.data);
                    }
                }
                if (notification.method === "updateGameState") {
                    if (Boolean(notification.data)) {
                        setInsideGameState(notification.data);
                    }
                }
                if (notification.method === "endSession") {
                    setCameraMode(false, false, false);
                    audioVideoClient.muteMic();
                    if (Boolean(notification.data)) {
                        setInsideGameState(notification.data);
                    }
                }
            }
            catch (e) {
                console.error(e);
            }
        });
    }, []);
    useEffect(() => {
        if (insideGameState.isThereThrownEvent) {
            const teamsRolling = insideGameState.teams
                .filter(t => t.dicesEvent.ShouldDiceBeRolled)
                .map(t => t.teamColor);
            const diceRollConfig = {
                currentTeam: myTeam === null || myTeam === void 0 ? void 0 : myTeam.teamColor,
                closeOnRolled: false,
                diceEvent: new Map(),
                isInteractable: myPlayer.capitain,
            };
            insideGameState.teams.filter(t => t.dicesEvent.ShouldDiceBeRolled).forEach(team => {
                diceRollConfig.diceEvent.set(team.teamColor, team.dicesEvent);
            });
            showDiceRoll(diceRollConfig, (team) => __awaiter(this, void 0, void 0, function* () {
                sendRequest("makeOneTeamThrowDicesEvent", { teamToThrow: team });
            }));
        }
        if (!(insideGameState.isThereThrownEvent || !!(myTeam === null || myTeam === void 0 ? void 0 : myTeam.dicesTurn.diceArray)))
            showDiceRoll(undefined, (team) => __awaiter(this, void 0, void 0, function* () { }));
        // console.log("INITIALIZE GAMEINPROGRESS", insideGameState.teams);
        insideGameState.teams.forEach((team) => {
            activatePawn(team.teamColor);
        });
    });
    useEffect(() => {
        if (!insideGameState.isThereThrownEvent)
            showDiceRoll(undefined, (team) => __awaiter(this, void 0, void 0, function* () { }));
    }, [insideGameState.isThereThrownEvent]);
    // console.log("RENDERING GAMEINPROGRESS", insideGameState.teams);
    const myTeam = insideGameState.teams.find((team) => {
        return team.players.find((player) => player.name === myPlayer.name);
    });
    const teamToPlay = insideGameState.teams.find((team) => team.turnState !== ETurn.NO_ACTION_REQUIRED);
    function handleClickToShowNotebook() {
        setCameraMode(true, isNotebookShown, isChatShown);
        setIsNotebookShown(!isNotebookShown);
    }
    function updatePawnPositionsWithoutAnimation(gameStateDTO) {
        console.log("updatePawnPositionsWithoutAnimation", gameStateDTO);
        if (Boolean(gameStateDTO)) {
            gameStateDTO.teams.forEach((team) => {
                movePawn(team.teamColor, team.slotTeam.slotAbsoluteNumber, false);
            });
        }
    }
    function getFirstGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState }) // TODO no need to send the gameState
                .catch((error) => { console.error(error); });
            if (Boolean(result)) {
                setInsideGameState(result);
                updatePawnPositionsWithoutAnimation(result);
            }
        });
    }
    function getMyPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getMyPlayer");
            const result = yield sendRequest("getMyPlayer", { gameState: gameState })
                .catch((error) => { console.error("getMyPlayer", error); });
            if (result !== undefined)
                setMyPlayer(result);
            else
                console.warn("probleme with the data");
        });
    }
    function hanldeTogleChat() {
        let chatState = isChatShown;
        setIsChatShown(!isChatShown);
        console.log("chatState", chatState);
        if (!chatState)
            setNewMessageAlert(false);
    }
    function handleThrowDice() {
        return __awaiter(this, void 0, void 0, function* () {
            if (myTeam) {
                setIsThrowDiceComponentActive(false);
                const diceRollResult = yield sendRequest("requestRollDice", { team: myTeam.teamColor, diceCount: 2 })
                    .catch(error => console.error("requestRollDice", error));
                console.log("throwing dice", diceRollResult.diceResults);
                const rollDiceConfig = {
                    currentTeam: myTeam.teamColor,
                    closeOnRolled: true,
                    diceEvent: new Map(),
                    isInteractable: true, // This is already only displayed for the captain
                };
                const diceEvent = {
                    dices: diceRollResult.diceResults,
                    ShouldDiceBeRolled: false,
                    AreDicesRolled: false
                };
                rollDiceConfig.diceEvent.set(myTeam.teamColor, diceEvent);
                showDiceRoll(rollDiceConfig, (team) => __awaiter(this, void 0, void 0, function* () {
                    const result = yield sendRequest("makeTeamThrowDiceTurn", {
                        team: myTeam.teamColor,
                        diceResults: diceRollResult.diceResults
                    }).catch(error => console.error("makeTeamThrowDiceTurn", error));
                }));
            }
        });
    }
    useEffect(() => {
        moveCamera(CameraMode.ALL_MAP);
        getFirstGameState();
        getMyPlayer();
    }, []);
    useEffect(() => {
        getMyPlayer();
        const buffTeamsInGame = insideGameState.teams;
        let handleCardShown = false;
        let handleCardCorrection = false;
        buffTeamsInGame.forEach((team) => {
            if (team.turnState === ETurn.HANDLE_DICE_RESULT && team.cardState === ECardStateGame.CARDS_CHOSEN && team.cardHandlerEnum === ECardHandler.MUST_CORRECT_CARD && team.cardSelected && team.cardSelected.type === ETypeCards.QUESTION) {
                handleCardShown = true;
                handleCardCorrection = false;
            }
            else if (team.turnState === ETurn.HANDLE_NEXT_TURN && team.cardState === ECardStateGame.CARDS_CHOSEN && team.cardHandlerEnum === ECardHandler.CARD_HANDLED && team.cardSelected && team.cardSelected.type === ETypeCards.QUESTION) {
                handleCardShown = false;
                handleCardCorrection = true;
            }
            else if (team.turnState === ETurn.HANDLE_NEXT_TURN && team.cardState === ECardStateGame.CARDS_CHOSEN && team.cardHandlerEnum === ECardHandler.CARD_HANDLED && team.cardSelected && team.cardSelected.type !== ETypeCards.QUESTION) {
                handleCardShown = true;
                handleCardCorrection = false;
            }
            else {
                handleCardShown = false;
                handleCardCorrection = false;
            }
        });
        setIsCardTextShown(handleCardShown);
        setIsCardCorrectionShown(handleCardCorrection);
        console.log("insideGameState", insideGameState);
    }, [insideGameState]);
    useEffect(() => {
        if (teamToPlay) {
            if (teamToPlay.turnState === ETurn.HANDLE_DICE_RESULT && teamToPlay.cardState === ECardStateGame.CARDS_CHOSEN && teamToPlay.cardHandlerEnum === ECardHandler.MUST_CORRECT_CARD && teamToPlay.cardSelected && teamToPlay.cardSelected.type === ETypeCards.QUESTION) {
                setIsCardTextShown(true);
                setIsCardCorrectionShown(false);
            }
            else if (teamToPlay.turnState === ETurn.HANDLE_NEXT_TURN && teamToPlay.cardState === ECardStateGame.CARDS_CHOSEN && teamToPlay.cardHandlerEnum === ECardHandler.CARD_HANDLED && teamToPlay.cardSelected && teamToPlay.cardSelected.type === ETypeCards.QUESTION) {
                setIsCardTextShown(false);
                setIsCardCorrectionShown(true);
            }
            else if (teamToPlay.turnState === ETurn.HANDLE_NEXT_TURN && teamToPlay.cardState === ECardStateGame.CARDS_CHOSEN && teamToPlay.cardHandlerEnum === ECardHandler.CARD_HANDLED && teamToPlay.cardSelected && teamToPlay.cardSelected.type !== ETypeCards.QUESTION) {
                setIsCardTextShown(true);
                setIsCardCorrectionShown(false);
            }
            else {
                setIsCardTextShown(false);
                setIsCardCorrectionShown(false);
            }
        }
    }, [insideGameState]);
    useEffect(() => {
        if (myTeam && myTeam.turnState === ETurn.MUST_ROLL_DICE) {
            // setTimeout(() => {
            setIsThrowDiceComponentActive(true);
            // }, 3000);
        }
        else {
            setIsThrowDiceComponentActive(false);
        }
        if (myTeam && myTeam.turnState === ETurn.HANDLE_DICE_RESULT && myTeam.cardHandlerEnum === ECardHandler.MUST_CHOOSE_CARD && (myTeam.slotTeam.slotType === ESlotType.QUESTION || myTeam.slotTeam.slotType === ESlotType.PLAISIR || myTeam.slotTeam.slotType === ESlotType.DILEMME)) {
            setTimeout(() => {
                setIsCardBeingDrawn(true);
            }, 3000);
        }
        else {
            setIsCardBeingDrawn(false);
        }
    }, [myTeam]);
    function getCurrentTeamColor() {
        var _a, _b;
        let gs = (_a = gameState.teams.find(team => team.turnState !== ETurn.NO_ACTION_REQUIRED)) === null || _a === void 0 ? void 0 : _a.teamColor;
        // console.log("CURTEAM1", gs);
        if (gs)
            return gs;
        let inside = (_b = insideGameState.teams.find(team => team.turnState !== ETurn.NO_ACTION_REQUIRED)) === null || _b === void 0 ? void 0 : _b.teamColor;
        // console.log("CURTEAM2", inside);
        if (inside)
            return inside;
        return ETeam.RED;
    }
    function toggleView(cameraMode) {
        moveCamera(cameraMode);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-center h-screen w-screen", style: {
            paddingTop: insideGameState.sessionClosed ? "0px" : "50px",
            paddingLeft: "40px",
            paddingRight: "40px",
        } }, { children: [insideGameState.gameEnded && insideGameState.sessionClosed &&
                _jsx(FinalPage, {}), insideGameState && insideGameState.gameEnded && !insideGameState.sessionClosed &&
                _jsx(RecapEndOfSessionPlayerPage, { gameState: insideGameState }), insideGameState && !insideGameState.gameEnded &&
                _jsx(TeamsIngameComponent, { teamsInGame: insideGameState.teams, myTeam: myTeam ? myTeam.teamColor : ETeam.NONE }), insideGameState && !insideGameState.gameEnded &&
                _jsx(TeamTurnComponement, { capitain: (_a = teamToPlay === null || teamToPlay === void 0 ? void 0 : teamToPlay.players.find(p => p.capitain)) === null || _a === void 0 ? void 0 : _a.name, teamColor: getCurrentTeamColor() }), _jsx(PedagogicContentPlayer, { setIsActive: setIsPedagogicContentShown }), myPlayer &&
                _jsx(ChatComponent, { newMessageAlert: setNewMessageAlert, myPlayer: myPlayer, isChatShown: isChatShown, toggleChat: hanldeTogleChat }, "ChatComponent"), !insideGameState.sessionClosed &&
                _jsx(SetOfButtonsPlayerComponent, { alertNewMessage: newMessageAlert, onToggleView: toggleView, toggleChat: hanldeTogleChat, isMapModifiable: true, isOnlyConfigButton: false }), insideGameState && !insideGameState.gameEnded && myTeam && myTeam.incomes && myTeam.charges && myTeam.project && myPlayer &&
                _jsx(ChargesNotebookComponent, { isDoubleOn: myTeam.dicesTurn.shouldReRollDices, isCaptain: myPlayer.capitain, cards: myTeam.cards, savings: myTeam.savings, numberOfMonthPlayed: Math.floor(myTeam.slotTeam.slotAbsoluteNumber / 38), turnState: myTeam.turnState, team: myTeam.teamColor, setInsideGameState: setInsideGameState, incomes: myTeam.incomes, charges: myTeam.charges, project: myTeam.project, isProjectDistributed: true, handleCLick: handleClickToShowNotebook }), insideGameState && !insideGameState.gameEnded && myTeam && myTeam.money && myTeam.pleasurePoints &&
                _jsx(MoneyAndPleasureInfoComponent, { money: myTeam.money, pleasurePoints: myTeam.pleasurePoints }), insideGameState && !insideGameState.gameEnded && isThrowDiceComponentActive && myPlayer &&
                _jsx(ThrowDiceComponent, { isCaptain: myPlayer.capitain, onClickThrowDices: handleThrowDice }), insideGameState && !insideGameState.gameEnded &&
                isCardBeingDrawn && myPlayer && myTeam && !isCardTextShown &&
                _jsx(WaitingDrawCardComponent, { slotType: myTeam.slotTeam.slotType }), insideGameState && !insideGameState.gameEnded && isCardTextShown && myPlayer &&
                _jsx(ShowCardComponent, { isThereThrowEvent: insideGameState.isThereThrownEvent, card: teamToPlay && teamToPlay.cardSelected ? teamToPlay.cardSelected : mockDataCardEvent, isActive: isCardTextShown }), insideGameState && !insideGameState.gameEnded && isCardCorrectionShown && myPlayer &&
                _jsx(ShowCardCorrectionComponent, { card: teamToPlay && teamToPlay.cardSelected ? teamToPlay.cardSelected : mockDataCardEvent, isActive: isCardCorrectionShown }), myPlayer && !insideGameState.sessionClosed &&
                _jsx(MyTeamDisplayComponent, { myPlayerAvatar: myPlayer.avatarComposition, avatars: (myTeam ? myTeam.players.filter(player => player.name !== myPlayer.name)
                        .map(el => el.avatarComposition) : []), team: myTeam ? myTeam.teamColor : ETeam.NONE })] })));
}
export default GameInProgressPage;
