import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import useMedia from "../../contexts/useMedia";
function MicButtonComponent(props) {
    var _a;
    const { audioVideoClient } = useMedia();
    const [isOnHover, setIsOnHover] = useState(false);
    const [isMuted, setIsMuted] = useState((_a = audioVideoClient._producer) === null || _a === void 0 ? void 0 : _a.paused);
    useEffect(() => {
        const eventMuteChange = (isMuted) => {
            setIsMuted(isMuted);
        };
        audioVideoClient.onMuteChange.push(eventMuteChange);
        return () => {
            let indexEvent = audioVideoClient.onMuteChange.indexOf(eventMuteChange);
            audioVideoClient.onMuteChange.splice(indexEvent, 1);
        };
    });
    return (_jsx("button", { className: "config-button", onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), onClick: () => {
            // props.toggleMute();
            setIsMuted(!isMuted);
            if (isMuted)
                audioVideoClient.unmuteMic();
            else
                audioVideoClient.muteMic();
        }, style: {
            zIndex: 100,
            minWidth: 40,
            minHeight: 40,
            maxHeight: 40 * 2,
            maxWidth: 40 * 2,
            backgroundImage: `url(./assets/UI/buttons/player_buttons/mic_images/mic_${!isMuted ? "on" : "off"}_hover_${isOnHover ? "on" : "off"}.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        } }));
}
export default MicButtonComponent;
