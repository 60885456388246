import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import FunctionalButton from "../FunctionalButton";
import DiceIcon from "../icons/DiceIcon";
function ThrowDiceComponent(props) {
    const [isCaptain, setIsCaptain] = useState(props.isCaptain);
    function handleThrowDices() {
        console.log("Throwing dices");
        if (props.onClickThrowDices)
            props.onClickThrowDices();
    }
    useEffect(() => {
        setIsCaptain(props.isCaptain);
    }, [props.isCaptain]);
    return (_jsx("div", Object.assign({ className: "flex absolute justify-center items-center self-center drawCardBoard", style: {
            maxWidth: 314 * 2,
            maxHeight: 134 * 2,
            minHeight: 134,
            minWidth: 314,
        } }, { children: _jsx("div", Object.assign({ className: "flex absolute w-full h-full items-center justify-center", style: {
                // right: "50cqw",
                borderRadius: 15,
                border: "2px solid #000000",
                backgroundColor: "rgba(255, 255, 255, 1)",
                zIndex: 0,
                padding: "2.25%",
                boxShadow: "-10px 10px 0px 0px rgba(0, 0, 0, 0.25)",
            } }, { children: _jsxs("div", Object.assign({ className: "flex justify-center relative w-full h-full flex-col gap-[4cqw]", style: {
                    padding: "10%",
                    borderRadius: 10,
                    backgroundColor: "rgba(111, 130, 192, 1)",
                } }, { children: [_jsx("div", Object.assign({ className: "flex justify-center items-center text-center flex-col text-white font-kalam gap-[7cqw] fontThrowDice", style: {
                            // fontSize: "6.5cqw",
                            fontWeight: 700,
                            // lineHeight: "6.5cqw",
                            textTransform: "uppercase",
                        } }, { children: "c'est \u00E0 votre tour" })), _jsx("div", Object.assign({ className: "buttonThrowDice hover:bg-[#38487E]", style: {
                            width: "50cqw",
                            border: "2px solid #000000",
                            backgroundColor: "#54659F",
                            alignSelf: "center",
                        } }, { children: _jsx(FunctionalButton, { isFullWidth: true, fontSize: "3.8cqw", text: "Lancer les d\u00E9s", enabled: isCaptain, showLeftIcon: true, icon: DiceIcon, isRound: false, onClick: () => handleThrowDices(), colorMode: 0 }) }))] })) })) }))
    // </div >
    );
}
export default ThrowDiceComponent;
{ /* <div className="flex absolute justify-center items-center self-center"
style={{
    width: 314,
    height: 134,
    borderRadius: 15,
    border: "2px solid #000000",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 0,
}}>
    <div className="flex justify-center"
    style={{
        width: 300,
        height: 120,
        borderRadius: 10,
        backgroundColor: "rgba(111, 130, 192, 1)",
    }}>
        <div className="flex justify-center items-center flex-col text-white gap-[10px]">
            <div className="font-kalam"
            style={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px",
                textTransform: "uppercase",
            }}>
                C'est à votre tour
            </div> */
}
