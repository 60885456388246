import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
function MoneyAndPleasureInfoComponent(props) {
    return (_jsxs("div", Object.assign({ className: "flex justify-between fixed moneyAndPleasure", style: {
            top: 10,
            right: 10,
            minWidth: 240,
            minHeight: 50,
            maxWidth: 240 * 2,
            maxHeight: 50 * 2,
            zIndex: 6,
        } }, { children: [props.money &&
                _jsx("div", Object.assign({ className: "flex absolute self-center", style: {
                        // width: 140,
                        // height: 50,
                        left: 0,
                        height: "100%",
                        width: "58.33%",
                        backgroundImage: "url(./assets/UI/game_elements_images/wallet_image.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    } }, { children: _jsxs("div", Object.assign({ className: "flex relative justify-center font-kalam text-white items-center self-center text-center fontSizeMoneyAndPleasure", style: {
                            height: "100%",
                            width: "62.86%",
                            // fontSize: "8.35cqw",
                            fontStyle: "normal",
                            fontWeight: "700",
                            // lineHeight: "6.66cqw",
                        } }, { children: [props.money.amount.toString(), " \u20AC"] })) })), props.pleasurePoints &&
                _jsx("div", Object.assign({ className: "flex absolute self-center", style: {
                        width: "37.5%",
                        height: "100%",
                        right: 0,
                        backgroundImage: "url(./assets/UI/game_elements_images/pleasure_points_image.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    } }, { children: _jsx("div", Object.assign({ className: "flex justify-center font-kalam text-white items-center self-center text-center fontSizeMoneyAndPleasure", style: {
                            height: "100%",
                            width: "44.44%",
                            // fontSize: "8.35cqw",
                            fontStyle: "normal",
                            fontWeight: "700",
                            // lineHeight: "6.66cqw",
                        } }, { children: props.pleasurePoints.amount.toString() })) }))] })));
}
export default MoneyAndPleasureInfoComponent;
