import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Environment, PerformanceMonitor, PerspectiveCamera, useDepthBuffer } from '@react-three/drei';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { EffectComposer } from '@react-three/postprocessing';
import { useEffect, useRef, useState } from 'react';
import { MathUtils, Vector3 } from 'three';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { Instances, Model } from './3D/test';
import { OutlineDepthBased } from '../../posteffects/PostEffectOutline';
import { RollDiceComponent } from './RollDiceComponent';
import { CameraMode } from './CameraMode';
import ETeam from 'cresus-common/dist/dto/ETeam';
import { SceneObject } from './SceneObject';
const CAMERA_GLOBAL_POSITION = [25, 45, -10];
const CAMERA_GLOBAL_LOOKAT = [7, 0, -13];
const SHOWCASE_CAMERA_TRANSITIONS = [
    { positionA: new Vector3(10, 10, 10), positionB: new Vector3(30, 10, 10), lookAtPosition: new Vector3(10, 0, -20) },
    { positionA: new Vector3(30, 10, -20), positionB: new Vector3(10, 10, -20), lookAtPosition: new Vector3(10, 0, -10) },
];
const CAMERA_PAN_DURATION = 10.0;
// if (refDirLight.current) {
//     refDirLight.current.shadow.camera.near = 0.01;
//     refDirLight.current.shadow.camera.far = 150;
//     refDirLight.current.shadow.bias = -0.002;
// }
function SceneComponent(props) {
    console.log("RENDERING SceneComponent");
    const [forceReload, setForceReload] = useState(false);
    const depthBuffer = useDepthBuffer();
    const gltf = useLoader(GLTFLoader, '/assets/3D/SceneDilemme.gltf', undefined, onprogress => {
        console.log("SceneComponent.loadGLTF: PROGRESS", onprogress); // onprogress.loaded / onprogress.total // total not always available
    });
    console.log("SceneComponent: GLTFLOADED", gltf);
    const refCam = useRef(null);
    const refControls = useRef(null);
    const refDirLight = useRef(null);
    const refGroupScene = useRef(null); // To toggle visibility of the whole scene
    const cameraTarget = useRef(undefined);
    const pawnsTeams = useRef(new Map());
    const cameraMode = useRef(CameraMode.SHOWCASE);
    const refTime = useRef(0); // Current elapsed time in sec since the start of the game
    const refSceneObject = useRef(new SceneObject(gltf));
    function updateCameraTarget(team) {
        const pawnInfo = pawnsTeams.current.get(team);
        console.log("updateCameraTarget", team, pawnInfo);
        cameraTarget.current = pawnInfo === null || pawnInfo === void 0 ? void 0 : pawnInfo.object3D;
    }
    function updateCameraPositionIfFollowPlayer() {
        if (cameraTarget.current && refCam.current && cameraMode.current === CameraMode.FOLLOW_PLAYER) {
            const centerBoard = new Vector3(6.204843353610132, -0.3100004959106537, -19.2713630753);
            let pos = cameraTarget.current.localToWorld(new Vector3(0, 0, 0)); //.multiplyScalar(0.01);//calculatePawnPosition(1);//.multiplyScalar(0.01);
            const offset = centerBoard.clone().sub(pos).normalize().multiplyScalar(10.0);
            refCam.current.position.set(pos.x - offset.x, pos.y + 7, pos.z - offset.z);
            refCam.current.lookAt(pos);
        }
    }
    useEffect(() => {
        console.log("INIT3D useEffect GLTFLOAD");
        // if (refDirLight.current) {
        //     refDirLight.current.shadow.camera.near = 0.01;
        //     refDirLight.current.shadow.camera.far = 150;
        //     refDirLight.current.shadow.bias = -0.002;
        // }
        refSceneObject.current.onPawnLoaded.on("pawnsLoaded", (bluePawn, redPawn, greenPawn, yellowPawn) => {
            pawnsTeams.current.set(ETeam.BLUE, { object3D: bluePawn, team: ETeam.BLUE, slotIndex: 0 });
            pawnsTeams.current.set(ETeam.RED, { object3D: redPawn, team: ETeam.RED, slotIndex: 0 });
            pawnsTeams.current.set(ETeam.GREEN, { object3D: greenPawn, team: ETeam.GREEN, slotIndex: 0 });
            pawnsTeams.current.set(ETeam.YELLOW, { object3D: yellowPawn, team: ETeam.YELLOW, slotIndex: 0 });
            const pawnObj = {
                redPawn: redPawn,
                yellowPawn: yellowPawn,
                greenPawn: greenPawn,
                bluePawn: bluePawn,
            };
            if (props.onPawnsLoaded)
                props.onPawnsLoaded(pawnObj);
        });
    }, []);
    function preprocessScene() {
        refSceneObject.current.Init(depthBuffer).then(() => {
            console.log("PROGRESS3 SCENE LOADED & INIT, starting init.then()");
            let sceneWrapper = {
                playSound: (soundName) => {
                    refSceneObject.current.PlaySound(soundName);
                },
                muteSound: (soundName) => {
                    refSceneObject.current.MuteSound(soundName);
                },
                setIsAdmin: (isAdmin) => {
                    console.log("setting board anim", isAdmin, refSceneObject.current._boardGameMesh);
                    if (isAdmin) {
                        if (refSceneObject.current._boardGameMesh)
                            refSceneObject.current._boardGameMesh.material.map = refSceneObject.current.TextureBoardAnimator;
                    }
                },
                updateCameraTarget: updateCameraTarget,
                moveCamera: (cameraMode_) => {
                    console.log('moveCamera', cameraMode_, refCam.current);
                    if (refCam.current) {
                        cameraMode.current = cameraMode_;
                        switch (cameraMode_) {
                            case CameraMode.ALL_MAP: {
                                refCam.current.position.set(CAMERA_GLOBAL_POSITION[0], CAMERA_GLOBAL_POSITION[1], CAMERA_GLOBAL_POSITION[2]);
                                refCam.current.lookAt(CAMERA_GLOBAL_LOOKAT[0], CAMERA_GLOBAL_LOOKAT[1], CAMERA_GLOBAL_LOOKAT[2]);
                                break;
                            }
                            case CameraMode.FOLLOW_PLAYER: {
                                updateCameraPositionIfFollowPlayer();
                                break;
                            }
                            case CameraMode.SHOWCASE: {
                                cameraTarget.current = undefined; // If camera global
                                break;
                            }
                        }
                    }
                },
                movePawn: (pawnId, index, animated) => {
                    const pawnToMove = pawnsTeams.current.get(pawnId);
                    if (!pawnToMove)
                        throw new Error("Pawn not found");
                    const lastIndex = pawnToMove === null || pawnToMove === void 0 ? void 0 : pawnToMove.slotIndex;
                    if (index === lastIndex)
                        return;
                    console.log("MOVEPAWN3D ", pawnId);
                    updateCameraTarget(pawnId);
                    updateCameraPositionIfFollowPlayer();
                    if (animated) {
                        refSceneObject.current.TweenObject.AddTween({
                            duration: 3.5,
                            currentTime: 0,
                            progress: function (currentProgress) {
                                if (pawnToMove) {
                                    const gradientIndex = MathUtils.lerp(lastIndex, index, currentProgress);
                                    const newPos = refSceneObject.current.calculatePawnPosition(gradientIndex, pawnId);
                                    let pawnMoveOscillation = Math.sin(currentProgress * 8.0 * Math.PI);
                                    pawnToMove.object3D.position.set(newPos.x, newPos.y + Math.abs(pawnMoveOscillation) * 50., newPos.z);
                                    pawnToMove.object3D.rotation.set(0, refSceneObject.current.calculatePawnRotation(gradientIndex), pawnMoveOscillation * 0.1);
                                    pawnToMove.slotIndex = index;
                                }
                            },
                            onComplete: function () {
                                updateCameraPositionIfFollowPlayer();
                            }
                        });
                    }
                    else {
                        if (pawnToMove) {
                            const newPos = refSceneObject.current.calculatePawnPosition(index, pawnId);
                            pawnToMove.object3D.position.set(newPos.x, newPos.y, newPos.z);
                            pawnToMove.object3D.rotation.set(0, refSceneObject.current.calculatePawnRotation(index), 0);
                        }
                    }
                },
                activatePawn: (pawnId) => {
                    const pawnToActivate = pawnsTeams.current.get(pawnId);
                    if (!pawnToActivate) {
                        console.error("Pawn not found");
                    }
                    else {
                        pawnToActivate.object3D.visible = true;
                    }
                },
                diceModel: refSceneObject.current._dice
            };
            console.log("SCENE LOADED & INIT", gltf.scene);
            if (props.onSceneLoaded)
                props.onSceneLoaded(sceneWrapper);
            // We wait for every processing to be done before showing the scene, this makes loading faster
            if (refGroupScene && refGroupScene.current)
                refGroupScene.current.visible = true;
        });
    }
    const refIndexFrame = useRef(0);
    useFrame((state, deltaTime) => {
        var _a, _b, _c, _d, _e;
        if (refIndexFrame.current === 0) { // We wait for the first frame to be rendered to preprocess the scene to have up to date matrices
            preprocessScene();
        }
        refIndexFrame.current++;
        refTime.current += deltaTime;
        refSceneObject.current.update(deltaTime);
        if (cameraMode.current === CameraMode.FOLLOW_PLAYER && cameraTarget.current) {
            const worldPos = cameraTarget.current.localToWorld(new Vector3(0, 0, 0));
            (_a = refCam.current) === null || _a === void 0 ? void 0 : _a.lookAt(new Vector3(worldPos.x, 0.0, worldPos.z));
        }
        else if (cameraMode.current === CameraMode.SHOWCASE) {
            const showCaseCurrentTime = refTime.current * 0.4; // We make the time slower for the showcase
            const currentTimeFactor = (showCaseCurrentTime % CAMERA_PAN_DURATION) / CAMERA_PAN_DURATION;
            const currentIndexCamTransitions = Math.floor(showCaseCurrentTime / CAMERA_PAN_DURATION) % SHOWCASE_CAMERA_TRANSITIONS.length;
            const positionA = SHOWCASE_CAMERA_TRANSITIONS[currentIndexCamTransitions].positionA.clone();
            const positionB = SHOWCASE_CAMERA_TRANSITIONS[currentIndexCamTransitions].positionB.clone();
            (_b = refCam.current) === null || _b === void 0 ? void 0 : _b.position.copy(positionA.lerp(positionB, currentTimeFactor).clone());
            (_c = refCam.current) === null || _c === void 0 ? void 0 : _c.updateMatrixWorld();
            const lookAt = SHOWCASE_CAMERA_TRANSITIONS[currentIndexCamTransitions].lookAtPosition; // refCam.current?.localToWorld(currentLookAt);
            (_d = refCam.current) === null || _d === void 0 ? void 0 : _d.lookAt(lookAt);
            (_e = refCam.current) === null || _e === void 0 ? void 0 : _e.up.copy(new THREE.Vector3(0, 1, 0));
        }
    });
    // Shows the current clicked object (useful for dev)
    function handleClick(event) {
        var _a;
        if (event.object && event.object.visible) {
            if (event.object instanceof THREE.Mesh) {
                if (event.object.name === 'Tuyau') {
                    refSceneObject.current.PlaySound('PipeSlide');
                }
                if (event.object.name.includes("Cow")) {
                    refSceneObject.current.PlaySound('UFO');
                    if (refSceneObject.current && refSceneObject.current._ufoObject) {
                        refSceneObject.current._ufoObject.visible = true;
                    }
                    const originalUFOPos = (_a = refSceneObject.current._ufoObject) === null || _a === void 0 ? void 0 : _a.position.clone();
                    refSceneObject.current.TweenObject.AddTween({
                        duration: 2.0,
                        currentTime: 0.0,
                        progress(currentProgress) {
                            if (refSceneObject.current._ufoObject && originalUFOPos) {
                                const target1 = originalUFOPos.clone().add(new Vector3(0, 0, -200));
                                const newPos = originalUFOPos.clone().lerp(target1, currentProgress);
                                refSceneObject.current._ufoObject.position.set(newPos.x, newPos.y, newPos.z);
                            }
                        },
                        onComplete() {
                            if (refSceneObject.current._ufoObject) {
                                const stop1 = refSceneObject.current._ufoObject.position.clone();
                                refSceneObject.current.TweenObject.AddTween({
                                    duration: 1.0,
                                    currentTime: 0.0,
                                    progress(currentProgress) {
                                        if (refSceneObject.current._ufoObject && originalUFOPos) {
                                            const progress = Math.pow(currentProgress, 2);
                                            const target1 = stop1.clone().add(new Vector3(-1000, 100, 1000));
                                            const newPos = stop1.clone().lerp(target1, progress);
                                            refSceneObject.current._ufoObject.position.set(newPos.x, newPos.y, newPos.z);
                                            if (currentProgress > 0.3) {
                                                refSceneObject.current._cows.forEach((cow) => cow.visible = false);
                                            }
                                            if (currentProgress > 0.8)
                                                refSceneObject.current._ufoObject.visible = false;
                                        }
                                    },
                                    onComplete() {
                                        if (refSceneObject.current._ufoObject) {
                                            refSceneObject.current._ufoObject.visible = false;
                                            refSceneObject.current._ufoObject.position.set(originalUFOPos.x, originalUFOPos.y, originalUFOPos.z);
                                        }
                                    },
                                });
                            }
                        },
                    });
                }
            }
            console.log("CLICKEDOBJECT", event);
            console.log("CLICKEDOBJECT", event.object);
            window.DEBUG_ClickedObject = event.object;
            event.stopPropagation();
        }
    }
    function handleSlowFrameRate(perf) {
        if (perf.fps < 20.0 && refSceneObject.current) {
            console.warn("Slow framerate (< 20fps), dropping quality...", perf);
            if (refSceneObject.current) {
                refSceneObject.current.lowerQuality();
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(PerformanceMonitor, { onDecline: (perf) => handleSlowFrameRate(perf) }), _jsxs("group", { children: [_jsx("ambientLight", { intensity: 1., color: "#8080ff" }), _jsx("directionalLight", { intensity: 5., position: [-35, 50, 25], ref: refDirLight }), _jsx(PerspectiveCamera, { ref: refCam, makeDefault: true, position: [CAMERA_GLOBAL_POSITION[0], CAMERA_GLOBAL_POSITION[1], CAMERA_GLOBAL_POSITION[2]], near: 0.01, far: 150 }), _jsx(EffectComposer, Object.assign({ depthBuffer: true, disableNormalPass: true, multisampling: 1, stencilBuffer: false }, { children: _jsx(OutlineDepthBased, {}) })), _jsx(Environment, { files: "./assets/360/skybox.hdr", background: true }), _jsx("group", Object.assign({ visible: false, ref: refGroupScene, onClick: handleClick }, { children: _jsx("primitive", { object: gltf.scene }) }))] })] }));
}
function ThreeJSComponent(props) {
    let setPawns_;
    let setDiceModel_;
    function onMounted(setPawns, setDiceModel, diceRollWrapper) {
        var _a;
        setPawns_ = setPawns;
        setDiceModel_ = setDiceModel;
        if (props.onDiceRollLoaded) {
            (_a = props.onDiceRollLoaded) === null || _a === void 0 ? void 0 : _a.call(props, diceRollWrapper);
        }
    }
    function onPawnsLoaded(pawn) {
        console.log("onPawnsLoaded", pawn);
        const pawnsMaps = new Map();
        pawnsMaps.set(ETeam.BLUE, pawn.bluePawn.clone());
        pawnsMaps.set(ETeam.RED, pawn.redPawn.clone());
        pawnsMaps.set(ETeam.GREEN, pawn.greenPawn.clone());
        pawnsMaps.set(ETeam.YELLOW, pawn.yellowPawn.clone());
        setPawns_ === null || setPawns_ === void 0 ? void 0 : setPawns_(pawnsMaps);
    }
    function onSceneLoaded(sceneWrapper) {
        if (props.onSceneLoaded)
            props.onSceneLoaded(sceneWrapper);
        setDiceModel_ === null || setDiceModel_ === void 0 ? void 0 : setDiceModel_(sceneWrapper.diceModel);
    }
    return (_jsxs("div", Object.assign({ className: "fixed w-full h-full" }, { children: [_jsx("div", Object.assign({ className: "absolute w-full h-full", style: { zIndex: 0 } }, { children: _jsx(Canvas, Object.assign({ shadows: true, gl: { depth: true, logarithmicDepthBuffer: true, powerPreference: "high-performance" }, dpr: [1.0, 1.0] }, { children: _jsx(SceneComponent, { onPawnsLoaded: onPawnsLoaded, onSceneLoaded: onSceneLoaded }) })) })), _jsx("div", Object.assign({ className: "absolute w-full h-full", style: { pointerEvents: 'none' } }, { children: _jsx(RollDiceComponent, { onMounted: onMounted }) }))] })));
}
export default ThreeJSComponent;
