import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CardsTypeIcon from "../../icons/CardTypeIcon";
import { switchTypeToColor } from "../../../tools/switchToCards";
import TrashIcon from "../../icons/TrashIcon";
import { replaceNewLines } from "cresus-common/dist/utils/StringUtils";
function CardTeamHandlerComponent(props) {
    const [name, setName] = useState(props.name);
    const [text, setText] = useState(props.text);
    const [slotType, setSlotType] = useState(props.slotType);
    useEffect(() => {
        setName(props.name);
    }, [props.name]);
    useEffect(() => {
        setText(props.text);
    }, [props.text]);
    useEffect(() => {
        setSlotType(props.slotType);
    }, [props.slotType]);
    return (_jsxs("div", Object.assign({ className: "flex gap-[10px] h-[86px] p-[10px] items-start font-roboto justify-between card_team_admin-style-width", style: {
            fontSize: "14px",
            fontWeight: "600",
            fontStyle: "normal",
            lineHeight: "16px",
            backgroundColor: "rgba(56, 73, 131, 1)",
            borderRadius: "5px",
        } }, { children: [_jsx(CardsTypeIcon, { color: switchTypeToColor(slotType) }), _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("span", Object.assign({ className: "flex" }, { children: name })), _jsx("span", Object.assign({ className: "flex flex-wrap w-full", style: {
                            textAlign: "left",
                            fontWeight: "400",
                            whiteSpace: "pre",
                        } }, { children: replaceNewLines(text) }))] })), _jsx("button", Object.assign({ className: "flex hover:border-[1px] border-white self-center", onClick: props.handleDelete, style: {
                    borderRadius: "50%",
                    width: "20px",
                    minWidth: "20px",
                    height: "20px",
                    padding: "3px",
                    backgroundColor: "rgba(0, 0, 0, 0.50)",
                } }, { children: _jsx(TrashIcon, {}) }))] })));
}
export default CardTeamHandlerComponent;
