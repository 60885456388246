export var ECaseType;
(function (ECaseType) {
    ECaseType[ECaseType["FIRST"] = 0] = "FIRST";
    ECaseType[ECaseType["DILEMME"] = 1] = "DILEMME";
    ECaseType[ECaseType["HEALTHCARE"] = 2] = "HEALTHCARE";
    ECaseType[ECaseType["STUDY"] = 3] = "STUDY";
    ECaseType[ECaseType["QUESTION"] = 4] = "QUESTION";
    ECaseType[ECaseType["BAILIFF"] = 5] = "BAILIFF";
    ECaseType[ECaseType["PLEASURE"] = 6] = "PLEASURE";
    ECaseType[ECaseType["SHOPPING"] = 7] = "SHOPPING";
    ECaseType[ECaseType["TAXES"] = 8] = "TAXES";
    ECaseType[ECaseType["TRANSPORT"] = 9] = "TRANSPORT";
})(ECaseType || (ECaseType = {}));
export var ETaxeType;
(function (ETaxeType) {
    ETaxeType[ETaxeType["HABITATION"] = 0] = "HABITATION";
    ETaxeType[ETaxeType["NETFLIX"] = 1] = "NETFLIX";
    ETaxeType[ETaxeType["WAGES"] = 2] = "WAGES";
})(ETaxeType || (ETaxeType = {}));
