import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import PayChargesIcon from "../../icons/PayChargesIcon";
import FilledTrashIcon from "../../icons/FilledThrashIcon";
function ChargesComponent(props) {
    const [name, setName] = useState(props.name);
    const [price, setPrice] = useState(props.price);
    const [payed, setPayed] = useState(props.payed);
    useEffect(() => {
        setName(props.name);
    }, [props.name]);
    useEffect(() => {
        setPrice(props.price);
    }, [props.price]);
    useEffect(() => {
        setPayed(props.payed);
    }, [props.payed]);
    return (_jsxs("div", Object.assign({ className: "flex charge_style_width p-[10px] justify-between items-center font-roboto", style: {
            fontSize: "14px",
            fontWeight: "600",
            fontStyle: "normal",
            lineHeight: "16px",
            color: payed ? "#D4D4D4" : "white",
            backgroundColor: payed ? "#121E46" : "rgba(56, 73, 131, 1)",
            borderRadius: "5px",
            border: payed ? "1px solid #8699C1" : "none",
            textDecoration: payed ? "line-through" : "none",
        } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("span", Object.assign({ className: "flex" }, { children: name })), _jsxs("span", Object.assign({ className: "flex", style: {
                            fontWeight: "400",
                        } }, { children: [price, " \u20AC"] }))] })), _jsxs("div", Object.assign({ className: "flex gap-[5px]" }, { children: [props.isCreated &&
                        _jsx("button", Object.assign({ className: "flex hover:border-[1px] border-white", onClick: () => props.handleDelete(), style: {
                                borderRadius: "29px",
                                width: "20px",
                                height: "20px",
                                padding: "3px",
                                backgroundColor: "rgba(0, 0, 0, 0.50)",
                            } }, { children: _jsx(FilledTrashIcon, {}) })), !payed &&
                        _jsx("button", Object.assign({ className: "flex hover:border-[1px] border-white", onClick: () => props.handlePay(), style: {
                                borderRadius: "29px",
                                width: "20px",
                                height: "20px",
                                padding: "3px",
                                backgroundColor: "rgba(0, 0, 0, 0.50)",
                            } }, { children: _jsx(PayChargesIcon, {}) }))] }))] })));
}
export default ChargesComponent;
