import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import FunctionalButton from "../FunctionalButton";
import CheckedIcon from "../icons/CheckedIcon";
function ProjectPropositionComponent(props) {
    const [isSelected, setIsSelected] = useState(props.isSelected);
    const [isActive, setIsActive] = useState(props.isActive);
    useEffect(() => {
        setIsSelected(props.isSelected);
    }, [props.isSelected]);
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    return (_jsxs("div", Object.assign({ className: "flex w-full" }, { children: [!isSelected &&
                _jsxs("div", Object.assign({ className: "flex flex-col gap-2 text-white text-center font-roboto items-center", style: {
                        minWidth: "209px",
                        minHeight: "139px",
                        width: "100%",
                        borderRadius: "4px",
                        padding: "16px 14px",
                        backgroundColor: "rgba(0, 0, 0, 0.50)",
                    } }, { children: [_jsx("div", { children: props.textProject }), _jsxs("div", { children: [props.projectPrice, "\u20AC"] }), _jsx("div", { children: _jsx(FunctionalButton, { text: "Choisir", onClick: () => { props.onClick(); }, isRound: true, enabled: isActive }) })] })), isSelected &&
                _jsxs("div", Object.assign({ className: "flex flex-col gap-2 text-white text-center font-roboto items-center justify-center", style: {
                        minWidth: "209px",
                        minHeight: "139px",
                        width: "100%",
                        borderRadius: "4px",
                        border: "2px solid rgba(255, 255, 255, 1)",
                        padding: "16px 14px",
                        backgroundColor: "rgba(0, 0, 0, 0.80)",
                    } }, { children: [_jsx("div", { children: props.textProject }), _jsxs("div", { children: [props.projectPrice, "\u20AC"] }), _jsx("div", Object.assign({ className: "flex justify-center items-center self-center", style: {
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                                border: "1px solid black",
                                padding: "3px",
                                backgroundColor: "rgba(71, 185, 105, 1)"
                            } }, { children: _jsx(CheckedIcon, {}) }))] }))] })));
}
export default ProjectPropositionComponent;
