import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
function ChatOpenButtonComponent(props) {
    const [isOnHover, setIsOnHover] = useState(false);
    return (_jsx("button", { className: "chatButtonDimension", onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), onClick: () => {
            props.closeChat();
        }, style: {
            // width:"19.41cqw",
            // height: "20.20cqw",
            backgroundImage: `url(./assets/UI/buttons/chat_buttons/btn_chat_close${isOnHover ? "_hover" : ""}.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        } }));
}
export default ChatOpenButtonComponent;
