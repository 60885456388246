import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CheckedIcon from "../icons/CheckedIcon";
function ItemComponent(props) {
    const [isSelected, setIsSelected] = useState(props.isSelected);
    useEffect(() => {
        setIsSelected(props.isSelected);
    }, [props.isSelected]);
    return (_jsxs("div", Object.assign({ className: "flex w-[50px] h-full relative" }, { children: [_jsx("div", { className: "flex w-[50px] h-full relative", onClick: () => {
                    props.handleClick(props.index, props.item);
                }, style: {
                    backgroundImage: "url(/assets/UI/avatar_composition/avatar_item_border.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    zIndex: 10
                } }), _jsx("div", Object.assign({ className: "flex w-full h-full items-center justify-center absolute", style: {
                    backgroundImage: `url(/assets/UI/avatar_composition/selection/${props.url_name}.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    zIndex: "1"
                } }, { children: isSelected &&
                    _jsx("div", Object.assign({ className: "flex w-[24px] h-[24px] p-[3px] bg-dilemmeGreenChance", style: {
                            borderRadius: "50%",
                            border: "1px solid #000000",
                        } }, { children: _jsx(CheckedIcon, {}) })) }))] })));
}
export default ItemComponent;
