import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import TextCardComponent from "./TextCardComponent";
function ChatMessageComponent(props) {
    const isMyMessageAsPlayer = props.message.person.name === props.myPlayerName;
    const isMyMessageAsAnimator = props.message.person.animatorName === props.myPlayerName;
    const messageName = props.message.person.name ? props.message.person.name : props.message.person.animatorName;
    function getColorToText(team) {
        switch (team) {
            case ETeam.RED:
                return "#DD2810";
            case ETeam.BLUE:
                return "rgba(16, 110, 221, 1)";
            case ETeam.GREEN:
                return "rgba(36, 189, 79, 1)";
            case ETeam.YELLOW:
                return "rgba(255, 213, 63, 1)";
            case ETeam.NONE:
                return "rgba(0,0,0,1)";
        }
    }
    return (_jsxs("div", Object.assign({ className: "flex font-kalam flex-col", style: {
        // fontSize: "4.71cqw",
        // lineHeight: "5.49cqw",
        } }, { children: [!isMyMessageAsPlayer && !isMyMessageAsAnimator &&
                _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "flex", style: {
                                fontWeight: 700,
                                color: getColorToText(props.message.person.team),
                            } }, { children: messageName })), _jsx("div", Object.assign({ className: "flex", style: {
                                color: "#666"
                            } }, { children: _jsx(TextCardComponent, { text: props.message.content }) }))] }), (isMyMessageAsPlayer || isMyMessageAsAnimator) &&
                _jsx("div", Object.assign({ className: "flex self-end", style: {
                        color: "#000"
                    } }, { children: _jsx(TextCardComponent, { text: props.message.content }) }))] })));
}
export default ChatMessageComponent;
