import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, DropdownList, Localization } from "react-widgets/cjs";
import { DateLocalizer } from 'react-widgets/IntlLocalizer';
import CalendarIcon from "./icons/CalendarIcon";
import "react-widgets/styles.css";
import DropdownArrowIcon from "./icons/DropdownArrowIcon";
import React, { useRef } from "react";
import CopyIcon from "./icons/CopyIcon";
var ETextFieldType;
(function (ETextFieldType) {
    ETextFieldType[ETextFieldType["SINGLELINE_TEXT"] = 0] = "SINGLELINE_TEXT";
    ETextFieldType[ETextFieldType["MULTILINE_TEXT"] = 1] = "MULTILINE_TEXT";
    ETextFieldType[ETextFieldType["COPY_TEXT"] = 2] = "COPY_TEXT";
    ETextFieldType[ETextFieldType["DROPDOWN"] = 3] = "DROPDOWN";
    ETextFieldType[ETextFieldType["DATE"] = 4] = "DATE";
})(ETextFieldType || (ETextFieldType = {}));
const TextField = (props) => {
    const refCopy = useRef(null);
    function clickedCopy() {
        const copyText = refCopy.current;
        /* Make the input field enabled for it to work */
        copyText.disabled = false;
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        /* Copy the text inside the text field */
        document.execCommand("copy");
        copyText.disabled = true;
    }
    function onChange(value) {
        if (props.onChange) {
            props.onChange(value);
        }
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("div", Object.assign({ className: "font-roboto", style: { fontWeight: 'bold', fontSize: '14px', marginBottom: '8px', height: '16px' } }, { children: props.label })), _jsxs("div", Object.assign({ className: "flex flex-row items-center" }, { children: [(props.type === ETextFieldType.MULTILINE_TEXT || props.type === ETextFieldType.SINGLELINE_TEXT) &&
                        _jsx("div", Object.assign({ className: "w-full", style: { borderWidth: '1px', height: '40px', borderColor: '#A1AABC', borderRadius: '4px' } }, { children: _jsx("input", { defaultValue: props.value, ref: props.parentRef, className: "w-full h-full", style: { background: 'transparent', outline: 'transparent', padding: '0px 10px' } }) })), props.type === ETextFieldType.DATE &&
                        _jsx(Localization, Object.assign({ date: new DateLocalizer({ culture: 'fr-FR', firstOfWeek: 1 }) }, { children: _jsx(DatePicker, { defaultValue: props.value, selectIcon: _jsx(CalendarIcon, {}), onChange: (date, str) => { props.parentRef.current = date; } }) })), props.type === ETextFieldType.DROPDOWN &&
                        _jsx(DropdownList, { defaultValue: props.value, data: props.dropdownOptions, onChange: (dataItem, _) => { props.parentRef.current = dataItem; onChange(dataItem); }, selectIcon: _jsx(DropdownArrowIcon, {}) }), props.type === ETextFieldType.COPY_TEXT &&
                        _jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("div", { children: props.value }), _jsx("input", { ref: refCopy, value: props.value, disabled: true, style: { position: 'absolute', opacity: 0, width: "1px" } }), _jsx("button", Object.assign({ style: { marginLeft: "8px" }, onClick: clickedCopy }, { children: _jsx(CopyIcon, {}) }))] }))] }))] })));
};
TextField.defaultProps = {
    value: "",
    type: ETextFieldType.SINGLELINE_TEXT,
    dropdownOptions: ["OPTION 1", "OPTION 2", "OPTION 3"]
};
export default TextField;
export { ETextFieldType };
