import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETurn from "cresus-common/dist/dto/ETurn";
import { useEffect, useState } from "react";
function ChargesNotFixed(props) {
    const [cost, setCost] = useState(props.cost);
    const [name, setName] = useState(props.name);
    const [isPaid, setIsPaid] = useState(props.isPaid);
    const [pleasurePointsbonus, setPleasurePointsbonus] = useState([]);
    const [turn, setTurn] = useState(props.turn);
    const [isOnHover, setIsOnHover] = useState(false);
    const [isCaptain, setIsCaptain] = useState(props.isCaptain);
    function handleClick() {
        props.payCharge();
    }
    useEffect(() => {
        setCost(props.cost);
    }, [props.cost]);
    useEffect(() => {
        setName(props.name);
    }, [props.name]);
    useEffect(() => {
        setIsPaid(props.isPaid);
    }, [props.isPaid]);
    useEffect(() => {
        setTurn(props.turn);
    }, [props.turn]);
    useEffect(() => {
        console.log("props.pleasurePointsbonus ", props.pleasurePointsbonus);
        let buffPleasurePointsbonus = [];
        for (let i = 0; i < props.pleasurePointsbonus; i++) {
            buffPleasurePointsbonus.push(i);
        }
        setPleasurePointsbonus(buffPleasurePointsbonus);
        console.log("pleasurePointsbonus ", pleasurePointsbonus);
    }, [props.pleasurePointsbonus]);
    useEffect(() => {
        setIsCaptain(props.isCaptain);
    }, [props.isCaptain]);
    return (_jsxs("button", Object.assign({ className: "flex relative font-kalam flex-col justify-between noteBookFixedCharges", onClick: handleClick, onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), disabled: isPaid || turn !== ETurn.MUST_ROLL_DICE || !isCaptain || props.isDoubleOn, style: {
            minWidth: 140,
            maxWidth: 140 * 2,
            minHeight: 63,
            maxHeight: 63 * 2,
            fontSize: "1.6cqw",
            fontStyle: "normal",
            lineHeight: "1.6cqw",
            border: "2px solid #000000",
            backgroundColor: "rgba(111, 130, 192, 0.2)",
            opacity: isPaid ? 0.3 : 1,
        } }, { children: [isOnHover && turn === ETurn.MUST_ROLL_DICE &&
                _jsx("div", { className: "flex h-full w-full absolute", style: {
                        position: "absolute",
                        backgroundImage: "url(./assets/UI/game_elements_images/isOnHoverCircle.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "flex fontFixedCharges paddingChargesNotFixedName", style: {
                            backgroundImage: isPaid ? "url(./assets/UI/game_elements_images/crossed.png)" : "none",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            // fontSize: "9cqw",
                            // lineHeight: "10.3cqw",
                            // paddingTop: "3.7cqw",
                            // paddingLeft: "3.7cqw",
                        } }, { children: name })), _jsxs("div", Object.assign({ className: "flex items-center gap-1 paddingChargesNotFixedValue", style: { fontWeight: 700,
                            //  paddingLeft: "3.7cqw",
                            //  fontSize: "9cqw",
                            //  lineHeight: "10.3cqw",
                        } }, { children: [isPaid ? "" : "Payer", _jsxs("div", Object.assign({ className: "flex", style: {
                                    backgroundImage: isPaid ? "url(./assets/UI/game_elements_images/crossed.png)" : "none",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                } }, { children: [props.cost, " \u20AC"] }))] }))] }), _jsxs("div", Object.assign({ className: "flex flex-row relative items-center justify-between w-full gap-[5px] paddingChargesNotFixedMensuality", style: {
                // paddingBottom: "3.7cqw",
                // paddingRight: "3.7cqw",
                } }, { children: [_jsx("div", Object.assign({ className: "flex relative w-full items-end self-end justify-end  fontFixedCharges" }, { children: "Mensuel" })), _jsx("div", Object.assign({ className: "flex flex-row gapPleasurePointChargesNotFixed" }, { children: pleasurePointsbonus.map((pleasurePoint, index) => {
                            console.log("pleasurePoint how many maps ", pleasurePoint);
                            return (_jsx("div", { className: "flex relative noteBookBailAndPleasure", style: {
                                    minWidth: 16,
                                    minHeight: 16,
                                    maxWidth: 16 * 2,
                                    maxHeight: 16 * 2,
                                    backgroundImage: "url(./assets/UI/game_elements_images/pleasure_points_picto.png)",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundColor: "rgba(111, 130, 192, 1)",
                                    borderRadius: "50%",
                                } }, index));
                        }) }))] }))] })));
}
export default ChargesNotFixed;
