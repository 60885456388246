import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import EDifficultyCards from "cresus-common/dist/dto/EDifficultyCards";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import CardInfoActiveIcon from "../../icons/CardInfoActiveIcon";
import DifficultyIcon from "../../icons/DifficultyIcon";
import CardInfoInactiveIcon from "../../icons/CardInfoInactiveIcon";
import switchForColorToCategorie from "../../../tools/switchForColorToCategorie";
import CustomRadioButton from "../../CustomRadioButton";
import TextCardComponent from "../../TextCardComponent";
import ECategoriesCards from "cresus-common/dist/dto/ECategoriesCards";
const mapCategories = new Map([
    ["Assurance", ECategoriesCards.ASSURANCE],
    ["Connaître la banque", ECategoriesCards.BANQUE],
    ["Crédit", ECategoriesCards.CREDIT],
    ["Droit et fiscalité", ECategoriesCards.DROIT],
    ["Épargne", ECategoriesCards.EPARGNE],
    ["Gérer son budget", ECategoriesCards.BUDGET],
    ["Jeu d'argent", ECategoriesCards.JEU],
    ["Quelques économies", ECategoriesCards.Q_ECONOMIE],
    ["Chance", ECategoriesCards.CHANCE],
    ["Dépense imprévue", ECategoriesCards.DEPENSE],
    ["Dilemme", ECategoriesCards.DILEMME],
    ["Économies", ECategoriesCards.ECONOMIE]
]);
const mapCategoriesReversed = new Map(Array.from(mapCategories, ([key, value]) => [value, key]));
export function textToCardCategory(text) {
    const result = mapCategories.get(text);
    if (result) {
        return result;
    }
    return ECategoriesCards.ASSURANCE;
}
export function cardCategoryToText(category) {
    const result = mapCategoriesReversed.get(category);
    console.log("cardCategoryToText", mapCategoriesReversed);
    if (result) {
        return result;
    }
    return "Unknown";
}
function switchDificultyToColor(eDifficultyCards) {
    switch (eDifficultyCards) {
        case 1:
            return "#47B969";
        case 2:
            return "#FCB35B";
        case 3:
            return "#F16769";
        default:
            return "#000000";
    }
}
function switchDificultyToString(eDifficultyCards) {
    switch (eDifficultyCards) {
        case 1:
            return EDifficultyCards.EASY;
        case 2:
            return EDifficultyCards.MEDIUM;
        case 3:
            return EDifficultyCards.HARD;
        default:
            return "UNKNOWN";
    }
}
function CardComponent(props) {
    const [isChecked, setIsChecked] = useState(props.isSelected);
    const [isActive, setIsActive] = useState(props.isActive);
    const [isInfoCardShowed, setIsInfoCardShowed] = useState(props.isChoosen);
    const [isChoosen, setIsChoosen] = useState(props.isChoosen);
    function clickedOnCardInfo() {
        setIsInfoCardShowed(!isInfoCardShowed);
    }
    useEffect(() => {
        setIsChecked(props.isSelected);
        console.log("props.isSelected ", props.isSelected);
    }, [props.isSelected]);
    useEffect(() => {
        setIsActive(props.isActive);
        console.log("props.isActive ", props.isActive);
    }, [props.isActive]);
    useEffect(() => {
        setIsChoosen(props.isChoosen);
        console.log("props.isChoosen ", props.isChoosen);
    }, [props.isChoosen]);
    return (_jsxs("div", Object.assign({ className: "flex  w-full justify-between p-[20px] rounded-[3px] items-center flex-col gap-[10px]", style: {
            backgroundColor: isActive ? "#121E46" : "transparent",
        } }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-4 items-center" }, { children: [!isChoosen &&
                                _jsx(CustomRadioButton, { mode: 0, isActivated: isActive, handleRadioButtonCLick: () => { props.handleRadioButtonClick(); }, isSelected: isChecked }), _jsx("div", Object.assign({ className: "font-roboto items-center text-white flex relative", style: {
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                } }, { children: props.card.title })), props.card.type !== ETypeCards.PLAISIR &&
                                _jsx("div", Object.assign({ className: "flex items-center self-center font-roboto relative", style: {
                                        height: "18px",
                                        padding: "0px 5px",
                                        borderRadius: "2px",
                                        backgroundColor: `${switchForColorToCategorie(props.card.category)}`,
                                        color: "white",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                    } }, { children: cardCategoryToText(props.card.category) }))] })), _jsxs("div", Object.assign({ className: "flex gap-6" }, { children: [props.card.type === ETypeCards.QUESTION &&
                                _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto items-center self-center relative", style: {
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                            } }, { children: switchDificultyToString(props.card.difficulty) })), _jsx(DifficultyIcon, { color: switchDificultyToColor(props.card.difficulty) })] })), !isChoosen &&
                                _jsx("button", Object.assign({ className: "flex", onClick: clickedOnCardInfo, style: {
                                        height: 30,
                                        width: 30,
                                        minWidth: 30,
                                        borderRadius: "50%",
                                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    } }, { children: isInfoCardShowed ? _jsx(CardInfoActiveIcon, {}) : _jsx(CardInfoInactiveIcon, {}) }))] }))] })), isInfoCardShowed &&
                _jsxs("div", Object.assign({ className: "flex w-full flex-col gap-2 self-start justify-start" }, { children: [_jsx("div", Object.assign({ className: "flex w-full relative flex-wrap font-roboto", style: {
                                fontSize: "14px",
                                fontWeight: "400",
                                fontStyle: "normal",
                                lineHeight: "normal",
                            } }, { children: _jsx(TextCardComponent, { text: props.card.textAnimator }, "TextCardComponent") })), _jsxs("div", Object.assign({ className: "flex relative se flex-wrap font-roboto", style: {
                                fontSize: "14px",
                                fontWeight: "400",
                                fontStyle: "normal",
                                lineHeight: "normal",
                            } }, { children: [props.card.comment === "" ? "" : "Commentaires :", " ", _jsx(TextCardComponent, { text: props.card.comment }, "TextCardComponent")] }))] }))] })));
}
export default CardComponent;
