var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import PeopleWaitingRoomComponent from "../../components/animator_components/PeopleWaitingRoomComponent";
import WebcamComponent from "../../components/animator_components/WebcamComponent";
import InformationWaitingRoomComponent from "../../components/animator_components/InformationWaitingRoomComponent";
import InformationPreGameComponent from "../../components/animator_components/InformationPreGameComponent";
// import { playersInWaitRoom } from "../../MockData/Players"
import { useNavigate } from "react-router-dom";
import useSessionContext from "../../tools/useSessionContext";
import { getDefaultGameStateDTO } from "cresus-common/dist/dto/GameStateDTO";
import { useBoardGame } from "../../contexts/BoardGameProvider";
import useMedia from "../../contexts/useMedia";
function WaitingRoomPage() {
    const { playSound } = useBoardGame();
    const { audioVideoClient } = useMedia();
    console.log("Rendering WaitingRoomPage");
    const navigate = useNavigate();
    const { sendRequest, websocketPeer } = useSessionContext();
    const [insideGameState, setInsideGameState] = useState(getDefaultGameStateDTO());
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);
    const [chatOn, setChatOn] = useState(false);
    const [paramOn, setParamOn] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    const counter = 0; //10;
    function startTheSession() {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendRequest("startSession", {})
                .catch((error) => { console.error("startSession", error); });
        });
    }
    function handleClick() {
        playSound("GlobalAmbiance");
        startTheSession();
    }
    // functions to handle Notifications from the websocket //////////////////////////////
    function updateIfSessionStarted(message) {
        // console.log("test callback", message);
        const buffGameState = Object.assign({}, insideGameState);
        buffGameState.sessionStarted = message.data.gameState;
        setInsideGameState(buffGameState);
        navigate('/IntroductionRoomPage');
    }
    function updateLobbyPlayers(message) {
        console.log("newPlayer Added to session", message);
        const buffGameState = Object.assign({}, insideGameState);
        buffGameState.lobbyPlayers = message.data;
        console.log('what is happening here ?', insideGameState);
        console.log("newPlayer Added to session", buffGameState);
        setInsideGameState(gameState => {
            return Object.assign(Object.assign({}, gameState), { lobbyPlayers: message.data });
        });
    }
    ;
    //-----------------------------------------------------------------------------//
    // notifications to update the gamestate websocket //////////////////////////////
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            // console.log("WaitingRoomPage test callback", notification);
            if (notification.method === "updateGameState")
                setInsideGameState(notification.data);
            if (notification.method === "startSession")
                updateIfSessionStarted(notification);
            if (notification.method === "newPlayerToLobby") {
                console.log("newPlayer Added to session", notification);
                updateLobbyPlayers(notification);
            }
        });
        playSound("GlobalAmbiance");
    }, []);
    //-----------------------------------------------------------------------------//
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", {})
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            console.log("WaitingRoomPage.getGameState()", result);
            if (result !== undefined) {
                setInsideGameState(result);
            }
        });
    }
    useEffect(() => {
        console.log("WaitingRoomPage.useEffect()", insideGameState);
    }, [insideGameState]);
    useEffect(() => {
        getGameState();
    }, []);
    return (_jsxs("div", Object.assign({ className: "absolute w-screen flex h-screen bg-dilemmeSimplePurple" }, { children: [_jsxs("div", Object.assign({ className: "flex h-full flex-col", style: {
                    backgroundColor: "rgba(0, 0, 0, 0.50)",
                    width: "25%",
                    minWidth: "256px",
                    zIndex: 2,
                } }, { children: [_jsxs("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                            paddingLeft: "20px",
                            paddingTop: "33px",
                            height: "91px",
                            minHeight: "91px",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        } }, { children: [insideGameState.lobbyPlayers.length, " joueurs dans ", _jsx("br", {}), " la salle d'attente"] })), _jsx("div", Object.assign({ className: "flex relative", style: {
                            paddingTop: "20px",
                            paddingRight: "10px",
                            height: "100%",
                            minHeight: "305px",
                            borderTop: "1px solid rgba(255, 255, 255, 0.20)",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                        } }, { children: _jsx("div", Object.assign({ className: "flex relative overflow-y-auto w-full flex-col", style: {
                                paddingLeft: "20px",
                                paddingRight: "8px",
                                height: "100%",
                                gap: "5px",
                            } }, { children: insideGameState.lobbyPlayers.map((person, index) => {
                                return _jsx(PeopleWaitingRoomComponent, { player: person, sessionPeerId: person.sessionPeerId, mediaAPIPeerId: person.mediaAPIPeerId, name: person.name, micOn: !person.micOn }, `peopleInWait${index}`);
                            }) })) })), _jsx(WebcamComponent, { isCommunicationActive: true, isThereNotification: newMessageAlert, newMessageAlert: setNewMessageAlert, isChatActive: false, cameraOn: cameraOn, setCameraOn: setCameraOn, micOn: micOn, setMicOn: setMicOn, chatOn: chatOn, setChatOn: setChatOn, paramOn: paramOn, setParamOn: setParamOn })] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-start h-full w-full", style: {
                    paddingLeft: "50px",
                    paddingRight: "50px",
                } }, { children: [_jsx(InformationPreGameComponent, { buttonActive: true, textButton: "Lancer la session", onClickButton: () => { handleClick(); }, sessionName: insideGameState.animatorSessionName, counterEnded: false, counter: counter }), _jsxs("div", Object.assign({ className: "flex flex-row w-full h-full" }, { children: [_jsxs("div", Object.assign({ className: " flex flex-col justify-start w-full h-full" }, { children: [_jsx("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                            fontSize: "24px",
                                            fontWeight: "700",
                                            lineHeight: "28px",
                                            zIndex: 10,
                                        } }, { children: "Informations de la session" })), _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsx(InformationWaitingRoomComponent, { tag: "Deck", information: insideGameState.deckName }), _jsx(InformationWaitingRoomComponent, { tag: "Langue", information: insideGameState.language }), _jsx(InformationWaitingRoomComponent, { tag: "Commentaire", information: insideGameState.comment.length > 0 ? insideGameState.comment : "Pas de commentaire" })] }))] })), _jsx("div", { className: "flex relative", style: {
                                    right: "-50px",
                                    minWidth: "359px",
                                    minHeight: "359px",
                                    backgroundImage: "url(/assets/UI/cygogne_prepartie.png)",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPositionX: "right",
                                    backgroundPositionY: "bottom",
                                } })] }))] }))] })));
}
export default WaitingRoomPage;
