import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import { useEffect, useState } from "react";
import PlayerToReconnect from "./PlayerToReconnect";
function chooseCigogne(teamColor) {
    switch (teamColor) {
        case ETeam.RED:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Red.png)";
        case ETeam.BLUE:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Blue.png)";
        case ETeam.YELLOW:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Yellow.png)";
        case ETeam.GREEN:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Green.png)";
        default:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Red.png)";
    }
}
function TeamReconnectionDisplay(props) {
    const [cigogne, setCigogne] = useState(chooseCigogne(props.team.teamColor));
    const arrayOfFour = [0, 1, 2, 3];
    const filledToFour = [...props.team.players];
    for (let i = 0; i < 4 - props.team.players.length; i++) {
        filledToFour.push(undefined);
    }
    console.log("filledToFour", filledToFour);
    useEffect(() => {
        setCigogne(chooseCigogne(props.team.teamColor));
    }, []);
    return (_jsxs("div", Object.assign({ className: "flex flex-col h-full w-full gap-[5px] items-center justify-center", style: {
            width: 200,
            opacity: true ? "1" : "0.2",
        } }, { children: [_jsx("div", Object.assign({ className: "flex w-full justify-center", style: {
                    backgroundImage: "url(./assets/UI/line.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "repeat-x",
                    backgroundPositionY: "bottom"
                } }, { children: _jsx("div", { className: "flex", style: {
                        width: "152px",
                        height: "95px",
                        backgroundImage: `${cigogne}`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundPositionY: "-1px"
                    } }) })), filledToFour.map((player, index) => {
                if (player !== undefined) {
                    return (_jsx(PlayerToReconnect, { joinAsPlayer: props.joinAsPlayer, player: player }, "PeopleInTeamComponent" + index.toString() + props.team.teamColor));
                }
                else {
                    return (_jsx("div", { className: "flex justify-around w-full text-white font-roboto gap-[10px]", style: {
                            background: "rgba(255, 255, 255, 0.2)",
                            height: 77.5,
                            borderRadius: "3px",
                        } }));
                }
            })] })));
}
export default TeamReconnectionDisplay;
