import ETypeCards from "cresus-common/dist/dto/ETypeCards";
const questionColor = "#FCB35B";
const pleasurColor = "#19A9CF";
const eventColor = "#6F82C0";
function switchTypeToColor(type) {
    switch (type) {
        case ETypeCards.QUESTION:
            return questionColor;
        case ETypeCards.PLAISIR:
            return pleasurColor;
        case ETypeCards.EVENT:
            return eventColor;
        default:
            return questionColor;
    }
}
function switchTypeToTextSingular(type) {
    switch (type) {
        case ETypeCards.QUESTION:
            return "question";
        case ETypeCards.PLAISIR:
            return "plaisir";
        case ETypeCards.EVENT:
            return "évènement";
        default:
            return "question";
    }
}
export { switchTypeToColor, switchTypeToTextSingular };
