import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import ChatButtonComponent from "../player_buttons/ChatButtonComponent";
import ConfigButtonComponent from "../player_buttons/ConfigButtonComponent";
import MicButtonComponent from "../player_buttons/MicButtonComponent";
import ToggleViewButtonComponent from "../player_buttons/ToggleViewButtonComponent";
import { CameraMode } from "../3D/CameraMode";
import useMedia from "../../contexts/useMedia";
function SetOfButtonsPlayerComponent(props) {
    const { openMediaSettings } = useMedia();
    const [toggleView, setToggleView] = useState(CameraMode.ALL_MAP);
    const [isMapModifiable, setIsMapModifiable] = useState(props.isMapModifiable);
    const [isOnlyConfigButton, setIsOnlyConfigButton] = useState(props.isOnlyConfigButton);
    function _handleToggleView() {
        let newCameraMode = CameraMode.ALL_MAP;
        if (toggleView === CameraMode.FOLLOW_PLAYER) {
            newCameraMode = CameraMode.ALL_MAP;
        }
        else {
            newCameraMode = CameraMode.FOLLOW_PLAYER;
        }
        setToggleView(newCameraMode);
        if (props.onToggleView)
            props.onToggleView(newCameraMode);
    }
    const isPlayerView = toggleView === CameraMode.FOLLOW_PLAYER;
    return (_jsxs("div", Object.assign({ className: "absolute flex flex-row gap-[10px] self-center bottom-[10px]", style: { zIndex: 1000010 } }, { children: [!isOnlyConfigButton && props.alertNewMessage !== undefined && _jsx(ChatButtonComponent, { newMessage: props.alertNewMessage, toggleChat: props.toggleChat }), !isOnlyConfigButton && _jsx(MicButtonComponent, { isMuted: false, toggleMute: () => { } }), !isOnlyConfigButton && isMapModifiable && _jsx(ToggleViewButtonComponent, { isPlayerView: isPlayerView, toggleToggleView: _handleToggleView }), _jsx(ConfigButtonComponent, { toggleConfig: openMediaSettings })] })));
}
export default SetOfButtonsPlayerComponent;
