import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ColorToTeam } from "cresus-common/dist/utils/ColorsSwitch";
function TeamSquareSelection(props) {
    const [isActive, setIsActive] = useState(props.isActive);
    // ColorToTeam(props.color);
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    return (_jsx("button", Object.assign({ className: "flex justify-center self-center items-center", onClick: () => {
            console.log("team square selection", ColorToTeam(props.color));
            // props.setTeam(ColorToTeam(props.color));
            props.onTeamSelectionChange(props.color);
        }, disabled: !isActive, style: {
            border: "1px solid rgba(255, 255, 255, 1)",
            opacity: isActive ? "1" : "0.2",
            padding: "2px",
            height: "16px",
            width: "16px",
            minWidth: "16px",
            minHeight: "16px",
            borderRadius: "3px",
        } }, { children: _jsx("div", { className: "flex self-center items-center justify-center", style: {
                backgroundColor: `${props.color}`,
                height: "12px",
                minWidth: "12px",
                width: "12px",
                borderRadius: "1px",
            } }) })));
}
export default TeamSquareSelection;
