"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultGameStateDTO = exports.GameStateSchema = void 0;
const zod_1 = require("zod");
const PlayerDTO_1 = require("./PlayerDTO");
const TeamDTO_1 = require("./TeamDTO");
const GameStateSchema = zod_1.z.object({
    animatorSessionName: zod_1.z.string(),
    comment: zod_1.z.string(),
    deckName: zod_1.z.string(),
    language: zod_1.z.string(),
    gameStarted: zod_1.z.boolean(),
    sessionStarted: zod_1.z.boolean(),
    avatarSelectionOn: zod_1.z.boolean(),
    projectSelectionOn: zod_1.z.boolean(),
    gameEnded: zod_1.z.boolean(),
    sessionClosed: zod_1.z.boolean(),
    lobbyPlayers: zod_1.z.array(PlayerDTO_1.PlayerDTOSchema),
    teams: zod_1.z.array(TeamDTO_1.TeamDTOSchema),
    indexCurrentTeam: zod_1.z.number(),
    sessionName: zod_1.z.string(),
    deck: zod_1.z.array(zod_1.z.any()),
    isThereThrownEvent: zod_1.z.boolean(),
});
exports.GameStateSchema = GameStateSchema;
// Used whenever we need a default game state in the frontend
function getDefaultGameStateDTO() {
    const gameState = {
        animatorSessionName: '',
        comment: '',
        deckName: '',
        language: '',
        gameStarted: false,
        sessionStarted: false,
        avatarSelectionOn: false,
        projectSelectionOn: false,
        gameEnded: false,
        sessionClosed: false,
        lobbyPlayers: [],
        teams: [],
        indexCurrentTeam: 0,
        sessionName: '',
        deck: [],
        isThereThrownEvent: false,
    };
    return gameState;
}
exports.getDefaultGameStateDTO = getDefaultGameStateDTO;
