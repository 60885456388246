var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MicOnIcon from "../icons/MicOnIcon";
import CrossIcon from "../icons/CrossIcon";
import AvatarCompositionComponent from "../avatar_creation_component/AvatarCompositionComponent";
import { useEffect, useState } from "react";
import useMedia from "../../contexts/useMedia";
import MicOffIcon from "../icons/MicOffIcon";
import useSession from "../../contexts/useSession";
function PeopleInTeamComponent(props) {
    const { audioVideoClient } = useMedia();
    const { websocketPeer, sendRequest } = useSession();
    const [micOn, setMicOn] = useState(true); // TODO default state
    useEffect(() => {
        const onMuteChangeCallback = (sessionPeerId, isMuted) => {
            console.log("Received onMuteChangeOthers", sessionPeerId, isMuted, props.player.sessionPeerId);
            if (sessionPeerId === props.player.sessionPeerId) {
                setMicOn(!isMuted);
                console.log("State changed");
            }
        };
        audioVideoClient.onMuteChangeOthers.push(onMuteChangeCallback);
        return () => {
            const indexCallback = audioVideoClient.onMuteChangeOthers.indexOf(onMuteChangeCallback);
            if (indexCallback !== -1)
                audioVideoClient.onMuteChangeOthers.splice(indexCallback, 1);
            else
                console.warn("PeopleWaitingRoomComponent unmount, cannot remove callback");
        };
    });
    function getDedefaultMuted() {
        return __awaiter(this, void 0, void 0, function* () {
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.player.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                const isMuted = audioVideoClient.getMuted(mediaAPIPeerId);
                setMicOn(!isMuted);
                console.log(`getDedefaultMuted ${isMuted} sessionPeerId:${props.player.sessionPeerId} name:${props.player.name} mediaPeerId:${mediaAPIPeerId}`);
            }
        });
    }
    useEffect(() => {
        getDedefaultMuted();
    }, []);
    function onClickMuteUnmuteButton() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!props.player.isConnected)
                return;
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.player.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                audioVideoClient.muteParticipant(mediaAPIPeerId, props.player.sessionPeerId);
            }
            else
                console.warn("Current player has no mediaAPIPeerId");
        });
    }
    return (_jsxs("div", Object.assign({ className: "flex justify-around w-full text-white font-roboto gap-[10px]", style: {
            background: "rgba(18, 30, 70, 1)",
            height: "45px",
            paddingTop: "5px",
            minHeight: "45px",
            paddingLeft: "5px",
            paddingRight: "5px",
            borderRadius: "3px",
            opacity: props.player.isConnected ? 1 : 0.3
        } }, { children: [_jsx("div", Object.assign({ className: "flex relative items-start ", style: {
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    // backgroundImage: "url('/assets/UI/avatarTestWaitingRoom.png')",
                    // backgroundSize: "cover",
                } }, { children: _jsx(AvatarCompositionComponent, { avatarComposition: props.avatar, toRight: true }, Math.random().toString()) })), _jsxs("div", Object.assign({ className: "flex w-full flex-col items-end gap-[5px]" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex justify-end self-end items-end gap-[5px]", style: {
                            height: "16px",
                            minHeight: "16px",
                        } }, { children: [_jsxs("button", Object.assign({ className: "h-full", onClick: onClickMuteUnmuteButton, style: {
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    zIndex: 100
                                } }, { children: [micOn && _jsx(MicOnIcon, {}), !micOn && _jsx(MicOffIcon, {})] })), _jsx("button", Object.assign({ className: "h-full", onClick: () => { props.onClickTakePlayerFromTeam(props.player); }, style: {
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    zIndex: 100
                                } }, { children: _jsx(CrossIcon, {}) }))] })), _jsx("div", Object.assign({ className: "flex w-full font-roboto justify-end overflow-hidden flex-nowrap", style: {
                            textAlign: "right",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "14px",
                            height: "14px",
                        } }, { children: props.player.name }))] }))] })));
}
export default PeopleInTeamComponent;
