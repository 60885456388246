import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CardRecapComponent from "../../components/player_game_element_component/CardRecapComponent";
import AvatarTeamComponent from "../../components/player_game_element_component/AvatarTeamComponent";
import ETeam from "cresus-common/dist/dto/ETeam";
import FunctionalButton from "../../components/FunctionalButton";
function RecapEndOfSessionPlayerPage(props) {
    const allTeamsColors = [ETeam.RED, ETeam.BLUE, ETeam.GREEN, ETeam.YELLOW];
    const teamsFilledToFour = props.gameState.teams.map((team, index) => {
        const indexFoundTeam = allTeamsColors.indexOf(team.teamColor);
        if (indexFoundTeam !== -1)
            allTeamsColors.splice(indexFoundTeam, 1);
        return {
            teamColor: team.teamColor,
            team: team,
        };
    });
    for (let i = 0; i < (4 - props.gameState.teams.length); ++i) {
        teamsFilledToFour.push({
            teamColor: allTeamsColors[i],
            team: undefined,
        });
    }
    const lowerTurnTeam = props.gameState.teams.sort((a, b) => b.slotTeam.slotAbsoluteNumber - a.slotTeam.slotAbsoluteNumber)[0];
    const totalTurn = lowerTurnTeam ? Math.floor(lowerTurnTeam.slotTeam.slotAbsoluteNumber / 38) : 0; // TODO perhaps 39
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-start items-center h-screen w-screen gap-[20px] z-50 px-2.5 pt-2.5" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full h-full relative items-center gap-[20px]" }, { children: [_jsx("div", Object.assign({ className: "flex font-kalam text-white self-center text-center", style: {
                        fontSize: "60px",
                        fontStyle: "normal",
                        lineHeight: "96px",
                        fontWeight: "700",
                        letterSpacing: "0.6px",
                        textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347, 3px 3px 0px #353347, 3px -3px 0px #353347, -3px 3px 0px #353347, -3px -3px 0px #353347, 4px 0px 0px #353347, 0px 4px 0px #353347, 4px 1px 0px #353347, 1px 4px 0px #353347, 4px 2px 0px #353347, 2px 4px 0px #353347, 4px 3px 0px #353347, 3px 4px 0px #353347, -4px 0px 0px #353347, 0px -4px 0px #353347, -4px 1px 0px #353347, 1px -4px 0px #353347, -4px 2px 0px #353347, 2px -4px 0px #353347, -4px 3px 0px #353347, 3px -4px 0px #353347, 4px -1px 0px #353347, -1px 4px 0px #353347, 4px -2px 0px #353347, -2px 4px 0px #353347, 4px -3px 0px #353347, -3px 4px 0px #353347, -4px -1px 0px #353347, -1px -4px 0px #353347, -4px -2px 0px #353347, -2px -4px 0px #353347, -4px -3px 0px #353347, -3px -4px 0px #353347, -5px 5px 0px #353347, -6px 5px 0px #353347, -5px 6px 0px #353347, -4px 6px 0px #353347, -3px 6px 0px #353347, -2px 6px 0px #353347, -1px 6px 0px #353347, -0px 6px 0px #353347, -6px 4px 0px #353347, -6px 3px 0px #353347, -6px 2px 0px #353347, -6px 1px 0px #353347, -6px 0px 0px #353347, -6px -1px 0px #353347",
                        // textShadow:" -5px 5px 0px #352247"
                        // textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, -5px 5px 0px #352247"
                    } }, { children: "C'est la fin de la partie !" })), _jsxs("div", Object.assign({ className: "flex w-full", style: { maxWidth: "688px" } }, { children: [
                        // teamsFilledToFour.map((team, index) => {
                        //     return (
                        //         <div key={index} className="flex flex-col flex-grow mx-2.5"
                        //             style={{opacity: (!!team.team ? 1 : 0.3)}}>
                        //             <AvatarTeamComponent teamColor={team.teamColor}/>
                        //             {team.team &&
                        //             <CardRecapComponent team={team.team} />}
                        // </div>
                        // );
                        props.gameState.teams.map((team, index) => {
                            return (_jsxs("div", Object.assign({ className: "flex flex-col flex-grow mx-2.5" }, { children: [_jsx(AvatarTeamComponent, { teamColor: team.teamColor }), _jsx(CardRecapComponent, { team: team })] }), index));
                        }), ";"] })), _jsx("button", Object.assign({ style: {
                        border: "2px solid #000000",
                    } }, { children: _jsx(FunctionalButton, { text: "QUESTIONNAIRE DE SATISFACTION", onClick: () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSeuDDA_4WLhPIfG2bUCVzMadrNPu2wXeCrIWRAXEzpouVlEpQ/viewform?usp=sf_link'), colorMode: 0, isRound: false }) }))] })) })));
}
export default RecapEndOfSessionPlayerPage;
