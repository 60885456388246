import React from "react";
import SessionContext from "./SessionContext";
const useSession = () => {
    const context = React.useContext(SessionContext);
    if (context === undefined) {
        throw new Error("useSession must be used within a SessionContextProvider");
    }
    return context;
};
export default useSession;
