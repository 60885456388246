import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function InformationWaitingRoomComponent(props) {
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-black w-fit", style: {
                    padding: "0px 5px",
                    background: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "16px",
                    fontStyle: "normal",
                    textTransform: "uppercase",
                } }, { children: props.tag })), _jsx("div", Object.assign({ className: "flex font-roboto text-white", style: {
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "16px",
                } }, { children: props.information }))] })));
}
export default InformationWaitingRoomComponent;
