import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
function CustomRadioButton(props) {
    const [isSelected, setIsSelected] = useState(props.isSelected);
    const [isActivated, setIsActivated] = useState(props.isActivated);
    const [borderColor, setBorderColor] = useState("white");
    const [backgroundColor, setBackgroundColor] = useState("transparent");
    useEffect(() => {
        setIsSelected(props.isSelected);
    }, [props.isSelected]);
    useEffect(() => {
        setIsActivated(props.isActivated);
        if (props.mode === 0) {
            setBorderColor("rgba(111, 130, 192, 1)");
            setBackgroundColor("rgba(111, 130, 192, 1)");
        }
        if (props.mode === 1) {
            setBorderColor("white");
            setBackgroundColor("white");
        }
    }, [props.isActivated]);
    return (_jsx("button", Object.assign({ className: "flex justify-center items-center", onClick: () => props.handleRadioButtonCLick(), style: {
            width: 16,
            height: 16,
            padding: "2px",
            borderRadius: "20px",
            border: isActivated ? `${isSelected ? `1px solid ${borderColor}` : "1px solid white"}` : "transparent",
        } }, { children: _jsx("div", { className: "flex self-center", style: {
                width: 8,
                height: 8,
                borderRadius: "10px",
                backgroundColor: isActivated ? `${isSelected ? `${backgroundColor}` : "transparent"}` : "transparent",
            } }) })));
}
export default CustomRadioButton;
