import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// A wrapper to provide the board game context to the diferent components of the app
import { Suspense, createContext, useContext, useRef } from "react";
import ThreeJSComponent from "../components/3D/ThreeJSComponent";
import LoadingPlayerPage from "../pages/playerPages/LoadingPlayerPage";
const BoardGameContext = createContext(undefined);
function BoardGameProvider(props) {
    let sceneWrapper_;
    let diceRollWrapper_;
    console.log("BoardGameProviderRendering");
    function onSceneLoaded(sceneWrapper) {
        // console.log(sceneWrapper);
        sceneWrapper_ = sceneWrapper;
        processDelayedCalls();
    }
    function onDiceRollLoaded(diceRollWrapper) {
        console.log("onDiceRollLoaded", diceRollWrapper);
        diceRollWrapper_ = diceRollWrapper;
        processDelayedCalls();
    }
    const refWaitSceneLoadCalls = useRef(new Map());
    function handleCall(name, call, ...params) {
        let calls = refWaitSceneLoadCalls.current.get(name);
        if (!calls) {
            calls = [];
            refWaitSceneLoadCalls.current.set(name, calls);
        }
        calls.push({ call: call, params: params });
        processDelayedCalls();
    }
    function processDelayedCalls() {
        if (sceneWrapper_ && diceRollWrapper_) {
            refWaitSceneLoadCalls.current.forEach((calls, name) => {
                calls.forEach((callInfos) => {
                    callInfos.call(...callInfos.params);
                });
                calls.splice(0, calls.length); // We clear the array
            });
            refWaitSceneLoadCalls.current.clear();
        }
    }
    let value = {
        muteSound: (sound) => {
            handleCall("muteSound", (sound) => {
                if (sceneWrapper_) {
                    sceneWrapper_.muteSound(sound);
                }
                else {
                    console.warn("muteSound No sceneWrapper_");
                }
            }, sound);
        },
        playSound: (sound) => {
            handleCall("playSound", (sound) => {
                if (sceneWrapper_) {
                    sceneWrapper_.playSound(sound);
                }
                else {
                    console.warn("playSound No sceneWrapper_");
                }
            }, sound);
        },
        setIsAdmin: (isAdmin) => {
            handleCall("setIsAdmin", (isAdmin) => {
                console.log("setting board anim.BoardGameProvider.tsx1", sceneWrapper_);
                if (sceneWrapper_) {
                    console.log("setting board anim.BoardGameProvider.tsx2");
                    sceneWrapper_.setIsAdmin(isAdmin);
                }
                else {
                    console.warn("setIsAdmin No sceneWrapper_");
                }
            }, isAdmin);
        },
        updateCameraTarget: (team) => {
            handleCall("updateCameraTarget", (team) => {
                if (sceneWrapper_) {
                    sceneWrapper_.updateCameraTarget(team);
                }
                else {
                    console.warn("updateCameraTarget No sceneWrapper_");
                }
            }, team);
        },
        moveCamera: (cameraMode) => {
            handleCall("moveCamera", (cameraMode) => {
                if (sceneWrapper_) {
                    sceneWrapper_.moveCamera(cameraMode);
                    console.log("Moving camera");
                }
                else {
                    console.warn("moveCamera No sceneWrapper_");
                }
            }, cameraMode);
        },
        movePawn: (pawnId, index, animated) => {
            handleCall("movePawn", (pawnId, index, animated) => {
                if (sceneWrapper_) {
                    sceneWrapper_.movePawn(pawnId, index, animated);
                }
                else {
                    console.warn("movePawn No sceneWrapper_");
                }
            }, pawnId, index, animated);
        },
        activatePawn: (pawnId) => {
            handleCall("activatePawn", (pawnId) => {
                if (sceneWrapper_) {
                    console.log("Activating pawn");
                    sceneWrapper_.activatePawn(pawnId);
                }
                else {
                    console.warn("activatePawn No sceneWrapper_");
                }
            }, pawnId);
        },
        showDiceRoll: (diceRollConfig, callback) => {
            handleCall("showDiceRoll", (diceRollConfig, callback) => {
                if (diceRollWrapper_) {
                    diceRollWrapper_.showDiceRoll(diceRollConfig, callback);
                }
                else {
                    console.warn("showDiceRoll No sceneWrapper_");
                }
            }, diceRollConfig, callback);
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(BoardGameContext.Provider, Object.assign({ value: value }, { children: [_jsx(Suspense, Object.assign({ fallback: _jsx(LoadingPlayerPage, {}) }, { children: _jsx(ThreeJSComponent, { onSceneLoaded: onSceneLoaded, onDiceRollLoaded: onDiceRollLoaded }) })), props.children] })) }));
}
export const useBoardGame = () => {
    const context = useContext(BoardGameContext);
    // console.log("useBoardGame context: ", context, BoardGameContext);
    if (context === undefined) {
        throw new Error("useBoardGame must be used within a BoardGame");
    }
    return context;
};
export default BoardGameProvider;
