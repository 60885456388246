import EEffects from "cresus-common/dist/dto/EEffects";
function switchEffectToTextAddSubstract(effect) {
    switch (effect) {
        case EEffects.COMPTE_COURANT:
            return "€";
        case EEffects.POINTS_PLAISIR:
            return "point(s) plaisir";
        case EEffects.MODIFICATION_REVENUS:
            return "€";
        case EEffects.EPARGNE:
            return "%";
        case EEffects.MODIFICATION_CHARGE:
            return "€";
        case EEffects.NOMBRE_DES:
            return "dé(s)";
        case EEffects.NOUVEAU_REVENUS:
            return "€/mois";
        case EEffects.NOUVELLE_CHARGE:
            return "€/mois";
        case EEffects.TRANSFERT_EPARGNE:
            return "€";
        case EEffects.DEPLACEMENT:
            return "";
        default:
            return "not the right effect";
    }
}
//TODO will have to put this in the backend to add the new incomes
function getNamesOfEffectsFromType(effect) {
    const names = [];
    switch (effect) {
        case EEffects.COMPTE_COURANT:
            return names;
        case EEffects.POINTS_PLAISIR:
            return names;
        case EEffects.MODIFICATION_REVENUS:
            return names.concat("Salaire");
        case EEffects.EPARGNE:
            return names;
        case EEffects.NOUVELLE_CHARGE:
            return names.concat("input");
        case EEffects.MODIFICATION_CHARGE:
            let buff = names.concat(["Logement", "Énergies", "Internet/TV/Téléphone", "Assurances", "Alimentation Autres", "Alimentation Frais", "Essence", "Autres"]);
            return buff;
        case EEffects.NOMBRE_DES:
            return names;
        case EEffects.NOUVEAU_REVENUS:
            return names.concat("input");
        case EEffects.TRANSFERT_EPARGNE:
            return names.concat(["Compte courant vers épargne", "Épargne vers compte courant"]);
        case EEffects.DEPLACEMENT:
            return names;
        default:
            return ["not the right effect"];
    }
}
function getTemporalityOfEffectFromType(effect) {
    const temporality = [];
    switch (effect) {
        case EEffects.COMPTE_COURANT:
            return temporality.concat(["Instantanément", "À la fin du mois"]);
        case EEffects.POINTS_PLAISIR:
            return temporality.concat(["Instantanément", "À la fin du mois"]);
        case EEffects.MODIFICATION_REVENUS:
            return temporality.concat(["Indéfiniment", "Jusqu'à la fin du mois"]);
        case EEffects.EPARGNE:
            return temporality.concat(["Jusqu'à la fin du mois"]);
        case EEffects.NOUVELLE_CHARGE:
            return temporality.concat(["Indéfiniment"]); //"Jusqu'a la fin du mois",
        case EEffects.MODIFICATION_CHARGE:
            return temporality.concat(["Jusqu'à la fin du mois", "Indéfiniment"]);
        case EEffects.NOMBRE_DES:
            return temporality.concat(["Pendant 1 tour", "Pendant 2 tours", "Jusqu'à la fin du mois", "Indéfiniment"]);
        case EEffects.NOUVEAU_REVENUS:
            return temporality;
        case EEffects.TRANSFERT_EPARGNE:
            return temporality;
        case EEffects.DEPLACEMENT:
            return temporality.concat(["Instantanément", "À la fin du mois"]);
        default:
            return ["not the right effect"];
    }
}
export const EffectTextMap = new Map([
    [EEffects.COMPTE_COURANT, "Compte courant"],
    [EEffects.POINTS_PLAISIR, "Points plaisir"],
    [EEffects.TRANSFERT_EPARGNE, "Transfert d'épargne"],
    [EEffects.NOUVELLE_CHARGE, "Nouvelle charge"],
    [EEffects.MODIFICATION_CHARGE, "Modification de charge"],
    [EEffects.NOUVEAU_REVENUS, "Nouveau revenu"],
    [EEffects.MODIFICATION_REVENUS, "Modification de revenu"],
    [EEffects.EPARGNE, "Épargne"],
    [EEffects.DEPLACEMENT, "Déplacement"],
    [EEffects.NOMBRE_DES, "Nombre de dés"],
]);
function getEffectType(effect) {
    var _a;
    return (_a = EffectTextMap.get(effect)) !== null && _a !== void 0 ? _a : "not the right effect";
}
function getAddSubstractOfEffectFromType(effect) {
    const addSubstract = {
        input: false,
        addSub: false,
        text: ""
    };
    switch (effect) {
        case EEffects.COMPTE_COURANT:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "€";
            return addSubstract;
        case EEffects.POINTS_PLAISIR:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "point(s) plaisir";
            return addSubstract;
        case EEffects.MODIFICATION_REVENUS:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "€";
            return addSubstract;
        case EEffects.EPARGNE:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "%";
            return addSubstract;
        case EEffects.NOUVELLE_CHARGE:
            addSubstract.addSub = false;
            addSubstract.input = true;
            addSubstract.text = "€/mois";
            return addSubstract;
        case EEffects.MODIFICATION_CHARGE:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "€";
            return addSubstract;
        case EEffects.NOMBRE_DES:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "dé(s)";
            return addSubstract;
        case EEffects.NOUVEAU_REVENUS:
            addSubstract.addSub = false;
            addSubstract.input = true;
            addSubstract.text = "€/mois";
            return addSubstract;
        case EEffects.TRANSFERT_EPARGNE:
            addSubstract.addSub = true;
            addSubstract.input = true;
            addSubstract.text = "€";
            return addSubstract;
        case EEffects.DEPLACEMENT:
            addSubstract.addSub = false;
            addSubstract.input = true;
            addSubstract.text = "";
            return addSubstract;
        default:
            addSubstract.addSub = false;
            addSubstract.input = false;
            addSubstract.text = "not the right effect";
            return addSubstract;
    }
}
export { switchEffectToTextAddSubstract, getNamesOfEffectsFromType, getTemporalityOfEffectFromType, getAddSubstractOfEffectFromType, getEffectType };
