var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import SessionContext from "./SessionContext";
import protooClient from "protoo-client";
import useAuth from "./useAuth";
import ETeam from "cresus-common/dist/dto/ETeam";
import ESlotType from "cresus-common/dist/dto/ESlotType";
import ECardHandler from "cresus-common/dist/dto/ECardHandler";
import ETurn from "cresus-common/dist/dto/ETurn";
import ECardStateGame from "cresus-common/dist/dto/ECardStateGame";
import DeconnectedPage from "../pages/DeconnectedPage";
const teamRed = {
    teamColor: ETeam.RED,
    teamActive: true,
    cards: [],
    money: undefined,
    pleasurePoints: undefined,
    projectsProposed: [],
    project: { content: "", price: -1 },
    slotTeam: {
        slotNumber: 0,
        slotAbsoluteNumber: 0,
        slotType: ESlotType.TAXES
    },
    cardProposed: [],
    cardHandlerEnum: ECardHandler.NO_ACTION_REQUIRED,
    turnState: ETurn.NO_ACTION_REQUIRED,
    cardState: ECardStateGame.NO_CARDS_CHOOSABLE,
    players: [],
    dicesTurn: {
        diceArray: [],
        diceMalusBonusTurnOne: 0,
        diceMalusBonusTurnTwo: 0,
        diceMalusBonusEndOfMonth: 0,
        diceMalusBonusPermanent: 0,
        shouldReRollDices: false,
        numberOfDoubles: 0
    },
    charges: [],
    dicesEvent: {
        dices: [],
        ShouldDiceBeRolled: false,
        AreDicesRolled: false
    },
    hasBailifPenalty: false,
    incomes: []
};
const teamBlue = {
    teamColor: ETeam.BLUE,
    teamActive: true,
    cards: [],
    money: undefined,
    pleasurePoints: undefined,
    projectsProposed: [],
    project: { content: "", price: -1 },
    slotTeam: {
        slotNumber: 0,
        slotAbsoluteNumber: 0,
        slotType: ESlotType.TAXES
    },
    cardProposed: [],
    cardHandlerEnum: ECardHandler.NO_ACTION_REQUIRED,
    turnState: ETurn.NO_ACTION_REQUIRED,
    cardState: ECardStateGame.NO_CARDS_CHOOSABLE,
    players: [],
    dicesTurn: {
        diceArray: [],
        diceMalusBonusTurnOne: 0,
        diceMalusBonusTurnTwo: 0,
        diceMalusBonusEndOfMonth: 0,
        diceMalusBonusPermanent: 0,
        shouldReRollDices: false,
        numberOfDoubles: 0
    },
    charges: [],
    dicesEvent: {
        dices: [],
        ShouldDiceBeRolled: false,
        AreDicesRolled: false
    },
    hasBailifPenalty: false,
    incomes: []
};
const teamGreen = {
    teamColor: ETeam.GREEN,
    teamActive: true,
    cards: [],
    money: undefined,
    pleasurePoints: undefined,
    projectsProposed: [],
    project: { content: "", price: -1 },
    slotTeam: {
        slotNumber: 0,
        slotAbsoluteNumber: 0,
        slotType: ESlotType.TAXES
    },
    cardProposed: [],
    cardHandlerEnum: ECardHandler.NO_ACTION_REQUIRED,
    turnState: ETurn.NO_ACTION_REQUIRED,
    cardState: ECardStateGame.NO_CARDS_CHOOSABLE,
    players: [],
    dicesTurn: {
        diceArray: [],
        diceMalusBonusTurnOne: 0,
        diceMalusBonusTurnTwo: 0,
        diceMalusBonusEndOfMonth: 0,
        diceMalusBonusPermanent: 0,
        shouldReRollDices: false,
        numberOfDoubles: 0
    },
    charges: [],
    dicesEvent: {
        dices: [],
        ShouldDiceBeRolled: false,
        AreDicesRolled: false
    },
    hasBailifPenalty: false,
    incomes: []
};
const teamYellow = {
    teamColor: ETeam.YELLOW,
    teamActive: true,
    cards: [],
    money: undefined,
    pleasurePoints: undefined,
    projectsProposed: [],
    project: { content: "", price: -1 },
    slotTeam: {
        slotNumber: 0,
        slotAbsoluteNumber: 0,
        slotType: ESlotType.TAXES
    },
    cardProposed: [],
    cardHandlerEnum: ECardHandler.NO_ACTION_REQUIRED,
    turnState: ETurn.NO_ACTION_REQUIRED,
    cardState: ECardStateGame.NO_CARDS_CHOOSABLE,
    players: [],
    dicesTurn: {
        diceArray: [],
        diceMalusBonusTurnOne: 0,
        diceMalusBonusTurnTwo: 0,
        diceMalusBonusEndOfMonth: 0,
        diceMalusBonusPermanent: 0,
        shouldReRollDices: false,
        numberOfDoubles: 0
    },
    charges: [],
    dicesEvent: {
        dices: [],
        ShouldDiceBeRolled: false,
        AreDicesRolled: false
    },
    hasBailifPenalty: false,
    incomes: []
};
const teamNone = {
    teamColor: ETeam.NONE,
    teamActive: true,
    cards: [],
    money: undefined,
    pleasurePoints: undefined,
    projectsProposed: [],
    project: { content: "", price: -1 },
    slotTeam: {
        slotNumber: 0,
        slotAbsoluteNumber: 0,
        slotType: ESlotType.TAXES
    },
    cardProposed: [],
    cardHandlerEnum: ECardHandler.NO_ACTION_REQUIRED,
    turnState: ETurn.NO_ACTION_REQUIRED,
    cardState: ECardStateGame.NO_CARDS_CHOOSABLE,
    players: [],
    dicesTurn: {
        diceArray: [],
        diceMalusBonusTurnOne: 0,
        diceMalusBonusTurnTwo: 0,
        diceMalusBonusEndOfMonth: 0,
        diceMalusBonusPermanent: 0,
        shouldReRollDices: false,
        numberOfDoubles: 0
    },
    charges: [],
    dicesEvent: {
        dices: [],
        ShouldDiceBeRolled: false,
        AreDicesRolled: false
    },
    hasBailifPenalty: false,
    incomes: []
};
const gameState = {
    animatorSessionName: "",
    comment: "",
    deckName: "",
    language: "",
    gameStarted: false,
    sessionStarted: false,
    avatarSelectionOn: false,
    projectSelectionOn: false,
    lobbyPlayers: [],
    teams: [teamRed, teamBlue, teamGreen, teamYellow, teamNone],
    deck: [],
    indexCurrentTeam: 0,
    sessionName: "",
    gameEnded: false,
    sessionClosed: false,
    isThereThrownEvent: false,
};
const SessionContextProvider = (props) => {
    const { isLogged, role, token, sessionId } = useAuth();
    const [isReady, setIsReady] = useState(false);
    const [isDeconnected, setIsDeconnected] = useState(false);
    const [isReconnecting, setIsReconnecting] = useState(false);
    useEffect(() => {
        if (!window.websocketPeer)
            window.websocketPeer = createWebsocket(token);
    }, []);
    const value = {
        sessionId: sessionId,
        websocketPeer: window.websocketPeer,
        sendNotification: (method, data) => {
            if (value.websocketPeer) {
                value.websocketPeer.notify(method, data).catch((error) => {
                    var _a;
                    console.error("Error sending notification", method, error);
                    setIsDeconnected(true);
                    (_a = value.websocketPeer) === null || _a === void 0 ? void 0 : _a.close();
                });
            }
        },
        sendRequest: (method, data) => __awaiter(void 0, void 0, void 0, function* () {
            if (value.websocketPeer) {
                const result = yield value.websocketPeer.request(method, data).catch((error) => {
                    var _a;
                    console.error("Error sending request", method, error);
                    setIsDeconnected(true);
                    (_a = value.websocketPeer) === null || _a === void 0 ? void 0 : _a.close();
                });
                return result;
            }
            return;
        }),
        closeWebsocket: () => {
            if (value.websocketPeer) {
                value.websocketPeer.close();
            }
        },
        gameState: gameState,
    };
    console.log("RENDERING SESSIONCONTEXTPROVIDER ", isReady);
    function createWebsocket(token_) {
        console.log(sessionId, "test");
        const protocol = process.env.REACT_APP_WEBSOCKET_PROTOCOL; // TODO WSS 
        let url = `${protocol}://${process.env.REACT_APP_API_DOMAIN}/websocket?sessionId=${sessionId}&token=${token_}`;
        if (role === EPlayerRole.ANIMATOR)
            url += "&isAnimator=true";
        console.log("ROLEROLE", role, url);
        const websocketTransport = new protooClient.WebSocketTransport(url, { clientConfig: { closeTimeout: 10000 } });
        const peer = new protooClient.Peer(websocketTransport);
        peer.on("open", () => {
            console.log("Websocket connection opened", sessionId, token_, peer);
            setIsReady(true);
            setIsReconnecting(false);
            // gameState = result.data.gameState;
        });
        peer.on("request", (request, accept, reject) => {
            setIsReconnecting(false);
            console.log("Websocket request received");
        });
        peer.on("notification", (notification) => {
            setIsReconnecting(false);
        });
        peer.on("failed", () => {
            console.warn("Websocket connection failed");
            setIsReconnecting(true);
            peer.close();
        });
        peer.on("close", () => {
            console.warn("Websocket connection closed");
            setIsDeconnected(true);
            peer.close();
            setIsReconnecting(false);
        });
        return peer;
    }
    return (_jsxs(SessionContext.Provider, Object.assign({ value: value }, { children: [isReconnecting && _jsx("div", Object.assign({ className: "absolute", style: { top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10 } }, { children: _jsxs("div", Object.assign({ className: "absolute flex flex-col items-center", style: { top: "50%", left: "50%", transform: "translate(-50%, -50%)" } }, { children: [_jsx("span", { className: "loaderReconnection" }), _jsx("h1", Object.assign({ className: "text-4xl text-white" }, { children: "Reconnexion en cours" }))] })) })), !isReconnecting && _jsxs(_Fragment, { children: [isDeconnected && _jsx(_Fragment, { children: _jsx(DeconnectedPage, {}) }), !isDeconnected && isReady && props.children] })] })));
};
export default SessionContextProvider;
