var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DiceOneIcon from "../../icons/DiceOneIcon";
import DiceTwoIcon from "../../icons/DiceTwoIcon";
import DiceFiveIcon from "../../icons/DiceFiveIcon";
import DiceFourIcon from "../../icons/DiceFourIcon";
import DiceSixIcon from "../../icons/DiceSixIcon";
import DiceThreeIcon from "../../icons/DiceThreeIcon";
import DiceIcon from "../../icons/DiceIcon";
import FunctionalButtonBlueFound from "../../FonctionnalButtonBlueFoundComponent";
import ESlotType from "cresus-common/dist/dto/ESlotType";
import ETurn from "cresus-common/dist/dto/ETurn";
import switchToTeamName from "../../../tools/switchColorToNameTeam";
import useSessionContext from "../../../tools/useSessionContext";
function BoardDescriptionComponent(props) {
    const { sendRequest, websocketPeer } = useSessionContext();
    const [turnState, setTurnState] = useState(props.turnState);
    const [team, setTeam] = useState(props.team);
    const [dices, setDices] = useState(props.dices);
    const [slotType, setSlotType] = useState(props.slotType);
    const [isActive, setIsActive] = useState(props.isActive);
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "createTeams") { }
        });
    }, []);
    function handleClickRollDice() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("makeTeamThrowDiceTurn", {
                team: props.team
            }).catch((error) => { console.log("makeTeamThrowDiceTurn", error); });
            console.log("result of the resuqest broo ", result);
            if (result !== undefined) {
                props.setInsideGameState(result);
                console.log("result ", result);
            }
        });
    }
    function switchToDiceIcon(numberRolled) {
        switch (numberRolled) {
            case 1:
                return _jsx(DiceOneIcon, {});
            case 2:
                return _jsx(DiceTwoIcon, {});
            case 3:
                return _jsx(DiceThreeIcon, {});
            case 4:
                return _jsx(DiceFourIcon, {});
            case 5:
                return _jsx(DiceFiveIcon, {});
            case 6:
                return _jsx(DiceSixIcon, {});
            default:
                console.log("error not a number between 1 and 6");
                return _jsx("div", {});
        }
    }
    function switchSlotTypeToName(slotType) {
        switch (slotType) {
            case ESlotType.DILEMME:
                return "Dilemme";
            case ESlotType.ACCES_SOINS:
                return "Assurances complémentaires";
            case ESlotType.ETUDES:
                return "Études";
            case ESlotType.QUESTION:
                return "Question";
            case ESlotType.HUISSIER:
                return "Huissier";
            case ESlotType.PLAISIR:
                return "Plaisir";
            case ESlotType.SHOPPING:
                return "Shopping";
            case ESlotType.TAXES:
                return "Taxes";
            case ESlotType.TRANSPORT:
                return "Transport";
            case ESlotType.NONE:
                return "Départ";
            default:
                console.log("error not a slotType");
                return _jsx("div", {});
        }
    }
    useEffect(() => {
        setTeam(props.team);
    }, [props.team]);
    useEffect(() => {
        setDices(props.dices);
        console.log("props.dices ", props.dices);
    }, [props.dices]);
    useEffect(() => {
        setSlotType(props.slotType);
    }, [props.slotType]);
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    useEffect(() => {
        setTurnState(props.turnState);
    }, [props.turnState]);
    function handleIsResultDouble(dices) {
        let isDouble = false;
        const arrayWithoutMinusOne = dices.filter(dice => dice !== -1);
        if (arrayWithoutMinusOne.length === 2) {
            isDouble = (arrayWithoutMinusOne[0] === arrayWithoutMinusOne[1]) && (arrayWithoutMinusOne[0] !== 0);
        }
        return isDouble;
    }
    return (_jsxs("div", Object.assign({ className: `bg-dilemmeDeepPurpple h-[50px] w-full items-center justify-between font-roboto`, style: {
            display: isActive ? "flex" : "none",
            padding: "10px",
            minWidth: 748,
            maxWidth: 950,
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            color: isActive ? "white" : "transparent",
            zIndex: isActive ? "1" : "0",
        } }, { children: [turnState !== ETurn.MUST_ROLL_DICE &&
                _jsxs("div", Object.assign({ className: "flex gap-[10px]" }, { children: [_jsx("div", Object.assign({ className: "flex" }, { children: dices.map((dice) => {
                                if (dice > 0)
                                    return (_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px] items-center justify-center" }, { children: switchToDiceIcon(dice) })));
                                else
                                    return _jsx("div", {});
                            }) })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: ["L'\u00E9quipe ", switchToTeamName(team), " est arriv\u00E9e sur une case", _jsxs("b", { children: ["\u00A0", switchSlotTypeToName(slotType)] })] }))] })), turnState === ETurn.MUST_ROLL_DICE && !handleIsResultDouble(dices) &&
                _jsxs("div", Object.assign({ className: "flex" }, { children: [props.captain, " le capitaine de l'\u00E9quipe ", switchToTeamName(team), " n'a pas encore lanc\u00E9 les d\u00E9s."] })), turnState === ETurn.MUST_ROLL_DICE && handleIsResultDouble(dices) &&
                _jsxs("div", Object.assign({ className: "flex gap-[10px]" }, { children: [_jsx("div", Object.assign({ className: "flex" }, { children: dices.map((dice) => {
                                if (dice > 0)
                                    return (_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px] items-center justify-center" }, { children: switchToDiceIcon(dice) })));
                                else
                                    return _jsx("div", {});
                            }) })), _jsxs("div", Object.assign({ className: "flex" }, { children: [props.captain, " le capitaine de l'\u00E9quipe ", switchToTeamName(team), " \u00E0 fait un double et peut rejouer."] }))] })), turnState === ETurn.MUST_ROLL_DICE &&
                _jsx(FunctionalButtonBlueFound, { text: "Lancer les d\u00E9s", onClick: handleClickRollDice, isRound: true, colorMode: 1, fontSize: "12px", icon: DiceIcon, showLeftIcon: true })] })));
}
export default BoardDescriptionComponent;
