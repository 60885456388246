var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import PlayerLoginPage from "../pages/PlayerLoginPage";
import AnimatorLoginPage from "../pages/AnimatorLoginPage";
import Userfront from "@userfront/core";
import FunctionalButton from "../components/FunctionalButton";
import AuthContext from "./AuthContext";
import { useBoardGame } from "./BoardGameProvider";
// Navigation API (reload...)
var NavigationType;
(function (NavigationType) {
    NavigationType["NAVIGATE"] = "navigate";
    NavigationType["RELOAD"] = "reload";
    NavigationType["BACK_FORWARD"] = "back_forward";
    NavigationType["PRERENDER"] = "prerender";
})(NavigationType || (NavigationType = {}));
;
const AuthContextProvider = (props) => {
    const { playSound } = useBoardGame();
    const [isLogged, setIsLogged] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    let sessionIdStr = queryParams.get('sessionId');
    if (sessionIdStr)
        sessionStorage.setItem("sessionId", sessionIdStr);
    else
        sessionIdStr = sessionStorage.getItem("sessionId");
    let sessionId = undefined;
    if (!!sessionIdStr) {
        sessionId = parseInt(sessionIdStr);
    }
    let isAnimator = queryParams.get('isAnimator') === "true" ? "true" : "false";
    let isCoAnimator = queryParams.get('isCoAnimator') === "true" ? "true" : "false";
    console.log("HERE", isAnimator, isCoAnimator);
    const currentNavigationType = performance.getEntriesByType("navigation")[0].type;
    console.log("currentNavigationType", currentNavigationType);
    if (currentNavigationType === NavigationType.RELOAD || currentNavigationType === NavigationType.BACK_FORWARD) {
        isAnimator = sessionStorage.getItem("isAnimator") === "true" ? "true" : "false";
        if (isAnimator !== "true")
            isCoAnimator = sessionStorage.getItem("isCoAnimator") === "true" ? "true" : "false";
        if (sessionId !== undefined) {
            if (isAnimator === "true")
                window.location.href = `/?sessionId=${sessionId}&isAnimator=true`;
            else if (isCoAnimator === "true")
                window.location.href = `/?sessionId=${sessionId}&isCoAnimator=true`;
            else
                window.location.href = `/?sessionId=${sessionId}`;
        }
    }
    sessionStorage.setItem("isAnimator", isAnimator);
    sessionStorage.setItem("isCoAnimator", isCoAnimator);
    const [token, setToken] = React.useState(null);
    const [role, setRole] = React.useState(isAnimator === "true" || isCoAnimator === "true" ? EPlayerRole.ANIMATOR : EPlayerRole.PLAYER);
    const value = {
        isLogged: isLogged,
        token: token,
        role: role,
        sessionId: sessionId !== undefined ? sessionId : -1,
        onLoginSession: handleLogin,
    };
    function handleLogin(code, _playerRole) {
        playSound("GlobalAmbiance");
        setToken(code);
        setRole(_playerRole);
        setIsLogged(true);
        console.log("login", code, _playerRole);
    }
    // Only in dev, should not be shipped
    function handleLogout() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Deconnecting user " + Userfront.user);
            yield Userfront.logout({
                redirect: "/?isAnimator=true"
            }).then(respone => {
                console.log(respone);
            }).catch((err) => {
                console.log(err);
            });
        });
    }
    console.log("HEREISANIMATOR", isAnimator, isCoAnimator);
    // The coanimator logs the same way as a regular player
    let loginPage = (isAnimator === "true" ?
        _jsx(AnimatorLoginPage, { onLogin: (token) => handleLogin(token, EPlayerRole.ANIMATOR) }) :
        _jsx(PlayerLoginPage, { onLogin: (code) => handleLogin(code, isCoAnimator === "true" ? EPlayerRole.ANIMATOR : EPlayerRole.PLAYER), sessionId: sessionId }));
    if (sessionId === undefined) {
        loginPage = _jsx("div", Object.assign({ className: "absolute", style: { top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10 } }, { children: _jsx("div", Object.assign({ className: "absolute", style: { top: "50%", left: "50%", transform: "translate(-50%, -50%)" } }, { children: _jsx("h1", Object.assign({ className: "text-4xl text-white" }, { children: "Le lien est invalide, merci de contacter votre animateur" })) })) }));
    }
    return (_jsxs(AuthContext.Provider, Object.assign({ value: value }, { children: [isLogged ? props.children : loginPage, role === EPlayerRole.ANIMATOR ?
                _jsx("div", Object.assign({ id: "logoutButton", className: "absolute hidden", style: { right: 10, bottom: 5, zIndex: 10 } }, { children: _jsx(FunctionalButton, { onClick: handleLogout, text: "Logout" }) }))
                : _jsx(_Fragment, {})] })));
};
export default AuthContextProvider;
