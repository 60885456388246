import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../../App.css";
import "../../App.tailwind.css";
import CrossIcon from "../icons/CrossIcon";
function EmailSentToaster(props) {
    return (
    // <div className="flex absolute w-full h-full justify-center">
    _jsxs("div", Object.assign({ className: "flex flex-col absolute bg-white justify-center self-center items-center", style: {
            width: "424px",
            height: "181px",
            border: "2px solid #000000",
            padding: "32px",
            gap: "24px",
            zIndex: 1,
        } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("button", Object.assign({ className: "flex absolute justify-center items-center", style: {
                            // top: "7%",
                            left: "90%"
                        }, onClick: () => props.setToasterEmail(false) }, { children: _jsx(CrossIcon, {}) })), _jsx("div", Object.assign({ className: "flex self-stretch font-roboto text-dilemmeDeepPurpple justify-center items-center", style: {
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "normal",
                            textTransform: "uppercase",
                            fontStyle: "normal",
                        } }, { children: "mot de passe oubli\u00E9" }))] })), _jsx("div", Object.assign({ className: "flex self-stretch font-roboto justify-center items-center", style: {
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontStyle: "normal",
                } }, { children: "Un email de r\u00E9initialisation a \u00E9t\u00E9 envoy\u00E9 sur l'adresse suivante :" })), _jsx("div", Object.assign({ className: "flex self-stretch font-roboto justify-center items-center", style: {
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontStyle: "normal",
                } }, { children: props.email }))] })));
}
export default EmailSentToaster;
