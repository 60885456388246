var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Userfront from "@userfront/core";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import isNullOrUndefined from "../tools/isNullOrUndefined";
import useAuth from "../contexts/useAuth";
function AnimatorLoginPage(props) {
    console.log('rendering animator login page');
    const queryParams = new URLSearchParams(window.location.search);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginErrorOpen, setLoginErrorOpen] = useState(false);
    // Access and store the values of the query parameters
    const uuid = queryParams.get('uuid');
    const token = queryParams.get('token');
    const type = queryParams.get('type');
    const { onLoginSession, role, isLogged } = useAuth();
    useEffect(() => {
        const callback = (e) => {
            if (e.key === "Enter") {
                handleSubmitFinal();
            }
        };
        document.addEventListener("keydown", callback);
        return () => document.removeEventListener("keydown", callback);
    }, [email, password, rememberMe]);
    function handleSubmitFinal() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('login ');
            setAlertMessage('');
            yield Userfront.login({
                method: "password",
                email: email,
                password: password,
                redirect: "/WaitingRoomPage?isAnimator=true",
            }).then(response => {
                // Check if "Remember Me" is checked
                if (rememberMe) {
                    // Store the username and password in local storage or a cookie
                    localStorage.setItem('email', email);
                    localStorage.setItem('password', password);
                }
                else {
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                }
                setLoginErrorOpen(false);
                console.log(response, 'login success');
                // TODO this does not work
                onLoginSession(localStorage.getItem('accessToken'), EPlayerRole.ANIMATOR);
                console.log('login success', role, isLogged);
                // getInfo();
            }).catch((error) => {
                setAlertMessage(error.message);
                setLoginErrorOpen(true);
            });
        });
    }
    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function navigateToForgottenPassword() {
        window.location.href = "../ForgottenPasswordPage"; // TODO will break things
    }
    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');
        if (savedEmail && savedPassword) {
            setEmail(savedEmail);
            setPassword(savedPassword);
            setRememberMe(true);
        }
        else {
            setEmail("");
            setPassword("");
            setRememberMe(false);
        }
        setLoginErrorOpen(false);
        if (!isNullOrUndefined(Userfront.tokens.accessToken)) {
            // It appears that when this happens too fast, there is an extra rendering of the MediaContext underneath which causes issue with the audio
            setTimeout(() => {
                console.log('AnimatorLoginPage ' + Userfront.tokens.accessToken);
                props.onLogin(Userfront.tokens.accessToken);
            }, 1000);
            // onLoginSession(Userfront.tokens.accessToken, EPlayerRole.ANIMATOR);
            // navigate('/WaitingRoomPage?isAnimator=true');
        }
        // Userfront.redirectIfLoggedIn();
    }, []);
    return (_jsxs("div", Object.assign({ className: " w-screen h-screen flex absolute justify-center", style: {
            opacity: "1",
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.8",
                } }), _jsx("div", Object.assign({ className: "flex absolute w-full h-full justify-center" }, { children: _jsxs("div", Object.assign({ className: "flex relative flex-col bg-white ", style: {
                        width: "424px",
                        height: `${loginErrorOpen ? "469px" : "413px"}`,
                        top: "16.48%",
                        padding: "32px",
                        minHeight: "413px",
                        minWidth: "424px",
                        border: "2px solid #000000",
                        // opacity: "1",
                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative justify-center text-dilemmeDeepPurpple font-roboto  text-center", style: {
                                // height: "5.16%",
                                height: "21px",
                                width: "100%",
                                marginBottom: "24px",
                                // left: "37.91%",
                                fontSize: "18px",
                                lineHeight: "21.9px",
                                fontWeight: "700",
                            } }, { children: "CONNEXION" })), loginErrorOpen && (_jsx("div", Object.assign({ className: "flex flex-wrap relative text-teamRed font-roboto items-center text-start", style: {
                                height: "32px",
                                width: "100%",
                                marginBottom: "24px",
                                fontSize: "14px",
                                lineHeight: "normal",
                                fontWeight: "400",
                                fontStyle: "normal",
                            } }, { children: "Cette combinaison d'addresse e-mail et de mot de passe est erron\u00E9e" }))), _jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                // top: "20.88%",
                                // left: "7.78%",
                                marginBottom: "16px",
                                width: "100%",
                                // height: "15.72%"
                                /* 65px */
                                height: "64px",
                            } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                        width: "100%",
                                        height: "24.61%",
                                        fontSize: "14px",
                                        lineHeight: "normal",
                                        fontWeight: "600",
                                    } }, { children: "Email" })), _jsx("input", { id: "email-address", name: "Email", type: "email", required: true, value: email, onChange: value => setEmail(value.target.value), className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                        width: "100%",
                                        marginTop: "8px",
                                        height: "62.89%",
                                        paddingLeft: "2.46%",
                                        paddingRight: "2.46%",
                                        paddingTop: "1.97%",
                                        paddingBottom: "1.97%",
                                        border: "1px solid #A1AABC",
                                        borderRadius: "4px",
                                    } })] })), _jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                // top: "40.53%",
                                // left: "7.78%",
                                width: "100%",
                                // height: "15.72%",    /* 65px */
                                height: "64px",
                            } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                        width: "100%",
                                        height: "24.61%",
                                        fontSize: "14px",
                                        lineHeight: "16.41px",
                                        fontWeight: "600",
                                    } }, { children: "Mot de Passe" })), _jsxs("div", Object.assign({ className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                        width: "100%",
                                        marginTop: "8px",
                                        height: "62.89%",
                                        paddingLeft: "2.46%",
                                        paddingRight: "2.46%",
                                        paddingTop: "1.97%",
                                        paddingBottom: "1.97%",
                                        border: "1px solid #A1AABC",
                                        borderRadius: "4px",
                                    } }, { children: [_jsx("input", { id: "password", name: "Password", type: showPassword ? "text" : "password", autoComplete: "current-password", required: true, value: password, onChange: value => setPassword(value.target.value), 
                                            //use pattern for password when creating the account
                                            className: "flex absolute font-roboto text-black bg-brokenWhite", style: {
                                                left: "0%",
                                                top: "0%",
                                                paddingLeft: "2.46%",
                                                paddingRight: "9.46%",
                                                paddingTop: "1.97%",
                                                paddingBottom: "1.97%",
                                                height: "100%",
                                                width: "100%",
                                                borderRadius: "4px",
                                            } }), _jsx("button", Object.assign({ onClick: handleShowPassword, className: "flex relative", style: {
                                                height: "100%",
                                                width: "7.08%",
                                                left: "94%",
                                            } }, { children: _jsx("svg", Object.assign({ width: "100%", height: "100%", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9ZM12 4.5C17 4.5 21.27 7.61 23 12C21.27 16.39 17 19.5 12 19.5C7 19.5 2.73 16.39 1 12C2.73 7.61 7 4.5 12 4.5ZM3.18 12C4.83 15.36 8.24 17.5 12 17.5C15.76 17.5 19.17 15.36 20.82 12C19.17 8.64 15.76 6.5 12 6.5C8.24 6.5 4.83 8.64 3.18 12Z", fill: "#333333" }) })) }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row justify-start relative", style: {
                                // height: "3.93%",
                                height: "16px",
                                width: "41.51%",
                                marginTop: "16px",
                            } }, { children: [_jsx("input", { type: "checkbox", id: "remember me", name: "remember me", value: "remember me", checked: rememberMe, onChange: value => setRememberMe(value.target.checked), className: "flex relative", style: {
                                        height: "100%",
                                        width: "11.19%",
                                        marginRight: "5.63%",
                                    } }), _jsx("label", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        borderRadius: "3px",
                                    } }, { children: "Se souvenir de moi" }))] })), _jsx("button", Object.assign({ onClick: handleSubmitFinal, className: "flex relative self-center bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple", style: {
                                width: "84.67%",
                                // height: "12.29%",    /* 50px */
                                height: "50px",
                                marginBottom: "24px",
                                marginTop: "24px",
                                fontSize: "14px",
                                fontWeight: "900",
                                lineHeight: "14px",
                                textTransform: "uppercase",
                                fontStyle: "normal",
                                border: "2px solid #000000",
                            } }, { children: "SE CONNECTER" })), _jsx("button", Object.assign({ onClick: navigateToForgottenPassword, className: "flex relative text-black font-roboto justify-center text-center items-center hover:text-dilemmeDeepPurpple", style: {
                                height: "30px",
                                width: "100%",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textDecorationLine: "underline",
                            } }, { children: "Mot de passe oubli\u00E9 ?" }))] })) }))] })));
}
export default AnimatorLoginPage;
