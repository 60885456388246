var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import axios from "axios";
import BrowserDetector from "../utils/BrowserDetector";
function PlayerLoginPage(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const [code, setCode] = useState("");
    const [validationError, setValidationError] = useState("");
    const browserDetector = new BrowserDetector();
    const isBrowserSupported = browserDetector.isSupported();
    const [loginEnabled, setLoginEnabled] = useState(isBrowserSupported);
    useEffect(() => {
        console.log("Detected browser ", browserDetector.browser.name, browserDetector.browser.version);
        if (!isBrowserSupported) {
            setValidationError("Votre navigateur n'est pas supporté. Merci de mettre à jour votre navigateur pour une meilleure expérience.");
        }
        else {
            setValidationError("");
        }
        // We enable the login button after 15 seconds avoiding locking the users out of the session
        setTimeout(() => {
            setLoginEnabled(true);
        }, 15000);
    });
    function handleLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!loginEnabled) {
                window.open("https://browsehappy.com/?locale=fr", "_blank", "noopener");
            }
            const response = yield axios.get(`${process.env.REACT_APP_API_URL}/sessions/checkPassword/${props.sessionId}?code=${code}`)
                .catch((error) => {
                console.log("Error sending request", error);
                setValidationError("Erreur lors de l'envoie du code. Veuillez réessayer plus tard.");
            });
            if (response && response.data && response.data.IsOK) {
                props.onLogin(code);
            }
            else {
                setValidationError("Code invalide");
            }
        });
    }
    useEffect(() => {
        const callback = (e) => {
            if (e.key === "Enter") {
                handleLogin();
            }
        };
        document.addEventListener("keydown", callback);
        return () => document.removeEventListener("keydown", callback);
    }, [code]);
    return (_jsxs("div", Object.assign({ className: "flex fixed w-full h-full justify-start items-center flex-col gap-[40px] bg-dilemmeSimplePurple", style: {
            padding: 50,
            zIndex: 1000,
            minHeight: "100vh",
        } }, { children: [_jsx("div", { className: "flex relative justify-center items-center", style: {
                    width: "200px",
                    height: "79px",
                    minHeight: "79px",
                    minWidth: "200px",
                    backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                } }), _jsxs("div", Object.assign({ className: "flex flex-col bg-white p-8 gap-6", style: {
                    width: "424px",
                    border: "2px solid #000000",
                } }, { children: [_jsx("div", Object.assign({ className: "flex relative justify-center text-dilemmeDeepPurpple font-roboto  text-center", style: {
                            fontSize: "18px",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            lineHeight: "21px",
                        } }, { children: "connexion \u00E0 la session" })), _jsx("div", Object.assign({ className: "flex relative justify-center font-roboto text-black text-center", style: {
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                        } }, { children: "Veuillez entrer le code d'acc\u00E8s re\u00E7u par mail pour vous connecter \u00E0 la session." })), _jsxs("div", Object.assign({ className: "flex flex-col gap-2 relative justify-start w-full" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-black", style: {
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "16px",
                                } }, { children: "Code d'acc\u00E8s" })), _jsx("input", { type: "text", id: "code", name: "Code", required: true, value: code, onChange: value => setCode(value.target.value), className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                    width: "100%",
                                    padding: "8px 10px",
                                    height: "40px",
                                    borderRadius: "4px",
                                    border: "1px solid #A1AABC",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "16px",
                                } })] })), validationError.length !== 0 &&
                        _jsx("div", Object.assign({ style: { color: 'red' } }, { children: validationError })), _jsx("button", Object.assign({ onClick: () => { handleLogin(); }, className: `flex relative self-center bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple`, style: {
                            width: "100%",
                            height: "50px",
                            fontSize: "14px",
                            fontWeight: "900",
                            lineHeight: "14px",
                            textTransform: "uppercase",
                            fontStyle: "normal",
                            filter: "greyscale(100%)",
                        } }, { children: loginEnabled ? "SE CONNECTER" : "Mettre à jour votre navigateur" }))] })), _jsx("div", Object.assign({ className: "flex justify-center", style: {
                    height: "70px",
                    width: "45px",
                } }, { children: _jsx("div", { className: "flex relative justify-center items-center h-full", style: {
                        left: 18,
                        minWidth: "60px",
                        minHeight: "70px",
                        backgroundImage: "url(./assets/UI/logos/cresus_logo.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } }) }))] })));
}
export default PlayerLoginPage;
