import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import AvatarCompositionComponent from "../avatar_creation_component/AvatarCompositionComponent";
function MyTeamDisplayComponent(props) {
    const cigogneTexPaths = new Map([
        [ETeam.RED, "./assets/UI/cigognes_teams_images/cigogne_with_team_RED.png"],
        [ETeam.BLUE, "./assets/UI/cigognes_teams_images/cigogne_with_team_BLUE.png"],
        [ETeam.GREEN, "./assets/UI/cigognes_teams_images/cigogne_with_team_GREEN.png"],
        [ETeam.YELLOW, "./assets/UI/cigognes_teams_images/cigogne_with_team_YELLOW.png"],
    ]);
    const cigogneTexPath = cigogneTexPaths.get(props.team);
    props.avatars.forEach(element => {
        element.teamColor = props.team;
    });
    const indexOfMyPlayer = props.avatars.findIndex((el) => el.teamColor === props.myPlayerAvatar.teamColor);
    return (_jsxs("div", Object.assign({ className: "fixed flex flex-row justify-start teamHolder", style: {
            minWidth: 250,
            minHeight: 200,
            maxWidth: 250 * 2,
            maxHeight: 200 * 2,
            bottom: 0,
            right: 0,
        } }, { children: [props.myPlayerAvatar &&
                _jsx("div", Object.assign({ className: "absolute flex myAvatarInTeam", style: {
                        minWidth: 120,
                        minHeight: 120,
                        maxWidth: 120 * 2,
                        maxHeight: 120 * 2,
                        bottom: 0,
                        right: "0%",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        zIndex: 3,
                    } }, { children: _jsx(AvatarCompositionComponent, { toRight: false, avatarComposition: props.myPlayerAvatar }) })), _jsx("div", { className: "flex absolute myCigogneInTeam", style: {
                    minWidth: 200,
                    minHeight: 200,
                    maxWidth: 200 * 2,
                    maxHeight: 200 * 2,
                    bottom: 0,
                    right: "20%",
                    backgroundImage: `url(${cigogneTexPath}`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    zIndex: 2,
                } }), props.avatars.length > 0 && props.avatars.map((avatar, index) => {
                return (_jsx("div", Object.assign({ className: "absolute flex teamateAvatarInTeam", style: {
                        minWidth: 80,
                        minHeight: 80,
                        maxWidth: 80 * 2,
                        maxHeight: 80 * 2,
                        bottom: 0,
                        right: `calc(50% + ${index} * 19.2%)`,
                        //  backgroundColor: "grey",
                        backgroundPosition: "center",
                        // backgroundImage: "url(./assets/UI/two.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        zIndex: 5 - index,
                    } }, { children: _jsx(AvatarCompositionComponent, { toRight: false, avatarComposition: avatar }) })));
            })] })));
}
export default MyTeamDisplayComponent;
