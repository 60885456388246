import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import BoardIcon from "../../icons/BoardIcon";
import CardsIcon from "../../icons/CardsIcon";
import DiceIcon from "../../icons/DiceIcon";
import EffectsIcon from "../../icons/EffectsIcon";
import NextTurnIcon from "../../icons/NextTurnIcon";
import ParametersIcon from "../../icons/ParametersIcon";
import PedagogicContentIcon from "../../icons/PedagogicContentIcon";
import TeamsIcon from "../../icons/TeamsIcon";
function ButtonHeadBarComponent(props) {
    function switchToSelectIcon(name) {
        switch (name) {
            case "board":
                return _jsx(BoardIcon, {});
            case "cards":
                return _jsx(CardsIcon, {});
            case "effects":
                return _jsx(EffectsIcon, {});
            case "teams":
                return _jsx(TeamsIcon, {});
            case "pedagogic content":
                return _jsx(PedagogicContentIcon, {});
            case "dice":
                return _jsx(DiceIcon, {});
            case "next turn":
                return _jsx(NextTurnIcon, {});
            case "parameters":
                return _jsx(ParametersIcon, {});
            default:
                return null;
        }
    }
    const [isActivated, setIsActivated] = useState(props.isActivated);
    const [isOver, setOver] = useState(false);
    useEffect(() => {
        setIsActivated(props.isActivated);
        console.log("isEnabled ", props.isEnable);
    }, [props.isActivated]);
    return (_jsxs("button", Object.assign({ className: " flex gap-2 text-white justify-center items-center font-roboto text-center hover:bg-[#242D4B]", onClick: props.handleClick, onMouseOver: () => setOver(true), onMouseOut: () => setOver(false), disabled: props.isEnable, style: {
            height: "100%",
            width: "auto",
            padding: "8px",
            fontSize: "14px",
            fontWeight: "500px",
            lineHeight: "14px",
            textTransform: "capitalize",
            borderRadius: "5px",
            backgroundColor: isActivated ? "rgba(84, 101, 159, 1)" : `${isOver ? "#242D4B" : "rgba(12, 21, 51, 1)"}`,
        } }, { children: [_jsx("div", Object.assign({ className: "flex", style: {
                    width: 24,
                    height: 24,
                } }, { children: switchToSelectIcon(props.name) })), props.title &&
                _jsx("div", Object.assign({ className: "flex" }, { children: props.title }))] })));
}
export default ButtonHeadBarComponent;
