"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DicesTurnSchema = void 0;
const zod_1 = require("zod");
const DicesTurnSchema = zod_1.z.object({
    diceArray: zod_1.z.array(zod_1.z.number()),
    diceMalusBonusTurnOne: zod_1.z.number(),
    diceMalusBonusTurnTwo: zod_1.z.number(),
    diceMalusBonusEndOfMonth: zod_1.z.number(),
    diceMalusBonusPermanent: zod_1.z.number(),
    shouldReRollDices: zod_1.z.boolean(),
    numberOfDoubles: zod_1.z.number(),
});
exports.DicesTurnSchema = DicesTurnSchema;
