import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import PeopleInTeamComponent from "./PeopleInTeamComponent";
function chooseCigogne(teamColor) {
    switch (teamColor) {
        case ETeam.RED:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Red.png)";
        case ETeam.BLUE:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Blue.png)";
        case ETeam.YELLOW:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Yellow.png)";
        case ETeam.GREEN:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Green.png)";
        default:
            return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Red.png)";
    }
}
function TeamDisplayComponent(props) {
    console.log("RENDERING TeamDisplayComp", props);
    const cigogne = chooseCigogne(props.team.teamColor);
    const filledToFour = [...props.team.players];
    for (let i = 0; i < 4 - props.team.players.length; i++) {
        filledToFour.push(undefined);
    }
    console.log("filledToFour", filledToFour);
    return (_jsxs("div", Object.assign({ className: "flex flex-col h-full w-full gap-[5px] items-center justify-center", style: {
            minWidth: "152px",
            minHeight: "295px",
            opacity: props.isActive ? "1" : "0.2",
        } }, { children: [_jsx("div", Object.assign({ className: "flex w-full justify-center", style: {
                    backgroundImage: "url(./assets/UI/line.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "repeat-x",
                    backgroundPositionY: "bottom"
                } }, { children: _jsx("div", { className: "flex", style: {
                        width: "152px",
                        height: "95px",
                        backgroundImage: `${cigogne}`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundPositionY: "-1px"
                    } }) })), filledToFour.map((elem, index) => {
                if (elem) {
                    console.log("realPlayerDTO", elem.name, elem);
                    return (_jsx(PeopleInTeamComponent, { onClickTakePlayerFromTeam: props.onClickTakePlayerFromTeam, player: elem, avatar: elem.avatarComposition }, "PeopleInTeamComponent" + index.toString() + props.team.teamColor));
                }
                else {
                    return (_jsx("div", { className: "flex justify-around w-full text-white font-roboto gap-[10px]", style: {
                            background: "rgba(255, 255, 255, 0.2)",
                            height: "45px",
                            borderRadius: "3px",
                        } }));
                }
            })] })));
}
export default TeamDisplayComponent;
