import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import { useEffect, useState } from "react";
import DiceIcon from "../../icons/DiceIcon";
import DiceOneIcon from "../../icons/DiceOneIcon";
import DiceTwoIcon from "../../icons/DiceTwoIcon";
import DiceThreeIcon from "../../icons/DiceThreeIcon";
import DiceFourIcon from "../../icons/DiceFourIcon";
import DiceFiveIcon from "../../icons/DiceFiveIcon";
import DiceSixIcon from "../../icons/DiceSixIcon";
function HandleTeamDiceThrow(props) {
    const [team, setTeam] = useState(props.team);
    const [colorCigogne, setColorCigogne] = useState("");
    const [dices, setDices] = useState(props.dices);
    const [isChoosen, setIsChoosen] = useState(props.isChoosen);
    const [AreDicesThrown, setAreDicesThrown] = useState(props.AreDicesThrown);
    useEffect(() => {
        setTeam(props.team);
        switch (props.team) {
            case ETeam.BLUE:
                setColorCigogne("Blue");
                break;
            case ETeam.GREEN:
                setColorCigogne("Green");
                break;
            case ETeam.YELLOW:
                setColorCigogne("Yellow");
                break;
            case ETeam.RED:
                setColorCigogne("Red");
                break;
            default:
                setColorCigogne("none");
                break;
        }
    }, [props.team]);
    useEffect(() => {
        setDices(props.dices);
    }, [props.dices]);
    useEffect(() => {
        setIsChoosen(props.isChoosen);
    }, [props.isChoosen]);
    useEffect(() => {
        setAreDicesThrown(props.AreDicesThrown);
    }, [props.AreDicesThrown]);
    function handleShowDices(numberRolled) {
        switch (numberRolled) {
            case 1:
                return _jsx(DiceOneIcon, {});
            case 2:
                return _jsx(DiceTwoIcon, {});
            case 3:
                return _jsx(DiceThreeIcon, {});
            case 4:
                return _jsx(DiceFourIcon, {});
            case 5:
                return _jsx(DiceFiveIcon, {});
            case 6:
                return _jsx(DiceSixIcon, {});
            default:
                console.log("error not a number between 1 and 6");
                return _jsx("div", {});
        }
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px]", style: {
            width: "22.23%",
            minWidth: 167,
            maxWidth: 167 * 2,
        } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-center" }, { children: [_jsx("div", { className: "flex", style: {
                            opacity: isChoosen ? 1 : 0.2,
                            width: 167,
                            minWidth: 167,
                            height: 95,
                            minHeight: 95,
                            backgroundImage: `url('/assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_${colorCigogne}.png')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center 1px",
                            backgroundRepeat: "no-repeat",
                        } }), _jsx("div", { className: "flex ", style: {
                            opacity: isChoosen ? 1 : 0.2,
                            minWidth: "100%",
                            height: 2,
                            minHeight: 2,
                            backgroundRepeat: "repeat no-repeat",
                            backgroundImage: "url('/assets/UI/line.png')",
                            backgroundSize: "cover",
                            backgroundPositionY: -1,
                        } })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-center h-[79px] w-full  p-[10px]", style: {
                    backgroundColor: isChoosen ? "#121E46" : "rgba(255, 255, 255, 0.2)",
                    borderRadius: "4px",
                    minHeight: 79,
                } }, { children: [!AreDicesThrown && isChoosen &&
                        _jsxs("div", Object.assign({ className: "flex flex-col items-center justify-center gap-[10px] font-roboto ", style: {
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "19px",
                            } }, { children: ["En attente du lancer", _jsx("button", Object.assign({ onClick: () => props.handleClickRollDice(team), className: "flex hover:border-[1px] hover:border-white hover:cursor-pointer", style: {
                                        width: 30,
                                        height: 30,
                                        padding: 5,
                                        backgroundColor: "rgba(255, 255, 255, 0.20)",
                                        borderRadius: "50%",
                                    } }, { children: _jsx(DiceIcon, {}) }))] })), AreDicesThrown && isChoosen &&
                        _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-6 h-6", style: {
                                        minHeight: 24,
                                        maxHeight: 24,
                                        minWidth: 24,
                                        maxWidth: 24,
                                    } }, { children: handleShowDices(dices[0]) })), dices[1] !== -1 &&
                                    _jsx("div", Object.assign({ className: "flex w-6 h-6", style: {
                                            minHeight: 24,
                                            maxHeight: 24,
                                            minWidth: 24,
                                            maxWidth: 24,
                                        } }, { children: handleShowDices(dices[1]) })), _jsxs("span", Object.assign({ className: "flex ml-[5px]", style: {} }, { children: ["= ", dices[1] === -1 ? 0 + dices[0] : dices[0] + dices[1]] }))] }))] }))] })));
}
export default HandleTeamDiceThrow;
