import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CardRecapComponent from "../../components/player_game_element_component/CardRecapComponent";
import AvatarTeamComponent from "../../components/player_game_element_component/AvatarTeamComponent";
import FunctionalButton from "../../components/FunctionalButton";
function RecapEndOfSessionPage(props) {
    // const allTeamsColors = [ETeam.RED, ETeam.BLUE, ETeam.GREEN, ETeam.YELLOW];
    // const teamsFilledToFour: { team: TeamDTO | undefined, teamColor: ETeam }[] = props.gameState.teams.map((team, index) => {
    //     const indexFoundTeam = allTeamsColors.indexOf(team.teamColor);
    //     if (indexFoundTeam !== -1)
    //         allTeamsColors.splice(indexFoundTeam, 1);
    //     return {
    //         teamColor: team.teamColor,
    //         team: team,
    //     }
    // });
    // for (let i = 0; i < (4 - props.gameState.teams.length); ++i) {
    //     teamsFilledToFour.push({
    //         teamColor: allTeamsColors[i],
    //         team: undefined,
    //     });
    // }
    const lowerTurnTeam = props.gameState.teams.sort((a, b) => b.slotTeam.slotAbsoluteNumber - a.slotTeam.slotAbsoluteNumber)[0];
    const totalTurn = lowerTurnTeam ? Math.floor(lowerTurnTeam.slotTeam.slotAbsoluteNumber / 38) : 0; // TODO perhaps 39
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-start items-center h-screen w-screen px-2.5 pt-2.5" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full h-full relative items-center" }, { children: [_jsxs("div", Object.assign({ className: "header-recap w-full p-5 mb-5 bg-dilemmeDarkBlue text-white flex flex-col font-kalam justify-start", style: { borderRadius: "3px" } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "text-3xl font-bold uppercase", style: { lineHeight: "45px" } }, { children: "r\u00E9capitulatif de la partie" })), _jsx(FunctionalButton, { text: "Cloturer la session", onClick: props.handleEndOfSession })] })), _jsxs("div", Object.assign({ className: "text-base" }, { children: [totalTurn, " tours"] }))] })), _jsx("div", Object.assign({ className: "flex w-full", style: { maxWidth: "688px" } }, { children: props.gameState.teams.map((team, index) => {
                        return (_jsxs("div", Object.assign({ className: "flex flex-col flex-grow mx-2.5" }, { children: [_jsx(AvatarTeamComponent, { teamColor: team.teamColor }), _jsx(CardRecapComponent, { team: team })] }), index));
                    }) }))] })) })));
}
export default RecapEndOfSessionPage;
