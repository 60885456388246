import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
function TeamSelectedComponement(props) {
    return (_jsxs("div", Object.assign({ className: "w-auto h-[18px] self-center flex flex-row gap-[5px]" }, { children: [_jsx("button", Object.assign({ className: "flex font-roboto text-white items-center", onClick: () => {
                    // updateTeamsSelected(ETeam.RED);
                    props.handleTeamInactive(ETeam.RED);
                }, style: {
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "14px",
                    fontStyle: "normal",
                    padding: "0px 5px",
                    backgroundColor: props.teamsSelected[ETeam.RED] ? "rgba(221, 40, 16, 1)" : "rgba(227, 227, 227, 1)",
                    borderRadius: "2px",
                } }, { children: "Rouge" })), _jsx("button", Object.assign({ className: "flex font-roboto text-white items-center", onClick: () => {
                    // updateTeamsSelected(ETeam.BLUE);
                    props.handleTeamInactive(ETeam.BLUE);
                }, style: {
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "14px",
                    fontStyle: "normal",
                    padding: "0px 5px",
                    backgroundColor: props.teamsSelected[ETeam.BLUE] ? "rgba(16, 110, 221, 1)" : "rgba(227, 227, 227, 1)",
                    borderRadius: "2px",
                } }, { children: "Bleue" })), _jsx("button", Object.assign({ className: "flex font-roboto items-center", onClick: () => {
                    // updateTeamsSelected(ETeam.YELLOW);
                    props.handleTeamInactive(ETeam.YELLOW);
                }, style: {
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "14px",
                    fontStyle: "normal",
                    padding: "0px 5px",
                    color: props.teamsSelected[ETeam.YELLOW] ? "black" : "white",
                    backgroundColor: props.teamsSelected[ETeam.YELLOW] ? "rgba(255, 213, 63, 1)" : "rgba(227, 227, 227, 1)",
                    borderRadius: "2px",
                } }, { children: "Jaune" })), _jsx("button", Object.assign({ className: "flex font-roboto text-white items-center", onClick: () => {
                    // updateTeamsSelected(ETeam.GREEN);
                    props.handleTeamInactive(ETeam.GREEN);
                }, style: {
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "14px",
                    fontStyle: "normal",
                    padding: "0px 5px",
                    color: props.teamsSelected[ETeam.GREEN] ? "black" : "white",
                    backgroundColor: props.teamsSelected[ETeam.GREEN] ? "rgba(36, 189, 79, 1)" : "rgba(227, 227, 227, 1)",
                    borderRadius: "2px",
                } }, { children: "Verte" }))] })));
}
export default TeamSelectedComponement;
