var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import MicOnIcon from "../icons/MicOnIcon";
import MicOffIcon from "../icons/MicOffIcon";
import useMedia from "../../contexts/useMedia";
import useSession from "../../contexts/useSession";
function PeopleWaitingRoomComponent(props) {
    const { audioVideoClient } = useMedia();
    const { websocketPeer, sendRequest } = useSession();
    const [micOn, setMicOn] = useState(props.micOn);
    useEffect(() => {
        const onMuteChangeCallback = (sessionPeerId, isMuted) => {
            console.log("Received onMuteChangeOthers", sessionPeerId, isMuted, props.sessionPeerId);
            if (sessionPeerId === props.sessionPeerId) {
                setMicOn(!isMuted);
                console.log("State changed");
            }
        };
        audioVideoClient.onMuteChangeOthers.push(onMuteChangeCallback);
        return () => {
            const indexCallback = audioVideoClient.onMuteChangeOthers.indexOf(onMuteChangeCallback);
            if (indexCallback !== -1)
                audioVideoClient.onMuteChangeOthers.splice(indexCallback, 1);
            else
                console.warn("PeopleWaitingRoomComponent unmount, cannot remove callback");
        };
    });
    function getDedefaultMuted() {
        return __awaiter(this, void 0, void 0, function* () {
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                const isMuted = audioVideoClient.getMuted(mediaAPIPeerId);
                setMicOn(!isMuted);
                console.log(`getDedefaultMuted ${isMuted} sessionPeerId:${props.sessionPeerId} name:${props.name} mediaPeerId:${mediaAPIPeerId}`);
            }
            else {
                console.warn("Current player has no mediaAPIPeerId " + props.sessionPeerId + " " + props.name);
            }
        });
    }
    useEffect(() => {
        getDedefaultMuted();
    }, []);
    function onClickMuteUnmuteButton() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!props.player.isConnected)
                return;
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                audioVideoClient.muteParticipant(mediaAPIPeerId, props.sessionPeerId);
            }
            else
                console.warn("Current player has no mediaAPIPeerId");
        });
    }
    return (_jsxs("div", Object.assign({ className: "flex justify-around w-full text-white font-roboto", style: {
            background: "rgba(0, 0, 0, 0.20)",
            height: "30px",
            minHeight: "30px",
            paddingLeft: "4px",
            opacity: props.player.isConnected ? 1 : 0.3,
        } }, { children: [_jsx("div", { className: "flex self-end ", style: {
                    height: "26px",
                    width: "20px",
                    // backgroundImage: "url('/assets/UI/avatarTestWaitingRoom.png')",
                    backgroundSize: "cover",
                } }), _jsx("div", Object.assign({ className: "flex items-center", style: {
                    width: "86.57%",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginLeft: "8px",
                    paddingRight: "8px",
                } }, { children: props.name })), _jsx("button", Object.assign({ className: "flex relative self-center items-enter", onClick: onClickMuteUnmuteButton, style: {
                    right: "0px",
                    height: "20px",
                    width: "30px",
                    paddingRight: "10px",
                } }, { children: _jsxs("div", { children: [micOn && _jsxs("div", Object.assign({ className: "flex", style: { borderRadius: "50%",
                                backgroundColor: "black" } }, { children: [_jsx(MicOnIcon, {}), " "] })), !micOn && _jsx(MicOffIcon, {})] }) }))] })));
}
export default PeopleWaitingRoomComponent;
