import { jsx as _jsx } from "react/jsx-runtime";
import ItemComponent from "./ItemComponent";
function ItemSelectionComponent(props) {
    const { index, onItemClicked } = props;
    const columns = [false, false, false, false, false, false]; // values are ignored but count is important
    const links = ["skin", "hair", "hair_color", "eyes", "nose", "mouth", "bonus"];
    function handleClick(index, item) {
        // const buffColumns = [...columns];
        // buffColumns.forEach((col, i) => {
        //     if(i === index)
        //         buffColumns[i] = !buffColumns[i];
        //     else
        //         buffColumns[i] = false; 
        // })
        // setColumns(buffColumns);
        onItemClicked(index, item);
    }
    return (_jsx("div", Object.assign({ className: " flex w-full h-[50px] gap-[5px]" }, { children: columns.map((col, j) => {
            const isSelected = props.selection[links[index]] === j;
            return (_jsx(ItemComponent, { handleClick: handleClick, item: `${links[index]}`, index: j, isSelected: isSelected, url_name: `${links[index]}-${j + 1}` }, "ItemComponent" + j));
        }) })));
}
export default ItemSelectionComponent;
