import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function DeconnectedPage() {
    function handleReload() {
        window.location.reload();
    }
    return (_jsxs("div", Object.assign({ className: "flex absolute w-full h-full justify-start items-center flex-col gap-[40px] bg-dilemmeSimplePurple", style: {
            padding: 50,
        } }, { children: [_jsx("div", { className: "flex relative justify-center items-center", style: {
                    width: "200px",
                    height: "79px",
                    minHeight: "79px",
                    minWidth: "200px",
                    backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                } }), _jsxs("div", Object.assign({ className: "flex flex-col bg-white p-8 gap-6", style: {
                    width: "424px",
                    border: "2px solid #000000",
                } }, { children: [_jsx("div", Object.assign({ className: "flex relative justify-center text-dilemmeDeepPurpple font-roboto  text-center", style: {
                            fontSize: "18px",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            lineHeight: "21px",
                        } }, { children: "Vous avez \u00E9t\u00E9 d\u00E9connect\u00E9" })), _jsx("div", Object.assign({ className: "flex relative justify-center font-roboto text-black text-center", style: {
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                        } }, { children: "Veuillez recharger la page pour rejoindre la session." })), _jsx("button", Object.assign({ onClick: handleReload, className: "flex relative self-center bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple", style: {
                            width: "100%",
                            height: "50px",
                            fontSize: "14px",
                            fontWeight: "900",
                            lineHeight: "14px",
                            textTransform: "uppercase",
                            fontStyle: "normal",
                            border: "2px solid #000000",
                        } }, { children: "RECHARGER LA PAGE" }))] })), _jsx("div", Object.assign({ className: "flex justify-center", style: {
                    height: "70px",
                    width: "45px",
                } }, { children: _jsx("div", { className: "flex relative justify-center items-center h-full", style: {
                        left: 18,
                        minWidth: "60px",
                        minHeight: "70px",
                        backgroundImage: "url(./assets/UI/logos/cresus_logo.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } }) }))] })));
}
export default DeconnectedPage;
