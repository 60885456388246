var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ShaderMaterial, Color, TextureLoader } from "three";
import { loadTextFile } from "../../../utils/loadTextFile";
import * as THREE from 'three';
export function createUFOBeamMaterial(depthBuffer) {
    return __awaiter(this, void 0, void 0, function* () {
        const textureLoader = new TextureLoader();
        let texNoise = textureLoader.load("./assets/3D/NoiseUFO.png");
        texNoise.wrapS = THREE.MirroredRepeatWrapping;
        texNoise.wrapT = THREE.MirroredRepeatWrapping;
        texNoise.generateMipmaps = false;
        const UFOBeamVertexShader = yield loadTextFile("./assets/shaders/UFOBeam.vs");
        const UFOBeamFragmentShader = yield loadTextFile("./assets/shaders/UFOBeam.fs");
        const material = new ShaderMaterial({
            uniforms: {
                time: { value: 0 },
                color: { value: new Color(0.2, 0.0, 0.1) },
                noiseTex: { value: texNoise },
                depthTex: { value: depthBuffer },
            },
            transparent: false,
            vertexShader: UFOBeamVertexShader,
            fragmentShader: UFOBeamFragmentShader,
        });
        return material;
    });
}
