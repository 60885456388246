var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSessionContext from '../../tools/useSessionContext';
import useMedia from '../../contexts/useMedia';
import { useBoardGame } from '../../contexts/BoardGameProvider';
import { CameraMode } from '../../components/3D/CameraMode';
import SetOfButtonsPlayerComponent from '../../components/player_game_element_component/SetOfButtonsPlayerComponent';
import { truncateString } from 'cresus-common/dist/utils/StringUtils';
function ChooseNamePage() {
    const { moveCamera } = useBoardGame();
    useEffect(() => {
        moveCamera(CameraMode.SHOWCASE);
    });
    const { setCameraMode, openMediaSettings, audioVideoClient } = useMedia();
    const { gameState, sendRequest, websocketPeer } = useSessionContext();
    const [isChatShown, setIsChatShown] = useState(false);
    const [insideGameState, setInsideGameState] = useState(gameState);
    const [errorName, setErrorName] = useState();
    const [playerName, setPlayerName] = useState("");
    const [myPlayer, setMyPlayer] = useState();
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    function updateIfSessionStarted(message) {
        console.log("test callback", message);
        const buffGameState = Object.assign({}, insideGameState);
        buffGameState.sessionStarted = message.data.gameState;
        setInsideGameState(buffGameState);
    }
    function sendPlayerName() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("joinLobby", { playerNameToSend: playerName })
                .catch((error) => { console.log("error", error); });
            console.log(result, 'the result');
        });
    }
    const navigate = useNavigate();
    useEffect(() => {
        const callback = (e) => {
            if (e.key === "Enter") {
                handleLogin();
            }
        };
        document.addEventListener("keydown", callback);
        return () => document.removeEventListener("keydown", callback);
    }, [playerName]);
    function handleLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (playerName === "")
                return;
            const { nameAvailable } = yield sendRequest("checkPlayerNameAvailable", { playerNameToSend: playerName });
            if (!nameAvailable) {
                setErrorName("Ce nom est déjà pris");
            }
            else {
                yield sendPlayerName();
                navigate('/WaitingRoomPlayerPage');
            }
        });
    }
    function handleNavigation(gameStateDTO) {
        // Navigate to the proper page once we are reconnected as a player
        if (gameStateDTO.gameStarted)
            navigate("/GameInProgressPage");
        else if (gameStateDTO.projectSelectionOn)
            navigate("/PlayerDistributionProjectPage");
        else if (gameStateDTO.avatarSelectionOn)
            navigate("/AvatarCompositionPage");
        else if (gameStateDTO.sessionStarted)
            navigate("/IntroductionPlayerPage");
    }
    // notifications to update the gamestate websocket //////////////////////////////
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "startSession") {
                updateIfSessionStarted(notification);
                handleNavigation(notification.data);
            }
            if (notification.method === "updateGameState") {
                setInsideGameState(notification.data);
                handleNavigation(notification.data);
            }
        });
    }, []);
    function toggleChat() {
        setIsChatShown(!isChatShown);
    }
    // function to get the gamestate from the server ///////////////////////////////
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.error("error", error); });
            console.log(result, "hello");
            if (result !== undefined)
                setInsideGameState(result);
        });
    }
    //-----------------------------------------------------------------------------//
    useEffect(() => {
        console.log("test", insideGameState);
    }, [insideGameState]);
    useEffect(() => {
        getGameState();
    }, []);
    return (_jsxs("div", Object.assign({ className: "absolute flex w-screen h-screen justify-center items-center p-[50px]", style: {} }, { children: [_jsx(SetOfButtonsPlayerComponent /*alertNewMessage={newMessageAlert} */, { toggleChat: toggleChat, isMapModifiable: false, isOnlyConfigButton: false }), _jsxs("div", Object.assign({ className: "flex flex-col bg-white p-8 gap-6", style: {
                    width: "424px",
                    height: "287px",
                    border: "2px solid #000000",
                } }, { children: [_jsx("div", Object.assign({ className: "flex relative justify-center text-dilemmeDeepPurpple font-roboto  text-center", style: {
                            fontSize: "18px",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            lineHeight: "21px",
                        } }, { children: "nom ou pseudo" })), _jsx("div", Object.assign({ className: "flex relative justify-center font-roboto text-black text-center", style: {
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                        } }, { children: "Les autres joueurs vous verront sous ce nom/s" })), _jsxs("div", Object.assign({ className: "flex flex-col gap-2 relative justify-start w-full" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-black", style: {
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "16px",
                                } }, { children: "Votre nom ou pseudo" })), _jsx("input", { type: "text", id: "code", name: "Code", required: true, value: playerName, onChange: value => setPlayerName(truncateString(value.target.value, 30)), className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                    width: "100%",
                                    padding: "8px 10px",
                                    height: "40px",
                                    borderRadius: "4px",
                                    border: "1px solid #A1AABC",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "16px",
                                } }), _jsx("div", Object.assign({ className: "font-roboto", style: { color: 'red', height: 0 } }, { children: errorName }))] })), _jsx("button", Object.assign({ onClick: () => { handleLogin(); }, className: "flex relative self-center bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple", style: {
                            width: "100%",
                            height: "50px",
                            fontSize: "14px",
                            fontWeight: "900",
                            lineHeight: "14px",
                            textTransform: "uppercase",
                            fontStyle: "normal",
                            border: "2px solid #000000",
                        } }, { children: "rejoindre la session" }))] }))] })));
}
export default ChooseNamePage;
