var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import useSessionContext from "../../tools/useSessionContext";
import React from "react";
function VideoPlayer(props) {
    const refVideo = React.useRef(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const HAVE_ENOUGH_DATA = 40; // https://html.spec.whatwg.org/multipage/media.html#the-ready-states
    return (_jsxs("div", Object.assign({ className: " relative w-full h-full flex" }, { children: [
            // refVideo.current?.readyState !== HAVE_ENOUGH_DATA 
            !isVideoLoaded
                && _jsx("div", Object.assign({ className: "bg-black flex w-full h-full items-center justify-center absolute" }, { children: _jsx("span", { className: "loaderReconnection flex" }) })), _jsx("video", Object.assign({ className: "flex relative justify-center items-center self-center w-[99%] h-[99%]", style: {
                    objectFit: "fill",
                    display: isVideoLoaded ? "flex" : "none",
                }, onLoadedData: () => setIsVideoLoaded(true), controls: true }, { children: _jsx("source", { src: props.url, type: "video/mp4" }) }))] })));
}
function PedagogicContentPlayer(props) {
    var _a, _b;
    const { websocketPeer, sendRequest } = useSessionContext();
    const [contentModel, setContentModel] = useState(undefined);
    const [isActiveContent, setIsActiveContent] = useState(false);
    function getContentShown() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield sendRequest("getContentDiffused")
                .catch((error) => { console.log("getContentDiffused", error); });
            if (response && response.content)
                setContentModel(response.content);
            else
                setContentModel(undefined);
            console.log("getContentShown", response);
        });
    }
    useEffect(() => {
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            console.log("ProjectionComponent RECEIVED NOTIFICATION", notification);
            try {
                if (notification.method === "startDiffusion") {
                    console.log("startDiffusion", notification.data.content);
                    setContentModel(notification.data);
                    // setIsActiveContent(true);
                }
                else if (notification.method === "stopDiffusion") {
                    setContentModel(undefined);
                    //  setIsActiveContent(false);
                }
            }
            catch (e) {
                console.log("error", e);
            }
        });
        getContentShown();
    }, []);
    useEffect(() => {
        if (contentModel !== undefined)
            props.setIsActive(true);
        else
            props.setIsActive(false);
        console.log("contentModel", contentModel);
    }, [contentModel]);
    return (_jsx("div", Object.assign({ className: " fixed w-full h-full justify-center items-center", style: {
            display: contentModel ? "flex" : "none",
            background: "rgba(12, 21, 51, 0.80)",
            top: 0,
            zIndex: 10
        } }, { children: _jsxs("div", Object.assign({ className: "bg-white fixed items-center justify-center", style: {
                display: contentModel ? "flex" : "none",
                width: "50%",
                height: "63%",
                minWidth: 514,
                minHeight: 400,
                zIndex: 10,
                padding: 7,
                borderRadius: 15,
                objectFit: "fill",
                border: "2px solid #000000",
            } }, { children: [contentModel && ((_a = contentModel.path) === null || _a === void 0 ? void 0 : _a.includes('?sv')) && contentModel.type === 1 &&
                    _jsx(VideoPlayer, { url: contentModel.path }), contentModel && ((_b = contentModel.path) === null || _b === void 0 ? void 0 : _b.includes('?sv')) && contentModel.type === 0 &&
                    _jsx("div", { className: "flex relative", style: {
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url(${contentModel.path})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        } })] })) })));
}
export default PedagogicContentPlayer;
