var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import FunctionalButtonBlueFound from "../../FonctionnalButtonBlueFoundComponent";
import EEffects from "cresus-common/dist/dto/EEffects";
import { EffectTextMap, getNamesOfEffectsFromType, getTemporalityOfEffectFromType } from "../../../tools/switchToEffects";
import ETeam from "cresus-common/dist/dto/ETeam";
import { DropdownList } from "react-widgets/cjs";
import DropdownArrowIcon from "../../icons/DropdownArrowIcon";
import TrashIcon from "../../icons/TrashIcon";
import AddSubstractComponent from "./AddSubstractComponent";
import EffectInputDropDownComponent from "./EffectInputDropDownComponent";
import useSession from "../../../contexts/useSession";
import TeamsSelectionInGameTag from "./TeamsSelectionInGameTag";
function numberOfEffectsToString(numberOfEffects) {
    if (numberOfEffects <= 1) {
        return (numberOfEffects.toString() + " EFFET");
    }
    else {
        return (numberOfEffects.toString() + " EFFETS");
    }
}
function EffectComponent_ModificationRevenu(props) {
    const refNumber = useRef(props.data.amount);
    const refOccurence = useRef(props.data.occurence);
    const refName = useRef(props.data.name);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, occurence: refOccurence.current, name: refName.current });
        console.log("onDataChanged", refNumber.current, refOccurence.current, refName.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Modification de revenu" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 2 }), _jsx(EffectInputDropDownComponent, { effectType: "Modification de revenu", isTemporality: false, handlePassInformationOnEffect: (name) => { refName.current = name; onDataChanged(); }, enumEffect: 2, isEffectDropdown: true, nameOfEffect: props.data.name, availableEffects: getNamesOfEffectsFromType(EEffects.MODIFICATION_REVENUS) }), _jsx(EffectInputDropDownComponent, { effectType: "Points de plaisir", isTemporality: true, handlePassInformationOnEffect: (temporality) => { refOccurence.current = temporality; onDataChanged(); }, enumEffect: 2, isEffectDropdown: true, nameOfEffect: props.data.occurence, availableEffects: getTemporalityOfEffectFromType(EEffects.MODIFICATION_REVENUS) })] }))] })));
}
function EffectComponent_Epargne(props) {
    const refNumber = useRef(props.data.amount);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current });
        console.log("onDataChanged", refNumber.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "\u00E9pargne" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 3 }), _jsx("div", Object.assign({ className: "flex items-center h-full w-[200px]" }, { children: "jusqu'\u00E0 la fin du mois" }))] }))] })));
}
function EffectComponent_NouvelleCharge(props) {
    const refNumber = useRef(props.data.amount);
    const refOccurence = useRef(props.data.occurence);
    const refName = useRef(props.data.name);
    const refGainOfPleasurePoints = useRef(props.data.gainOfPleasurePoints);
    const refInputPleasurePoints = useRef(null);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, occurence: refOccurence.current, name: refName.current, gainOfPleasurePoints: refGainOfPleasurePoints.current });
        console.log("onDataChanged", refNumber.current, refOccurence.current, refName.current, refGainOfPleasurePoints.current);
    }
    function handlePleasurePointsInput(e) {
        console.log("handlePleasurePointsInput", e.target.value);
        const value = parseInt(e.target.value);
        refGainOfPleasurePoints.current = isNaN(value) ? 0 : value;
        refGainOfPleasurePoints.current = Math.abs(refGainOfPleasurePoints.current);
        if (refInputPleasurePoints.current)
            refInputPleasurePoints.current.value = refGainOfPleasurePoints.current.toString();
        onDataChanged();
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Nouvelle Charge" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 4 }), _jsx(EffectInputDropDownComponent, { effectType: "Nouvelle charge", isTemporality: false, handlePassInformationOnEffect: (name) => { refName.current = name; onDataChanged(); }, enumEffect: 4, isEffectDropdown: false, nameOfEffect: props.data.name, availableEffects: getNamesOfEffectsFromType(EEffects.NOUVELLE_CHARGE) }), _jsx(EffectInputDropDownComponent, { effectType: "Nouvelle Charge", isTemporality: true, handlePassInformationOnEffect: (temporality) => { refOccurence.current = temporality; onDataChanged(); }, enumEffect: 4, isEffectDropdown: true, nameOfEffect: props.data.occurence, availableEffects: getTemporalityOfEffectFromType(EEffects.NOUVELLE_CHARGE) })] })), _jsxs("div", Object.assign({ className: "flex items-center gap-[10px]" }, { children: [_jsx("input", { className: "flex h-full p-[10px] font-roboto w-full items-center", defaultValue: refGainOfPleasurePoints.current, onChange: (e) => handlePleasurePointsInput(e), ref: refInputPleasurePoints, style: {
                            padding: "10px",
                            fontSize: "14px",
                            fontWeight: 400,
                            width: 54,
                            fontStyle: "normal",
                            lineHeight: "normal",
                            color: "#FFFFFF",
                            backgroundColor: "#384983",
                            borderRadius: "4px",
                        } }), "Gain de point(s) de plaisir"] }))] })));
}
function EffectComponent_PointsPlaisir(props) {
    const refNumber = useRef(props.data.amount);
    const refOccurence = useRef(props.data.occurence);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, occurence: refOccurence.current });
        console.log("onDataChanged", refNumber.current, refOccurence.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Points de plaisir" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [true &&
                        _jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 1 }), true &&
                        _jsx(EffectInputDropDownComponent, { effectType: "Points de plaisir", isTemporality: true, handlePassInformationOnEffect: (temporality) => { refOccurence.current = temporality; onDataChanged(); }, enumEffect: 1, isEffectDropdown: true, nameOfEffect: props.data.occurence, availableEffects: getTemporalityOfEffectFromType(EEffects.POINTS_PLAISIR) }) //effectTemporality
                ] }))] })));
}
function EffectComponent_CompteCourant(props) {
    const refNumber = useRef(props.data.amount);
    const refOccurence = useRef(props.data.occurence);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, occurence: refOccurence.current });
        console.log("onDataChanged", refNumber.current, refOccurence.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Compte courant" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [true &&
                        _jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 0 }), true &&
                        _jsx(EffectInputDropDownComponent, { effectType: "Compte courant", isTemporality: true, handlePassInformationOnEffect: (temporality) => { refOccurence.current = temporality; onDataChanged(); }, enumEffect: 0, isEffectDropdown: true, nameOfEffect: props.data.occurence, availableEffects: getTemporalityOfEffectFromType(EEffects.COMPTE_COURANT) }) //effectTemporality
                ] }))] })));
}
function EffectComponent_ModificationCharge(props) {
    const refNumber = useRef(props.data.amount);
    const refOccurence = useRef(props.data.occurence);
    const refName = useRef(props.data.name);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, occurence: refOccurence.current, name: refName.current });
        console.log("onDataChanged", refNumber.current, refOccurence.current, refName.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Modification de Charge" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 5 }), _jsx(EffectInputDropDownComponent, { effectType: "Modification de charge", isTemporality: false, handlePassInformationOnEffect: (name) => { refName.current = name; onDataChanged(); }, enumEffect: 5, isEffectDropdown: true, nameOfEffect: props.data.name, availableEffects: getNamesOfEffectsFromType(EEffects.MODIFICATION_CHARGE) }), _jsx(EffectInputDropDownComponent, { effectType: "Modification de Charge", isTemporality: true, handlePassInformationOnEffect: (temporality) => { refOccurence.current = temporality; onDataChanged(); }, enumEffect: 5, isEffectDropdown: true, nameOfEffect: props.data.occurence, availableEffects: getTemporalityOfEffectFromType(EEffects.MODIFICATION_CHARGE) })] }))] })));
}
function EffectComponent_Deplacement(props) {
    const refNumber = useRef(props.data.amount);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current });
        console.log("onDataChanged", refNumber.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "D\u00E9placement" })), _jsx("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: _jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 6 }) }))] })));
}
function EffectComponent_NewIncome(props) {
    const refNumber = useRef(props.data.amount);
    const refName = useRef(props.data.name);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, name: refName.current });
        console.log("onDataChanged", refNumber.current, refName.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Nouveau Revenu" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 8 }), _jsx(EffectInputDropDownComponent, { effectType: "Nouveau Revenue", isTemporality: false, handlePassInformationOnEffect: (name) => { refName.current = name; onDataChanged(); }, enumEffect: 8, isEffectDropdown: false, nameOfEffect: props.data.name, availableEffects: getNamesOfEffectsFromType(EEffects.NOUVEAU_REVENUS) })] }))] })));
}
function EffectComponent_DiceNumber(props) {
    const refNumber = useRef(props.data.amount);
    const refOccurence = useRef(props.data.occurence);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current, occurence: refOccurence.current });
        console.log("EffectComponent_DiceNumber.onDataChanged", refNumber.current, refOccurence.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Nombre de d\u00E9s" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 7 }), _jsx(EffectInputDropDownComponent, { effectType: "Nombre de des", isTemporality: true, handlePassInformationOnEffect: (temporality) => { refOccurence.current = temporality; onDataChanged(); }, enumEffect: 7, isEffectDropdown: true, nameOfEffect: props.data.occurence, availableEffects: getTemporalityOfEffectFromType(EEffects.NOMBRE_DES) })] }))] })));
}
function EffectComponent_TransfertEpargne(props) {
    const refNumber = useRef(props.data.amount);
    // const refName = useRef<string>(props.data.name);
    function onDataChanged() {
        props.onDataChanged({ amount: refNumber.current });
        console.log("onDataChanged", refNumber.current);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full justify-start ", style: {
            height: "auto",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full font-roboto items-center", style: {
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                } }, { children: "Transfert \u00E9pargne" })), _jsxs("div", Object.assign({ className: "flex w-full items-center", style: {
                    gap: "20px",
                    height: 40,
                } }, { children: [_jsx(AddSubstractComponent, { setNumberToAddorSubstractOfEffect: (number) => { refNumber.current = number; onDataChanged(); }, numberToAddorSubstract: props.data.amount, effect: 9 }), "Compte courant vers \u00E9pargne"] }))] })));
}
// This stores the component to instantiate for each type of effect
const EffectsConfig = new Map();
EffectsConfig.set(EEffects.COMPTE_COURANT, { component: EffectComponent_CompteCourant, defaultValue: { amount: 0, occurence: "Instantanément" } });
EffectsConfig.set(EEffects.EPARGNE, { component: EffectComponent_Epargne, defaultValue: { amount: 0 } });
EffectsConfig.set(EEffects.DEPLACEMENT, { component: EffectComponent_Deplacement, defaultValue: { amount: 0 } });
EffectsConfig.set(EEffects.MODIFICATION_CHARGE, { component: EffectComponent_ModificationCharge, defaultValue: { amount: 0, occurence: "Jusqu'à la fin du mois", name: "Logement" } });
EffectsConfig.set(EEffects.TRANSFERT_EPARGNE, { component: EffectComponent_TransfertEpargne, defaultValue: { amount: 0 } });
EffectsConfig.set(EEffects.MODIFICATION_REVENUS, { component: EffectComponent_ModificationRevenu, defaultValue: { amount: 0, occurence: "Indéfiniment", name: "Salaire" } });
EffectsConfig.set(EEffects.NOUVEAU_REVENUS, { component: EffectComponent_NewIncome, defaultValue: { amount: 0, name: "APL" } });
EffectsConfig.set(EEffects.NOUVELLE_CHARGE, { component: EffectComponent_NouvelleCharge, defaultValue: { amount: 42, occurence: "Indéfiniment", name: "Mutuelle", gainOfPleasurePoints: 0 } });
EffectsConfig.set(EEffects.NOMBRE_DES, { component: EffectComponent_DiceNumber, defaultValue: { amount: 1, occurence: "Pendant 1 tour" } });
EffectsConfig.set(EEffects.POINTS_PLAISIR, { component: EffectComponent_PointsPlaisir, defaultValue: { amount: 2, occurence: "Instantanément" } });
const AnimatorSoundTriggers = [
    { name: "Rire", imagePath: "./assets/UI/animator_triggers/Laugh.png" },
    { name: "Applause", imagePath: "./assets/UI/animator_triggers/Applause.png" },
    { name: "EarnMoney", imagePath: "./assets/UI/animator_triggers/Money.png" },
    { name: "Grillons", imagePath: "./assets/UI/animator_triggers/Grillons.png" },
    { name: "Violon", imagePath: "./assets/UI/animator_triggers/Violon.png" },
    { name: "Alarm", imagePath: "./assets/UI/animator_triggers/Alarm.png" },
    { name: "BatterieFail", imagePath: "./assets/UI/animator_triggers/Batteriefail.png" },
    { name: "Stade", imagePath: "./assets/UI/animator_triggers/Stade.png" },
    { name: "Tictac", imagePath: "./assets/UI/animator_triggers/Tictac.png" },
    { name: "TrompetteTriste", imagePath: "./assets/UI/animator_triggers/TrompetteTriste.png" },
    { name: "WOW", imagePath: "./assets/UI/animator_triggers/WOW.png" },
];
function EffectsHandlerComponent(props) {
    const [effectsToAdd, setEffectsToAdd] = useState([]);
    const { websocketPeer, sendRequest } = useSession();
    function addEffect(effect) {
        var _a;
        // We find the effect type from the effect name
        const effectType = (_a = Array.from(EffectTextMap.entries()).find((entry) => entry[1] === effect)) === null || _a === void 0 ? void 0 : _a[0];
        console.log("EffectType", effect, effectType);
        if (effectType !== undefined && effectType !== null) {
            const effectConfig = EffectsConfig.get(effectType);
            if (effectConfig) {
                const newEffectToAdd = {
                    id: "effectsToAdd" + Math.random(),
                    effectType: effectType,
                    component: effectConfig.component,
                    data: JSON.parse(JSON.stringify(effectConfig.defaultValue)),
                    teamsSelected: [false, false, false, false]
                };
                if (props.currentTeam)
                    newEffectToAdd.teamsSelected[props.currentTeam.teamColor] = true;
                console.log("NewEffectToAdd", newEffectToAdd);
                setEffectsToAdd([...effectsToAdd, newEffectToAdd]);
            }
            else
                console.warn("Effect not found", effectType);
        }
        else
            console.warn("Effect not found", effect);
    }
    function deleteEffect(index) {
        const newEffectsToAdd = [...effectsToAdd];
        newEffectsToAdd.splice(index, 1);
        console.log("deleteEffect", newEffectsToAdd);
        setEffectsToAdd(newEffectsToAdd);
    }
    function handleEffectChange(effect, data) {
        effect.data = data; // Quand on change une valeur dans un composant, on met à jour la valeur dans le state
    }
    function pushToServer() {
        return __awaiter(this, void 0, void 0, function* () {
            const dataToSend = effectsToAdd.map(element => {
                return {
                    effectType: element.effectType,
                    data: element.data,
                    teamsSelected: element.teamsSelected
                };
            });
            setEffectsToAdd([]);
            yield sendRequest("applicationOfEffects", { effectsToAdd: dataToSend });
            if (props.handleApplicationOfEffects)
                props.handleApplicationOfEffects();
            // Ici la donnée a envoyer au server est simple
            // Avec ca le server a tout ce qu'il lui faut pour appliquer les effets
            // http.post("/api/effects", dataToSend);
        });
    }
    const [forceRender, setForceRender] = useState(false);
    const teamActiveInGame = [
        props.teamsInGame.findIndex((team) => team.teamColor === ETeam.RED) !== -1,
        props.teamsInGame.findIndex((team) => team.teamColor === ETeam.BLUE) !== -1,
        props.teamsInGame.findIndex((team) => team.teamColor === ETeam.YELLOW) !== -1,
        props.teamsInGame.findIndex((team) => team.teamColor === ETeam.GREEN) !== -1
    ];
    function handleTeamSelection(effect, team) {
        if (team !== ETeam.NONE) {
            effect.teamsSelected[team] = !effect.teamsSelected[team];
        }
        setForceRender(!forceRender);
    }
    function handleClickedSound(sound) {
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.notify("playSound", { sound: sound });
    }
    return (_jsxs("div", Object.assign({ 
        // hidden={true}
        className: `absolute bg-dilemmeDeepPurpple w-full justify-start  font-roboto flex-col gap-[20px]`, style: {
            display: props.isActive ? "flex" : "none",
            padding: "20px 10px",
            minWidth: 748,
            maxWidth: 950,
            height: "auto",
            maxHeight: "100%",
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            color: props.isActive ? "white" : "transparent",
            zIndex: props.isActive ? "1" : "0",
        } }, { children: [_jsx("div", Object.assign({ className: "flex gap-[10px]" }, { children: AnimatorSoundTriggers.map((trigger) => {
                    return (_jsx("button", Object.assign({ className: "flex items-center justify-center h-[30px] w-[48px] px-[4px] border-white border-[1px]", onClick: () => { handleClickedSound(trigger.name); }, style: {
                            borderRadius: "40px"
                        } }, { children: _jsx("div", { style: {
                                width: "20px",
                                aspectRatio: "1/1",
                                backgroundImage: `url(${trigger.imagePath})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                            } }) })));
                }) })), _jsxs("div", Object.assign({ className: "flex justify-between w-full items-center" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-[10px] items-center" }, { children: [numberOfEffectsToString(effectsToAdd.length), _jsx("br", {}), "Cliquez sur un des effets dans la liste pour en ajouter un."] })), _jsx(FunctionalButtonBlueFound, { text: "Appliquer les effets", colorMode: 0, enabled: true, onClick: pushToServer })] })), _jsx(DropdownList, { onSelect: (value) => { addEffect(value); }, data: Array.from(EffectTextMap.values()), placeholder: "Choisir un effet", selectIcon: _jsx(DropdownArrowIcon, { color: "black" }) }), _jsx("div", Object.assign({ className: "flex w-full gap-[10px] h-full flex-col overflow-auto" }, { children: effectsToAdd.map((effect, index) => {
                    console.log("hello, i am rerendered", effect);
                    // 
                    return (_jsxs("div", Object.assign({ className: "flex p-[10px] flex-col gap-[10px] w-full justify-start relative", style: {
                            borderRadius: "4px",
                            height: "auto",
                            backgroundColor: "#121E46",
                        } }, { children: [_jsx("button", Object.assign({ className: "flex absolute", onClick: () => { deleteEffect(index); }, style: {
                                    borderRadius: "29px",
                                    top: "10px",
                                    right: "10px",
                                    width: "30px",
                                    height: "30px",
                                    padding: "5px",
                                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                                } }, { children: _jsx(TrashIcon, {}) })), _jsx(effect.component, { data: effect.data, onDataChanged: (data) => { handleEffectChange(effect, data); } }, effect.id), _jsx("div", { className: "flex w-full h-[0px]", style: {
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #FFFFFF",
                                } }), _jsx(TeamsSelectionInGameTag, { handleTeamInactive: (team) => { handleTeamSelection(effect, team); }, teamsSelected: effect.teamsSelected, teamsActiveInGame: teamActiveInGame })] })));
                }) }))] })));
}
export default EffectsHandlerComponent;
