"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ESlotType;
(function (ESlotType) {
    ESlotType[ESlotType["TAXES"] = 9] = "TAXES";
    ESlotType[ESlotType["HUISSIER"] = 1] = "HUISSIER";
    ESlotType[ESlotType["ACCES_SOINS"] = 2] = "ACCES_SOINS";
    ESlotType[ESlotType["ETUDES"] = 3] = "ETUDES";
    ESlotType[ESlotType["TRANSPORT"] = 4] = "TRANSPORT";
    ESlotType[ESlotType["SHOPPING"] = 5] = "SHOPPING";
    ESlotType[ESlotType["QUESTION"] = 6] = "QUESTION";
    ESlotType[ESlotType["PLAISIR"] = 7] = "PLAISIR";
    ESlotType[ESlotType["DILEMME"] = 8] = "DILEMME";
    ESlotType[ESlotType["NONE"] = 0] = "NONE";
})(ESlotType || (ESlotType = {}));
exports.default = ESlotType;
