import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { replaceNewLines } from "cresus-common/dist/utils/StringUtils";
import { useEffect, useState } from "react";
function CardOnNoteComponent(props) {
    return (_jsxs("div", Object.assign({ className: "flex self-start flex-col text-left" }, { children: [_jsx("div", Object.assign({ className: "flex self-start" }, { children: props.card.title })), _jsx("div", Object.assign({ className: "flex self-start items-start flex-row gap-1", style: {
                    fontWeight: "700",
                    whiteSpace: "pre-wrap",
                } }, { children: replaceNewLines(props.card.short_description) }))] })));
}
function CardsNoteComponent(props) {
    const [isShown, setIsShown] = useState(false);
    const [isNotebookShown, setIsNotebookShown] = useState(props.isNotebookShown);
    const [isOnHover, setIsOnHover] = useState(false);
    const [cards, setCards] = useState(props.cards);
    useEffect(() => {
        setIsNotebookShown(props.isNotebookShown);
        setIsShown(false);
    }, [props.isNotebookShown]);
    useEffect(() => {
        setCards(props.cards);
    }, [props.cards]);
    return (
    //TODO make disapear chen no cards 
    _jsx("div", Object.assign({ className: `fixed  flex-col font-kalam text-black cardNote`, style: {
            display: isNotebookShown && cards.length > 0 ? "flex" : "none",
            maxWidth: 180 * 2,
            maxHeight: 270 * 2,
            minWidth: 180 * 1,
            minHeight: 270 * 1,
            zIndex: 2,
            right: 0,
            bottom: 0,
            //use function like top : max(18.0%, 50px)
            // paddingLeft: 30 * 1.2,
            // paddingRight: 15 * 1.2,
            // paddingTop: 20 * 1.2,
            // paddingBottom: 20 * 1.2,
            // rotate: `${isShown? "5deg" : "-5deg"}`,
            transition: "all 1s ease-in-out",
            // gap: 16 * 1.2,
            fontSize: 12,
            // lineHeight: "16.8px", //14 * 1.2,
            fontWeight: "400",
        } }, { children: _jsx("button", Object.assign({ className: "flex relative w-full h-full flex-col fontCardNote", onClick: () => { setIsShown(!isShown); }, onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), style: {
                paddingTop: "calc(10%*0.66)",
                paddingLeft: "19%",
                paddingRight: "9.5%",
                // fontSize: "6.65cqw",
                // lineHeight: "7.8cqw",
                rotate: `${isShown ? "5deg" : "-5deg"}`,
                transition: "all 0.5s ease-in-out",
                backgroundImage: "url(./assets/UI/note_introduction.png)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                // gap: "10%",
                top: isShown ? "calc(-132%*0.66)" : isOnHover ? "calc(-102%*0.66)" : "calc(-92%*0.66)",
                right: isShown ? "23.5%" : isOnHover ? "-50%" : "-73%",
            } }, { children: cards.map((cardToPass) => {
                return _jsx(CardOnNoteComponent, { card: cardToPass });
            }) })) })));
}
export default CardsNoteComponent;
