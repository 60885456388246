var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CigogneDilemme from "../../components/icons/CigogneDilemme";
import { useNavigate } from "react-router-dom";
import useSessionContext from "../../tools/useSessionContext";
import useMedia from "../../contexts/useMedia";
import SetOfButtonsPlayerComponent from "../../components/player_game_element_component/SetOfButtonsPlayerComponent";
function secondsToHms(s) {
    let minuteString = "";
    let secondString = "";
    let m = Math.floor(s % 3600 / 60);
    let s1 = Math.floor(s % 3600 % 60);
    if (s1 < 10)
        secondString = `0${s1}`;
    else
        secondString = `${s1}`;
    if (m < 10)
        minuteString = `0${m}`;
    else
        minuteString = `${m}`;
    return `${minuteString}:${secondString}`;
}
function WaitingRoomPlayerPage() {
    const { setCameraMode, openMediaSettings, audioVideoClient } = useMedia();
    const { gameState, sendRequest, websocketPeer } = useSessionContext();
    const navigate = useNavigate();
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    const [myPlayer, setMyPlayer] = useState();
    const [isChatShown, setIsChatShown] = useState(false);
    const [insideGameState, setInsideGameState] = useState(gameState);
    const [playerName, setPlayerName] = useState("");
    const [counterEnded, setCounterEnded] = useState(false); // temporary
    const [counter, setCounter] = useState(10); // temporary // TODO ref instead
    let timer;
    // notifications to update the gamestate websocket //////////////////////////////
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", // TODO put this in a useEffect(() => { ... }, []) to avoid registering the same callback multiple times
        (notification) => {
            if (notification.method === "startSession") {
                updateIfSessionStarted(notification);
            }
            if (notification.method === "updateGameState")
                setInsideGameState(notification.data);
        });
    }, []);
    useEffect(() => {
        updateCounter();
        console.log('counter', counter);
        return () => clearInterval(timer);
    }, [counter]);
    useEffect(() => {
        // if(counterEnded)
        // navigate('/IntroductionPlayerPage')
    }, [counterEnded]);
    useEffect(() => {
        getGameState();
        getMyPlayer();
    }, []);
    useEffect(() => {
        console.log("USEEFFECT()", insideGameState.sessionStarted);
        if (insideGameState.sessionStarted) {
            navigate('/IntroductionPlayerPage');
        }
    }, [insideGameState]);
    function getMyPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("getMyPlayer", { gameState: gameState }).catch((error) => { console.log("getMyPlayer", error); });
            console.log(result, "get my player");
            if (result !== undefined && result.name !== undefined)
                setPlayerName(result.name);
        });
    }
    function updateIfSessionStarted(message) {
        console.log("test callback", message);
        const buffGameState = Object.assign({}, insideGameState);
        buffGameState.sessionStarted = message.data.gameState;
        setInsideGameState(buffGameState);
    }
    // function to get the gamestate from the server ///////////////////////////////
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            console.log(result, "hello");
            if (result !== undefined)
                setInsideGameState(result);
        });
    }
    function updateCounter() {
        if (counterEnded) {
            clearInterval(timer);
            return;
        }
        timer = setInterval(() => {
            setCounter(prevCounter => prevCounter - 1);
            console.log(counter);
            if (counter === 0) {
                setCounterEnded(true);
                clearInterval(timer);
            }
        }, 1000);
    }
    function toggleChat() {
        setIsChatShown(!isChatShown);
        if (!isChatShown)
            setNewMessageAlert(false);
    }
    useEffect(() => {
        getMyPlayer();
        console.log("my player", myPlayer);
    }, []);
    console.log("my player", myPlayer);
    return (_jsxs("div", Object.assign({ className: "flex fixed flex-col justify-start items-center h-screen w-screen", style: {
            paddingTop: "50px",
        } }, { children: [_jsxs("div", Object.assign({ className: "flex gap-[50px] flex-col h-full relative " }, { children: [_jsx("div", { className: "flex dilemeLogo", style: {
                            backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            minHeight: 166,
                            minWidth: 420,
                            maxHeight: 166 * 2,
                            maxWidth: 420 * 2,
                            zIndex: 1,
                        } }), _jsx("div", Object.assign({ className: "flex font-kalam text-white self-center text-center", style: {
                            fontSize: "60px",
                            fontStyle: "normal",
                            lineHeight: "96px",
                            fontWeight: "700",
                            letterSpacing: "0.6px",
                            textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347, 3px 3px 0px #353347, 3px -3px 0px #353347, -3px 3px 0px #353347, -3px -3px 0px #353347, 4px 0px 0px #353347, 0px 4px 0px #353347, 4px 1px 0px #353347, 1px 4px 0px #353347, 4px 2px 0px #353347, 2px 4px 0px #353347, 4px 3px 0px #353347, 3px 4px 0px #353347, -4px 0px 0px #353347, 0px -4px 0px #353347, -4px 1px 0px #353347, 1px -4px 0px #353347, -4px 2px 0px #353347, 2px -4px 0px #353347, -4px 3px 0px #353347, 3px -4px 0px #353347, 4px -1px 0px #353347, -1px 4px 0px #353347, 4px -2px 0px #353347, -2px 4px 0px #353347, 4px -3px 0px #353347, -3px 4px 0px #353347, -4px -1px 0px #353347, -1px -4px 0px #353347, -4px -2px 0px #353347, -2px -4px 0px #353347, -4px -3px 0px #353347, -3px -4px 0px #353347, -5px 5px 0px #353347, -6px 5px 0px #353347, -5px 6px 0px #353347, -4px 6px 0px #353347, -3px 6px 0px #353347, -2px 6px 0px #353347, -1px 6px 0px #353347, -0px 6px 0px #353347, -6px 4px 0px #353347, -6px 3px 0px #353347, -6px 2px 0px #353347, -6px 1px 0px #353347, -6px 0px 0px #353347, -6px -1px 0px #353347",
                            // textShadow:" -5px 5px 0px #352247"
                            // textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, -5px 5px 0px #352247"
                        } }, { children: counterEnded ? "Encore un instant..." : secondsToHms(counter) }))] })), _jsx("div", {}), _jsx("div", Object.assign({ className: "flex w-full fixed noteBookWaitingRoom containerNotebook", style: {
                    minWidth: 753.5,
                    minHeight: 550.5,
                    maxHeight: 550.5 * 2,
                    maxWidth: 753.5 * 2,
                    bottom: 0,
                    left: 0,
                    zIndex: 2,
                } }, { children: _jsx("div", Object.assign({ className: "flex w-full fixed noteBookWaitingRoom notebookPositionWaitingRoom", style: {
                        minWidth: 753.5,
                        minHeight: 550.5,
                        maxHeight: 550.5 * 2,
                        maxWidth: 753.5 * 2,
                        // bottom: "-54cqw",
                        // left: "-19cqw",
                        transform: "rotate(-3deg)",
                        backgroundImage: "url(./assets/UI/game_elements_images/notebook_bg.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        zIndex: 2,
                    } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col relative font-kalam text-center fontSizenoteBookWaitingRoom", style: {
                            paddingLeft: "39%",
                            paddingTop: "5%",
                            paddingRight: "11%",
                            width: "100%",
                            // lineHeight: "2.625cqw",
                            // fontSize: "2.1cqw",
                        } }, { children: [_jsx("div", Object.assign({ className: "flex font-bold self-center" }, { children: "La session va commencer dans quelques instants." })), _jsx("div", Object.assign({ className: "flex" }, { children: "En attendant, v\u00E9rifiez que vos hauts-parleurs et votre micro sont bien branch\u00E9s. Vous en aurez besoin pour communiquer avec les autres joueurs !" }))] })) })) })), _jsxs("div", Object.assign({ className: "flex fixed flex-col postItWaitingRoom", style: {
                    minWidth: "232px",
                    minHeight: "232px",
                    left: 100,
                    bottom: 0,
                    backgroundImage: "url(./assets/UI/post_its/post_it_purple.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    rotate: "-5deg",
                    zIndex: 1,
                    padding: 40,
                } }, { children: [_jsx("div", Object.assign({ className: "flex font-kalam text-center text-black self-center fontSizePostitWaitingRoom", style: {
                            // fontSize: "26px",
                            // fontSize: "15.8cqw",
                            // lineHeight: "15.8cqw",
                            fontStyle: "normal",
                            // lineHeight: "20px",
                            fontWeight: "700",
                        } }, { children: "Bienvenue" })), _jsx("div", Object.assign({ className: "flex font-kalam text-center text-black self-center namePlayerWaitingRoom", style: {
                            // fontSize: "16px",
                            fontStyle: "normal",
                            // lineHeight: "20px",
                            // fontSize: "10.53cqw",
                            // lineHeight: "15cqw",
                            fontWeight: "700",
                        } }, { children: playerName }))] })), _jsx("div", { className: "flex fixed postItWaitingRoom", style: {
                    minWidth: 232,
                    minHeight: 232,
                    maxWidth: 232 * 2,
                    maxHeight: 232 * 2,
                    bottom: -60,
                    left: 10,
                    zIndex: 0,
                    rotate: "3deg",
                    backgroundImage: "url(./assets/UI/post_its/post_it_pink.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                } }), _jsx(SetOfButtonsPlayerComponent /*alertNewMessage={newMessageAlert} */, { toggleChat: toggleChat, isMapModifiable: false, isOnlyConfigButton: false }), _jsx("div", Object.assign({ className: "flex fixed cigogneWaitingRoom", style: {
                    minWidth: 300,
                    minHeight: 280,
                    maxHeight: 280 * 2,
                    maxWidth: 300 * 2,
                    bottom: 0,
                    right: 0,
                } }, { children: _jsx(CigogneDilemme, {}) }))] })));
}
export default WaitingRoomPlayerPage;
