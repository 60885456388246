import { jsx as _jsx } from "react/jsx-runtime";
import { replaceNewLines } from "cresus-common/dist/utils/StringUtils";
function TextCardComponent(props) {
    function handleSplit(text) {
        return text.split("\n").map((str, index) => {
            return _jsx("p", Object.assign({ className: "flex self-start items-start justify-start text-left" }, { children: str }), index);
        });
    }
    return (_jsx("div", Object.assign({ className: "flex flex-col w-full self-start items-start", style: {
            whiteSpace: "pre-wrap"
        } }, { children: handleSplit(replaceNewLines(props.text)) })));
}
export default TextCardComponent;
