import { jsx as _jsx } from "react/jsx-runtime";
import ProjectPropositionComponent from "./ProjectPropositionComponent";
function ProjectHolderComponent(props) {
    return (_jsx("div", Object.assign({ className: "flex w-full" }, { children: _jsx("div", Object.assign({ className: "flex justify-between font-roboto text-white items-center gap-[20px] w-full" }, { children: props.team.projectsProposed.map((project, index) => {
                var _a, _b;
                const isSelected = (_a = (props.team.project && props.team.project.content === project.content)) !== null && _a !== void 0 ? _a : false;
                return (_jsx(ProjectPropositionComponent, { isActive: !props.team.project, isSelected: isSelected, onClick: () => { props.handleClickProjectSelection(props.team, project); }, projectPrice: (_b = project.price) !== null && _b !== void 0 ? _b : 0, textProject: project.content }));
            }) })) })));
}
export default ProjectHolderComponent;
