import { jsx as _jsx } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import { useEffect, useState } from "react";
import CheckedIcon from "../icons/CheckedIcon";
function CigogneProjectsSelectionComponent(props) {
    // const [IsActive, setIsActive] = useState(props.isActive);
    const [projectDistributed, setProjectDistributed] = useState(props.isProjectDistributed);
    const [isEnable, setIsEnable] = useState(props.isEnable);
    // useEffect(() => {
    //     setIsActive(props.isActive);
    // }, [props.isActive])
    useEffect(() => {
        console.log("isProjectDistributed props", props.isProjectDistributed);
        setProjectDistributed(props.isProjectDistributed);
    }, [props.isProjectDistributed]);
    useEffect(() => {
        console.log("isEnable props", props.isEnable);
        setIsEnable(props.isEnable);
        console.log("isEnable cigogne", isEnable);
    }, [props.isEnable]);
    function chooseCigogne(teamColor) {
        switch (teamColor) {
            case ETeam.RED:
                return "Red";
            case ETeam.BLUE:
                return "Blue";
            case ETeam.YELLOW:
                return "Yellow";
            case ETeam.GREEN:
                return "Green";
            default:
                return "Red";
        }
    }
    return (_jsx("div", { children: _jsx("button", Object.assign({ className: `flex relative justify-center items-center ${props.isActive || !props.isEnable ? "" : "cigogne-position"}`, disabled: !isEnable, onClick: () => {
                props.handleClick(props.team);
            }, style: {
                backgroundImage: `${"url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_" + chooseCigogne(props.team) + ".png"}`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                overflow: "hidden",
                height: "95px",
                width: "152px",
                minWidth: "152px",
                minHeight: "95px",
                opacity: isEnable ? 1 : 0.2,
            } }, { children: projectDistributed &&
                _jsx("div", Object.assign({ className: "flex relative self checkProjectCigogne", style: {
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        backgroundColor: "green",
                        border: "1px solid black",
                        padding: 1,
                    } }, { children: _jsx(CheckedIcon, {}) })) })) }));
}
export default CigogneProjectsSelectionComponent;
