import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import { useEffect, useState } from "react";
import TextCardComponent from "../TextCardComponent";
function ShowCardComponent(props) {
    const [pathOfCard, setPathOfCard] = useState("");
    const [isCardTextHidden, setIsCardTextHidden] = useState(false);
    useEffect(() => {
        switch (props.card.type) {
            case ETypeCards.EVENT:
                setPathOfCard("url(/assets/UI/game_elements_images/card_images/card_event_player.png)");
                break;
            case ETypeCards.PLAISIR:
                setPathOfCard("url(/assets/UI/game_elements_images/card_images/card_pleasure_player.png)");
                break;
            case ETypeCards.QUESTION:
                setPathOfCard("url(/assets/UI/game_elements_images/card_images/card_question_player.png)");
                break;
            default:
                setPathOfCard("problem");
        }
        console.log("pathOfCard ", pathOfCard);
    }, [props.card]);
    return (_jsxs("div", Object.assign({ className: "flex fixed justify-center items-center w-[600px] h-[400px]", style: {
            zIndex: 0,
            display: props.isThereThrowEvent ? "none" : "flex",
        } }, { children: [_jsx("div", Object.assign({ className: "flex  card", style: {
                    minWidth: 180,
                    minHeight: 285,
                    maxHeight: 285 * 2,
                    maxWidth: 180 * 2,
                    bottom: isCardTextHidden ? "0" : "",
                    alignSelf: isCardTextHidden ? "start" : "center",
                    position: isCardTextHidden ? "fixed" : "absolute",
                } }, { children: _jsx("button", { className: "flex  justify-center card", onClick: () => setIsCardTextHidden(!isCardTextHidden), style: {
                        transition: "all 0.3s",
                        backgroundImage: pathOfCard,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        transform: "rotate(-3deg)",
                        left: isCardTextHidden ? "" : "-113%",
                        bottom: isCardTextHidden ? "-35%" : "calc(50% * 0.63)",
                        position: isCardTextHidden ? "relative" : "relative",
                    } }) })), !isCardTextHidden &&
                _jsx("div", Object.assign({ className: "flex absolute justify-center items-center cardText", onClick: () => setIsCardTextHidden(!isCardTextHidden), style: {
                        minWidth: 400,
                        minHeight: 250,
                        maxWidth: 400 * 2,
                        maxHeight: 250 * 2,
                        borderRadius: 15,
                        // padding: 7,
                        // border: "2px solid #000000",
                        // backgroundColor: "rgba(161, 170, 188, 1)",
                        // rotate: "3deg",
                    } }, { children: _jsx("button", Object.assign({ className: "flex relative w-full h-full items-center", onClick: () => setIsCardTextHidden(!isCardTextHidden), style: {
                            bottom: "calc(-10% * 0.63)",
                            left: "0cqw",
                            padding: "1.75%",
                            border: "2px solid #000000",
                            borderRadius: 15,
                            backgroundColor: "rgba(161, 170, 188, 1)",
                            rotate: "3deg",
                        } }, { children: _jsxs("div", Object.assign({ className: "flex justify-start relative items-center flex-col gap-[20px]", style: {
                                height: "100%",
                                // minHeight: 236,
                                width: "100%",
                                borderRadius: 10,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                paddingTop: "calc(7%*0.63)",
                                paddingLeft: "3.5%",
                                paddingRight: "3.5%",
                                paddingBottom: "calc(7%*0.63)",
                            } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-kalam overflow-visible fontTitleCard", style: {
                                        // fontSize: "5cqw",
                                        fontWeight: 700,
                                        color: "rgba(0, 0, 0, 1)",
                                        // lineHeight: "5cqw",
                                    } }, { children: props.card.title })), _jsx("div", Object.assign({ className: "flex relative font-kalam justify-start items-start self-start fontTextCard", style: {
                                        // fontSize: "3cqw",
                                        fontWeight: 400,
                                        color: "rgba(0, 0, 0, 1)",
                                        // lineHeight: "3.5cqw",
                                    } }, { children: _jsx(TextCardComponent, { text: props.card.textPlayer }, "TextCardComponent player") }))] })) })) })), "        "] })));
}
export default ShowCardComponent;
