import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
function CamPlayerComponent(props) {
    const [isVisible, setIsVisible] = useState(false);
    const [isNotebookShown, setIsNotebookShown] = useState(false);
    const [isChatShown, setIsChatShown] = useState(false);
    console.log("CamPlayerComponent", isVisible, isNotebookShown, isChatShown);
    useEffect(() => {
        if (props.onComponentMount) {
            props.onComponentMount({ isVisible, setIsVisible }, { isNotebookShown, setIsNotebookShown }, { isChatShown, setIsChatShown });
        }
    }, []);
    useEffect(() => {
        console.log("isVisible", isVisible);
        console.log("isNotebookShown", isNotebookShown);
        console.log("isChatShown", isChatShown);
    }, [isVisible, isNotebookShown, isChatShown]);
    let bottom = `${(isNotebookShown || isChatShown) ? "-82px" : "-20px"}`;
    if (!isVisible)
        bottom = "-350px";
    const camScaling = 1.;
    return (_jsxs("div", Object.assign({ className: "fixed webcamPlayer", style: {
            // width: `calc(253px * ${camScaling})`,
            // height: `calc(208px * ${camScaling})`,
            bottom: bottom,
            left: `${(isNotebookShown || isChatShown) ? "46px" : "40px"}`,
            minWidth: 253,
            minHeight: 208,
            maxHeight: 208 * 2,
            maxWidth: 253 * 2,
            transition: "all 1s ease-in-out",
            // bottom: 0,
            // left: 25,
            rotate: `${(isNotebookShown) ? "5deg" : "-5deg"}`,
            zIndex: 100,
            //overflow: "hidden"
        } }, { children: [_jsx("div", { className: " fixed flex w-full h-full", style: {
                    bottom: "-14cqw",
                    left: "1cqw",
                    backgroundImage: "url(./assets/UI/mask_cam.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    zIndex: 100,
                    overflow: "hidden"
                } }), _jsx(_Fragment, { children: props.videoComponent })] })));
}
export default CamPlayerComponent;
