import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
function PostItComponent(props) {
    const [isShown, setIsShown] = useState(props.isShown);
    useEffect(() => {
        setIsShown(props.isShown);
    }, [props.isShown]);
    return (_jsxs("div", Object.assign({ className: `flex absolute flex-col justify-between postItWaitingRoom `, style: {
            minHeight: 232,
            minWidth: 232,
            maxHeight: 232 * 2,
            maxWidth: 232 * 2,
            rotate: `${isShown ? "10deg" : "-20deg"}`,
            right: `${isShown ? "-16%" : "95%"}`,
            top: `${isShown ? "20%" : "47%"}`,
            // transition: "all 1s ease-in-out",
            backgroundImage: "url(./assets/UI/post_its/post_it.png)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            zIndex: 1,
            transition: "all 1s ease-in-out",
        } }, { children: [_jsx("div", Object.assign({ className: "w-full flex relative font-kalam text-center justify-center postItFontNoteBook", style: {
                    // fontSize: "6.9cqw",
                    // lineHeight: "6.9cqw",
                    fontWeight: 400,
                    // paddingTop: "27cqw",
                    // paddingBottom: "23cqw",
                    // paddingLeft: "12cqw",
                    // paddingRight: "12cqw",
                    // top: 50,
                    // lineHeight: "16px",
                    rotate: "-5deg",
                } }, { children: props.projet.content })), _jsxs("div", Object.assign({ className: "w-full flex relative justify-center items-center font-kalam postItFontPrice", style: {
                    // fontSize: 30,
                    // fontSize: "13cqw",
                    // lineHeight: "13cqw",
                    fontWeight: 700,
                    // lineHeight: "30px",
                    // top: 85,
                    rotate: "-5deg",
                    // paddingTop: "27cqw",
                    // paddingBottom: "23cqw",
                    // paddingLeft: "12cqw",
                    // paddingRight: "12cqw",
                } }, { children: [props.projet.price, " \u20AC"] }))] })));
}
export default PostItComponent;
