import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CashIcon from "../../icons/CashIcon";
import StarIcon from "../../icons/StarIcon";
import PiggyIcon from "../../icons/PiggyIcon";
import EurosIcons from "../../icons/EurosIcon";
function InformationTeamComponent(props) {
    // const [income, setIncome] = useState(props.income);
    // const [cash, setCash] = useState(props.cash);
    // const [piggyBank, setPiggyBank] = useState(props.piggyBank);
    // const [piggyBankPercent, setPiggyBankPercent] = useState(props.piggyBankPercent);
    // const [chargesToPay, setChargesToPay] = useState(props.chargesToPay);
    // const [pleasurePoints, setPleasurePoints] = useState(props.pleasurePoints);
    // const [project, setProject] = useState(props.project);
    // const [cards, setCards] = useState(props.cards);
    // //TODO : see if this hack is necessary
    // useEffect(() => {
    //     setIncome(props.income);
    // }, [props.income]);
    // useEffect(() => {
    //     setCash(props.cash);
    //     console.log("cash : " + props.cash);
    // }, [props.cash]);
    // useEffect(() => {
    //     setPiggyBank(props.piggyBank);
    // }, [props.piggyBank]);
    // useEffect(() => {
    //     setPiggyBankPercent(props.piggyBankPercent);
    // }, [props.piggyBankPercent]);
    // useEffect(() => {
    //     setChargesToPay(props.chargesToPay);
    // }, [props.chargesToPay]);
    // useEffect(() => {
    //     setPleasurePoints(props.pleasurePoints);
    // }, [props.pleasurePoints]);
    // useEffect(() => {
    //     setProject(props.project);
    // }, [props.project]);
    // useEffect(() => {
    //     setCards(props.cards);
    // }, [props.cards]);
    const colorIcons = "rgba(111, 130, 192, 1)";
    return (_jsxs("div", Object.assign({ className: "flex justify-between w-fullitems-center", style: {
            height: 45,
            minHeight: 45,
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16px",
        } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-[5px]" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-4 items-center" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px]" }, { children: _jsx(CashIcon, { color: colorIcons }) })), _jsxs("span", Object.assign({ className: "flex" }, { children: [props.cash, "\u20AC"] }))] })), _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px]" }, { children: _jsx(StarIcon, { color: colorIcons }) })), _jsx("span", Object.assign({ className: "flex" }, { children: props.pleasurePoints }))] })), _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px]" }, { children: _jsx(PiggyIcon, { color: colorIcons }) })), _jsxs("span", Object.assign({ className: "flex" }, { children: [props.piggyBank, "\u20AC"] }))] })), _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px]" }, { children: _jsx(EurosIcons, { color: colorIcons }) })), _jsxs("span", Object.assign({ className: "flex" }, { children: [props.income, "\u20AC/mois"] }))] })), _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-[24px] h-[24px]" }, { children: _jsx(PiggyIcon, { color: colorIcons }) })), _jsxs("span", Object.assign({ className: "flex" }, { children: [props.piggyBankPercent, "%/mois"] }))] }))] })), _jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [_jsxs("span", Object.assign({ className: "flex", style: {
                                    fontWeight: 700,
                                    color: colorIcons,
                                } }, { children: [props.project.price, "\u20AC"] })), _jsx("span", { children: props.project.content })] }))] })), _jsxs("div", Object.assign({ className: "flex flex-col h-[45px] gap-[10px] justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("span", Object.assign({ className: "flex items-center", style: {
                                    fontWeight: 700,
                                    color: colorIcons,
                                } }, { children: props.chargesToPay })), _jsx("span", { children: "charge(s) \u00E0 payer" })] })), _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("span", Object.assign({ className: "flex items-center", style: {
                                    fontWeight: 700,
                                    color: colorIcons,
                                } }, { children: props.cards.length })), _jsx("span", { children: "carte(s)" })] }))] }))] })));
}
export default InformationTeamComponent;
