var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import MediaContext from "./MediaContext";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import useSessionContext from "../tools/useSessionContext";
import useAuth from "./useAuth";
import AudioVideoClient from "./AudioVideoClient";
import CamPlayerComponent from "../components/player_game_element_component/CamPlayerComponent";
import { MediaSettings } from "../components/MediaSettings";
import useMedia from "./useMedia";
function AudioComponent(props) {
    const consumer = props.participant.consumer;
    const [isMuted, setIsMuted] = useState(consumer.paused);
    const { audioVideoClient } = useMedia();
    const refMuted = useRef(null);
    const refAudio = useRef(null);
    console.log("CONSUMER", consumer);
    useEffect(() => {
        let stream = new MediaStream();
        stream.addTrack(consumer.track);
        // TODO keeps this as it might be usful to get audio volume
        // const AudioContext = window.AudioContext;// || window.webkitAudioContext;
        // let myAudioCtx = new AudioContext();
        // let mySource = myAudioCtx.createMediaStreamSource(stream);
        // let gainNode = myAudioCtx.createGain();
        // gainNode.gain.value = 2;
        // mySource.connect(gainNode);
        // gainNode.connect(myAudioCtx.destination);
        refAudio.current.srcObject = stream;
        refAudio.current.play().catch((error) => console.warn('audioElem.play() failed:%o', error));
        window.hack = refAudio.current;
        console.log("audio component", consumer.track, consumer.track.enabled);
    });
    // function onCLick() {
    //     audioVideoClient.muteParticipant(props.participant.peerId);
    // }
    return (_jsx(_Fragment, { children: _jsx("audio", { autoPlay: true, ref: refAudio, muted: false, controls: false }, consumer.id) }));
}
const AudioVideoComponent = (props) => {
    const [consumers, setConsumers] = useState();
    // const refTextPeerId = useRef<HTMLDivElement>(null);
    // async function getMyPeerId()
    // {
    //     const peerId = await props.audioVideoClient.peer.request("getPeerId");
    //     refTextPeerId.current!.innerText = peerId;
    // }
    console.log("RENDERING AudioVideoComponent", props.audioVideoClient._consumers.size);
    useEffect(() => {
        props.audioVideoClient.onNewConsumer.push((participant) => {
            const consumer = participant;
            console.log("new consumer", consumer);
            if (consumer.consumer.kind === "video") {
                if (props.onVideoConsumer)
                    props.onVideoConsumer(consumer.consumer);
                return; // We won't add it here, we'll add it in the video component
            }
            setConsumers((consumers) => {
                if (!consumers) {
                    return [consumer];
                }
                return [...consumers, consumer];
            });
        });
        props.audioVideoClient.onConsumerClosed.push((consumer) => {
            console.log("delete consumer", consumer);
            setConsumers((consumers) => {
                if (!consumers) {
                    return [];
                }
                return consumers.filter((c) => c.consumer.id !== consumer.id);
            });
        });
        return () => {
            setConsumers([]);
            props.audioVideoClient.ResetConsumers();
        };
    }, []);
    console.log(props.audioVideoClient.peer);
    return (_jsx("div", Object.assign({ className: "flex flex-col", style: { gap: 10 } }, { children: consumers === null || consumers === void 0 ? void 0 : consumers.filter(consumer => consumer.consumer.kind === "audio").map((consumer, i) => {
            return (_jsx(AudioComponent, { participant: consumer }, `audioConsumer${i}`));
        }) })));
};
function MediaComponent(props) {
    var _a;
    const { audioVideoClient } = useMedia();
    const [selectedMicro, setSelectedMicro] = useState("");
    const [selectedCamera, setSelectedCamera] = useState("");
    const [settingsVisibility, setSettingsVisibility] = useState(false);
    const [fakeState, setFakeState] = useState(false);
    function getDefaultDevices() {
        return __awaiter(this, void 0, void 0, function* () {
            let videoDevices = yield navigator.mediaDevices.enumerateDevices();
            videoDevices = videoDevices.filter(el => el.kind === "videoinput");
            let foundCamera = videoDevices[0];
            let audioDevices = yield navigator.mediaDevices.enumerateDevices();
            audioDevices = audioDevices.filter(el => el.kind === "audioinput");
            let foundMicro = audioDevices[0];
            setSelectedCamera(foundCamera.label);
            setSelectedMicro(foundMicro.label);
        });
    }
    useEffect(() => {
        props.onMount([settingsVisibility, setSettingsVisibility]);
        getDefaultDevices();
    }, []);
    let muted = (((_a = audioVideoClient._producer) === null || _a === void 0 ? void 0 : _a.paused) ? "muted" : "not muted");
    function handleMute() {
        var _a;
        if ((_a = audioVideoClient._producer) === null || _a === void 0 ? void 0 : _a.paused)
            audioVideoClient.unmuteMic();
        else
            audioVideoClient.muteMic();
        setFakeState(!fakeState);
    }
    function onValidateChange(micro, camera) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("changing cam & mic", camera, micro);
            setSelectedCamera(camera);
            setSelectedMicro(micro);
            setSettingsVisibility(false);
            let devices = yield navigator.mediaDevices.enumerateDevices();
            let camDeviceId = devices.filter(el => el.label === camera)[0].deviceId;
            let micDeviceId = devices.filter(el => el.label === micro)[0].deviceId;
            audioVideoClient.changeWebcam(camDeviceId);
            audioVideoClient.changeMicrophone(micDeviceId);
        });
    }
    return (_jsx("div", { children: settingsVisibility &&
            _jsx(MediaSettings, { selectedCamera: selectedCamera, selectedMicrophone: selectedMicro, onClickClose: () => setSettingsVisibility(false), onClickValidate: onValidateChange }) }));
}
const MediaContextProvider = (props) => {
    console.log("MediaContextProvider rendering");
    // DO NOT SET ANY STATE HERE, this would cause a re-render and a re-render would cause 
    // a re-creation of the websocket without closing the previous one
    const { token, role } = useAuth();
    const { sessionId, websocketPeer } = useSessionContext();
    const refVideo = useRef(null);
    let settingsVisibility = null;
    let setSettingsVisibility = null;
    function onMediaComponentMount(data) {
        settingsVisibility = data[0];
        setSettingsVisibility = data[1];
    }
    let cameraIsVisible = false;
    let setCameraIsVisible = () => { };
    let cameraIsNotebookShown = false;
    let setCameraIsNotebookShown = () => { };
    let isChatShown = false;
    let setIsChatShown = () => { };
    function onCamPlayerComponentMount(visibleState, notebookState, chatState) {
        cameraIsVisible = visibleState.isVisible;
        setCameraIsVisible = visibleState.setIsVisible;
        cameraIsNotebookShown = notebookState.isNotebookShown;
        setCameraIsNotebookShown = notebookState.setIsNotebookShown;
        isChatShown = chatState.isChatShown;
        setIsChatShown = chatState.setIsChatShown;
    }
    const videoTag = role === EPlayerRole.ANIMATOR ?
        _jsx("video", { autoPlay: true, ref: refVideo, muted: true, controls: false }, "videocomponent") :
        _jsx("video", { autoPlay: true, ref: refVideo, muted: false, controls: false, style: { height: "100%", objectFit: "cover", position: "absolute", zIndex: 50 } }, "videocomponent");
    const value = {
        audioVideoClient: new AudioVideoClient(sessionId, token, role, websocketPeer),
        videoComponent: videoTag,
        openMediaSettings: () => {
            setSettingsVisibility(true);
        },
        setCameraMode: (isVisible, isNotebookShown, isChatShown) => {
            console.log("setting camera mode", isVisible, isNotebookShown, isChatShown);
            setCameraIsVisible(isVisible);
            setIsChatShown(isChatShown);
            setCameraIsNotebookShown(isNotebookShown);
        },
        moveVideoTag: (parent) => {
            var _a;
            if (Boolean(parent))
                parent.appendChild(videoHolderRef.current);
            // Catch to handle the case where the video is not playing (local when not running webaudio server)
            // value.audioVideoClient.enableWebcam();
            (_a = refVideo.current) === null || _a === void 0 ? void 0 : _a.play().catch((error) => console.error("Cannot play video", error));
        },
        releaseVideoTag: () => {
            if (Boolean(videoHolderHolderRef === null || videoHolderHolderRef === void 0 ? void 0 : videoHolderHolderRef.current))
                videoHolderHolderRef === null || videoHolderHolderRef === void 0 ? void 0 : videoHolderHolderRef.current.appendChild(videoHolderRef.current);
        }
    };
    function onVideoConsumer(consumer) {
        console.log("received video consumer", consumer);
        let stream = new MediaStream();
        stream.addTrack(consumer.track);
        refVideo.current.srcObject = stream;
    }
    useEffect(() => {
        value.audioVideoClient.unmuteMic();
    }, []);
    const videoPhoneWrapper = _jsx("div", Object.assign({ className: "h-full relative", style: {
            objectFit: "cover",
            maskPosition: "center 14cqw",
            maskImage: "url(./assets/UI/mask_cam_mask.png)",
            maskRepeat: "no-repeat",
            maskSize: "cover",
            borderRadius: 10,
            margin: 10,
            background: "#282828",
            zIndex: -1
        } }, { children: value.videoComponent }));
    const videoHolderRef = useRef(null);
    const videoHolderHolderRef = useRef(null);
    return (_jsxs(MediaContext.Provider, Object.assign({ value: value }, { children: [_jsx(MediaComponent, { onMount: onMediaComponentMount }), _jsx(AudioVideoComponent, { onVideoConsumer: onVideoConsumer, audioVideoClient: value.audioVideoClient }), _jsx("div", Object.assign({ style: { visibility: 'hidden', height: 0 } }, { children: _jsx("div", Object.assign({ ref: videoHolderHolderRef }, { children: _jsx("div", Object.assign({ ref: videoHolderRef }, { children: value.videoComponent })) })) })), role !== EPlayerRole.ANIMATOR && _jsx(CamPlayerComponent, { onComponentMount: onCamPlayerComponentMount, videoComponent: videoPhoneWrapper }), props.children] })));
};
export default MediaContextProvider;
