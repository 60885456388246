import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import PlusIcon from "../../icons/PlusIcon";
import MinusIcon from "../../icons/MinusIcon";
import EEffects from "cresus-common/dist/dto/EEffects";
import { getAddSubstractOfEffectFromType, switchEffectToTextAddSubstract } from "../../../tools/switchToEffects";
import React from "react";
function AddSubstractComponent(props) {
    const [addSubstract, setAddSubstract] = useState(getAddSubstractOfEffectFromType(props.effect));
    const [isAdding, setIsAdding] = useState(props.numberToAddorSubstract >= 0);
    const [textToShow, setTextToShow] = useState("");
    console.log("AddSubstractComponent", isAdding);
    const refInput = React.useRef(null);
    const refValue = React.useRef(Math.abs(props.numberToAddorSubstract));
    useEffect(() => {
        setTextToShow(switchEffectToTextAddSubstract(props.effect));
        setAddSubstract(getAddSubstractOfEffectFromType(props.effect));
        // setIsAdding(addSubstract.input);
    }, [props.effect]);
    function handleClickSubstract() {
        setIsAdding(false);
        props.setNumberToAddorSubstractOfEffect(-Math.abs(refValue.current));
    }
    ;
    function handleClickAdd() {
        setIsAdding(true);
        props.setNumberToAddorSubstractOfEffect(Math.abs(refValue.current));
    }
    ;
    function handleChangeNumberToAddorSubstract(e) {
        console.log("handleChangeNumberToAddorSubstract", e.target.value);
        const value = parseInt(e.target.value);
        refValue.current = isNaN(value) ? 0 : value;
        refValue.current = Math.abs(refValue.current);
        if (refInput.current)
            refInput.current.value = refValue.current.toString();
        props.setNumberToAddorSubstractOfEffect((isAdding ? 1. : -1.) * Math.abs(refValue.current));
    }
    return (_jsxs("div", Object.assign({ className: "flex gap-2 h-full item-center", style: {} }, { children: [props.effect === EEffects.DEPLACEMENT &&
                _jsx("div", Object.assign({ className: "flex h-full font-roboto gap-[10px] w-full items-center" }, { children: "case" })), addSubstract.addSub &&
                _jsxs("div", Object.assign({ className: "flex gap-1 h-full items-center" }, { children: [_jsx("button", Object.assign({ className: "flex w-[16px] h-[16px]", onClick: handleClickAdd, style: {
                                borderRadius: "3px",
                                backgroundColor: "#000000",
                                border: isAdding ? "2px solid #FFFFFF" : "2px solid rgba(161, 170, 188, 1)",
                                color: isAdding ? "#FFFFFF" : "#rgba(161, 170, 188, 1)",
                            } }, { children: _jsx(PlusIcon, { color: isAdding ? "#FFFFFF" : "rgba(161, 170, 188, 1)" }) })), _jsx("button", Object.assign({ className: "flex w-[16px] h-[16px]", onClick: handleClickSubstract, style: {
                                borderRadius: "3px",
                                backgroundColor: "#000000",
                                border: !isAdding ? "2px solid #FFFFFF" : "2px solid rgba(161, 170, 188, 1)",
                                color: "#rgba(161, 170, 188, 1)",
                            } }, { children: _jsx(MinusIcon, { color: !isAdding ? "#FFFFFF" : "rgba(161, 170, 188, 1)" }) }))] })), _jsx("input", { className: "flex h-full font-roboto", ref: refInput, defaultValue: refValue.current.toString(), onChange: (e) => { handleChangeNumberToAddorSubstract(e); }, style: {
                    width: 54,
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    color: "#FFFFFF",
                    backgroundColor: "#384983",
                    borderRadius: "4px",
                } }), _jsx("div", Object.assign({ className: "flex items-center font-roboto text-[14px] text-white" }, { children: textToShow }))] })));
}
export default AddSubstractComponent;
