var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
// import { playersInWaitRoom } from "../../MockData/Players";
import PeopleWaitingRoomComponent from "../../components/animator_components/PeopleWaitingRoomComponent";
import WebcamComponent from "../../components/animator_components/WebcamComponent";
import InformationPreGameComponent from "../../components/animator_components/InformationPreGameComponent";
import FunctionalButton from "../../components/FunctionalButton";
import { useNavigate } from "react-router-dom";
import useSessionContext from "../../tools/useSessionContext";
import { useBoardGame } from "../../contexts/BoardGameProvider";
import ChatAnimatorComponent from "../../components/animator_components/ChatAnimatorComponent";
import useMedia from "../../contexts/useMedia";
function IntroductionRoomPage() {
    const { muteSound } = useBoardGame();
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const { audioVideoClient } = useMedia();
    const [insideGameState, setInsideGameState] = useState(gameState);
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);
    const [chatOn, setChatOn] = useState(false);
    const [paramOn, setParamOn] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    const multiple = 1.3;
    const navigate = useNavigate();
    useEffect(() => {
        audioVideoClient.unmuteMic();
        audioVideoClient.enableWebcam();
    });
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.log("onConnectionOrReaload", error); });
            console.log(result, "getGameState");
            if (result !== undefined) {
                setInsideGameState(result);
            }
        });
    }
    function startSelectionAvatar() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("startAvatarSelection", { gameState: gameState })
                .catch((error) => { console.error("startAvatarSelection", error); });
            console.log(result, "startSelectionAvatar");
            //setInsideGameState(result);
            // muteSound("GlobalAmbiance");
            // navigate('/TeamSelectionPage')
        });
    }
    useEffect(() => {
        muteSound("GlobalAmbiance");
        getGameState();
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "updateGameState") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "startAvatarSelection") {
                setInsideGameState(notification.data);
                muteSound("GlobalAmbiance");
                navigate('/TeamSelectionPage');
            }
        });
    }, []);
    function handleClick() {
        startSelectionAvatar();
    }
    return (_jsxs("div", Object.assign({ className: "w-screen fixed flex h-screen bg-dilemmeSimplePurple" }, { children: [_jsx("div", Object.assign({ className: "flex absolute", style: { zIndex: chatOn ? 1000 : -1 } }, { children: _jsx(ChatAnimatorComponent, { newMessageAlert: setNewMessageAlert, isChatShown: chatOn, toggleChat: () => { setChatOn(!chatOn); setNewMessageAlert(false); } }) })), _jsxs("div", Object.assign({ className: "flex h-full flex-col", style: {
                    backgroundColor: "rgba(0, 0, 0, 0.50)",
                    width: "25%",
                    minWidth: "256px",
                    zIndex: 2,
                } }, { children: [_jsxs("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                            paddingLeft: "20px",
                            paddingTop: "33px",
                            height: "91px",
                            minHeight: "91px",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        } }, { children: [insideGameState.lobbyPlayers.length, " joueurs dans ", _jsx("br", {}), " la salle d'attente"] })), _jsx("div", Object.assign({ className: "flex relative", style: {
                            paddingTop: "20px",
                            paddingRight: "10px",
                            height: "100%",
                            minHeight: "305px",
                            borderTop: "1px solid rgba(255, 255, 255, 0.20)",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                        } }, { children: _jsx("div", Object.assign({ className: "flex relative overflow-y-auto w-full flex-col", style: {
                                paddingLeft: "20px",
                                paddingRight: "8px",
                                height: "100%",
                                gap: "5px",
                            } }, { children: insideGameState.lobbyPlayers.map((person, index) => {
                                return _jsx(PeopleWaitingRoomComponent, { player: person, sessionPeerId: person.sessionPeerId, name: person.name, micOn: !person.micOn }, `peopleInWait${index}`);
                            }) })) })), _jsx(WebcamComponent, { isCommunicationActive: true, isThereNotification: newMessageAlert, newMessageAlert: setNewMessageAlert, isChatActive: true, cameraOn: cameraOn, setCameraOn: setCameraOn, micOn: micOn, setMicOn: setMicOn, chatOn: chatOn, setChatOn: setChatOn, paramOn: paramOn, setParamOn: setParamOn })] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-start h-full w-full", style: {
                    paddingLeft: "50px",
                    paddingRight: "50px",
                } }, { children: [_jsx(InformationPreGameComponent, { buttonActive: true, textButton: "Lancer la session", onClickButton: () => { }, sessionName: insideGameState.animatorSessionName }), _jsxs("div", Object.assign({ className: "flex flex-col justify-between gap-6", style: {
                            // height: "388px",
                            height: 388 * multiple,
                            paddingTop: 24 * multiple,
                        } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col self-center font-kalam text-black", style: {
                                    // height: "314px",
                                    // width: "209px",
                                    height: 314 * multiple,
                                    width: 209 * multiple,
                                    // height:"377px",
                                    // width:"251px",
                                    backgroundImage: "url(./assets/UI/note_introduction.png)",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    paddingTop: 21 * multiple,
                                    paddingBottom: 31 * multiple,
                                    paddingLeft: 35 * multiple,
                                    paddingRight: 8 * multiple,
                                } }, { children: [_jsx("div", Object.assign({ className: "font-bold", style: {
                                            paddingLeft: 3 * multiple,
                                            paddingBottom: 20 * multiple,
                                        } }, { children: "Introduction aux joueurs" })), _jsx("div", Object.assign({ style: {
                                            paddingBottom: 16 * multiple,
                                        } }, { children: "Souhaiter la bienvenue" })), _jsx("div", Object.assign({ style: { paddingBottom: 16 * multiple } }, { children: "Présentation de soi-même et de l'objet de cet atelier" })), _jsx("div", Object.assign({ style: { paddingBottom: 16 * multiple } }, { children: "Rappel des objectifs d'une session Dilemme Education" })), _jsx("div", Object.assign({ style: { paddingBottom: 16 * multiple } }, { children: "Rappel des règles de bienséance" })), _jsx("div", Object.assign({ style: { paddingBottom: 24 * multiple } }, { children: "Expliquer l'étape suivante : la création d'avatars" })), _jsx("div", Object.assign({ style: { paddingBottom: 41 * multiple } }, { children: "C'est parti !" }))] })), _jsx("div", Object.assign({ className: "flex justify-center self-center", style: {
                                    border: "2px solid #000000",
                                    width: "363px",
                                } }, { children: _jsx(FunctionalButton, { text: "Lancer la personnalisation des avatars", colorMode: 0, isRound: false, onClick: () => handleClick() }) }))] }))] }))] })));
}
export default IntroductionRoomPage;
