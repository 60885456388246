import React from "react";
import MediaContext from "./MediaContext";
const useMedia = () => {
    const context = React.useContext(MediaContext);
    if (context === undefined) {
        throw new Error("useMedia must be used within a MediaContextProvider");
    }
    return context;
};
export default useMedia;
