import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
function ConfigButtonComponent(props) {
    const [isOnHover, setIsOnHover] = useState(false);
    return (_jsx("button", { className: "config-button", onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), onClick: () => {
            props.toggleConfig();
        }, style: {
            zIndex: 100,
            minWidth: 40,
            minHeight: 40,
            maxHeight: 40 * 2,
            maxWidth: 40 * 2,
            backgroundImage: `url(./assets/UI/buttons/player_buttons/config_images/config_hover_${isOnHover ? "on" : "off"}.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        } }));
}
export default ConfigButtonComponent;
