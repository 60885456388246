import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { useBoardGame } from "../../contexts/BoardGameProvider";
function ChatButtonComponent(props) {
    const { playSound } = useBoardGame();
    const [isOnHover, setIsOnHover] = useState(false);
    // const [newMessage, setNewMessage] = useState<boolean>(false);
    return (_jsx("button", Object.assign({ className: "config-button", onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), onClick: () => {
            playSound("Tchat");
            props.toggleChat();
        }, style: {
            minWidth: 40,
            minHeight: 40,
            maxHeight: 40 * 2,
            maxWidth: 40 * 2,
            backgroundImage: `url(./assets/UI/buttons/player_buttons/chat_images/chat_hover_${isOnHover ? "on" : "off"}.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        } }, { children: props.newMessage &&
            _jsx("div", { className: "flex absolute", style: {
                    width: "40cqw",
                    height: "40cqw",
                    borderRadius: "50%",
                    backgroundColor: "red",
                    border: "5cqw solid white",
                    top: "0",
                    right: "0",
                } }) })));
}
export default ChatButtonComponent;
