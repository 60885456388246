import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import GearIcon from "./icons/GearIcon";
const FunctionalButtonBlueFound = (props) => {
    let [over, setOver] = useState(false);
    let textColor = 'pink';
    let backgroundColor = 'red';
    let borderColor = 'blue';
    let borderThickness = '1px';
    let textDecoration = 'none';
    const text = props.isRound ? props.text : props.text.toUpperCase();
    const paddingButton = props.isRound ? '5px 16px' : '10px 16px';
    const borderRadius = props.isRound ? '16px' : '0px';
    const height = props.isRound ? '30px' : '50px';
    if (props.colorMode === 0) {
        textColor = 'rgba(84, 101, 159, 1)';
        borderColor = 'transparent';
        borderThickness = '0px';
        if (props.enabled) {
            backgroundColor = '#FFFFFF';
            if (over) {
                backgroundColor = 'rgba(220, 225, 242, 1)';
            }
        }
        else {
            backgroundColor = 'rgba(134, 153, 193, 1)';
        }
    }
    if (props.colorMode === 1) {
        backgroundColor = '';
        borderColor = '#FFFFFF';
        borderThickness = props.isRound ? '1px' : '2px';
        textColor = '#FFFFFF';
        if (props.enabled) {
            if (over) {
                backgroundColor = 'rgba(255, 255, 255, 0.2)';
            }
        }
        else {
            textColor = 'rgba(134, 153, 193, 1)';
            borderColor = 'rgba(134, 153, 193, 1)';
        }
    }
    if (props.colorMode === 2) {
        textDecoration = 'underline';
        backgroundColor = 'transparent';
        borderColor = 'black';
        borderThickness = '0px';
        textColor = 'white';
        if (props.enabled) {
            if (over) {
                textColor = 'rgba(190, 201, 240, 1)';
            }
        }
        else {
            textColor = 'rgba(134, 153, 193, 1)';
        }
    }
    function handleClick() {
        if (props.onClick) {
            props.onClick();
        }
    }
    const IconNode = props.icon;
    return (_jsxs("button", Object.assign({ disabled: !props.enabled, className: "flex items-center flex-row font-roboto h-full", style: {
            backgroundColor: backgroundColor,
            borderColor: borderColor, borderStyle: 'solid', borderWidth: borderThickness, borderRadius: borderRadius,
            fontWeight: '700', fontSize: props.fontSize,
            color: textColor,
            gap: '4px', height: height, padding: paddingButton,
        }, onMouseOver: () => setOver(true), onMouseOut: () => setOver(false), onClick: handleClick }, { children: [props.showLeftIcon ? _jsx(IconNode, { color: textColor }) : _jsx(_Fragment, {}), _jsx("div", Object.assign({ className: "flex", style: {
                    textDecoration: textDecoration,
                    whiteSpace: 'nowrap',
                } }, { children: text })), props.showRightIcon ? _jsx(IconNode, { color: textColor }) : _jsx(_Fragment, {})] })));
};
FunctionalButtonBlueFound.defaultProps = {
    onClick: undefined,
    enabled: true,
    showLeftIcon: false,
    showRightIcon: false,
    colorMode: 0,
    icon: GearIcon,
    fontSize: '14px',
    isRound: true,
};
export default FunctionalButtonBlueFound;
