import { jsx as _jsx } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
import { useEffect, useState } from "react";
function AvatarTeamComponent(props) {
    const [cigogne, setCigogne] = useState("url(./assets/UI/Cigogne_TeamSelection_Red.png)");
    function chooseCigogne(teamColor) {
        switch (teamColor) {
            case ETeam.RED:
                return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Red.png)";
            case ETeam.BLUE:
                return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Blue.png)";
            case ETeam.YELLOW:
                return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Yellow.png)";
            case ETeam.GREEN:
                return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Green.png)";
            default:
                return "url(./assets/UI/cigogne_team_selection_images/Cigogne_TeamSelection_Red.png)";
        }
    }
    useEffect(() => {
        setCigogne(chooseCigogne(props.teamColor));
    }, []);
    return (_jsx("div", Object.assign({ className: "flex flex-col w-full items-center justify-center", style: {
            minWidth: "152px",
            opacity: "1",
            marginBottom: "5px"
        } }, { children: _jsx("div", Object.assign({ className: "flex w-full justify-center", style: {
                backgroundImage: "url(./assets/UI/line.png)",
                backgroundSize: "contain",
                backgroundRepeat: "repeat-x",
                backgroundPositionY: "bottom"
            } }, { children: _jsx("div", { className: "flex", style: {
                    width: "152px",
                    height: "95px",
                    backgroundImage: `${cigogne}`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundPositionY: "-1px"
                } }) })) })));
}
export default AvatarTeamComponent;
