import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import useMedia from "../contexts/useMedia";
function FinalPage() {
    const { audioVideoClient } = useMedia();
    useEffect(() => {
        audioVideoClient.muteMic();
        audioVideoClient.disableWebcam();
        audioVideoClient.close();
    });
    return (_jsx("div", Object.assign({ className: "flex fixed flex-col justify-center items-center h-screen w-screen z-50", style: {
            paddingTop: "0px",
        } }, { children: _jsxs("div", Object.assign({ className: "flex gap-[40px] justify-center flex-col h-full relative " }, { children: [_jsx("div", { className: "flex dilemeLogo self-center", style: {
                        backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        minHeight: 166,
                        minWidth: 420,
                        maxHeight: 166 * 2,
                        maxWidth: 420 * 2,
                        zIndex: 1,
                    } }), _jsxs("div", Object.assign({ className: "flex font-kalam text-white flex-col self-center text-center", style: {
                        fontSize: "60px",
                        fontStyle: "normal",
                        lineHeight: "96px",
                        fontWeight: "700",
                        letterSpacing: "0.6px",
                        textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347, 3px 3px 0px #353347, 3px -3px 0px #353347, -3px 3px 0px #353347, -3px -3px 0px #353347, 4px 0px 0px #353347, 0px 4px 0px #353347, 4px 1px 0px #353347, 1px 4px 0px #353347, 4px 2px 0px #353347, 2px 4px 0px #353347, 4px 3px 0px #353347, 3px 4px 0px #353347, -4px 0px 0px #353347, 0px -4px 0px #353347, -4px 1px 0px #353347, 1px -4px 0px #353347, -4px 2px 0px #353347, 2px -4px 0px #353347, -4px 3px 0px #353347, 3px -4px 0px #353347, 4px -1px 0px #353347, -1px 4px 0px #353347, 4px -2px 0px #353347, -2px 4px 0px #353347, 4px -3px 0px #353347, -3px 4px 0px #353347, -4px -1px 0px #353347, -1px -4px 0px #353347, -4px -2px 0px #353347, -2px -4px 0px #353347, -4px -3px 0px #353347, -3px -4px 0px #353347, -5px 5px 0px #353347, -6px 5px 0px #353347, -5px 6px 0px #353347, -4px 6px 0px #353347, -3px 6px 0px #353347, -2px 6px 0px #353347, -1px 6px 0px #353347, -0px 6px 0px #353347, -6px 4px 0px #353347, -6px 3px 0px #353347, -6px 2px 0px #353347, -6px 1px 0px #353347, -6px 0px 0px #353347, -6px -1px 0px #353347",
                        // textShadow:" -5px 5px 0px #352247"
                        // textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, -5px 5px 0px #352247"
                    } }, { children: [_jsx("span", { children: "La session est termin\u00E9e." }), _jsx("span", { children: "Merci d'avoir jou\u00E9." })] })), _jsxs("div", Object.assign({ className: "flex flex-col relative justify-start self-center items-start text-left gap-[10px]", style: {
                        width: 600,
                        padding: "20px",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        border: "2px solid #000000",
                        // textAlign: "center",
                        fontFamily: "Kalam",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                        listStyleType: "disc",
                    } }, { children: [_jsx("li", { children: "Vous souhaitez en savoir plus ? Pour tout renseignement compl\u00E9mentaire, n'h\u00E9sitez pas \u00E0 contacter l'ambassadeur.ice  qui a anim\u00E9 votre atelier." }), _jsxs("li", { children: ["Pour tout renseignement compl\u00E9mentaire sur Dilemme et Cr\u00E9sus : ", _jsx("button", Object.assign({ style: { color: "blue", textDecoration: "underline" }, onClick: () => window.open('https://www.dilemme.org') }, { children: "c'est par ici " }))] }), _jsxs("li", { children: ["Vous souhaitez g\u00E9rer votre budget sereinement au quotidien ? Connectez vous \u00E0  ", _jsx("button", Object.assign({ style: { color: "blue", textDecoration: "underline" }, onClick: () => window.open('https://www.bgvapp.com/?o=RYRflb') }, { children: " BGV (Budget Grande Vitesse) " })), " , c'est gratuit, s\u00E9curis\u00E9 et sans engagement !"] })] }))] })) })));
}
export default FinalPage;
