import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
function NotebookButtonComponent(props) {
    const [isNotebookShown, setIsNotebookShown] = useState(props.isNotebookShown);
    const [isOnHover, setIsOnHover] = useState(false);
    const handleClick = () => {
        props.handleCLick();
        setIsNotebookShown(!isNotebookShown);
    };
    return (_jsx("button", { className: `flex absolute buttonNoteBook ${isNotebookShown ? "buttonNoteBookShown" : "buttonNoteBookHidden"}`, onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), onClick: handleClick, style: {
            minWidth: 50.5,
            maxWidth: 50.5 * 2,
            minHeight: 66.5,
            maxHeight: 66.5 * 2,
            // rotate: `${isNotebookShown ? "-3deg" : "10deg"}`,
            // top: `${isNotebookShown ? "6cqw" : "21cqw"}`,
            // right: `${isNotebookShown ? "9.5cqw" : "88.5cqw"}`,
            transition: "all 1s ease-in-out",
            //transitionProperty: ""
            // transition: "all 0.3s ease-in-out",
            backgroundImage: `url(./assets/UI/buttons/notebook_buttons/btn_notebook_${isNotebookShown ? "hide" : "show"}${isOnHover ? "_hover" : ""}.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            zIndex: 100,
        } }));
}
export default NotebookButtonComponent;
