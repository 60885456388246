import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
function ToggleViewButtonComponent(props) {
    const [isPlayerView, setIsPlayerView] = useState(props.isPlayerView);
    return (_jsx("button", { className: "flex toogle_view", onClick: () => {
            props.toggleToggleView();
            setIsPlayerView(!isPlayerView);
        }, style: {
            minWidth: 90,
            minHeight: 40,
            maxHeight: 40 * 2,
            maxWidth: 90 * 2,
            backgroundImage: `url(./assets/UI/buttons/player_buttons/toggle_view_images/toggle_view_${isPlayerView ? "player" : "board"}.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        } }));
}
export default ToggleViewButtonComponent;
