import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import CameraOnIcon from "../icons/CameraOnIcon";
import ChatIcon from "../icons/ChatIcon";
import GearIcon from "../icons/GearIcon";
import MicOffIcon from "../icons/MicOffIcon";
import MicOnIcon from "../icons/MicOnIcon";
import CameraOffIcon from "../icons/CameraOffIcon";
import useMedia from "../../contexts/useMedia";
import useSessionContext from "../../tools/useSessionContext";
function WebcamComponent(props) {
    var _a, _b;
    const { websocketPeer } = useSessionContext();
    const { videoComponent, audioVideoClient, openMediaSettings, moveVideoTag, releaseVideoTag } = useMedia();
    const refVideoContainer = useRef(null);
    // Used to trigger rerender when we want as some state are not updated by react
    // Example: audioVideoClient._producer?.paused
    const [fakeState, setFakeState] = useState(false);
    const [chatOn, setChatOn] = useState(props.chatOn);
    useEffect(() => {
        initCamera();
    }, []);
    useEffect(() => {
        console.log("WebcamComponent mount", refVideoContainer.current);
        moveVideoTag(refVideoContainer.current);
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "cameraEnabled") { // If someone else enabled his camera we disable ours
                audioVideoClient.disableWebcam();
                setFakeState(!fakeState); // We force refresh for the UI to be updated
            }
        });
        return () => {
            releaseVideoTag();
        };
    }, []);
    useEffect(() => {
        setChatOn(props.chatOn);
    }, [props.chatOn]);
    function handleMute() {
        var _a;
        if ((_a = audioVideoClient._producer) === null || _a === void 0 ? void 0 : _a.paused)
            audioVideoClient.unmuteMic();
        else
            audioVideoClient.muteMic();
        setFakeState(!fakeState); // Triggers rerender // TODO perhaps it would be better to wait the mute / unmute promise to complete
    }
    function initCamera() {
        if (!audioVideoClient._webcamProducer) {
            audioVideoClient.enableWebcam().then(() => {
                setFakeState(!fakeState); // Triggers rerender
            });
        }
    }
    function handleCamera() {
        if (!audioVideoClient._webcamProducer) {
            audioVideoClient.enableWebcam().then(() => {
                setFakeState(!fakeState); // Triggers rerender
            });
        }
        else
            audioVideoClient.disableWebcam().then(() => {
                setFakeState(!fakeState); // Triggers rerender
            });
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] w-full items-center justify-center", style: {
            padding: "20px 33px",
            height: "235px",
            minHeight: "235px",
        } }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-white", style: {
                    width: "190px",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    visibility: props.isCommunicationActive ? "hidden" : "visible",
                } }, { children: "\u00C0 cette \u00E9tape, les joueurs ne vous voient et ne vous entendent pas." })), _jsx("div", { className: "flex justify-center", ref: refVideoContainer, style: {
                    background: "#00000080",
                    height: "107px",
                    width: "190px",
                } }), _jsxs("div", Object.assign({ className: "flex flex-row gap-[10px]" }, { children: [_jsxs("button", Object.assign({ className: "flex h-[40px] w-[40px]", onClick: handleMute, style: {
                            padding: "8px",
                            backgroundColor: "#000000",
                            borderRadius: "50%",
                            visibility: props.isCommunicationActive ? "visible" : "hidden",
                        } }, { children: [!((_a = audioVideoClient._producer) === null || _a === void 0 ? void 0 : _a.paused) && _jsx(MicOnIcon, {}), ((_b = audioVideoClient._producer) === null || _b === void 0 ? void 0 : _b.paused) && _jsx(MicOffIcon, {})] })), _jsxs("button", Object.assign({ className: "flex h-[40px] w-[40px]", onClick: handleCamera, style: {
                            padding: "8px",
                            backgroundColor: "#000000",
                            borderRadius: "50%",
                            visibility: props.isCommunicationActive ? "visible" : "hidden",
                        } }, { children: [!!audioVideoClient._webcamProducer && _jsx(CameraOnIcon, {}), !audioVideoClient._webcamProducer && _jsx(CameraOffIcon, {})] })), props.isChatActive &&
                        _jsxs("button", Object.assign({ className: "flex relative h-[40px] w-[40px]", onClick: () => {
                                props.setChatOn(!chatOn);
                                if (!chatOn)
                                    props.newMessageAlert(false);
                            }, style: {
                                padding: "8px",
                                backgroundColor: "#000000",
                                borderRadius: "50%",
                            } }, { children: [_jsx(ChatIcon, {}), props.isThereNotification &&
                                    _jsx("div", { className: "flex absolute", style: {
                                            width: "16px",
                                            height: "16px",
                                            top: "0px",
                                            right: "0px",
                                            borderRadius: "50%",
                                            backgroundColor: "red",
                                            border: "2px solid white",
                                        } })] })), _jsx("button", Object.assign({ className: "flex h-[40px] w-[40px]", onClick: openMediaSettings, style: {
                            padding: "8px",
                            backgroundColor: "#000000",
                            borderRadius: "50%",
                        } }, { children: _jsx(GearIcon, {}) }))] }))] })));
}
export default WebcamComponent;
