var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ChargesNotebookComponent from "../../components/player_game_element_component/ChargesNotebookComponent";
import SetOfButtonsPlayerComponent from "../../components/player_game_element_component/SetOfButtonsPlayerComponent";
import MoneyAndPleasureInfoComponent from "../../components/player_game_element_component/MoneyAndPleausreInfoComponent";
import MyTeamDisplayComponent from "../../components/player_game_element_component/MyTeamDisplayComponent";
import ProjectProposalsComponent from "../../components/player_game_element_component/ProjectProposalsComponent";
import { useNavigate } from "react-router-dom";
import ChatComponent from "../../components/ChatComponent";
import useSessionContext from "../../tools/useSessionContext";
import useMedia from "../../contexts/useMedia";
import ETeam from "cresus-common/dist/dto/ETeam";
import ETurn from "cresus-common/dist/dto/ETurn";
const defaultPlayerDTO = {
    avatarComposition: {
        bonus: 0,
        eyes: 0,
        hair: 0,
        hair_color: 0,
        mouth: 0,
        nose: 0,
        skin: 0,
        teamColor: ETeam.NONE,
    },
    name: "",
    capitain: false,
    team: ETeam.NONE,
    micOn: false,
    sessionPeerId: "undefined",
    isConnected: true,
};
function PlayerDistributionProjectPage() {
    const navigate = useNavigate();
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const { setCameraMode } = useMedia();
    const [insideGameState, setInsideGameState] = useState(gameState);
    const [myPlayer, setMyPlayer] = useState(defaultPlayerDTO);
    const [isChargesShown, setIsChargesShown] = useState(false);
    const [isChatShown, setIsChatShown] = useState(false);
    const [isNotebookShown, setIsNotebookShown] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            if (result !== undefined)
                setInsideGameState(result);
        });
    }
    function getMyPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getMyPlayer");
            const result = yield sendRequest("getMyPlayer", { gameState: gameState })
                .catch((error) => { console.error("getMyPlayer", error); });
            if (result !== undefined)
                setMyPlayer(result);
            else
                console.warn("problem with the data");
        });
    }
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "distributeIncome") {
                console.log("distributeIncome", notification);
                setInsideGameState(Object.assign({}, notification.data));
                console.log(myPlayer, "myPlayer In distribution Income");
                // getMyTeam(notification.data as GameState, myPlayer);
            }
            if (notification.method === "updateGameState") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "distributePleasurePoints") {
                console.log("distributePleasurePoints", notification);
                setInsideGameState(Object.assign({}, notification.data));
                // getMyTeam(notification.data as GameState, myPlayer);
                console.log(myTeam, notification.data, "myTeam.pleasurePoints");
            }
            if (notification.method === "distributeCharges") {
                console.log("distributeCharges", notification);
                setInsideGameState(Object.assign({}, notification.data));
                // getMyTeam(notification.data as GameState, myPlayer);
                // setIsChargesShown(myTeam.chargesDistributed)
            }
            if (notification.method === "proposeProject") {
                console.log("proposeProject", notification);
                setInsideGameState(Object.assign({}, notification.data));
                // getMyTeam(notification.data as GameState, myPlayer);
            }
            if (notification.method === "validateProject") {
                console.log("validateProject", notification);
                setInsideGameState(Object.assign({}, notification.data));
                // getMyTeam(notification.data as GameState, myPlayer);
                //in back think to set project propose as []
            }
            if (notification.method === "startTheGameInitialisation") {
                console.log("startGame", notification);
                setInsideGameState(Object.assign({}, notification.data));
                // getMyTeam(notification.data as GameState, myPlayer);
                navigate("/GameInProgressPage");
            }
        });
    }, []);
    const myTeam = insideGameState.teams.find((team) => {
        return team.players.find((player) => player.name === myPlayer.name);
    });
    //-----------------------------------------------------------------------------//
    function checkIfProjectIsSelected(team) {
        console.log("checkIfProjectIsSelected", team.project);
        let projectSelected = false;
        if (team.project !== undefined) {
            projectSelected = true;
        }
        console.log("projectSelected", projectSelected);
        return projectSelected;
    }
    function handleClickToShowNotebook() {
        setCameraMode(true, isNotebookShown, isChatShown);
        setIsNotebookShown(!isNotebookShown);
    }
    function hanldeTogleChat() {
        // setCameraMode(true, isNotebookShown, isChatShown);
        setIsChatShown(!isChatShown);
        if (!isChatShown)
            setNewMessageAlert(false);
    }
    useEffect(() => {
        getGameState();
        getMyPlayer();
    }, []);
    useEffect(() => {
        console.log("myPlayer got actualized ", myPlayer);
        // getMyTeam(insideGameState, myPlayer)
    }, [myPlayer]);
    useEffect(() => {
        var _a, _b;
        if (((_b = (_a = insideGameState.teams[0]) === null || _a === void 0 ? void 0 : _a.charges) === null || _b === void 0 ? void 0 : _b.length) > 0)
            setIsChargesShown(true);
        getMyPlayer();
        if (insideGameState.gameStarted) {
            navigate("/GameInProgressPage");
        }
    }, [insideGameState]);
    return (_jsxs("div", Object.assign({ className: "flex fixed flex-col justify-center items-center h-screen w-screen", style: {
            paddingTop: "50px",
            paddingLeft: "40px",
            paddingRight: "40px",
        } }, { children: [_jsx(SetOfButtonsPlayerComponent, { alertNewMessage: newMessageAlert, toggleChat: hanldeTogleChat, isMapModifiable: true, isOnlyConfigButton: false }), myPlayer &&
                _jsx(ChatComponent, { newMessageAlert: setNewMessageAlert, myPlayer: myPlayer, isChatShown: isChatShown, toggleChat: hanldeTogleChat }), myTeam && myTeam.projectsProposed && myTeam.projectsProposed.length > 0 && !checkIfProjectIsSelected(myTeam) &&
                _jsx(ProjectProposalsComponent, { projects: myTeam.projectsProposed }), myTeam && isChargesShown && myTeam.incomes && myTeam.charges &&
                _jsx(ChargesNotebookComponent, { isDoubleOn: false, isCaptain: false, cards: [], savings: { amout: 0, percentageOfBonus: 0 }, numberOfMonthPlayed: 0, turnState: ETurn.NO_ACTION_REQUIRED, team: ETeam.NONE, setInsideGameState: () => { }, incomes: myTeam.incomes, charges: myTeam.charges, project: myTeam.project, isProjectDistributed: checkIfProjectIsSelected(myTeam), handleCLick: handleClickToShowNotebook }), myTeam &&
                _jsx(MoneyAndPleasureInfoComponent, { money: myTeam.money, pleasurePoints: myTeam.pleasurePoints }), myPlayer &&
                _jsx(MyTeamDisplayComponent, { myPlayerAvatar: myPlayer.avatarComposition, avatars: (myTeam ? myTeam.players.filter(player => player.name !== myPlayer.name)
                        .map(el => el.avatarComposition) : []), team: myTeam ? myTeam.teamColor : ETeam.NONE })] })));
}
export default PlayerDistributionProjectPage;
