import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./App.css";
import "./App.tailwind.css";
function TrainWithoutDynamic() {
    return (_jsxs("div", Object.assign({ className: "w-screen h-screen justify-center items-center flex bg-purple-500" }, { children: [_jsxs("div", Object.assign({ className: "flex  m-0 p-0 z-20", style: {
                    // width: `${"73.58vw"}`,
                    height: `${"87.24vh"}`,
                    minHeight: "550.5px",
                    minWidth: "753.5px",
                    // maxHeight: "1101px",
                    // maxWidth: "1507px",
                    bottom: "-4vh",
                    left: "-9.05vw",
                    position: "fixed",
                    rotate: "-3deg",
                    backgroundImage: "url(./assets/UI/notebook_bg.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box",
                    aspectRatio: "753.5/550.5",
                    zIndex: "1",
                } }, { children: [_jsxs("div", Object.assign({ 
                        // ref={testToPay}
                        className: " flex relative", style: {
                            // width: `${"61.05%"}`,
                            height: `${"54.3%"}`,
                            // minHeight: "229px",
                            // minWidth: "460px",
                            // maxHeight: "458px",
                            // maxWidth: "920px",
                            top: "6%",
                            left: "34%",
                            position: "absolute",
                            rotate: "0deg",
                            // backgroundImage: "url(./assets/UI/to_pay.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            aspectRatio: "460/305",
                            zIndex: "2",
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gridTemplateRows: "repeat(4, 1fr)",
                            rowGap: "0.96vw",
                            columnGap: "2.17vh",
                        } }, { children: ["        ", _jsx("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "1/1",
                                    gridRow: "1/1",
                                    backgroundImage: "url(./assets/UI/cadre.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                } }, { children: _jsx("div", Object.assign({ className: "flex-col relative", style: {
                                        margin: "auto",
                                        width: "80%",
                                        height: "60%"
                                    } }, { children: _jsxs("div", Object.assign({ className: "flex-col relative", style: {
                                            fontSize: `${"smaller"}`,
                                            width: "100%"
                                        } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-kalam", style: { fontSize: `${"100%"}`, } }, { children: "SALAIRE DU MOIS" })), _jsx("div", Object.assign({ className: "flex relative font-kalam", style: {
                                                    fontSize: `${"110%"}`,
                                                    fontWeight: "bold"
                                                } }, { children: "1200\u20AC" }))] })) })) })), _jsxs("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "2/2",
                                    gridRow: "1/1",
                                } }, { children: [_jsx("div", { className: "flex relative" }), _jsxs("div", Object.assign({ className: "flex relative self-end", style: {
                                            width: "100%", height: "40%",
                                            backgroundImage: "url(./assets/UI/line.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPositionY: "0%",
                                            backgroundClip: "unset"
                                        } }, { children: [_jsx("div", { className: "flex h-full", style: {
                                                    width: "11.43%",
                                                    marginRight: "3.57%",
                                                    position: "relative",
                                                    bottom: "-25%",
                                                    backgroundImage: "url(./assets/UI/huissier.png)",
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundClip: "content-box",
                                                } }), _jsx("div", { className: "flex h-full", style: {
                                                    width: "11.43%",
                                                    marginRight: "3.57%",
                                                    position: "relative",
                                                    bottom: "-25%",
                                                    backgroundImage: "url(./assets/UI/picto_plaisir.png)",
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundClip: "content-box",
                                                } })] }))] })), _jsx("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "3/3",
                                    gridRow: "1/1",
                                    // opacity: "0.5",
                                } }, { children: _jsxs("div", Object.assign({ className: "flex relative self-end", style: {
                                        width: "100%", height: "40%",
                                        backgroundImage: "url(./assets/UI/line.png)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPositionY: "0%",
                                        backgroundClip: "unset"
                                    } }, { children: [_jsx("div", { className: "flex h-full", style: {
                                                width: "11.43%",
                                                marginRight: "3.57%",
                                                position: "relative",
                                                bottom: "-25%",
                                                backgroundImage: "url(./assets/UI/huissier.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundClip: "content-box",
                                            } }), _jsx("div", { className: "flex h-full", style: {
                                                width: "11.43%",
                                                marginRight: "3.57%",
                                                position: "relative",
                                                bottom: "-25%",
                                                backgroundImage: "url(./assets/UI/picto_plaisir.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundClip: "content-box",
                                            } })] })) })), _jsx("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "1/1",
                                    gridRow: "2/2",
                                    // opacity: "0.5",
                                } }, { children: _jsxs("div", Object.assign({ className: "flex relative self-end", style: {
                                        width: "100%", height: "40%",
                                        backgroundImage: "url(./assets/UI/line.png)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPositionY: "0%",
                                        backgroundClip: "unset"
                                    } }, { children: [_jsx("div", { className: "flex h-full", style: {
                                                width: "11.43%",
                                                marginRight: "3.57%",
                                                position: "relative",
                                                bottom: "-25%",
                                                backgroundImage: "url(./assets/UI/huissier.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundClip: "content-box",
                                            } }), _jsx("div", { className: "flex h-full", style: {
                                                width: "11.43%",
                                                marginRight: "3.57%",
                                                position: "relative",
                                                bottom: "-25%",
                                                backgroundImage: "url(./assets/UI/picto_plaisir.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundClip: "content-box",
                                            } })] })) })), _jsx("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "2/2",
                                    gridRow: "2/2",
                                    // opacity: "0.5",
                                } }, { children: _jsxs("div", Object.assign({ className: "flex relative self-end", style: {
                                        width: "100%", height: "40%",
                                        backgroundImage: "url(./assets/UI/line.png)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPositionY: "0%",
                                        backgroundClip: "unset"
                                    } }, { children: [_jsx("div", { className: "flex h-full", style: {
                                                width: "11.43%",
                                                marginRight: "3.57%",
                                                position: "relative",
                                                bottom: "-25%",
                                                backgroundImage: "url(./assets/UI/huissier.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundClip: "content-box",
                                            } }), _jsx("div", { className: "flex h-full", style: {
                                                width: "11.43%",
                                                marginRight: "3.57%",
                                                position: "relative",
                                                bottom: "-25%",
                                                backgroundImage: "url(./assets/UI/picto_plaisir.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundClip: "content-box",
                                            } })] })) })), _jsx("div", { className: "bg-zinc-500", style: {
                                    gridColumn: "3/3",
                                    gridRow: "2/2",
                                    opacity: "0.5",
                                } }), _jsx("div", { className: "bg-amber-900", style: {
                                    gridColumn: "1/1",
                                    gridRow: "3/3",
                                    opacity: "0.5",
                                } }), _jsx("div", { className: "bg-violet-700", style: {
                                    gridColumn: "2/2",
                                    gridRow: "3/3",
                                    opacity: "0.5",
                                } }), _jsx("div", { className: "bg-rose-500", style: {
                                    gridColumn: "3/3",
                                    gridRow: "3/3",
                                    opacity: "0.5",
                                } }), _jsx("div", { className: "bg-teal-500", style: {
                                    gridColumn: "1/1",
                                    gridRow: "4/4",
                                    opacity: "0.5",
                                } })] })), _jsxs("div", Object.assign({ 
                        // ref={testToPay}
                        className: " flex relative", style: {
                            // width: `${"61.05%"}`,
                            height: `${"26.15%"}`,
                            top: "65%",
                            left: "54.3%",
                            position: "absolute",
                            rotate: "0deg",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            aspectRatio: "633/305",
                            zIndex: "2",
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gridTemplateRows: "repeat(2, 1fr)",
                            rowGap: "0.96vw",
                            columnGap: "2.17vh",
                            opacity: "1",
                        } }, { children: [_jsxs("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "1/1",
                                    gridRow: "1/1",
                                } }, { children: [_jsx("div", { className: "flex relative w-full h-full ", style: {
                                            position: "absolute",
                                            backgroundImage: "url(./assets/UI/cadre.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundClip: "content-box",
                                            backgroundSize: "contain",
                                            zIndex: "2",
                                        } }), _jsx("div", { className: "bg-dilemmeBluePleasure flex", style: {
                                            position: "absolute",
                                            width: "99%",
                                            left: "0.5%",
                                            height: "95%",
                                            top: "0.5%",
                                            zIndex: "1",
                                            opacity: "0.5",
                                        } })] })), _jsxs("div", Object.assign({ className: "flex relative w-full h-full ", style: {
                                    gridColumn: "2/2",
                                    gridRow: "1/1",
                                } }, { children: [_jsxs("div", Object.assign({ className: "flex-row relative w-full h-full ", style: {
                                            position: "absolute",
                                            backgroundImage: "url(./assets/UI/cadre.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundClip: "content-box",
                                            backgroundSize: "contain",
                                            zIndex: "2",
                                        } }, { children: [_jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                                    width: "86%",
                                                    height: "52%",
                                                    marginTop: "8%",
                                                    marginLeft: "7%",
                                                    marginRight: "7%",
                                                } }, { children: [_jsx("div", Object.assign({ className: "self-start items-start font-kalam relative justify-start text-start text flex", style: {
                                                            width: "100%",
                                                            height: "40%",
                                                            fontSize: "1.1vw",
                                                        } }, { children: "Adoption chien" })), _jsx("div", Object.assign({ className: "self-start justify-start font-kalam items-start relative flex", style: {
                                                            width: "100%",
                                                            height: "40%",
                                                            fontSize: "1.1vw",
                                                        } }, { children: "40 \u20AC/mois" }))] })), _jsxs("div", Object.assign({ className: "flex relative self-end justify-end", style: {
                                                    width: "100%", height: "30%",
                                                    top: "-4%",
                                                } }, { children: [_jsx("div", Object.assign({ className: "flex text-center justify-end items-center relative font-kalam", style: {
                                                            height: "50%",
                                                            width: "40%",
                                                            bottom: "-26%",
                                                            marginRight: "5%",
                                                            fontSize: "1.1vw",
                                                            fontWeight: "600",
                                                        } }, { children: "Mensuel" })), _jsx("div", { className: "flex self-center ", style: {
                                                            width: "10.43%",
                                                            height: "70%",
                                                            marginRight: "3%",
                                                            position: "relative",
                                                            bottom: "0%",
                                                            backgroundImage: "url(./assets/UI/picto_plaisir_colored.png)",
                                                            backgroundSize: "contain",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundClip: "content-box",
                                                        } }), _jsx("div", { className: "flex self-center ", style: {
                                                            width: "10.43%",
                                                            height: "70%",
                                                            marginRight: "3%",
                                                            position: "relative",
                                                            bottom: "0%",
                                                            backgroundImage: "url(./assets/UI/picto_plaisir_colored.png)",
                                                            backgroundSize: "contain",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundClip: "content-box",
                                                        } })] }))] })), _jsx("div", { className: "bg-dilemmeGreenChance flex", style: {
                                            position: "absolute",
                                            width: "99%",
                                            left: "0.5%",
                                            height: "95%",
                                            top: "0.5%",
                                            zIndex: "1",
                                            opacity: "0.5",
                                        } })] })), _jsx("div", { className: "flex relative w-full h-full ", style: {
                                    gridColumn: "1/1",
                                    gridRow: "2/2",
                                    backgroundImage: "url(./assets/UI/cadre.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                    opacity: "0.5",
                                } }), _jsx("div", { className: "flex relative w-full h-full ", style: {
                                    gridColumn: "2/2",
                                    gridRow: "2/2",
                                    backgroundImage: "url(./assets/UI/cadre.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                    opacity: "0.5",
                                } })] })), _jsxs("div", Object.assign({ className: " m-0 p-0 flex", style: {
                            height: `${"32.96vh"}`,
                            minHeight: "208px",
                            minWidth: "253px",
                            rotate: "5deg",
                            position: "absolute",
                            bottom: "-3%",
                            left: "14%",
                            aspectRatio: "253/208",
                            zIndex: "3",
                        } }, { children: [_jsx("div", { 
                                // ref={testMask}
                                className: " m-0 p-0 flex relative", style: {
                                    height: "100%",
                                    minHeight: "208px",
                                    minWidth: "253px",
                                    aspectRatio: "253/208",
                                    backgroundImage: "url(./assets/UI/mask_cam.png)",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    zIndex: "3",
                                } }), _jsx("div", { className: "flex relative", style: {
                                    height: "100%",
                                    bottom: "-4%",
                                    position: "absolute",
                                    left: "3%",
                                    backgroundImage: "url(./assets/UI/cam.png)",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    aspectRatio: "253/208",
                                    zIndex: "2",
                                } })] })), _jsx("div", { 
                        // ref={testButtonNotebook}
                        className: "flex  m-0 p-0", style: {
                            // width: `${"4.93vw"}`,
                            height: `${"10.54vh"}`,
                            minHeight: "66.5px",
                            minWidth: "50.5px",
                            // maxHeight: "133px",
                            // maxWidth: "101px",
                            top: "8%",
                            right: "-5%",
                            position: "absolute",
                            rotate: "0deg",
                            backgroundImage: "url(./assets/UI/btn_notebook_hide.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            aspectRatio: "50.5/66.5",
                            zIndex: "2",
                        } }), _jsx("div", { 
                        // ref={testEpargne}
                        className: "flex  m-0 p-0", style: {
                            // width: `${"34.86vw"}`,
                            height: `${"63.23vh"}`,
                            minHeight: "399px",
                            minWidth: "357px",
                            // maxHeight: "798px",
                            // maxWidth: "714px",
                            top: "7%",
                            left: "-13.5%",
                            position: "absolute",
                            rotate: "-10deg",
                            backgroundImage: "url(./assets/UI/epargne.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            aspectRatio: "357/399",
                            zIndex: "1",
                        } })] })), _jsx("div", Object.assign({ 
                // ref={testNotebook}
                className: "flex  m-0 p-0", style: {
                    // width: `${"73.58vw"}`,
                    height: `${"87.24vh"}`,
                    minHeight: "550.5px",
                    minWidth: "753.5px",
                    // maxHeight: "1101px",
                    // maxWidth: "1507px",
                    bottom: "-4vh",
                    left: "-9.05vw",
                    position: "fixed",
                    rotate: "-3deg",
                    aspectRatio: "753.5/550.5",
                } }, { children: _jsxs("div", Object.assign({ 
                    // ref={testPostIt}
                    className: "flex-row m-0 p-0", style: {
                        // width: `${"22.84vw"}`,
                        height: `${"36.77vh"}`,
                        minHeight: "232px",
                        minWidth: "234px",
                        // maxHeight: "464px",
                        // maxWidth: "468px",
                        rotate: "10deg",
                        top: "18.5%",
                        left: "98.5%",
                        position: 'absolute',
                        backgroundImage: "url(./assets/UI/post_it.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box",
                        aspectRatio: "234/232",
                        zIndex: "0",
                    } }, { children: [_jsx("div", Object.assign({ className: "flex justify-cente text-center font-kalam overflow-hidden", style: {
                                width: "75%",
                                height: "20%",
                                left: "12.5%",
                                position: "relative",
                                rotate: "-5deg",
                                top: "32%",
                                fontWeight: "bold",
                                fontSize: "1.4vw",
                            } }, { children: "Week-end \u00E0 Prague pour 2 personnes" })), _jsx("div", Object.assign({ className: "flex justify-center text-center font-kalam overflow-hidden", style: {
                                width: "75%",
                                height: "20%",
                                left: "12.5%",
                                position: "relative",
                                rotate: "-5deg",
                                top: "32%",
                                fontSize: "2.6vw",
                                fontWeight: "bold",
                            } }, { children: "780  \u20AC" }))] })) })), _jsx("div", { style: {
                    // width: "8.40vw",
                    height: "18.86vh",
                    minHeight: "119px",
                    minWidth: "86px",
                    position: "fixed",
                    zIndex: "4",
                    backgroundImage: "url(./assets/UI/me.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box",
                    aspectRatio: "86/119",
                    bottom: "-1.2vh",
                    right: "0.5vw",
                } }), _jsx("div", { style: {
                    // width: "19.24vw",
                    height: "32.49vh",
                    position: "fixed",
                    zIndex: "5",
                    minWidth: "197px",
                    minHeight: "205px",
                    backgroundImage: "url(./assets/UI/cigogne_holder.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box",
                    aspectRatio: "197/205",
                    bottom: "-1.2vh",
                    right: "4.5vw",
                } }), _jsx("div", { style: {
                    // width: "7.13%",
                    height: "13.63%",
                    position: "fixed",
                    zIndex: "5",
                    minWidth: "73px",
                    minHeight: "86px",
                    backgroundImage: "url(./assets/UI/pnj.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box",
                    aspectRatio: "73/86",
                    bottom: "-1.2vh",
                    right: "12vw",
                } }), _jsx("div", { style: {
                    // width: "7.13%",
                    height: "13.63%",
                    position: "fixed",
                    zIndex: "4",
                    minWidth: "73px",
                    minHeight: "86px",
                    backgroundImage: "url(./assets/UI/pnj.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box",
                    aspectRatio: "73/86",
                    bottom: "-1.2vh",
                    right: "17vw",
                } }), _jsx("div", { style: {
                    // width: "7.13%",
                    height: "13.63%",
                    position: "fixed",
                    zIndex: "3",
                    minWidth: "73px",
                    minHeight: "86px",
                    backgroundImage: "url(./assets/UI/pnj.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box",
                    aspectRatio: "73/86",
                    bottom: "-1.2vh",
                    right: "22vw",
                } })] })));
}
export default TrainWithoutDynamic;
