import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "../App.css";
import "../App.tailwind.css";
import Userfront from "@userfront/core";
import EmailSentToaster from "../components/Toasters/EmailSentToaster";
// import {ReactComponent as OffSVG} from "../../assets/UI/Vector.svg" a voir pour fix ça bloque parceque c'est en dehors de src
function ForgottenPasswordPage() {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [toasterEmail, setToasterEmail] = useState("");
    function handleSubmit() {
        Userfront.sendResetLink(email).then((respone) => {
            console.log(respone);
            setEmailSent(true);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        // console.log(Userfront.mode)
        // Userfront.mode.value = "live";
        // console.log(Userfront.mode)
    }, []);
    return (_jsxs("div", Object.assign({ className: " w-screen h-full flex justify-center", style: {
            opacity: "1",
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.8",
                } }), emailSent && _jsx(EmailSentToaster, { setToasterEmail: setEmailSent, email: email }), !emailSent && _jsx("div", Object.assign({ className: "flex absolute w-full h-full justify-center" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col relative bg-white justify-start items-center", style: {
                        padding: "32px",
                        gap: "24px",
                        width: "424px",
                        height: "307px",
                        top: "23.45%",
                        minHeight: "303px",
                        minWidth: "424px",
                        border: "2px solid #000000",
                        // opacity: "1",
                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative text-dilemmeDeepPurpple font-roboto justify-center items-center text-center", style: {
                                width: "100%",
                                fontSize: "18px",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                textTransform: "uppercase",
                            } }, { children: "MOT DE PASSE OUBLI\u00E9" })), _jsx("div", Object.assign({ className: "flex-col flex justify-start relative font-roboto text-black", style: {
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                            } }, { children: "Un lien pour r\u00E9initialiser votre mot de passe va \u00EAtre envoy\u00E9 sur votre adresse email." })), _jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                gap: "8px",
                                width: "100%",
                                height: "65px", /* 65px */
                            } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                        width: "100%",
                                        fontSize: "14px",
                                        lineHeight: "normal",
                                        fontWeight: "600",
                                    } }, { children: "Email" })), _jsx("input", { id: "email-address", name: "Email", type: "email", required: true, value: email, onChange: value => setEmail(value.target.value), className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                        width: "100%",
                                        height: "auto",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingTop: "8px",
                                        paddingBottom: "8px",
                                        borderRadius: "4px",
                                        border: "1px solid #A1AABC",
                                    } })] })), _jsx("button", Object.assign({ onClick: handleSubmit, className: "flex relative bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple", style: {
                                width: "100%",
                                height: "50px",
                                fontSize: "14px",
                                fontWeight: "900",
                                lineHeight: "14px",
                                textTransform: "uppercase",
                                fontStyle: "normal",
                                border: "2px solid #000000",
                            } }, { children: "envoyer le lien" }))] })) }))] })));
}
export default ForgottenPasswordPage;
