var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import { useEffect } from "react";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import useSession from "./useSession";
// Handles default navigation for the app
// Done in a tricky way to avoid rerender contexts when navigating causing issues with the MediaContext
function DefaultNavigation(props) {
    const navigate = useNavigate();
    const { role } = useAuth();
    const { sendRequest } = useSession();
    console.log("DefaultNavigation RENDERING");
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getGameState CALL");
            const result = yield sendRequest("onConnectionOrReaload", {})
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            console.log("getGameState useEffect insideGameState", result);
            if (result !== undefined) {
                onceGameStateIsReady(result);
            }
        });
    }
    useEffect(() => {
        getGameState(); // TODO this might cause an infinite loop
    });
    function onceGameStateIsReady(gameState) {
        console.log("useEffect insideGameState", gameState);
        if (gameState.sessionStarted) {
            if (role === EPlayerRole.ANIMATOR) {
                if (gameState.gameStarted)
                    navigate("/AnimatorGamePage");
                else if (gameState.projectSelectionOn)
                    navigate("/DistributionProjectPage");
                else if (gameState.avatarSelectionOn)
                    navigate("/TeamSelectionPage");
                else
                    navigate("/IntroductionRoomPage");
            }
            else {
                navigate("/ReconnectionPage");
            }
        }
        else {
            if (role === EPlayerRole.ANIMATOR) {
                navigate("/WaitingRoomPage");
            }
            else {
                navigate("/ChooseNamePage");
            }
        }
    }
    return (_jsx(_Fragment, {}));
}
export default DefaultNavigation;
