var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import PlayerDisplayComponent from "../../components/animator_components/PlayersDisplayComponent";
import WebcamComponent from "../../components/animator_components/WebcamComponent";
// import { playersInGame } from "../../MockData/Players";
import HeadBarComponent from "../../components/animator_components/game_components/HeadBarComponent";
import BoardDescriptionComponent from "../../components/animator_components/game_components/BoardDescriptionComponent";
import CardsHandlerComponent from "../../components/animator_components/game_components/CardsHandlerComponent";
import EffectsHandlerComponent from "../../components/animator_components/game_components/EffectsHandlerComponent";
import TeamsHandlerComponent from "../../components/animator_components/game_components/TeamsHandlerComponent";
import DiceHandlerComponent from "../../components/animator_components/game_components/DiceHandlerComponent";
import ProjectionContentComponent from "../../components/animator_components/game_components/ProjectionContentComponent";
import ETurn from "cresus-common/dist/dto/ETurn";
import { getDefaultGameStateDTO } from "cresus-common/dist/dto/GameStateDTO";
import useSessionContext from "../../tools/useSessionContext";
import { useBoardGame } from "../../contexts/BoardGameProvider";
import { CameraMode } from "../../components/3D/CameraMode";
import useAuth from "../../contexts/useAuth";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import { TeamToName } from "cresus-common/dist/utils/ColorsSwitch";
import RecapEndOfSessionPage from "./RecapEndOfSessionPage";
import ChatAnimatorComponent from "../../components/animator_components/ChatAnimatorComponent";
import InGamePopin from "../../components/animator_components/game_components/InGamePopin";
import switchToTeamName from "../../tools/switchColorToNameTeam";
import FinalPage from "../FinalPage";
const buttonsHeadBarDefaultState = [
    {
        name: "teams",
        isActivated: false,
        text: "équipes",
    },
    {
        name: "board",
        isActivated: false,
        text: "plateau",
    },
    {
        name: "cards",
        isActivated: false,
        text: "cartes",
    },
    {
        name: "effects",
        isActivated: false,
        text: "effets",
    },
    {
        name: "pedagogic content",
        isActivated: false,
        text: "",
    },
    {
        name: "dice",
        isActivated: false,
        text: "",
    },
    {
        name: "next turn",
        isActivated: false,
        text: "",
    },
    {
        name: "parameters",
        isActivated: false,
        text: "",
    }
];
function AnimatorGamePage() {
    const { role } = useAuth();
    const { movePawn, activatePawn, moveCamera, setIsAdmin, playSound, muteSound } = useBoardGame();
    setIsAdmin(role === EPlayerRole.ANIMATOR); // TODO propabably better way to do that ?
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const [insideGameState, setInsideGameState] = useState(getDefaultGameStateDTO());
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);
    const [chatOn, setChatOn] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    const [paramOn, setParamOn] = useState(false);
    const [isButtonActivatedArray, setIsButtonActivatedArray] = useState(buttonsHeadBarDefaultState);
    const [bailiffPopin, setBailiffPopin] = useState(false);
    const [nextTurnPopin, setNextTurnPopin] = useState(false);
    useEffect(() => {
        console.log("INITIALIZE ANIMATORGAMEPAGE", insideGameState.teams);
        if (insideGameState && insideGameState.teams) {
            insideGameState.teams.forEach((team) => {
                activatePawn(team.teamColor);
            });
        }
        else {
            console.warn("insideGameState.teamsInGame is undefined");
        }
    });
    useEffect(() => {
        muteSound("GlobalAmbiance");
        getFirstGameState();
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            console.log("ANIMATOR RECEIVED NOTIFICATION", notification);
            try {
                if (notification.method === "playSound") {
                    playSound(notification.data.sound);
                }
                if (notification.method === "makeTeamThrowDiceTurn") {
                    const gameStateDTO = notification.data;
                    if (gameStateDTO.teams[gameStateDTO.indexCurrentTeam].hasBailifPenalty) {
                        setBailiffPopin(true);
                    }
                    setInsideGameState(gameStateDTO);
                    if (gameStateDTO.teams) {
                        gameStateDTO.teams.forEach((team) => {
                            activatePawn(team.teamColor);
                        });
                    }
                    else {
                        console.warn("insideGameState.teamsInGame is undefined");
                    }
                    console.log("makeTeamThrowDiceTurn", notification);
                    updatePawnPositions(gameStateDTO);
                }
                if (notification.method === "makeOneTeamsThrowDicesEvent") {
                    setInsideGameState(notification.data);
                }
                if (notification.method === "updateGameState") {
                    setInsideGameState(notification.data);
                }
                if (notification.method === 'payChargeByPlayer') {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
                if (notification.method === "endGame") {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
                if (notification.method === "applicationOfEffects") {
                    const gameStateDTO = notification.data;
                    if (Boolean(gameStateDTO)) {
                        setInsideGameState(gameStateDTO);
                        updatePawnPositions(gameStateDTO);
                    }
                }
                if (notification.method === "endSession") {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
                if (notification.method === "nextTurn") {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
                if (notification.method === "payChargeByAnimator") {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
                if (notification.method === "deleteCard") {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
                if (notification.method === "deleteCharge") {
                    const gameStateDTO = notification.data;
                    setInsideGameState(gameStateDTO);
                }
            }
            catch (e) {
                console.error(e);
            }
        });
        insideGameState.teams.forEach((team) => {
            activatePawn(team.teamColor);
        });
        moveCamera(CameraMode.ALL_MAP);
    }, []);
    useEffect(() => {
        console.log("useEffect[insideGameState]", insideGameState);
        console.log(insideGameState.teams.filter(team => team.turnState !== ETurn.NO_ACTION_REQUIRED)[0], "teamToPlay actualaized");
    }, [insideGameState]);
    useEffect(() => {
        if (isButtonActivatedArray.filter(button => button.name === "next turn")[0].isActivated) {
            setNextTurnPopin(true);
            // handleNextTurn();
        }
    }, [isButtonActivatedArray]);
    function getFirstGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState }) // TODO no need to send the gameState
                .catch((error) => { console.error(error); });
            if (result !== undefined) {
                setInsideGameState(result);
                updatePawnPositionsWithoutAnimation(result);
            }
        });
    }
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState }) // TODO no need to send the gameState
                .catch((error) => { console.error(error); });
            console.log("getGameState", result);
            if (result !== undefined) {
                setInsideGameState(result);
            }
        });
    }
    function updatePawnPositionsWithoutAnimation(gameStateDTO) {
        console.log("updatePawnPositionsWithoutAnimation", gameStateDTO);
        if (Boolean(gameStateDTO)) {
            gameStateDTO.teams.forEach((team) => {
                movePawn(team.teamColor, team.slotTeam.slotAbsoluteNumber, false);
            });
        }
    }
    function updatePawnPositions(gameStateDTO) {
        console.log("updatePawnPositions", gameStateDTO);
        if (Boolean(gameStateDTO)) {
            gameStateDTO.teams.forEach((team) => {
                movePawn(team.teamColor, team.slotTeam.slotAbsoluteNumber, true);
            });
        }
    }
    function onApplicationsOfEffects() {
        getGameState();
    }
    function handleButtonHeadBarClick(nameOfButton) {
        const buffArray = [...isButtonActivatedArray];
        buffArray.forEach((button) => {
            if (button.name === nameOfButton)
                button.isActivated = true;
            else
                button.isActivated = false;
        });
        setIsButtonActivatedArray(buffArray);
    }
    function handleNextTurn() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("nextTurn", { team: teamToPlay })
                .catch((error) => { console.error("error", error); });
            console.log("handleNextTurn result", result);
            // setInsideGameState(result);
        });
    }
    function payChargeByAnimator(charge, team) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("payChargeByAnimator", { charge: charge, team: team })
                .catch((error) => { console.error("payChargeByAnimator", error); });
            console.log("payChargeByAnimator", result);
            // setInsideGameState(result);
        });
    }
    function handlePayCharge(chargeToPay, teamToPayCharge) {
        payChargeByAnimator(chargeToPay, teamToPayCharge);
    }
    function handleEndGame() {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendRequest("endGame", {}).catch((error) => { console.error("error", error); });
        });
    }
    // function hanldeTogleChat() {
    //     setChatOn(!chatOn);
    //     if(chatOn)
    //     {
    //         setNewMessageAlert(false);
    //     }
    // }
    function handleEndOfSession() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("endSession", { gameState: gameState })
                .catch((error) => { console.error(error); });
        });
    }
    console.log("RENDERING ANIMATORGAMEPAGE", insideGameState);
    const teamToPlay = insideGameState.teams[insideGameState.indexCurrentTeam];
    const foundCaptain = (teamToPlay ? teamToPlay.players.find(player => player.capitain) : undefined);
    const currentCaptain = (foundCaptain ? foundCaptain.name : "");
    return (_jsxs("div", Object.assign({ className: "w-screen fixed flex h-screen" }, { children: [!insideGameState.sessionClosed &&
                _jsxs("div", Object.assign({ className: "flex h-full flex-col", style: {
                        backgroundColor: "rgba(12, 21, 51, 1)",
                        width: "25%",
                        minWidth: "256px",
                        maxWidth: "300px",
                    } }, { children: [_jsx("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                                paddingLeft: "20px",
                                paddingTop: "33px",
                                height: "91px",
                                minHeight: "91px",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                            } }, { children: teamToPlay && _jsxs(_Fragment, { children: ["Tour ", teamToPlay.slotTeam ? Math.floor(teamToPlay.slotTeam.slotAbsoluteNumber / 39) + 1 : 1, " ", _jsx("br", {}), " \u00E9quipe ", TeamToName(teamToPlay.teamColor)] }) })), _jsx("div", Object.assign({ className: "flex relative", style: {
                                paddingTop: "20px",
                                paddingRight: "10px",
                                height: "100%",
                                minHeight: "305px",
                                borderTop: "1px solid rgba(255, 255, 255, 0.20)",
                                borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                            } }, { children: _jsx("div", Object.assign({ className: "flex relative overflow-y-auto w-full flex-col", style: {
                                    paddingLeft: "20px",
                                    paddingRight: "8px",
                                    height: "100%",
                                    gap: "5px",
                                } }, { children: insideGameState.teams.map(team => team.players).flat().map((person, index) => {
                                    return _jsx(PlayerDisplayComponent, { playerDTO: person }, index);
                                }) })) })), _jsx(WebcamComponent, { isCommunicationActive: true, isThereNotification: newMessageAlert, newMessageAlert: setNewMessageAlert, isChatActive: true, cameraOn: cameraOn, setCameraOn: setCameraOn, micOn: micOn, setMicOn: setMicOn, chatOn: chatOn, setChatOn: setChatOn, paramOn: paramOn, setParamOn: setParamOn })] })), insideGameState.gameEnded && insideGameState.sessionClosed &&
                _jsx(FinalPage, {}), insideGameState.gameEnded && !insideGameState.sessionClosed &&
                _jsx(RecapEndOfSessionPage, { handleEndOfSession: handleEndOfSession, gameState: insideGameState }), !insideGameState.gameEnded &&
                _jsxs("div", Object.assign({ className: "flex items-center w-full h-full p-[10px] pb-0 overflow-hidden flex-col z-10" }, { children: [_jsx(HeadBarComponent, { isNextTurnPossible: true, buttonsHeadBar: isButtonActivatedArray, handleClick: handleButtonHeadBarClick }), teamToPlay && teamToPlay.dicesTurn &&
                            _jsxs("div", Object.assign({ className: "flex relative  justify-center w-full h-full z-10" }, { children: [_jsx(BoardDescriptionComponent, { captain: currentCaptain, turnState: teamToPlay.turnState, isActive: isButtonActivatedArray.filter(button => button.name === "board")[0].isActivated, dices: teamToPlay.dicesTurn.diceArray, setInsideGameState: setInsideGameState, slotType: teamToPlay.slotTeam.slotType, team: teamToPlay.teamColor, hasBailiffPenalty: teamToPlay.hasBailifPenalty }), _jsx(CardsHandlerComponent, { setInsideGameState: setInsideGameState, isActive: isButtonActivatedArray.filter(button => button.name === "cards")[0].isActivated, teamToPlay: teamToPlay }), _jsx(EffectsHandlerComponent, { currentTeam: teamToPlay, handleApplicationOfEffects: onApplicationsOfEffects, teamsInGame: insideGameState.teams, isActive: isButtonActivatedArray.filter(button => button.name === "effects")[0].isActivated }), _jsx(TeamsHandlerComponent, { setInsideGameState: setInsideGameState, handlePayCharge: handlePayCharge, teamsInGame: insideGameState.teams, isActive: isButtonActivatedArray.filter(button => button.name === "teams")[0].isActivated }), _jsx(DiceHandlerComponent, { gameState: insideGameState, setInsideGameStates: setInsideGameState, isActive: isButtonActivatedArray.filter(button => button.name === "dice")[0].isActivated }), _jsx(ProjectionContentComponent, { isActive: isButtonActivatedArray.filter(button => button.name === "pedagogic content")[0].isActivated }), isButtonActivatedArray.filter(button => button.name === "parameters")[0].isActivated &&
                                        _jsx("div", Object.assign({ className: "flex flex-row justify-end", style: {
                                                position: "absolute",
                                                minWidth: 748,
                                                maxWidth: 950,
                                                width: "100%",
                                                top: 0
                                            } }, { children: _jsxs("button", Object.assign({ className: "flex flex-row bg-white font-roboto", onClick: handleEndGame, style: {
                                                    padding: "20px",
                                                    borderRadius: "7px",
                                                    alignItems: "center",
                                                    gap: 10,
                                                    fontWeight: 400
                                                } }, { children: [_jsx("div", { className: "font-roboto", style: {
                                                            backgroundImage: 'url(./assets/UI/game_elements_images/IconQuitGame.png)',
                                                            width: "30px",
                                                            aspectRatio: 1,
                                                            backgroundSize: "contain",
                                                            backgroundRepeat: "no-repeat",
                                                            fontWeight: 700
                                                        } }), "Terminer la partie", _jsx("div", { style: { width: 109 } })] })) }))] })), bailiffPopin &&
                            _jsx("div", Object.assign({ className: "flex absolute justify-center self-center items-center top-1/2" }, { children: _jsx(InGamePopin, { validateFunction: () => setBailiffPopin(false), isItInformative: true, textInformation: `L'équipe ${switchToTeamName(teamToPlay.teamColor)} est retenue sur la case huissier. Des pénalités doivent être appliquées.` }, "in game popin bailiff") })), nextTurnPopin &&
                            _jsx("div", Object.assign({ className: "flex absolute justify-center self-center items-center top-1/2" }, { children: _jsx(InGamePopin, { validateFunction: () => { setNextTurnPopin(false); handleNextTurn(); }, isItInformative: false, cancelFunction: () => setNextTurnPopin(false), textButtonCancel: "Annuler", textButtonValidate: "Passer au tour suivant", textInformation: `Souhaitez-vous mettre fin à ce tour et passer au suivant ?` }, "in game popin Next turn") }))] })), _jsx("div", Object.assign({ className: "flex absolute", style: { zIndex: chatOn ? 1000 : -1 } }, { children: _jsx(ChatAnimatorComponent, { newMessageAlert: setNewMessageAlert, isChatShown: chatOn, toggleChat: () => { setChatOn(!chatOn); setNewMessageAlert(false); } }) }))] })));
}
export default AnimatorGamePage;
