var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ETeam from "cresus-common/dist/dto/ETeam";
import MicOnIcon from "../icons/MicOnIcon";
import MicOffIcon from "../icons/MicOffIcon";
import TeamSquareSelection from "./TeamSquareSelection";
import { ColorToTeam } from "cresus-common/dist/utils/ColorsSwitch";
import AvatarCompositionComponent from "../avatar_creation_component/AvatarCompositionComponent";
import useMedia from "../../contexts/useMedia";
import useSession from "../../contexts/useSession";
function PeopleWaitingTeamComponent(props) {
    const { audioVideoClient } = useMedia();
    const [micOn, setMicOn] = useState(props.playerDTO.micOn);
    const { websocketPeer, sendRequest } = useSession();
    function shoulBeSquareActive(isTeamActive, isTeamFull) {
        if (isTeamActive && !isTeamFull) {
            return true;
        }
        return false;
    }
    useEffect(() => {
        setMicOn(!props.playerDTO.micOn);
    }, [props.playerDTO]);
    useEffect(() => {
        const onMuteChangeCallback = (sessionPeerId, isMuted) => {
            console.log("Received onMuteChangeOthers", sessionPeerId, isMuted, props.playerDTO.sessionPeerId);
            if (sessionPeerId === props.playerDTO.sessionPeerId) {
                setMicOn(!isMuted);
                console.log("State changed");
            }
        };
        audioVideoClient.onMuteChangeOthers.push(onMuteChangeCallback);
        return () => {
            const indexCallback = audioVideoClient.onMuteChangeOthers.indexOf(onMuteChangeCallback);
            if (indexCallback !== -1)
                audioVideoClient.onMuteChangeOthers.splice(indexCallback, 1);
            else
                console.warn("PeopleWaitingRoomComponent unmount, cannot remove callback");
        };
    });
    function getDedefaultMuted() {
        return __awaiter(this, void 0, void 0, function* () {
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.playerDTO.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                const isMuted = audioVideoClient.getMuted(mediaAPIPeerId);
                setMicOn(!isMuted);
                console.log(`getDedefaultMuted ${isMuted} sessionPeerId:${props.playerDTO.sessionPeerId} name:${props.playerDTO.name} mediaPeerId:${mediaAPIPeerId}`);
            }
        });
    }
    useEffect(() => {
        getDedefaultMuted();
    }, []);
    function onClickMuteUnmuteButton() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!props.playerDTO.isConnected)
                return;
            const { mediaAPIPeerId } = yield sendRequest("getMediaAPIPeerId", { sessionPeerId: props.playerDTO.sessionPeerId }); // Getup to date data from server to avoid a race condition
            if (mediaAPIPeerId) {
                audioVideoClient.muteParticipant(mediaAPIPeerId, props.playerDTO.sessionPeerId);
            }
            else
                console.warn("Current player has no mediaAPIPeerId");
        });
    }
    function onTeamSelectionChange(color) {
        const teamSelected = ColorToTeam(color);
        console.log("teamSelected", teamSelected, color);
        props.onTeamSelectionChange(props.playerDTO, teamSelected);
    }
    return (_jsxs("div", Object.assign({ className: "flex justify-around w-full text-white font-roboto gap-[10px]", style: {
            background: "rgba(0, 0, 0, 0.20)",
            height: "55px",
            paddingTop: "10px",
            minHeight: "55px",
            paddingLeft: "10px",
            opacity: props.playerDTO.isConnected ? 1 : 0.3,
        } }, { children: [_jsx("div", Object.assign({ className: "flex relative self-end ", style: {
                    height: "44px",
                    width: "44px",
                    minWidth: "44px",
                    // backgroundImage: "url('/assets/UI/avatarTestWaitingRoom.png')",
                    // backgroundSize: "cover",
                } }, { children: _jsx(AvatarCompositionComponent, { avatarComposition: props.playerDTO.avatarComposition, toRight: true }, "AvatarCompositionComponent" + Math.random()) })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-[5px]", style: {
                    width: "86.57%",
                } }, { children: [_jsx("div", Object.assign({ className: "font-roboto justify-start overflow-hidden flex flex-nowrap", style: {
                            height: "14px",
                            minHeight: "14px",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            fontSize: "12px",
                            fontWeight: "400",
                        } }, { children: props.playerDTO.name })), _jsx("div", Object.assign({ className: "flex justify-start self-start items-start", style: {
                            gap: "5px",
                        } }, { children: props.teamsActive.map((teamActive, index) => {
                            return (_jsx(TeamSquareSelection, { color: ETeam[index].toLowerCase(), onTeamSelectionChange: onTeamSelectionChange, isActive: shoulBeSquareActive(teamActive, props.teamsFull[index]) }, "TeamSquareSelection" + index));
                        }) }))] })), _jsxs("button", Object.assign({ className: "flex relative self-start items-center", onClick: onClickMuteUnmuteButton, style: {
                    right: "0px",
                    height: "20px",
                    width: "30px",
                    paddingRight: "10px",
                } }, { children: [micOn && _jsxs("div", Object.assign({ className: "flex", style: {
                            borderRadius: "50%",
                            backgroundColor: "black"
                        } }, { children: [_jsx(MicOnIcon, {}), " "] })), !micOn && _jsx(MicOffIcon, {})] }))] })));
}
export default PeopleWaitingTeamComponent;
