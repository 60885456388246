"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ETurn;
(function (ETurn) {
    ETurn[ETurn["NO_ACTION_REQUIRED"] = 0] = "NO_ACTION_REQUIRED";
    ETurn[ETurn["MUST_ROLL_DICE"] = 1] = "MUST_ROLL_DICE";
    ETurn[ETurn["HANDLE_DICE_RESULT"] = 2] = "HANDLE_DICE_RESULT";
    ETurn[ETurn["HANDLE_NEXT_TURN"] = 3] = "HANDLE_NEXT_TURN";
})(ETurn || (ETurn = {}));
exports.default = ETurn;
