var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Color, Mesh, TextureLoader, Vector3 } from "three";
import * as THREE from "three";
import { createBackgroundMaterialOverride } from "./materials/BackgroundMaterialOverride";
import { TweenObject } from "../../utils/TweenObject";
import { ECaseType, ETaxeType } from "../../types/GameBoard";
import { slerpAngle } from "../../utils/MathUtils";
import { createWaterMaterial } from "./materials/WaterMaterial";
import { instancedByMeshNameInclude, meshesToInstances } from "./InstancingUtils";
import ETeam from "cresus-common/dist/dto/ETeam";
import EventEmitter from "events";
import { createUFOBeamMaterial } from "./materials/ufoBeamMaterial";
const PAWN_SCALE = 0.8;
export class SceneObject {
    GetSound(soundName) {
        return this.AudioConfig.get(soundName);
    }
    PlaySound(soundName) {
        this._audioListener.context.resume();
        const audioConfig = this.AudioConfig.get(soundName);
        if (audioConfig && audioConfig.audio) {
            console.log("PlaySound:", audioConfig.audio);
            audioConfig.audio.setVolume(audioConfig.volume);
            audioConfig.audio.play();
        }
        else {
            console.warn("PlaySound: No audio found for", soundName);
        }
    }
    MuteSound(soundName) {
        const audioConfig = this.AudioConfig.get(soundName);
        if (audioConfig && audioConfig.audio) {
            console.log("MuteSound:", audioConfig.audio);
            audioConfig.audio.setVolume(0);
            audioConfig.audio.pause();
            audioConfig.audio.stop();
        }
        else {
            console.warn("MuteSound: No audio found for", soundName);
        }
    }
    constructor(gltf) {
        this._backgroundMaterial = createBackgroundMaterialOverride(new Color(0.44, 0.51, 0.75).multiplyScalar(0.7));
        this._eoliennesPales = [];
        this._cows = [];
        this._pawns = {};
        this._meshesInstances = new Map();
        this._slotsInfos = [];
        this.TweenObject = new TweenObject();
        this.AudioConfig = new Map();
        this._lowQuality = false;
        this.onPawnLoaded = new EventEmitter();
        this._audioListener = new THREE.AudioListener();
        this.AudioConfig.set("GlobalAmbiance", { path: "/assets/sounds/Musique/SON_5_V3.2.mp3", loop: true, playOnStart: false, volume: 0.1 });
        // Triggered by animator
        this.AudioConfig.set("Alarm", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/alarm.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Applause", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/ApplauV2.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("BatterieFail", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/Batteriefail.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Grillons", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/Grillons.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Rire", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/Rire2.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Stade", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/Stade.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Tictac", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/Tictac1v2.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("TiroirCaisse", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/tiroircaisse.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("TrompetteTriste", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/TrompetteTristeV2.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Violon", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/Violon2.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("WOW", { path: "/assets/sounds/SonsAmbiances/TriggerAnimator/WOW.mp3", loop: false, playOnStart: false, volume: 0.5 });
        // UIEvents
        this.AudioConfig.set("OpenChargeBook", { path: "/assets/sounds/SonsAmbiances/book.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Click", { path: "/assets/sounds/SonsAmbiances/Click1.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("PayCharges", { path: "/assets/sounds/SonsAmbiances/crayonraye2.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("RollDice", { path: "/assets/sounds/SonsAmbiances/DES7.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("KeepCard", { path: "/assets/sounds/SonsAmbiances/Papier1.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("Tchat", { path: "/assets/sounds/SonsAmbiances/Papier3.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("EarnMoney", { path: "/assets/sounds/SonsAmbiances/piecetombe.mp3", loop: false, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("RollDice", { path: "/assets/sounds/dice_roulette.mp3", loop: true, playOnStart: false, volume: 0.5 });
        this.AudioConfig.set("ScoreDice", { path: "/assets/sounds/dice_score.mp3", loop: false, playOnStart: false, volume: 0.5 });
        // Easter Eggs
        this.AudioConfig.set("UFO", { path: "/assets/sounds/EasterEggs/UFO.mp3", loop: false, playOnStart: false, volume: 0.3 });
        this.AudioConfig.set("PipeSlide", { path: "/assets/sounds/EasterEggs/PipeSlide.mp3", loop: false, playOnStart: false, volume: 0.3 });
        const audioLoader = new THREE.AudioLoader();
        this.AudioConfig.forEach((audioConfig, audioName) => {
            audioLoader.load(audioConfig.path, (buffer) => {
                const audio = new THREE.Audio(this._audioListener);
                audio.hasPlaybackControl = true;
                audio.setBuffer(buffer);
                audio.setLoop(audioConfig.loop);
                audio.setVolume(audioConfig.volume);
                if (audioConfig.playOnStart)
                    audio.play();
                audioConfig.audio = audio;
            });
        });
        this._gltf = gltf;
        this._textureLoader = new TextureLoader();
        this.TextureBoardAnimator = this._textureLoader.load("/assets/3D/board_base_anim.png");
        this.TextureBoardAnimator.flipY = false;
        this.TextureBoardAnimator.colorSpace = THREE.SRGBColorSpace;
    }
    // Index_ can be a floating point number (it autommatically lerps values if so)
    calculatePawnPosition(index_, playerIndex) {
        const index = index_ % this._slotsInfos.length;
        if (index >= 0 && index < this._slotsInfos.length) {
            const gradientIndex = index % 1.0; // We get the fractional part of the number
            const lowerBound = Math.floor(index);
            let upperBound = Math.ceil(index) % this._slotsInfos.length;
            let nextBound = Math.ceil(index + 0.1) % this._slotsInfos.length; // Always greater than lowerBound
            const forwardVec = this._slotsInfos[lowerBound].position.clone().sub(this._slotsInfos[nextBound].position).normalize();
            const rightVec = forwardVec.clone().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2.0);
            const lerpedPos = this._slotsInfos[lowerBound].position.clone().lerp(this._slotsInfos[upperBound].position, gradientIndex);
            forwardVec.multiplyScalar(0.8);
            rightVec.multiplyScalar(1);
            const offsets = [
                rightVec.clone().add(forwardVec),
                rightVec.clone().negate().add(forwardVec),
                forwardVec.clone().negate().add(rightVec.clone().negate()),
                forwardVec.clone().negate().add(rightVec),
                new THREE.Vector2(0, 0)
            ];
            const offsetPlayer = (offsets[playerIndex].clone().add(rightVec.clone().multiplyScalar(0.5))).multiplyScalar(80);
            return new Vector3(lerpedPos.x, -65., lerpedPos.y).add(new THREE.Vector3(offsetPlayer.x, 0.0, offsetPlayer.y));
        }
        console.warn("Invalid pawn position index", index);
        return new Vector3(0, 100., 0); // We make noticeable if it fails
    }
    // Index_ can be a floating point number (it autommatically lerps values if so)
    calculatePawnRotation(index_) {
        const index = index_ % this._slotsInfos.length;
        if (index >= 0 && index < this._slotsInfos.length) {
            const gradientIndex = index % 1.0; // We get the fractional part of the number
            const lowerBound = Math.floor(index) % this._slotsInfos.length;
            const upperBound = Math.ceil(index) % this._slotsInfos.length;
            const lerpedPos = this._slotsInfos[lowerBound].position.clone().lerp(this._slotsInfos[upperBound].position, gradientIndex);
            const diffXPrev = this._slotsInfos[lowerBound].position.x - this._slotsInfos[(lowerBound + 1) % this._slotsInfos.length].position.x;
            const diffZPrev = this._slotsInfos[lowerBound].position.y - this._slotsInfos[(lowerBound + 1) % this._slotsInfos.length].position.y;
            const anglePrev = Math.atan2(diffZPrev, diffXPrev);
            const diffXNext = this._slotsInfos[upperBound].position.x - this._slotsInfos[(upperBound + 1) % this._slotsInfos.length].position.x;
            const diffZNext = this._slotsInfos[upperBound].position.y - this._slotsInfos[(upperBound + 1) % this._slotsInfos.length].position.y;
            const angleNext = Math.atan2(diffZNext, diffXNext);
            // console.log("ANGLE", anglePrev, angleNext, gradientIndex);
            const angle = slerpAngle(anglePrev, angleNext, gradientIndex);
            return -angle - Math.PI / 2.;
        }
        console.warn("Invalid pawn position index", index);
        return 42.0; // We make noticeable if it fails
    }
    createInstancedMeshes() {
        let totalMeshInstanced = 0;
        let totalRemoved = 0;
        const meshInstancesArray = this._meshesInstances;
        console.log("CREATING MESHES TREE", meshInstancesArray);
        meshInstancesArray.forEach((meshes, index) => {
            // console.log("CREATING INSTANCES2 for",  index, meshes.length)
            if (meshes.length === 0) {
                console.warn("No meshes found for", index, meshes.length);
                return;
            }
            const instancedMeshes = new THREE.InstancedMesh(meshes[0].geometry.clone(), meshes[0].material.clone(), meshes.length);
            instancedMeshes.castShadow = true;
            instancedMeshes.receiveShadow = true;
            instancedMeshes.material.side = THREE.DoubleSide;
            // instancedMeshes.matrixAutoUpdate = true;
            instancedMeshes.name = meshes[0].name + "_instanced";
            instancedMeshes.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
            meshes.forEach((mesh, index_) => {
                mesh.matrixWorldAutoUpdate = true;
                mesh.matrixWorldNeedsUpdate = true;
                mesh.matrixAutoUpdate = true;
                mesh.updateMatrixWorld(true);
                mesh.updateMatrix();
                instancedMeshes.setMatrixAt(index_, mesh.matrixWorld.clone());
                totalMeshInstanced++;
            });
            // instancedMeshes.frustumCulled = false;
            instancedMeshes.instanceMatrix.needsUpdate = true;
            instancedMeshes.updateWorldMatrix(true, true); // Not sure if it helps but we have issues with the instances not being updated sometimes
            instancedMeshes.updateMatrix();
            this._gltf.scene.add(instancedMeshes);
            totalRemoved += meshes.length;
            // Remove meshes from scene
            meshes.forEach(mesh => {
                this._gltf.scene.remove(mesh);
            });
        });
        this._meshesInstances.clear(); // No need to keep the map in memory
        console.log(`created ${totalMeshInstanced} instances and removing ${totalRemoved}`);
    }
    lowerQuality() {
        if (this._lowQuality)
            return;
        this._lowQuality = true;
        const instancesToCutHalf = [
            "arbre_instanced",
            "arbre_1_instanced",
            "arbre_2_instanced",
            "arbre_3_instanced",
            "sapin_1_instanced",
            "sapin_2_instanced",
            "sapin_3_instanced",
            "sapin_4_instanced",
            "sapin_5_instanced",
            "buisson_(41)_instanced",
        ];
        instancesToCutHalf.forEach((instanceName) => {
            const instancedMesh = this._gltf.scene.getObjectByName(instanceName);
            const cutCount = 4; // Divides selected object count by cutCount
            if (instancedMesh) {
                for (let i = 0; i < instancedMesh.count / cutCount; i++) {
                    let matrice = new THREE.Matrix4();
                    instancedMesh.getMatrixAt(i * cutCount, matrice);
                    instancedMesh.setMatrixAt(i, matrice.clone());
                }
                instancedMesh.count = Math.floor(instancedMesh.count / cutCount);
                instancedMesh.instanceMatrix.needsUpdate = true;
                instancedMesh.updateWorldMatrix(true, true);
            }
        });
        const objectsToRemove = [
            "feux_tricolores_instanced",
            "feux_tricolores_1_instanced",
            "feux_tricolores_2_instanced",
            "feux_tricolores_3_instanced",
            "reverbere_parc_instanced",
            "reverbere_parc_1_instanced",
            "reverbere_parc_2_instanced",
            "reverbere_parc_3_instanced",
            "reverbere_008_instanced",
            "cheminée_1_instanced",
            "reverbere_double_instanced",
            "ballons_1",
            "Car__01_instanced",
            "evacuation_toit_(1)_instanced",
            "accès_toit_instanced",
            "accès_toit_1_instanced",
            "accès_toit_2_instanced",
            "Cylinder001_instanced",
            "Cylinder001_1_instanced",
            "Cylinder001_2_instanced",
            "Cylinder001_3_instanced",
            "Cylinder001_4_instanced",
            "Cylinder002_instanced",
            "Cylinder002_1_instanced",
            "Cylinder002_2_instanced",
            "Cylinder002_3_instanced",
            "Cylinder002_4_instanced",
            "Cylinder003_instanced",
            "Cylinder003_1_instanced",
            "Cylinder003_2_instanced",
            "Cylinder003_3_instanced",
            "Cylinder003_4_instanced",
            "Cylinder004_instanced",
            "Cylinder004_1_instanced",
            "Cylinder004_2_instanced",
            "Cylinder004_3_instanced",
            "Cylinder004_4_instanced",
            "Car__01_instanced",
            "Car__02_instanced",
            "Car__03_instanced",
            "Car__04_instanced",
            "Car__05_instanced",
            "Car__06_instanced",
            "Car__07_instanced",
            "Car__08_instanced",
            "Car__09_1_instanced",
            "Car__police_01_instanced",
            "Trucks__01_instanced",
            "Trucks__02_instanced",
            "Trucks__03_instanced",
            "van__01_1",
            "arbre_instanced",
            "arbre_1_instanced",
        ];
        objectsToRemove.forEach((objectName) => {
            // We remove all objects matching the name
            const toRemove = [];
            this._gltf.scene.traverse((node) => {
                if (node.name === objectName) {
                    toRemove.push(node);
                }
            });
            toRemove.forEach((node) => {
                this._gltf.scene.remove(node);
            });
        });
        console.log("REDUCED INSTANCES");
    }
    Init(depthBuffer) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Init 3D");
            this._waterMaterial = yield createWaterMaterial(depthBuffer);
            this._ufoBeamMaterial = yield createUFOBeamMaterial(depthBuffer);
            this._gltf.scene.traverse((node) => {
                var _a, _b, _c;
                if (!node)
                    return;
                // node.visible = false;
                // There are some remants of vertex color on some objects, we disable it
                if (node instanceof THREE.Material) { // TODO probably useless
                    node.vertexColors = false;
                }
                if (node instanceof Mesh) {
                    node.castShadow = true;
                    node.receiveShadow = true;
                    node.material.roughness = 1.;
                    node.material.fog = true;
                    node.material.vertexColors = false;
                }
                if (node instanceof Mesh && (node.name === "Box001_1" || node.name === "pont_pierres")) {
                    node.material.color = new Color(0.4, 0.51, 0.63).multiplyScalar(0.3);
                }
                if (node instanceof Mesh && node.name.includes("pales")) {
                    this._eoliennesPales.push(node);
                }
                if (node instanceof Mesh && node.name.includes("Dice")) {
                    if (this._dice)
                        console.warn("Multiple dice found in scene, ignoring");
                    else
                        this._dice = node;
                    node.visible = false; // We hide it
                }
                if (node instanceof Mesh && node.name.includes("fontaine")) {
                    this._fountainTex = (_a = node.material.map) !== null && _a !== void 0 ? _a : undefined;
                }
                if (node instanceof THREE.Group && node.name === "UFO") {
                    node.visible = false;
                    this._ufoObject = node;
                }
                if (node instanceof Mesh && node.name === "UFOBeam") {
                    node.material = this._ufoBeamMaterial;
                }
                if (node instanceof Mesh && node.name.includes("Cow")) {
                    const cowScale = 2.0;
                    node.scale.set(cowScale, cowScale, cowScale);
                    this._cows.push(node);
                }
                if (node instanceof Mesh && node.material.name === "board_plane 1") {
                    this._boardGameMesh = node;
                    let oldMat = node.material;
                    node.material = new THREE.MeshBasicMaterial({
                        map: node.material.map,
                        transparent: false,
                    });
                    oldMat.dispose();
                    node.material.alphaTest = 0.5;
                    node.material.transparent = false;
                    console.log("MATBOARD", node.material);
                }
                if (node instanceof Mesh && (node.name === "socle2_8" || node.name === "socle2_2" || node.name === "Yellow_2" || node.name === "Green_2" // Cigogne body
                    || node.name === "socle2_9" || node.name === "socle2_3" || node.name === "Yellow_3" || node.name === "Green_3" // Cigogne eyes
                    || node.name === "Yellow_4" || node.name === "Green_5" // Cigogne beak
                )) {
                    node.material.envMapIntensity = 0.3;
                }
                if (node instanceof Mesh && (node.name === "Yellow_4" || node.name === "Green_5")) {
                    node.material.color = new Color(0.836006939, 0.049840048, 0.049840048);
                }
                if (node instanceof Mesh && node.name === "socle2_6") { // Red
                    node.material = new THREE.MeshStandardMaterial({ color: new Color(0.96, 0.0, 0.0), metalness: 0.5, roughness: 1., vertexColors: false, envMapIntensity: 0.7 });
                }
                if (node instanceof Mesh && node.name === "socle2") { // Blue
                    node.material = new THREE.MeshStandardMaterial({ color: new Color(0, 0.45, 0.93), metalness: 0.5, roughness: 1., vertexColors: false, envMapIntensity: 0.7 });
                }
                if (node instanceof Mesh && node.name === "Yellow") { // Yellow
                    node.material = new THREE.MeshStandardMaterial({ color: new Color(0.82, 0.81, 0.06), metalness: 0.5, roughness: 1., vertexColors: false, envMapIntensity: 0.7 });
                }
                if (node instanceof Mesh && node.name === "Green") { // Green
                    node.material = new THREE.MeshStandardMaterial({ color: new Color(0.08, 0.93, 0.15), metalness: 0.5, roughness: 1., vertexColors: false, envMapIntensity: 0. });
                }
                if (node.name.includes("Pawn")) {
                    if (node.name.includes("Red")) {
                        this._pawns.redPawn = node;
                    }
                    if (node.name.includes("Yellow")) {
                        this._pawns.yellowPawn = node;
                    }
                    if (node.name.includes("Green")) {
                        this._pawns.greenPawn = node;
                    }
                    if (node.name.includes("Blue")) {
                        this._pawns.bluePawn = node;
                    }
                }
                // PERFORMANCE OPTIMIZATION
                if (node instanceof THREE.Mesh && meshesToInstances.has(node.name)) {
                    if (node.matrixWorld.equals(new THREE.Matrix4()))
                        console.log("1Matrix is identity for", node.name, node.clone());
                    const objectName = node.geometry.uuid + node.material.uuid;
                    if (!this._meshesInstances.has(objectName)) {
                        this._meshesInstances.set(objectName, []);
                    }
                    (_b = this._meshesInstances.get(objectName)) === null || _b === void 0 ? void 0 : _b.push(node);
                    node.visible = false;
                }
                function isInstancedByMeshNameInclude(name) {
                    for (let include of instancedByMeshNameInclude) {
                        if (name.includes(include))
                            return true;
                    }
                    return false;
                }
                if (node instanceof THREE.Mesh && isInstancedByMeshNameInclude(node.name)) {
                    if (node.matrixWorld.equals(new THREE.Matrix4()))
                        console.log("2Matrix is identity for", node.name, node.clone());
                    if (!this._meshesInstances.has(node.geometry.uuid + "_include")) {
                        this._meshesInstances.set(node.geometry.uuid + "_include", []);
                    }
                    (_c = this._meshesInstances.get(node.geometry.uuid + "_include")) === null || _c === void 0 ? void 0 : _c.push(node);
                    node.visible = false;
                }
                // Brick buildings
                if (node instanceof Mesh &&
                    (node.material.name === "Material #151" ||
                        node.material.name === "Material #43")) {
                    node.material.color = new Color(0.9, 0.19, 0.03);
                }
                if (node instanceof Mesh &&
                    (node.material.name === "glass")) {
                    node.material.color = new Color(0.53, 0.7, 0.93);
                }
                if (node instanceof Mesh &&
                    node.material.name === "BG") {
                    node.material.dispose();
                    node.material = this._backgroundMaterial;
                    // node.receiveShadow = false;
                    // node.castShadow = false;
                }
                if (node instanceof Mesh && (node.name === "fleuve" || node.name === "water")) { //"fleuve") {
                    node.material.dispose();
                    node.material = this._waterMaterial;
                }
                if (node instanceof Mesh && node.name === "Plane_(2)") {
                    //node.material.color = new Color(0.2, 0.18, 0.13)
                    node.material.transparent = true; // to prevent transparency flipping with water surface
                    node.receiveShadow = false;
                    node.castShadow = false;
                    //node.material.depthWrite = true;
                }
                if (node.name.startsWith("Case_")) {
                    const caseValues = node.name.split("_");
                    // console.log("case", node.name)
                    const caseType = ECaseType[caseValues[1]]; // TODO dirty hack
                    this._slotsInfos.push({
                        type: caseType,
                        taxeType: caseType === ECaseType.TAXES ? ETaxeType[caseValues[2]] : undefined,
                        position: new THREE.Vector2(node.position.x, node.position.z),
                        index: parseInt(caseValues.slice(-1.)[0]) // Last element
                    });
                }
            });
            this._gltf.scene.scale.set(0.01, 0.01, 0.01);
            (_a = this._pawns.bluePawn) === null || _a === void 0 ? void 0 : _a.position.copy(this.calculatePawnPosition(0, ETeam.BLUE));
            (_b = this._pawns.bluePawn) === null || _b === void 0 ? void 0 : _b.rotation.set(0, this.calculatePawnRotation(0), 0);
            (_c = this._pawns.redPawn) === null || _c === void 0 ? void 0 : _c.position.copy(this.calculatePawnPosition(0, ETeam.RED));
            (_d = this._pawns.redPawn) === null || _d === void 0 ? void 0 : _d.rotation.set(0, this.calculatePawnRotation(0), 0);
            (_e = this._pawns.greenPawn) === null || _e === void 0 ? void 0 : _e.position.copy(this.calculatePawnPosition(0, ETeam.GREEN));
            (_f = this._pawns.greenPawn) === null || _f === void 0 ? void 0 : _f.rotation.set(0, this.calculatePawnRotation(0), 0);
            (_g = this._pawns.yellowPawn) === null || _g === void 0 ? void 0 : _g.position.copy(this.calculatePawnPosition(0, ETeam.YELLOW));
            (_h = this._pawns.yellowPawn) === null || _h === void 0 ? void 0 : _h.rotation.set(0, this.calculatePawnRotation(0), 0);
            // By default all pawn are hidden until the game starts
            if (this._pawns.bluePawn)
                this._pawns.bluePawn.visible = false;
            if (this._pawns.redPawn)
                this._pawns.redPawn.visible = false;
            if (this._pawns.greenPawn)
                this._pawns.greenPawn.visible = false;
            if (this._pawns.yellowPawn)
                this._pawns.yellowPawn.visible = false;
            (_j = this._pawns.bluePawn) === null || _j === void 0 ? void 0 : _j.scale.set(PAWN_SCALE, PAWN_SCALE, PAWN_SCALE);
            (_k = this._pawns.redPawn) === null || _k === void 0 ? void 0 : _k.scale.set(PAWN_SCALE, PAWN_SCALE, PAWN_SCALE);
            (_l = this._pawns.greenPawn) === null || _l === void 0 ? void 0 : _l.scale.set(PAWN_SCALE, PAWN_SCALE, PAWN_SCALE);
            (_m = this._pawns.yellowPawn) === null || _m === void 0 ? void 0 : _m.scale.set(PAWN_SCALE, PAWN_SCALE, PAWN_SCALE);
            this.onPawnLoaded.emit("pawnsLoaded", this._pawns.bluePawn, this._pawns.redPawn, this._pawns.greenPawn, this._pawns.yellowPawn);
            this.createInstancedMeshes();
        });
    }
    // Called once per frame
    update(deltaTime) {
        if (this._boardGameMesh) { // Dirty hack because transparent keeps turning back to true if the texture is transparent
            this._boardGameMesh.material.transparent = false;
        }
        this.TweenObject.update(deltaTime);
        if (this._waterMaterial)
            this._waterMaterial.uniforms.time.value += deltaTime;
        if (this._ufoBeamMaterial)
            this._ufoBeamMaterial.uniforms.time.value += deltaTime;
        this._eoliennesPales.forEach((pale, index) => {
            pale.rotation.z += deltaTime * THREE.MathUtils.lerp(0.1, 1., Math.sin(index * 0.5) * 0.5 + 0.5);
        });
        if (this._fountainTex)
            this._fountainTex.offset.set(0, this._fountainTex.offset.y - deltaTime * 0.1);
    }
}
