import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import TeamDisplayComponent from "./player_game_element_component/TeamDisplayComponent";
import { getTeamColor } from "./animator_components/game_components/TeamsHandlerComponent";
import ETeam from "cresus-common/dist/dto/ETeam";
import { TeamToName } from "cresus-common/dist/utils/ColorsSwitch";
function TeamsIngameComponent(props) {
    const [isOnHover, setIsOnHover] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const filteredTeams = props.teamsInGame.filter(el => el.teamColor !== props.myTeam);
    // console.log("filteredTeams", filteredTeams)
    const [activeTab, setActiveTab] = useState(getTeamColor((filteredTeams.length > 0 ? filteredTeams[0].teamColor : ETeam.NONE)));
    const refTeamData = useRef(new Map());
    useEffect(() => {
        if (props.myTeam !== ETeam.NONE && activeTab === "NONE") { // We do it this way as the first call to TeamsIngameComponent is done once before with myTeam = NONE
            if (filteredTeams.length > 0) {
                setActiveTab(getTeamColor(filteredTeams[0].teamColor));
            }
        }
    });
    refTeamData.current.clear();
    filteredTeams.forEach(team => {
        refTeamData.current.set(getTeamColor(team.teamColor), {
            avatarPlayers: team.players.map(player => player.name),
            avatars: team.players.map(player => player.avatarComposition),
            teamName: TeamToName(team.teamColor),
            totalCash: team.money ? team.money.amount : 0,
            pleasureCoins: team.pleasurePoints ? team.pleasurePoints.amount : 0,
            teamColor: team.teamColor
        });
    });
    // console.log("refTeamData.current", filteredTeams, props.myTeam, getTeamColor((filteredTeams.length > 0 ? filteredTeams[0].teamColor : ETeam.BLUE)))
    const hasData = refTeamData.current.size > 0;
    const activeTeamData = refTeamData.current.get(activeTab);
    function closeTeams() {
        setIsShown(!isShown);
    }
    function openTeams(tab) {
        if (!isShown) {
            setIsShown(true);
        }
        setActiveTab(tab);
    }
    const getTeamBackground = () => {
        switch (activeTab) {
            case "BLUE":
                return "url(./assets/UI/teams/team_BLUE.png)";
            case "GREEN":
                return "url(./assets/UI/teams/team_GREEN.png)";
            case "RED":
                return "url(./assets/UI/teams/team_RED.png)";
            case "YELLOW":
                return "url(./assets/UI/teams/team_YELLOW.png)";
            default:
                return "url(./assets/UI/teams/team_BLUE.png)";
        }
    };
    return (_jsx("div", Object.assign({ className: "flex fixed holderOtherTeams", style: {
            minHeight: 120,
            maxHeight: 120 * 2,
            minWidth: 320,
            maxWidth: 320 * 2,
            top: 0,
            left: 0,
            zIndex: 1,
            pointerEvents: "none",
        } }, { children: _jsxs("div", Object.assign({ className: `flex relative w-full h-full ${isShown ? "" : "teamInGamePositionHidden"}`, style: {
                pointerEvents: "all",
                left: "10px",
                transition: "all 0.5s ease-out",
                top: `${isShown ? "0" : "calc(-27.5% * 2.66)"}`,
                zIndex: 1,
            } }, { children: [_jsx("div", Object.assign({ className: `absolute flex px-[6.25cqw] py-[3.125cqw] flex-col ${hasData ? "justify-between" : "justify-center items-center"} `, style: {
                        left: 0,
                        top: 0,
                        backgroundImage: getTeamBackground(),
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        height: "calc(31.25% * 2.66)",
                        width: "84.37%",
                        zIndex: 1
                    } }, { children: !hasData ? (_jsx("div", Object.assign({ className: "font-kalam" }, { children: "Aucune autre \u00E9quipe" }))) : (_jsx(TeamDisplayComponent, { avatarPlayers: activeTeamData ? activeTeamData.avatarPlayers : [], avatars: activeTeamData ? activeTeamData.avatars : [], teamName: activeTeamData ? activeTeamData.teamName : "", teamColor: activeTeamData ? activeTeamData.teamColor : ETeam.NONE, totalCash: activeTeamData ? activeTeamData.totalCash : 0, pleasureCoins: activeTeamData ? activeTeamData.pleasureCoins : 0 })) })), _jsx("div", Object.assign({ className: "flex absolute items-start", style: {
                        left: 0,
                        top: "calc(29.69% * 2.66)",
                        // height: "7.81cqw",
                        height: "calc(7.81%*2.66)",
                        width: "84.375%",
                        zIndex: 1,
                        paddingLeft: "calc(6.25%)",
                    } }, { children: !hasData ? (_jsx("div", { className: "flex", onClick: () => {
                            openTeams(activeTab);
                        }, style: {
                            backgroundImage: `url(./assets/UI/teams/team_${activeTab}_active.png)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                            width: "23.44%",
                            zIndex: 1,
                            cursor: "pointer"
                        } })) : (Array.from(refTeamData.current.keys()).map(team => (_jsx("div", { className: "flex", onClick: () => {
                            openTeams(team);
                        }, style: {
                            backgroundImage: `url(./assets/UI/teams/team_${team}_${activeTab === team ? "active" : "inactive"}.png)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                            width: "23.44%",
                            zIndex: 1,
                            cursor: "pointer"
                        } })))) })), _jsx("div", { onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), onClick: () => {
                        closeTeams();
                    }, className: "absolute flex cursor-pointer", style: {
                        left: "83.125%",
                        top: "calc(4.69% *2.66)",
                        backgroundImage: `url(./assets/UI/buttons/chat_buttons/btn_chat_close${isOnHover ? "_hover" : ""}.png)`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        transition: "all 0.5s ease-out",
                        transform: "rotate(10deg)",
                        width: "11.875%",
                        height: "calc(10.625% * 2.66)",
                        zIndex: 0,
                    } })] })) })));
}
export default TeamsIngameComponent;
