var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import useSessionContext from "../../tools/useSessionContext";
import { useNavigate } from "react-router-dom";
import ItemsImageComponent from "../../components/avatar_creation_component/ItemsImageComponent";
import ItemSelectionComponent from "../../components/avatar_creation_component/ItemSelectionComponent";
import useMedia from "../../contexts/useMedia";
import AvatarCompositionComponent from "../../components/avatar_creation_component/AvatarCompositionComponent";
import ETeam from "cresus-common/dist/dto/ETeam";
import { getRandomInt } from "cresus-common/dist/utils/MathUtils";
import React from "react";
import { useBoardGame } from "../../contexts/BoardGameProvider";
import SetOfButtonsPlayerComponent from "../../components/player_game_element_component/SetOfButtonsPlayerComponent";
import ChatComponent from "../../components/ChatComponent";
function zeroPad(numberStr) {
    return numberStr.padStart(2, "0");
}
const defaultPlayerDTO = {
    avatarComposition: {
        bonus: 0,
        eyes: 0,
        hair: 0,
        hair_color: 0,
        mouth: 0,
        nose: 0,
        skin: 0,
        teamColor: ETeam.NONE,
    },
    name: "",
    capitain: false,
    team: ETeam.NONE,
    micOn: false,
    sessionPeerId: "undefined",
    isConnected: true,
};
function AvatarCompositionPage() {
    const { setCameraMode, openMediaSettings } = useMedia();
    const { muteSound } = useBoardGame();
    useEffect(() => {
        setCameraMode(true, false, false);
    });
    const navigate = useNavigate();
    const { gameState, sendRequest, websocketPeer } = useSessionContext();
    const [insideGameState, setInsideGameState] = useState(gameState);
    const line = [0, 1, 2, 3, 4, 5, 6];
    const refTimer = React.useRef(null);
    const defaltTimerValue = 3 * 60;
    const refTimerValue = React.useRef(defaltTimerValue);
    const [avatarItems, setAvatarItems] = useState({ hair: 0, hair_color: 0, nose: 0, mouth: 0, skin: 0, bonus: 0, eyes: 0, teamColor: ETeam.NONE });
    const [myPlayer, setMyPlayer] = useState(defaultPlayerDTO);
    const [validatedAvatar, setValidatedAvatar] = useState(false);
    const [isChatShown, setIsChatShown] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    function updateAvatarItems(index, item) {
        setAvatarItems(avatarDTO => {
            let newAvatarDTO = Object.assign({}, avatarDTO);
            if (item === "hair")
                newAvatarDTO.hair = index;
            else if (item === "hair_color")
                newAvatarDTO.hair_color = index;
            else if (item === "skin")
                newAvatarDTO.skin = index;
            else if (item === "nose")
                newAvatarDTO.nose = index;
            else if (item === "mouth")
                newAvatarDTO.mouth = index;
            else if (item === "eyes")
                newAvatarDTO.eyes = index;
            else if (item === "bonus")
                newAvatarDTO.bonus = index;
            return newAvatarDTO;
        });
    }
    function shuffleAvatar(avatarDTO) {
        const newAvatarDTO = {
            hair_color: getRandomInt(6),
            eyes: getRandomInt(6),
            nose: getRandomInt(6),
            mouth: getRandomInt(6),
            hair: getRandomInt(6),
            bonus: getRandomInt(6),
            skin: getRandomInt(6),
            teamColor: avatarDTO.teamColor,
        };
        sendAvatarComposition(newAvatarDTO);
        setAvatarItems(newAvatarDTO);
    }
    function onItemClicked(index, item) {
        // updateSelectedItems(index, item);
        updateAvatarItems(index, item);
    }
    function updateIfProjectSelectionLunched(message) {
        console.log("test callbac avatar projectk", message);
        setInsideGameState(message.data);
    }
    useEffect(() => {
        sendAvatarComposition();
        console.log("avatarItems", avatarItems);
    }, [avatarItems]);
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            console.log(result, "hello");
            setInsideGameState(result);
        });
    }
    function getMyPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("get my player");
            const result = yield sendRequest("getMyPlayer", { gameState: gameState })
                .catch((error) => { console.log("getMyPlayer", error); });
            console.log(result, "get my player");
            if (result !== undefined)
                setMyPlayer(result);
            //  getMyTeam(result.gameState, result.myPlayerDTOToSend)
        });
    }
    function validateAvatar() {
        setValidatedAvatar(true);
        sendAvatarComposition();
    }
    function toggleChat() {
        setIsChatShown(!isChatShown);
        if (!isChatShown) {
            setNewMessageAlert(false);
        }
    }
    function sendAvatarComposition(composition = undefined) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const resultMyPlayer = yield sendRequest("getMyPlayer", { gameState: gameState })
                .catch((error) => { console.error("getMyPlayer", error); });
            if (resultMyPlayer === undefined) {
                console.error("sendAvatarComposition : cannot get playername");
                return;
            }
            const myPlayerName = (_a = resultMyPlayer.name) !== null && _a !== void 0 ? _a : "";
            const result = yield sendRequest("avatarComposition", { avatarComposition: composition !== null && composition !== void 0 ? composition : avatarItems, playerName: myPlayerName })
                .catch((error) => { console.error("avatarComposition", error); });
            console.log("sendAvatarComposition", result);
            if (result && result.gameState !== undefined) { // TODO state is not longer this shape
                setInsideGameState(result.gameState);
            }
        });
    }
    useEffect(() => {
        muteSound("GlobalAmbiance");
        const intervalId = setInterval(() => {
            refTimerValue.current -= 1;
            refTimerValue.current = Math.max(0, refTimerValue.current);
            if (refTimer && refTimer.current) {
                refTimer.current.innerText = `${zeroPad(Math.floor(refTimerValue.current / 60).toString())}:${zeroPad((refTimerValue.current % 60).toString())}`;
            }
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalId);
        }, defaltTimerValue * 1000);
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "updateGameState")
                setInsideGameState(notification.data);
            if (notification.method === "createTeams") // TODO remove
                updateIfProjectSelectionLunched(notification);
        });
        shuffleAvatar(avatarItems);
    }, []);
    useEffect(() => {
        getGameState();
        getMyPlayer();
    }, []);
    useEffect(() => {
        if (insideGameState.projectSelectionOn)
            navigate('/PlayerDistributionProjectPage');
    }, [insideGameState]);
    return (_jsxs("div", Object.assign({ className: "w-full h-screen flex bg-dilemmeSimplePurple justify-center fixed" }, { children: [_jsx(SetOfButtonsPlayerComponent, { alertNewMessage: newMessageAlert, toggleChat: toggleChat, isMapModifiable: false, isOnlyConfigButton: false }), _jsx("div", Object.assign({ className: "flex fixed items-center justify-center", style: { zIndex: isChatShown ? 100000 : -1 } }, { children: _jsx(ChatComponent, { newMessageAlert: setNewMessageAlert, isChatShown: isChatShown, toggleChat: toggleChat, myPlayer: myPlayer }) })), _jsx("div", { className: "flex fixed yellow_cigogne_postit", style: {
                    backgroundImage: "url(/assets/UI/avatar_composition/post_it/yellow_cigogne_post_it.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                } }), _jsx("div", { className: "flex fixed blue_cigogne_postit", style: {
                    backgroundImage: "url(/assets/UI/avatar_composition/post_it/blue_cigogne_post_it.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                } }), _jsx("div", { className: "flex fixed green_cigogne_postit", style: {
                    backgroundImage: "url(/assets/UI/avatar_composition/post_it/green_cigogne_post_it.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                } }), _jsx("div", { className: "flex fixed red_cigogne_postit", style: {
                    backgroundImage: "url(/assets/UI/avatar_composition/post_it/red_cigogne_post_it.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                } }), _jsxs("div", Object.assign({ className: "flex justify-center  ", style: {
                    width: 647,
                    height: "100%",
                } }, { children: [_jsxs("div", Object.assign({ className: "flex w-1/2 relative h-full justify-start pt-[110px]" }, { children: [_jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx("div", { className: "flex", style: {
                                            backgroundImage: "url(/assets/UI/avatar_composition/post_it/player_post_it.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            zIndex: "1100",
                                            width: "220px",
                                            height: "231px",
                                            transform: "rotate(-5deg)",
                                            position: "relative"
                                        } }), _jsx("div", { className: "flex absolute", style: {
                                            backgroundImage: "url(/assets/UI/avatar_composition/composition/background.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            zIndex: "0",
                                            width: "150px",
                                            height: "150px",
                                            top: "22px",
                                            left: "28px"
                                        } }), _jsx("div", Object.assign({ className: "w-[140px] h-[140px] absolute", style: {
                                            transform: "translateX(50%) rotate(-5deg)",
                                            top: "26px",
                                            right: "52%"
                                        } }, { children: _jsx(AvatarCompositionComponent, { avatarComposition: avatarItems, toRight: false }) }))] })), _jsx(ItemsImageComponent, {})] })), _jsxs("div", Object.assign({ className: "flex w-1/2 h-full justify-start flex-col pt-[40px]" }, { children: [_jsxs("div", Object.assign({ className: "flex w-full relative h-[50px] justify-start flex-row gap-[20px] items-center" }, { children: [_jsx("button", Object.assign({ className: "flex bg-dilemmeDeepPurpple justify-center", disabled: validatedAvatar, onClick: () => shuffleAvatar(avatarItems), style: {
                                            width: 50,
                                            height: 50,
                                            border: "2px solid #000000",
                                        } }, { children: _jsx("div", { className: "flex items-center self-center", style: {
                                                width: 20,
                                                height: 16,
                                                backgroundImage: "url(/assets/UI/avatar_composition/shuffle_icon.png)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                            } }) })), _jsx("div", Object.assign({ className: "flex font-kalam text-center", ref: refTimer, style: {
                                            fontSize: "40px",
                                            fontWeight: "700",
                                            lineHeight: "64px",
                                            letterSpacing: "0.4px",
                                            color: "white",
                                            textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347, 3px 3px 0px #353347, 3px -3px 0px #353347, -3px 3px 0px #353347, -3px -3px 0px #353347, 4px 0px 0px #353347, 0px 4px 0px #353347, 4px 1px 0px #353347, 1px 4px 0px #353347, 4px 2px 0px #353347, 2px 4px 0px #353347, 4px 3px 0px #353347, 3px 4px 0px #353347, -4px 0px 0px #353347, 0px -4px 0px #353347, -4px 1px 0px #353347, 1px -4px 0px #353347, -4px 2px 0px #353347, 2px -4px 0px #353347, -4px 3px 0px #353347, 3px -4px 0px #353347, 4px -1px 0px #353347, -1px 4px 0px #353347, 4px -2px 0px #353347, -2px 4px 0px #353347, 4px -3px 0px #353347, -3px 4px 0px #353347, -4px -1px 0px #353347, -1px -4px 0px #353347, -4px -2px 0px #353347, -2px -4px 0px #353347, -4px -3px 0px #353347, -3px -4px 0px #353347, -5px 5px 0px #353347, -6px 5px 0px #353347, -5px 6px 0px #353347, -4px 6px 0px #353347, -3px 6px 0px #353347, -2px 6px 0px #353347, -1px 6px 0px #353347, -0px 6px 0px #353347, -6px 4px 0px #353347, -6px 3px 0px #353347, -6px 2px 0px #353347, -6px 1px 0px #353347, -6px 0px 0px #353347, -6px -1px 0px #353347",
                                        } }, { children: "2:34" })), !validatedAvatar &&
                                        _jsx("button", Object.assign({ className: "flex font-roboto justify-center text-white bg-dilemmeDeepPurpple items-center", onClick: validateAvatar, style: {
                                                width: 155,
                                                height: 50,
                                                padding: "16px 13px",
                                                border: "2px solid #000000",
                                                textTransform: "uppercase",
                                                fontSize: "10px",
                                                fontWeight: "900",
                                                lineHeight: "14px",
                                            } }, { children: "valider mon avatar" })), validatedAvatar && _jsx("div", Object.assign({ className: "font-roboto", style: {
                                            textTransform: "uppercase",
                                            padding: "16px 13px",
                                            fontSize: "10px",
                                            fontWeight: "900",
                                            lineHeight: "14px",
                                            color: "white",
                                        } }, { children: "Avatar valid\u00E9" }))] })), _jsx("div", Object.assign({ className: "flex w-full flex-col relative pt-[20px] gap-[10px] h-full", style: {
                                    pointerEvents: validatedAvatar ? "none" : "auto"
                                } }, { children: line.map((col, i) => {
                                    return (_jsx(ItemSelectionComponent, { index: i, onItemClicked: onItemClicked, selection: avatarItems }));
                                }) }))] }))] }))] })));
}
export default AvatarCompositionPage;
