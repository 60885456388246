"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PleasurePointsSchema = void 0;
const zod_1 = require("zod");
const PleasurePointsSchema = zod_1.z.object({
    amount: zod_1.z.number(),
    bonusMalusAmout: zod_1.z.number(),
    //bonusMalusAmout is marked ar 0 once the income is given to the player because it is for one month only
});
exports.PleasurePointsSchema = PleasurePointsSchema;
