"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ETeam;
(function (ETeam) {
    ETeam[ETeam["RED"] = 0] = "RED";
    ETeam[ETeam["BLUE"] = 1] = "BLUE";
    ETeam[ETeam["YELLOW"] = 2] = "YELLOW";
    ETeam[ETeam["GREEN"] = 3] = "GREEN";
    ETeam[ETeam["NONE"] = 4] = "NONE";
})(ETeam || (ETeam = {}));
exports.default = ETeam;
