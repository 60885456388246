var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import FunctionalButton from "./FunctionalButton";
import CrossIcon from "./icons/CrossIcon";
import TextField, { ETextFieldType } from "./TextField";
import useAuth from "../contexts/useAuth";
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
function MediaSettings(props) {
    const { role } = useAuth();
    const refVideo = useRef(null);
    const [microphones, setMicrophones] = useState([]);
    const [videos, setVideos] = useState([]);
    const refInput = useRef();
    const refCamera = useRef();
    function clickClose() {
        if (props.onClickClose)
            props.onClickClose();
    }
    function clickValidate() {
        var _a, _b;
        if (props.onClickValidate) {
            const micro = (_a = refInput.current) !== null && _a !== void 0 ? _a : props.selectedMicrophone; // ref are not populated by the Dropdown component if not clicked
            const cam = (_b = refCamera.current) !== null && _b !== void 0 ? _b : props.selectedCamera;
            props.onClickValidate(micro, cam);
        }
    }
    function getMicrophonesLabel() {
        return microphones.map(el => {
            if (!el.label)
                return "Default Microphone";
            return el.label;
        });
    }
    function getVideosLabel() {
        return videos.map(el => {
            return el.label;
        });
    }
    function updateVideo(name) {
        return __awaiter(this, void 0, void 0, function* () {
            if (navigator.mediaDevices.getUserMedia) {
                let videoDevices = yield navigator.mediaDevices.enumerateDevices();
                videoDevices = videoDevices.filter(el => el.kind === "videoinput");
                let foundCamera = videoDevices[0];
                if (name)
                    foundCamera = videoDevices.filter(el => el.label === name)[0];
                navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: foundCamera.deviceId } } })
                    .then(function (stream) {
                    var _a;
                    if (refVideo.current)
                        refVideo.current.srcObject = stream;
                    // Catch to handle the case where the video is not playing (local when not running webaudio server)
                    (_a = refVideo.current) === null || _a === void 0 ? void 0 : _a.play().catch((error) => console.error("Cannot play video", error));
                })
                    .catch(function (error) {
                    console.error("Cannot getUserMedia", error);
                });
            }
        });
    }
    useEffect(() => {
        updateVideo(props.selectedCamera);
        navigator.mediaDevices.enumerateDevices()
            .then(function (devices) {
            const devicesMic = devices.filter(el => el.kind === "audioinput");
            setMicrophones(devicesMic);
            const devicedVideos = devices.filter(el => el.kind === "videoinput");
            setVideos(devicedVideos);
            // const devicesSpeakers = devices.filter(el => el.kind === "audiooutput");
            // setSpeakers(devicesSpeakers);
        })
            .catch(function (error) {
            console.log("Something went wrong!");
        });
    }, []);
    function onVideoChange(video) {
        updateVideo(video);
    }
    return (_jsxs("div", Object.assign({ className: "flex justify-center fixed w-full h-full", style: { zIndex: 100 } }, { children: [_jsx("div", Object.assign({ className: "fixed h-full flex items-center justify-center", style: {
                    zIndex: 1,
                    padding: "12px 0px",
                    width: "500px",
                } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col", style: {
                        background: "white",
                        borderColor: "black",
                        borderWidth: "2px",
                        padding: "21px",
                        gap: '16px'
                    } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("div", Object.assign({ className: "font-roboto uppercase", style: { fontSize: '18px', color: '#54659F', fontWeight: '700' } }, { children: "Gestion des P\u00E9riph\u00E9riques" })), _jsx("button", Object.assign({ style: { width: "24px", height: "24px" }, onClick: clickClose }, { children: _jsx(CrossIcon, { color: "black" }) }))] })), _jsx(TextField, { value: props.selectedMicrophone, dropdownOptions: getMicrophonesLabel(), parentRef: refInput, label: "Micro", type: ETextFieldType.DROPDOWN }), role === EPlayerRole.ANIMATOR &&
                            _jsxs(_Fragment, { children: [_jsx(TextField, { onChange: onVideoChange, value: props.selectedCamera, dropdownOptions: getVideosLabel(), parentRef: refCamera, label: "Camera", type: ETextFieldType.DROPDOWN }), _jsx("video", { autoPlay: true, ref: refVideo, muted: true })] }), _jsxs("div", Object.assign({ className: "flex flex-row justify-end", style: { gap: '16px', height: '50px' } }, { children: [_jsx(FunctionalButton, { colorMode: 1, isRound: false, text: "Annuler", onClick: clickClose }), _jsx(FunctionalButton, { colorMode: 0, isRound: false, text: "Valider les modifications", onClick: clickValidate })] }))] })) })), _jsx("div", { className: "fixed right-0 w-full h-full", style: {
                    opacity: 0.8,
                    background: "#54659F",
                } })] })));
}
export { MediaSettings };
