import * as THREE from "three";
export class TweenObject {
    constructor() {
        this._tweens = [];
        this._toRemoveTweens = [];
    }
    AddTween(tween) {
        this._tweens.push(tween);
    }
    update(deltaTime) {
        this._tweens.forEach((tween, indexTween) => {
            tween.currentTime += deltaTime;
            if (tween.currentTime > tween.duration) {
                tween.progress(1);
                if (tween.onComplete)
                    tween.onComplete();
                this._toRemoveTweens.push(indexTween);
            }
            else {
                const factor = THREE.MathUtils.smoothstep(tween.currentTime / tween.duration, 0, 1); // TODO move smoothstep
                tween.progress(factor);
            }
        });
        // console.log("FRAME2", toRemoveTweens.current)
        this._toRemoveTweens.sort((a, b) => b - a); // We sort in reverse order to avoid index shifting
        this._toRemoveTweens.forEach(tweenIdx => {
            this._tweens.splice(tweenIdx, 1);
        });
        this._toRemoveTweens.splice(0, this._toRemoveTweens.length); // We clear the array
    }
}
