var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Html, PerspectiveCamera } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import DiceOneIcon from "../icons/DiceOneIcon";
import DiceTwoIcon from "../icons/DiceTwoIcon";
import DiceThreeIcon from "../icons/DiceThreeIcon";
import DiceFourIcon from "../icons/DiceFourIcon";
import DiceFiveIcon from "../icons/DiceFiveIcon";
import DiceSixIcon from "../icons/DiceSixIcon";
import { useEffect, useRef, useState } from "react";
import { Mesh, Vector3 } from "three";
import { useBoardGame } from "../../contexts/BoardGameProvider";
// Durations for animations in seconds
const DURATION_JUMP = 0.25;
const DiceIcons = [DiceOneIcon, DiceTwoIcon, DiceThreeIcon, DiceFourIcon, DiceFiveIcon, DiceSixIcon];
function RollingDice(props) {
    useEffect(() => {
        props.mesh.rotation.x += props.animationOffset;
        props.mesh.rotation.y += props.animationOffset;
    }, []);
    useFrame((_, deltaTime) => {
        props.mesh.rotation.x += deltaTime * 4.5;
        props.mesh.rotation.y += deltaTime * 3.33;
        props.mesh.rotation.z += deltaTime * 3.67;
    });
    return _jsx("primitive", { object: props.mesh, position: props.position });
}
function OneTeamRollDiceComponent(props) {
    const { playSound, muteSound } = useBoardGame();
    const [diceResultVisibility, setDiceResultVisibility] = useState(false);
    let currentResultVisibility = diceResultVisibility;
    if (!props.isInteractable) {
        currentResultVisibility = props.diceEvent.AreDicesRolled;
    }
    const refPawn = useRef(null);
    const dice1 = useRef(props.diceModel.clone());
    const dice2 = useRef(props.diceModel.clone());
    const dice3 = useRef(props.diceModel.clone());
    const refOriginalPawnPos = useRef();
    const refTimeJump = useRef(-1);
    const refTimeJumpEnd = useRef(-1);
    const refGroupDice = useRef(null);
    const currentCountableDices = props.diceEvent.dices.filter(el => el !== -1);
    useEffect(() => {
        const lengthDice = currentCountableDices.filter(el => el !== -1).length;
        dice1.current.visible = lengthDice > 0;
        dice2.current.visible = lengthDice > 1;
        dice3.current.visible = lengthDice > 2;
    });
    const totalDice = currentCountableDices.reduce((prev, cur) => prev + cur); // diceValues[0] + diceValues[1];
    const DiceIconsComps = currentCountableDices.map((value) => DiceIcons[Math.max(value - 1, 0)]);
    useEffect(() => {
        var _a;
        props.pawnModel.visible = true;
        refOriginalPawnPos.current = (_a = refPawn.current) === null || _a === void 0 ? void 0 : _a.position.clone();
        if (!currentResultVisibility) {
            playSound("RollDice");
        }
        if (diceResultVisibility) {
            muteSound("RollDice");
        }
    });
    useEffect(() => {
        return () => {
            muteSound("RollDice");
        };
    }, []);
    function handleClick() {
        return __awaiter(this, void 0, void 0, function* () {
            muteSound("RollDice");
            playSound("ScoreDice");
            setDiceResultVisibility(true);
            refTimeJump.current = DURATION_JUMP;
            setTimeout(() => {
                props.hasRolled();
            }, 3000);
        });
    }
    const refTotalTime = useRef(0);
    useFrame((state, deltaTime) => {
        if (refGroupDice.current) {
            refGroupDice.current.rotation.z = Math.sin(refTotalTime.current * 2.5 + props.team) * 3.14 * 0.5;
            refGroupDice.current.rotation.y = props.team + refTotalTime.current * 3.33;
        }
        refTotalTime.current += deltaTime;
    });
    const offsetHeight = 2;
    useFrame((state, deltaTime) => {
        if (refTimeJump.current > 0) {
            console.log("ANIMATINGPAWN");
            refTimeJump.current -= deltaTime;
            if (refPawn.current && refOriginalPawnPos.current) {
                // console.log("FAILLLLLLLLLANIMATINGPAWN");
                let factor = 1 - refTimeJump.current / DURATION_JUMP;
                factor = Math.pow(factor, 0.5);
                refPawn.current.position.copy(refOriginalPawnPos.current.clone().lerp(refOriginalPawnPos.current.clone().add(new Vector3(0, offsetHeight, 0)), factor));
            }
            if (refTimeJump.current <= 0) { // When animation done
                refTimeJumpEnd.current = DURATION_JUMP;
            }
        }
        if (refTimeJumpEnd.current > 0) {
            refTimeJumpEnd.current -= deltaTime;
            if (refPawn.current && refOriginalPawnPos.current) {
                let factor = 1 - refTimeJumpEnd.current / DURATION_JUMP;
                factor = Math.pow(factor, 2);
                refPawn.current.position.copy(refOriginalPawnPos.current.clone().lerp(refOriginalPawnPos.current.clone().add(new Vector3(0, offsetHeight, 0)), 1 - factor));
            }
        }
    });
    return (_jsxs("group", { children: [_jsx(Html, Object.assign({ style: { pointerEvents: 'none' }, position: [-2, 7.2, 0] }, { children: props.isInteractable &&
                    _jsx("div", { style: { opacity: 0, width: 250, height: 600, background: 'red', zIndex: 1000, position: 'absolute', pointerEvents: 'all' }, onClick: handleClick }) })), _jsx("group", Object.assign({ ref: refPawn }, { children: _jsx("primitive", { object: props.pawnModel }) })), !currentResultVisibility &&
                _jsx("group", Object.assign({ position: [0, 6, 0], scale: [1, 1, 1] }, { children: _jsxs("group", Object.assign({ ref: refGroupDice }, { children: [_jsx(RollingDice, { animationOffset: props.team, mesh: dice1.current, position: [0, -1, 0] }), _jsx(RollingDice, { animationOffset: props.team + 5.23, mesh: dice2.current, position: [1, 0, 0] }), _jsx(RollingDice, { animationOffset: props.team + 3.97, mesh: dice3.current, position: [0.4, 0.2, 1.5] })] })) })), _jsx(Html, Object.assign({ position: [-0.5, 6.2, 0] }, { children: _jsx("div", Object.assign({ style: { width: 120, height: 120 } }, { children: currentResultVisibility &&
                        _jsxs("div", Object.assign({ className: "animate__animated animate__bounceIn absolute flex flex-col", style: { background: '#6F82C0', border: 'solid white', borderRadius: 10, borderWidth: 3, transform: 'rotate(-3deg)' } }, { children: [_jsx("div", Object.assign({ className: "flex flex-row", style: { padding: '10px 20px' } }, { children: DiceIconsComps.map((Icon, index) => _jsx("div", Object.assign({ style: { width: 37 } }, { children: _jsx(Icon, {}, `diceIcon${index}`) }))) })), _jsx("div", Object.assign({ className: "flex relative font-kalam text-white itemps-center justify-center", style: {
                                        fontSize: "50px",
                                        fontWeight: "900",
                                        lineHeight: "50px",
                                        letterSpacing: "0.2px",
                                        textAlign: "center",
                                        textShadow: "-1px 0px 0px #352247, 0px -3px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347",
                                    } }, { children: totalDice }))] })) })) }))] }));
}
function RollDice3DComponent(props) {
    var _a;
    const { muteSound } = useBoardGame();
    const [pawns, setPawns] = useState(new Map());
    const [diceModel, setDiceModel] = useState(new Mesh());
    const refRolledDice = useRef();
    const refCloseOnDiceRolled = useRef(false);
    const [selectedTeams, setselectedTeams] = useState([]);
    const refCam = useRef(null);
    let diceRollWrapper_ = {
        showDiceRoll: (diceRollConfig, callback) => {
            if (!diceRollConfig) {
                props.setVisibility(false);
                return;
            }
            console.log("SHOWING DICE ROLL", callback);
            refRolledDice.current = callback;
            refCloseOnDiceRolled.current = diceRollConfig.closeOnRolled;
            setselectedTeams(Array.from(diceRollConfig.diceEvent.entries()).map((teamConfig) => {
                const pawn = pawns.get(teamConfig[0]);
                if (pawn)
                    pawn.visible = true;
                console.log("SHOWING PAWN", teamConfig[0], pawn.visible);
                return {
                    isInteractable: teamConfig[0] === diceRollConfig.currentTeam && !teamConfig[1].AreDicesRolled,
                    team: teamConfig[0],
                    pawnModel: pawn,
                    diceEvent: teamConfig[1]
                };
            }));
            props.setVisibility(true);
        }
    };
    (_a = props.onMounted) === null || _a === void 0 ? void 0 : _a.call(props, setPawns, setDiceModel, diceRollWrapper_);
    useEffect(() => {
        var _a;
        (_a = refCam === null || refCam === void 0 ? void 0 : refCam.current) === null || _a === void 0 ? void 0 : _a.lookAt(0, 1, 0);
        return () => {
            setselectedTeams([]);
        };
    }, []);
    useEffect(() => {
        if (selectedTeams.every(t => t.diceEvent.AreDicesRolled)) {
            muteSound("RollDice");
        }
    });
    if (pawns.size > 0) {
        // let pawnCopy = pawns.map((pawn: any) => { return pawn.clone() }) : [];
        Array.from(pawns).forEach((pawn) => {
            console.log("ArrayPAWN", pawn);
            if (pawn[1]) {
                pawn[1].visible = true;
                const sc = .012;
                pawn[1].scale.set(sc, sc, sc);
                pawn[1].rotation.set(0, 0, 0);
                pawn[1].position.set(0, 0, 0);
            }
        });
    }
    diceModel.scale.set(0.1, 0.1, 0.1);
    function hasTeamRolled(team) {
        if (refCloseOnDiceRolled.current)
            props.setVisibility(false);
        console.log("HASROLLED", refRolledDice.current);
        if (refRolledDice.current) {
            console.log("HASROLLED calling callback", team);
            refRolledDice.current(team);
        }
        refRolledDice.current = undefined;
    }
    return (_jsx(_Fragment, { children: props.visibility && _jsxs(_Fragment, { children: [_jsx(PerspectiveCamera, { ref: refCam, makeDefault: true, position: [0, 2, 14], near: 0.01, far: 150 }), _jsx("ambientLight", { intensity: 10.8, color: "#3d4d77" }), _jsx("directionalLight", { color: "white", position: [2, 5, -2], intensity: 3.5 }), _jsx("directionalLight", { color: "white", position: [2, 5, 5], intensity: 0.5 }), pawns && selectedTeams.filter(t => t.pawnModel && t.pawnModel.visible).map((pawn, index) => {
                    return (_jsx("group", Object.assign({ position: [((index + 0.5) - selectedTeams.length * 0.5) * 3.5, -2, 0], rotation: [0, 0.5, 0] }, { children: _jsx(OneTeamRollDiceComponent, { isInteractable: pawn.isInteractable, hasRolled: () => { hasTeamRolled(pawn.team); }, diceEvent: pawn.diceEvent, team: pawn.team, diceModel: diceModel, pawnModel: pawn.pawnModel }) }), `diceRollGroup${pawn.team}`));
                })] }) }));
}
export function RollDiceComponent(props) {
    const [visibility, setVisibility] = useState(false);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ style: {
                width: '100%', height: '100%', overflow: 'hidden',
                visibility: visibility ? 'visible' : 'hidden',
                zIndex: visibility ? -10 : -200,
                pointerEvents: visibility ? 'all' : 'none',
            } }, { children: [_jsx("div", { style: { position: 'absolute', background: '#0C1533CC', width: '100%', height: '100%' } }), _jsx(Canvas, Object.assign({ style: { position: 'absolute' }, gl: { depth: true, logarithmicDepthBuffer: true } }, { children: _jsx(RollDice3DComponent, { onMounted: props.onMounted, setVisibility: setVisibility, visibility: visibility }) }))] })) }));
}
