import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import CashIcon from "../icons/CashIcon";
import PiggyBankIcon from "../icons/PiggyBankIcon";
import CalendarRangeIcon from "../icons/CalendarRangeIcon";
import StarIcon from "../icons/StarIcon";
function CardRecapComponent(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let data = [
        {
            amount: _jsxs(_Fragment, { children: [(_b = (_a = props.team.money) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0, "\u20AC"] }),
            icon: _jsx(CashIcon, {})
        },
        {
            amount: _jsxs(_Fragment, { children: [(_d = (_c = props.team.savings) === null || _c === void 0 ? void 0 : _c.amout) !== null && _d !== void 0 ? _d : 0, "\u20AC"] }),
            icon: _jsx(PiggyBankIcon, {})
        },
        {
            amount: _jsx(_Fragment, { children: (_f = (_e = props.team.pleasurePoints) === null || _e === void 0 ? void 0 : _e.amount) !== null && _f !== void 0 ? _f : 0 }),
            icon: _jsx(StarIcon, {})
        },
        {
            amount: _jsxs(_Fragment, { children: [Math.floor(props.team.slotTeam.slotAbsoluteNumber / 38), " mois"] }),
            icon: _jsx(CalendarRangeIcon, {})
        }
    ];
    return (_jsx("div", Object.assign({ className: "px-2.5 flex flex-col text-white text-sm font-roboto", style: {
            borderRadius: "3px",
            backgroundColor: "#121E46",
            borderColor: "#313D64",
            borderWidth: "1px"
        } }, { children: _jsxs("div", Object.assign({ className: "flex-col flex" }, { children: [data.map(el => {
                    return (_jsxs("div", Object.assign({ className: "flex py-2.5", style: {
                            borderBottomWidth: "1px",
                            borderColor: "#313D64",
                        } }, { children: [_jsx("div", Object.assign({ style: { width: 24 } }, { children: el.icon })), _jsx("div", Object.assign({ className: "pl-2.5" }, { children: el.amount }))] })));
                }), _jsx("div", Object.assign({ className: "flex py-2.5" }, { children: _jsxs("div", { children: ["Projet \u00E0 ", _jsxs("span", Object.assign({ className: "font-bold" }, { children: [(_h = (_g = props.team.project) === null || _g === void 0 ? void 0 : _g.price) !== null && _h !== void 0 ? _h : 0, "\u20AC"] }))] }) }))] })) })));
}
export default CardRecapComponent;
