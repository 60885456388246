// This is the list of all the meshes that should be instanced in the 3D scene.
// This is list compares exact names of the meshes in the 3D scene.
export const meshesToInstances = new Set([
    "reverbere_parc_1",
    "reverbere_parc_2",
    "reverbere_parc_3",
    "reverbere",
    //"reverbere_008", // HERE specific handling
    //"reverbere_double", // HERE specific handling
    "feux_tricolore",
    "feux_tricolores",
    "feux_tricolores_1",
    "feux_tricolores_2",
    "feux_tricolores_3",
    //"evacuation", // HERE specific handling
    "arbre",
    "arbre_1",
    "arbre_2",
    "arbre_3",
    "sapin",
    "sapin_1",
    "sapin_2",
    "sapin_3",
    "sapin_4",
    "sapin_5",
    "Gratte_ciel_E_14",
    "Gratte_ciel_E_15",
    "Gratte_ciel_E_16",
    "Gratte_ciel_F_16",
    "Gratte_ciel_F_17",
    "Gratte_ciel_F_18",
    "Gratte_ciel_F_21",
    "Gratte_ciel_E_20",
    "Gratte_ciel_G_5",
    "Gratte_ciel_C_8",
    "Gratte_ciel_A_5",
    "Gratte_ciel_D_4",
    "Gratte_ciel_C_6",
    "court_tennis_01",
    "Gratte_ciel_A_1",
    "Gratte_ciel_A_2",
    "Gratte_ciel_A_3",
    "Gratte_ciel_G_1",
    "Gratte_ciel_G_2",
    "Gratte_ciel_G_3",
    "cheminée_1",
    "cheminée_2",
    "cheminée_3",
    "accès_toit_1",
    "accès_toit_2",
    "house_type_C",
    "stand_forrain",
    "stand_forrain_1",
    "stand_forrain_2",
    "stand_forrain_3",
    "cab",
    "Bat_type_M_1",
    "Bat_type_M_2",
    "Bat_type_M_3",
    "Bat_type_M_4",
    "Bat_type_F_5",
    "Bat_type_F_6",
    "Bat_type_F_7",
    "Bat_type_F_8",
    "Bat_type_E_1",
    "Bat_type_E_2",
    "Bat_type_E_3",
    "Bat_type_E_4",
    "Bat_type_G_6",
    "Bat_type_G_7",
    "Bat_type_G_8",
    "Bat_type_G_9",
    "Bat_type_G_10",
    "Bat_type_A_1",
    "Bat_type_A_2",
    "Bat_type_A_3",
    "Bat_type_A_4",
    "Bat_type_A_5",
    "Bat_type_B_1",
    "Bat_type_B_2",
    "Bat_type_B_3",
    "Bat_type_B_4",
    "Bat_type_H_1",
    "Bat_type_H_2",
    "Bat_type_H_3",
    "Bat_type_H_4",
    "Bat_type_F_1",
    "Bat_type_F_2",
    "Bat_type_F_3",
    "Bat_type_F_4",
    "house_type_A_1",
    "house_type_A_2",
    "house_type_A_3",
    "house_type_A_4",
    "house_type_A_5",
    "house_type_A_6",
    "house_type_A_7",
    "house_type_A_8",
    "house_type_A_9",
    "house_type_A_10",
    "house_type_B_1",
    "house_type_B_2",
    "house_type_B_3",
    "house_type_B_4",
    "house_type_B_5",
    "house_type_B_6",
    "house_type_B_7",
    "house_type_B_8",
    "house_type_C_1",
    "house_type_C_2",
    "house_type_C_3",
    "house_type_C_4",
    "house_type_C_5",
    "house_type_C_6",
    "house_type_C_7",
    "house_type_C_8",
    "house_type_C_9",
    "house_type_C_10",
    "house_type_D_1",
    "house_type_D_2",
    "house_type_D_3",
    "house_type_D_4",
    "house_type_D_5",
    "Bat_type_D_9",
    "Bat_type_D_10",
    "Bat_type_D_11",
    "Gratte_ciel_B_4",
    "Gratte_ciel_E_18",
    "Gratte_ciel_E_19",
    "Gratte_ciel_G_6",
    "Bat_type_A_5",
    "Bat_type_A_6",
    "Bat_type_A_7",
    "Box037_1"
]);
export const instancedByMeshNameInclude = new Set([
    "evacuation_toit",
    "reverbere_008",
    "reverbere_double",
    "cab_0",
    "Car__",
    "Cylinder",
    "Trucks",
    "buisson",
]);
