var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ChargesFixedSlots from "./ChargesFixedSlots";
import ChargesNotFixed from "./ChargesNotFixed";
import IncomeComponent from "./IncomeComponent";
import NotebookButtonComponent from "../player_buttons/NotebookButtonComponent";
import PostItComponent from "./PostItComponent";
import CardsNoteComponent from "./CardsNoteComponent";
import useSessionContext from "../../tools/useSessionContext";
import PiggyBankIcon from "../icons/PiggyBankIcon";
import { useBoardGame } from "../../contexts/BoardGameProvider";
function ChargesNotebookComponent(props) {
    const { playSound } = useBoardGame();
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const [isShown, setIsShown] = useState(true);
    const [isOnHoverPiggyBank, setIsOnHoverPiggyBank] = useState(false);
    const [isCardRecapShown, setIsCardRecapShown] = useState(false);
    const [isProjectDistributed, setIsProjectDistributed] = useState(props.isProjectDistributed);
    // const [project, setProject] = useState<ProjectDTO| undefined>(props.project);
    const [charges, setCharges] = useState(props.charges);
    const [incomes, setIncomes] = useState(props.incomes);
    const [totalIncome, setTotalIncome] = useState(0);
    const [turnState, setTurnState] = useState(props.turnState);
    const [numberOfMonthPlayed, setNumberOfMonthPlayed] = useState(props.numberOfMonthPlayed);
    const [savings, setSavings] = useState(props.savings);
    const [cardsOfTeam, setCardsOfTeam] = useState(props.cards);
    const [isCaptain, setIsCaptain] = useState(props.isCaptain);
    //todo make into a component
    const [isOnHoverSavingBook, setIsOnHoverSavingBook] = useState(false);
    const [isSavingBookOut, setIsSavingBookOut] = useState(false);
    function payChargeByPlayer(chargeToPay, teamToPay) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("payChargeByPlayer", { charge: chargeToPay, teamColor: teamToPay })
                .catch((err) => { console.error("payChargeByPlayer", err); });
            console.log("payChargeByPlayer", result);
            if (result && result.gameState !== undefined) { // TODO state is not longer this shape
                props.setInsideGameState(result.gameState);
            }
        });
    }
    function handleClickToShowNotebook() {
        playSound("OpenChargeBook");
        props.handleCLick();
        setIsShown(!isShown);
        setIsSavingBookOut(false);
    }
    useEffect(() => {
        setIsProjectDistributed(props.isProjectDistributed);
    }, [props.isProjectDistributed]);
    useEffect(() => {
        setTurnState(props.turnState);
    }, [props.turnState]);
    useEffect(() => {
        setIsCaptain(props.isCaptain);
    }, [props.isCaptain]);
    useEffect(() => {
        setCharges(props.charges);
        setIncomes(props.incomes);
        const incomeTotalArray = props.incomes.map((income) => {
            return income.amount + income.bonusMalusAmout;
        });
        const incomeTotal = incomeTotalArray.reduce((a, b) => a + b, 0);
        setTotalIncome(incomeTotal);
        console.log("i got change in the props", props.charges, props.incomes);
    }, [props.charges, props.incomes]);
    useEffect(() => {
        setNumberOfMonthPlayed(props.numberOfMonthPlayed);
    }, [props.numberOfMonthPlayed]);
    useEffect(() => {
        setSavings(props.savings);
    }, [props.savings]);
    useEffect(() => {
        setCardsOfTeam(props.cards);
    }, [props.cards]);
    return (_jsxs("div", Object.assign({ style: { zIndex: `${isShown ? 5 : 0}`, } }, { children: [_jsxs("div", Object.assign({ className: "fixed flex noteBookWaitingRoom ", style: {
                    minHeight: 550.5 * 1.0,
                    minWidth: 753.5 * 1.0,
                    maxHeight: 1101,
                    maxWidth: 1507,
                    zIndex: 2,
                    left: 0,
                    bottom: 0,
                    pointerEvents: "none",
                    // display: "none"
                } }, { children: [_jsx("div", Object.assign({ style: { pointerEvents: 'all' } }, { children: _jsx(NotebookButtonComponent, { isNotebookShown: isShown, handleCLick: handleClickToShowNotebook }) })), isProjectDistributed && props.project &&
                        _jsx("div", Object.assign({ className: "noteBookWaitingRoom" }, { children: _jsx(PostItComponent, { isShown: isShown, projet: props.project }) })), _jsxs("div", Object.assign({ className: `fixed flex noteBookWaitingRoom ${isShown ? "noteBookWaitingRoomShown" : "noteBookWaitingRoomHidden"}`, style: {
                            pointerEvents: 'all',
                            transition: "all 1s ease-in-out",
                            minHeight: 550.5 * 1.0,
                            minWidth: 753.5 * 1.0,
                            maxHeight: 1101,
                            maxWidth: 1507,
                            // rotate: `${isShown ? "-3deg" : "10deg"}`,
                            // bottom: `${isShown ? "-3cqw" : "-6cqw"}`,
                            // left: `${isShown ? "-13cqw" : "-97cqw"}`,
                            backgroundImage: "url(./assets/UI/game_elements_images/notebook_bg.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            zIndex: 2,
                        } }, { children: [numberOfMonthPlayed > 0 && savings &&
                                _jsx("div", Object.assign({ className: `flex absolute justify-start items-center flex-col gap-[5px] piggyBank `, 
                                    // onMouseEnter={() => setIsOnHoverPiggyBank(true)}
                                    // onMouseLeave={() => setIsOnHoverPiggyBank(false)}
                                    // onClick={() => setIsSavingBookOut(!isSavingBookOut)}
                                    style: {
                                        minWidth: 300 * 1,
                                        minHeight: 400,
                                        maxHeight: 400 * 2,
                                        maxWidth: 300 * 2 * 1,
                                        // bottom: isSavingBookOut ? "7cqw" : isOnHoverPiggyBank? "14cqw" : "14cqw", // 50 et 100
                                        // left: isSavingBookOut ? "9.8cqw" : isOnHoverPiggyBank? "-11cqw" : "-14cqw", // 70 et -108
                                        transform: isSavingBookOut ? "rotate(2deg)" : "rotate(-10deg)",
                                        transition: " all 0.5s ease-in-out",
                                        zIndex: 3,
                                        // paddingTop: "14cqw", // "100px",
                                        // paddingRight: "7cqw", //"50px",
                                    } }, { children: _jsxs("button", Object.assign({ className: `w-full h-full justify-start flex items-center flex-col gap-[5px] relative ${isSavingBookOut ? "piggyBankOut" : isOnHoverPiggyBank ? "piggyBankHover" : "piggyBankIn"}`, onMouseEnter: () => setIsOnHoverPiggyBank(true), onMouseLeave: () => setIsOnHoverPiggyBank(false), onClick: () => setIsSavingBookOut(!isSavingBookOut), style: {
                                            transform: isSavingBookOut ? "rotate(2deg)" : "rotate(0)",
                                            transition: " all 0.5s ease-in-out",
                                            backgroundImage: "url(./assets/UI/game_elements_images/saving_book.png)",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            bottom: isSavingBookOut ? "-22%" : isOnHoverPiggyBank ? "" : "", // 50 et 100
                                        } }, { children: [_jsx("div", Object.assign({ className: "flex w-full font-kalam justify-center text-center fontPiggyBank", style: {
                                                    // fontSize: "6.5cqw",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                    // lineHeight: "5.65cqw",
                                                    color: "white",
                                                    textTransform: "uppercase",
                                                } }, { children: "Livret d'\u00E9pargne" })), _jsx("div", Object.assign({ className: "flex relative font-kalam  justify-center items-center", style: {
                                                    // width: 160,
                                                    // height: 44,
                                                    width: "64.5%",
                                                    height: "17%",
                                                    borderRadius: "10px",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    border: "2px solid #000000",
                                                    padding: "2.85%",
                                                } }, { children: _jsxs("div", Object.assign({ className: "flex relative justify-between items-center self-center font-kalam", style: {
                                                        // width: 146,
                                                        // height: 30,
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(221, 40, 16, 1)",
                                                        color: "white",
                                                        // paddingRight: "2cqw",
                                                        // paddingLeft: "2cqw",
                                                        paddingRight: "2.85%",
                                                        paddingLeft: "2.85%",
                                                    } }, { children: [_jsxs("div", Object.assign({ className: "flex relative w-full h-full  items-center gap-[5px]", style: { fontSize: "4.1cqw", lineHeight: "5.65cqw", backgroundColor: "rgba(221, 40, 16, 1)" } }, { children: [_jsx("div", Object.assign({ className: "flex relative ", style: {
                                                                        width: "20%",
                                                                        height: "80%"
                                                                    } }, { children: _jsx(PiggyBankIcon, { color: "white" }) })), savings.percentageOfBonus, "% /mois"] })), _jsxs("div", Object.assign({ className: "flex relative self-center items-center  h-full", style: {
                                                                width: "30%",
                                                                fontSize: "5.65cqw",
                                                                fontStyle: "normal",
                                                                lineHeight: "5.65cqw",
                                                                fontWeight: "700",
                                                            } }, { children: [savings.amout, " \u20AC"] }))] })) }))] })) })), _jsxs("div", Object.assign({ className: "flex relative gap-[20px]", style: {
                                    height: "100%",
                                    width: "100%",
                                    paddingLeft: "32.5%",
                                    paddingTop: "6.16%",
                                    paddingRight: "5%",
                                    paddingBottom: "7.5%",
                                } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col flex-wrap gap-[20px]" }, { children: [_jsxs("div", Object.assign({ className: "flex relative flex-col font-kalam text-center text-black noteBookIncomeSlot", style: {
                                                    maxHeight: 144 * 2.0,
                                                    maxWidth: 138 * 2.0,
                                                    minWidth: 138,
                                                    minHeight: 144,
                                                    paddingTop: 10,
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingBottom: 5,
                                                    backgroundImage: "url(./assets/UI/game_elements_images/income_frame_notebook.png)",
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                } }, { children: [_jsx("div", Object.assign({ className: "flex text-center fontIncomeNotebook", style: {
                                                            paddingBottom: "10px",
                                                            // fontSize: "1.85cqw",
                                                            fontStyle: "normal",
                                                            lineHeight: "normal",
                                                            fontWeight: "700",
                                                            textTransform: "uppercase",
                                                        } }, { children: "revenus du mois" })), _jsx("div", Object.assign({ className: "flex h-full flex-col" }, { children: incomes.map((income, index) => {
                                                            return (_jsx(IncomeComponent, { income: income.amount + income.bonusMalusAmout, titleOfIncome: income.name }, index));
                                                        }) })), _jsx("div", { className: "flex", style: {
                                                            paddingTop: "4px",
                                                            height: "2px",
                                                            backgroundImage: "url(./assets/UI/line.png)",
                                                            backgroundSize: "contain",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "top",
                                                        } }), _jsx("div", { children: _jsxs("div", Object.assign({ className: "flex flex-row w-full justify-between self-end fontTotalName" }, { children: [_jsx("div", Object.assign({ className: "flex", style: {
                                                                        width: "auto",
                                                                        // fontSize: "12px",
                                                                        // fontSize: "1.6cqw",
                                                                        fontStyle: "normal",
                                                                        // lineHeight: "2.53cqw",
                                                                        // lineHeight: "19px",
                                                                        fontWeight: "700",
                                                                    } }, { children: "Total" })), _jsxs("div", Object.assign({ className: "flex self-end fontTotalValue", style: {
                                                                        width: "auto",
                                                                        // fontSize: "2.13cqw",
                                                                        // lineHeight: "2.53cqw",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "700",
                                                                    } }, { children: [totalIncome, " \u20AC"] }))] })) })] })), charges.map((charge, index) => {
                                                if (charge.name === "Mutuelle" || charge.name === "Assurance voiture") {
                                                    return (_jsx(ChargesFixedSlots, { isDoubleOn: props.isDoubleOn, isCaptain: isCaptain, turn: turnState, payChargeByPlayer: () => payChargeByPlayer(charge, props.team), isPaid: charge.isPaid, name: charge.name, bailiff: charge.huissier, cost: charge.amount + charge.bonusMalusAmout, pleasurePointsPenalty: charge.pleasurePointsMalus }, index));
                                                }
                                            })] })), _jsxs("div", Object.assign({ className: "flex relative justify-start flex-row font-kalam text-black flex-wrap gap-[18px] content-start", style: {
                                            height: "100%",
                                            width: "100%",
                                            zIndex: 10,
                                        } }, { children: [charges.map((charge, index) => {
                                                if (charge.name !== "Mutuelle" && charge.name !== "Assurance voiture" && charge.createdCharge === false) {
                                                    return (_jsx(ChargesFixedSlots, { isDoubleOn: props.isDoubleOn, isCaptain: isCaptain, turn: turnState, payChargeByPlayer: () => payChargeByPlayer(charge, props.team), isPaid: charge.isPaid, name: charge.name, bailiff: charge.huissier, cost: charge.amount + charge.bonusMalusAmout, pleasurePointsPenalty: charge.pleasurePointsMalus }, index));
                                                }
                                            }), props.charges.map((charge, index) => {
                                                if (charge.createdCharge === true && charge.name !== "Mutuelle" && charge.name !== "Assurance voiture") {
                                                    return (_jsx(ChargesNotFixed, { isDoubleOn: props.isDoubleOn, isCaptain: isCaptain, cost: charge.amount + charge.bonusMalusAmout, pleasurePointsbonus: charge.pleasurePointsToAdd, name: charge.name, isPaid: charge.isPaid, turn: turnState, payCharge: () => payChargeByPlayer(charge, props.team) }, index));
                                                }
                                            })] }))] }))] }))] })), _jsx(CardsNoteComponent, { isNotebookShown: isShown, cards: cardsOfTeam })] })));
}
export default ChargesNotebookComponent;
