"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EEffects;
(function (EEffects) {
    EEffects[EEffects["COMPTE_COURANT"] = 0] = "COMPTE_COURANT";
    EEffects[EEffects["POINTS_PLAISIR"] = 1] = "POINTS_PLAISIR";
    EEffects[EEffects["MODIFICATION_REVENUS"] = 2] = "MODIFICATION_REVENUS";
    EEffects[EEffects["EPARGNE"] = 3] = "EPARGNE";
    EEffects[EEffects["NOUVELLE_CHARGE"] = 4] = "NOUVELLE_CHARGE";
    EEffects[EEffects["MODIFICATION_CHARGE"] = 5] = "MODIFICATION_CHARGE";
    EEffects[EEffects["DEPLACEMENT"] = 6] = "DEPLACEMENT";
    EEffects[EEffects["NOMBRE_DES"] = 7] = "NOMBRE_DES";
    EEffects[EEffects["NOUVEAU_REVENUS"] = 8] = "NOUVEAU_REVENUS";
    EEffects[EEffects["TRANSFERT_EPARGNE"] = 9] = "TRANSFERT_EPARGNE";
})(EEffects || (EEffects = {}));
exports.default = EEffects;
