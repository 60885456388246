var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { getDefaultGameStateDTO } from "cresus-common/dist/dto/GameStateDTO";
import useSessionContext from "../../tools/useSessionContext";
import TeamReconnectionDisplay from "../../components/player_game_element_component/TeamReconnectionDisplay";
import { deepCopy } from "cresus-common/dist/utils/CopyUtils";
import PlayerToReconnect from "../../components/player_game_element_component/PlayerToReconnect";
import ETeam from "cresus-common/dist/dto/ETeam";
import { useNavigate } from "react-router-dom";
const defaultPlayerDTO = {
    micOn: false,
    name: "",
    avatarComposition: {
        teamColor: ETeam.RED,
        skin: 0,
        hair: 0,
        hair_color: 0,
        eyes: 0,
        nose: 0,
        mouth: 0,
        bonus: 0
    },
    team: ETeam.RED,
    capitain: false,
    sessionPeerId: "",
    isConnected: true,
};
function ReconnectionPage() {
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const navigate = useNavigate();
    const [insideGameState, setInsideGameState] = useState(deepCopy(getDefaultGameStateDTO()));
    const [myPlayer, setMyPlayer] = useState(defaultPlayerDTO);
    const refHasReconnected = useRef(false);
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", {})
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            console.log("getGameState", result);
            setInsideGameState(result);
        });
    }
    function getMyPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("get my player");
            const result = yield sendRequest("getMyPlayer", {})
                .catch((error) => { console.error("getMyPlayer", error); });
            if (result !== undefined)
                setMyPlayer(result);
        });
    }
    useEffect(() => {
        getGameState();
        getMyPlayer();
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            console.log('notification', notification);
            try {
                if (notification.method === "updateGameState") {
                    setInsideGameState(notification.data);
                    if (refHasReconnected.current)
                        handleNavigation(notification.data);
                }
            }
            catch (error) {
                console.error("Error in notification", error);
            }
        });
    }, []);
    function handleNavigation(gameStateDTO) {
        // Navigate to the proper page once we are reconnected as a player
        if (gameStateDTO.gameStarted)
            navigate("/GameInProgressPage");
        else if (gameStateDTO.projectSelectionOn)
            navigate("/PlayerDistributionProjectPage");
        else if (gameStateDTO.avatarSelectionOn)
            navigate("/AvatarCompositionPage");
        else
            navigate("/IntroductionPlayerPage");
    }
    function handleJoinAsPlayer(player) {
        console.log("Join as player", player);
        refHasReconnected.current = true;
        sendRequest("joinAsPlayer", { player: player });
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-center justify-center bg-dilemmeDeepPurpple absolute w-full h-full", style: {
            paddingTop: "20px",
            gap: "20px",
        } }, { children: [_jsx("div", { className: "flex w-full", style: {
                    top: 50,
                    backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    height: 200,
                    aspectRatio: "2/1",
                    width: "auto",
                } }), _jsxs("div", Object.assign({ className: "flex flex-row ", style: {
                    paddingTop: "20px",
                    gap: "20px",
                } }, { children: [_jsx("div", Object.assign({ className: "flex flex-col", style: { width: 200, gap: 20 } }, { children: insideGameState.lobbyPlayers.map((player, index) => {
                            if (player.name !== myPlayer.name) {
                                return (_jsx(PlayerToReconnect, { player: player, joinAsPlayer: handleJoinAsPlayer }));
                            }
                        }) })), _jsx("div", Object.assign({ className: "flex flex-row gap-[5px]" }, { children: insideGameState === null || insideGameState === void 0 ? void 0 : insideGameState.teams.map((team, index) => {
                            return _jsx(TeamReconnectionDisplay, { joinAsPlayer: handleJoinAsPlayer, team: team, insideGameState: insideGameState }, index);
                        }) }))] }))] })));
}
export default ReconnectionPage;
