var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import WebcamComponent from "../../components/animator_components/WebcamComponent";
import PlayerDisplayComponent from "../../components/animator_components/PlayersDisplayComponent";
// import { playersInGame } from "../../MockData/Players";
import InformationPreGameComponent from "../../components/animator_components/InformationPreGameComponent";
import ETeam from "cresus-common/dist/dto/ETeam";
import ElementDistributionComponent from "../../components/animator_components/ElementsDistributionComponent";
import CigogneProjectsSelectionComponent from "../../components/animator_components/CigogneProjectsSelectionComponent";
import ProjectHolderComponent from "../../components/animator_components/ProjectHolderComponent";
import { useNavigate } from "react-router-dom";
import useSessionContext from "../../tools/useSessionContext";
import { getDefaultGameStateDTO } from "cresus-common/dist/dto/GameStateDTO";
import FunctionalButton from "../../components/FunctionalButton";
import ChatAnimatorComponent from "../../components/animator_components/ChatAnimatorComponent";
import { deepCopy } from "cresus-common/dist/utils/CopyUtils";
function DistibutionProjectPage() {
    const navigate = useNavigate();
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const [insideGameState, setInsideGameState] = useState(deepCopy(getDefaultGameStateDTO()));
    const [indexSelectedTeam, setIndexSelectedTeam] = useState(ETeam.NONE);
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);
    const [chatOn, setChatOn] = useState(false);
    const [paramOn, setParamOn] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(false);
    function getGameState() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("onConnectionOrReaload", { gameState: gameState })
                .catch((error) => { console.error("onConnectionOrReaload", error); });
            console.log("getGameState()", result);
            if (result !== undefined) {
                setInsideGameState(result);
            }
        });
    }
    function requestIncomeDistribution() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("distributeIncome", {})
                .catch((error) => { console.error("distributeIncome", error); });
            console.log("requestIncomeDistribution", result);
            // setInsideGameState(result);
        });
    }
    function requestPleasurePointsDistribution() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("distributePleasurePoints", {})
                .catch((error) => { console.error("distributePleasurePoints", error); });
            console.log("requestPleasurePointsDistribution", result);
            // setInsideGameState(result);
        });
    }
    function requestChargesDistribution() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("distributeCharges", {})
                .catch((error) => { console.error("distributeCharges", error); });
            console.log("requestChargesDistribution", result);
            // setInsideGameState(result);
        });
    }
    function requestProjectProposal(teamColor) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("requestProjectProposal", teamColor);
            const result = yield sendRequest("proposeProject", { teamColor: teamColor })
                .catch((error) => { console.error("proposeProject", error); });
            console.log(result, "hello project proposal for 1 team");
            // setInsideGameState(result);
        });
    }
    function requestValidateProject(team, project) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("validateProject", { teamColor: team.teamColor, project: project })
                .catch((error) => { console.error("validateProject", error); });
            console.log("requestValidateProject result:", result);
            // setInsideGameState(result);
        });
    }
    function startTheGameInitialisation(gameState) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("startTheGameInitialisation", {})
                .catch((error) => { console.error("startTheGameInitialisation", error); });
            console.log("startTheGameInitialisation", result);
            // setInsideGameState(result);
        });
    }
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "updateOfOneAvatar") {
            }
            if (notification.method === "updateGameState") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "distributeCharges") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "validateProject") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "proposeProject" || notification.method === "projectProposal") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "distributePleasurePoints") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "distributeIncome") {
                setInsideGameState(notification.data);
            }
            if (notification.method === "startTheGameInitialisation") {
                setInsideGameState(notification.data);
            }
        });
    }, []);
    useEffect(() => {
        console.log("insideGameState", insideGameState);
        if (insideGameState.gameStarted) {
            navigate("/AnimatorGamePage");
        }
    }, [insideGameState]);
    function handleClick() {
        startTheGameInitialisation(insideGameState);
    }
    function handleDistributeEarnings() {
        requestIncomeDistribution();
    }
    function handleDistributePleasurePoints() {
        requestPleasurePointsDistribution();
    }
    function handleDistributeCharges() {
        requestChargesDistribution();
    }
    function handleClickCigogne(teamClicked) {
        console.log("teamClicked checking", teamClicked);
        const foundTeamIndex = insideGameState.teams.findIndex(team => team.teamColor === teamClicked);
        setIndexSelectedTeam(foundTeamIndex);
    }
    function handleClickProjectProposal(team) {
        // const newDisttributionTeamsDTO = disttributionTeamsDTO.map(teamDTO => {
        //     if (teamDTO.team === team) {
        //         return {
        //             ...teamDTO,
        //             isProjectProposed: true,
        //         }
        //     }
        //     return teamDTO;
        // })
        // setDisttributionTeamsDTO(newDisttributionTeamsDTO);
        // requestProjectProposal(team);
    }
    useEffect(() => {
        getGameState();
    }, []);
    // useEffect(() => {
    //     // This has to be done in the useEffect to avoid the event to be subscribed multiple times
    //     websocketPeer?.on("notification",
    //         (notification) => {
    //             console.log("RECEIVED notification", notification.data)
    //             if (notification.method === "distributeCharges") {
    //                 setInsideGameState(notification.data);
    //             }
    //             if (notification.method === "validateProject") {
    //                 setInsideGameState(notification.data);
    //             }
    //             if (notification.method === "proposeProject") {
    //                 setInsideGameState(notification.data);
    //             }
    //             if (notification.method === "distributeCharges") {
    //                 setInsideGameState(notification.data);
    //             }
    //             if (notification.method === "distributePleasurePoints") {
    //                 setInsideGameState(notification.data);
    //             }
    //             if(notification.method === "distributeIncome") {
    //                 setInsideGameState(notification.data);
    //             }
    //             if (notification.method === "startTheGameInitialisation") {
    //                 setInsideGameState(notification.data);
    //             }
    //         });
    // }, []);
    useEffect(() => {
        console.log("indexSelectedTeam", indexSelectedTeam);
        console.log(currentSelectedTeam, "currentSelectedTeam");
    }, [indexSelectedTeam]);
    const allPlayers = insideGameState.teams.map(team => team.players).flat();
    const allActiveTeams = insideGameState.teams.filter(t => t.teamActive);
    const currentSelectedTeam = insideGameState.teams[indexSelectedTeam];
    const isReadyToStart = allActiveTeams.every(team => {
        return (team.project !== undefined &&
            team.money !== undefined &&
            team.charges && team.charges.length > 0 &&
            team.incomes && team.incomes.length > 0 &&
            team.pleasurePoints !== undefined);
    });
    function currentSelectedTeamHasProjectProposed() {
        return currentSelectedTeam && currentSelectedTeam.projectsProposed && currentSelectedTeam.projectsProposed.length > 0;
    }
    return (_jsxs("div", Object.assign({ className: "absolute w-screen flex h-screen bg-dilemmeSimplePurple" }, { children: [_jsx("div", Object.assign({ className: "flex absolute", style: { zIndex: chatOn ? 1000 : -1 } }, { children: _jsx(ChatAnimatorComponent, { newMessageAlert: setNewMessageAlert, isChatShown: chatOn, toggleChat: () => { setChatOn(!chatOn); setNewMessageAlert(false); } }) })), _jsxs("div", Object.assign({ className: "flex h-full flex-col", style: {
                    backgroundColor: "rgba(0, 0, 0, 0.50)",
                    width: "25%",
                    minWidth: "256px",
                    zIndex: 2,
                } }, { children: [_jsxs("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                            paddingLeft: "20px",
                            paddingTop: "33px",
                            height: "91px",
                            minHeight: "91px",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        } }, { children: [allPlayers.length, " joueurs dans ", _jsx("br", {}), " la salle d'attente"] })), _jsx("div", Object.assign({ className: "flex relative", style: {
                            paddingTop: "20px",
                            paddingRight: "10px",
                            height: "100%",
                            minHeight: "305px",
                            borderTop: "1px solid rgba(255, 255, 255, 0.20)",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                        } }, { children: _jsx("div", Object.assign({ className: "flex relative overflow-y-auto w-full flex-col", style: {
                                paddingLeft: "20px",
                                paddingRight: "8px",
                                height: "100%",
                                gap: "5px",
                            } }, { children: allPlayers.map((person, index) => {
                                return _jsx(PlayerDisplayComponent, { playerDTO: person }, "PlayerDisplayComponent" + index);
                            }) })) })), _jsx(WebcamComponent, { isCommunicationActive: true, isThereNotification: newMessageAlert, newMessageAlert: setNewMessageAlert, isChatActive: true, cameraOn: cameraOn, setCameraOn: setCameraOn, micOn: micOn, setMicOn: setMicOn, chatOn: chatOn, setChatOn: setChatOn, paramOn: paramOn, setParamOn: setParamOn })] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-start h-full w-full", style: {
                    paddingLeft: "50px",
                    paddingRight: "50px",
                } }, { children: [_jsx(InformationPreGameComponent, { buttonActive: isReadyToStart, textButton: "Lancer la session", onClickButton: () => { handleClick(); }, sessionName: insideGameState.animatorSessionName, counterEnded: true, counter: 2 }), _jsxs("div", Object.assign({ className: " flex flex-col justify-start w-full h-full" }, { children: [_jsx("div", Object.assign({ className: "flex text-white font-roboto w-full", style: {
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "28px",
                                    zIndex: 100
                                } }, { children: "\u00C9l\u00E9ments de jeu \u00E0 distribuer" })), _jsxs("div", Object.assign({ className: "flex flex-row justify-between w-full gap-[20px]" }, { children: [_jsx(ElementDistributionComponent, { isDistributable: allActiveTeams.length > 0 && !!allActiveTeams[0].money, textToDistribute: "Revenus", handleClick: () => handleDistributeEarnings() }), _jsx(ElementDistributionComponent, { isDistributable: allActiveTeams.length > 0 && !!allActiveTeams[0].pleasurePoints, textToDistribute: "Points Plaisir", handleClick: () => handleDistributePleasurePoints() }), _jsx(ElementDistributionComponent, { isDistributable: allActiveTeams.length > 0 && allActiveTeams[0].charges ? allActiveTeams.length > 0 && allActiveTeams[0].charges.length > 0 : false, textToDistribute: "Charges", handleClick: () => handleDistributeCharges() })] })), _jsxs("div", Object.assign({ className: "flex flex-col pb-[20px]" }, { children: [_jsx("div", Object.assign({ className: "flex pt-[40px] gap-[20px] justify-around overflow-hidden" }, { children: insideGameState.teams.map((team, index) => {
                                            return _jsx(CigogneProjectsSelectionComponent, { isEnable: team.teamActive, isProjectDistributed: !!team.project, isActive: index === indexSelectedTeam, handleClick: handleClickCigogne, team: team.teamColor }, index);
                                        }) })), _jsx("img", { src: "/assets/UI/line.png", alt: "", className: "flex w-full h-[2px]" })] })), _jsxs("div", Object.assign({ className: "flew relative w-full" }, { children: [currentSelectedTeam && !currentSelectedTeamHasProjectProposed() &&
                                        _jsx("div", Object.assign({ className: "flex justify-center font-roboto text-white items-center", style: {
                                                minWidth: "668px",
                                                minHeight: "139px",
                                                width: "100%",
                                                backgroundColor: "rgba(0, 0, 0, 0.30)",
                                                borderRadius: "4px",
                                                border: "2px dashed black",
                                            } }, { children: _jsx(FunctionalButton, { text: "tirer les projets", onClick: () => { requestProjectProposal(currentSelectedTeam.teamColor); }, isRound: false, fontSize: "12px" }) })), currentSelectedTeam && currentSelectedTeamHasProjectProposed() &&
                                        _jsx("div", Object.assign({ className: "flex absolute w-full", style: {
                                                zIndex: 2
                                            } }, { children: _jsx(ProjectHolderComponent, { team: currentSelectedTeam, handleClickProjectProposal: () => handleClickProjectProposal(currentSelectedTeam.teamColor), handleClickProjectSelection: requestValidateProject }) }))] }))] }))] }))] })));
}
export default DistibutionProjectPage;
