import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
function ItemsImageComponent() {
    const [imagesName, setImagesName] = useState(["skin", "hair", "hair_color", "eyes", "nose", "mouth", "bonus"]);
    return (_jsx("div", Object.assign({ className: "flex flex-col gap-[10px] absolute h-full w-[50px]", style: {
            right: 10,
        } }, { children: imagesName.map((name) => {
            return (_jsx("div", { className: "h-[50px] w-full flex", style: {
                    backgroundImage: `url(./assets/UI/avatar_composition/picto/${name}.png)`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                } }));
        }) })));
}
export default ItemsImageComponent;
