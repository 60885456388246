import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ButtonHeadBarComponent from "./ButtonHeadBarComponent";
function HeadBarComponent(props) {
    const [isButtonActivatedArray, setIsButtonActivatedArray] = useState(props.buttonsHeadBar);
    // const [headBarEnum, setHeadBarEnum] = useState<EHeadBar>(props.headBarEnum);
    useEffect(() => {
        setIsButtonActivatedArray(props.buttonsHeadBar);
    }, [props.buttonsHeadBar]);
    return (_jsxs("div", Object.assign({ className: "flex  justify-between", style: {
            minWidth: 748,
            maxWidth: 950,
            width: "100%",
            height: 50,
            padding: "5px",
            backgroundColor: "rgba(12, 21, 51, 1)"
        } }, { children: [_jsx("div", Object.assign({ className: " flex gap-2 text-white", style: {
                    height: "100%",
                    width: "auto",
                } }, { children: isButtonActivatedArray.map((button) => {
                    if (button.text !== "")
                        return _jsx(ButtonHeadBarComponent, { name: button.name, handleClick: () => props.handleClick(button.name), isActivated: button.isActivated, title: button.text });
                    else
                        return (_jsx("div", {}));
                }) })), _jsx("div", Object.assign({ className: " flex gap-2 text-white w-auto h-full" }, { children: isButtonActivatedArray.map((button) => {
                    if (button.text === "") {
                        return _jsx(ButtonHeadBarComponent, { name: button.name, handleClick: () => props.handleClick(button.name), isActivated: button.isActivated });
                    }
                    else if (button.name === "next turn") {
                        return _jsx(ButtonHeadBarComponent, { isEnable: props.isNextTurnPossible, name: button.name, handleClick: () => props.handleClick(button.name), isActivated: button.isActivated });
                    }
                    else {
                        return (_jsx("div", {}));
                    }
                }) }))] })));
}
export default HeadBarComponent;
