"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiceEventSchema = void 0;
const zod_1 = require("zod");
const DiceEventSchema = zod_1.z.object({
    dices: zod_1.z.array(zod_1.z.number()),
    ShouldDiceBeRolled: zod_1.z.boolean(),
    AreDicesRolled: zod_1.z.boolean(),
});
exports.DiceEventSchema = DiceEventSchema;
