"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
///
/// Used to define user role in a session
///
var EPlayerRole;
(function (EPlayerRole) {
    EPlayerRole[EPlayerRole["ANIMATOR"] = 0] = "ANIMATOR";
    EPlayerRole[EPlayerRole["PLAYER"] = 1] = "PLAYER";
})(EPlayerRole || (EPlayerRole = {}));
exports.default = EPlayerRole;
