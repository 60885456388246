import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function IncomeComponent(props) {
    return (_jsxs("div", Object.assign({ className: "flex flex-row w-full justify-between fontIncomeName" }, { children: [_jsx("div", Object.assign({ className: "flex", style: {
                    width: "auto",
                    // fontSize: "1.3cqw",
                    fontStyle: "normal",
                    // lineHeight: "1.8cqw",
                    fontWeight: "400",
                } }, { children: props.titleOfIncome })), _jsxs("div", Object.assign({ className: "flex self-end fontIncomeValue", style: {
                    width: "auto",
                    // fontSize: "1.5cqw",
                    fontStyle: "normal",
                    // lineHeight: "1.8cqw",
                    fontWeight: "700",
                } }, { children: [props.income.toString(), " \u20AC"] }))] })));
}
export default IncomeComponent;
