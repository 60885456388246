import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import FunctionalButton from "../FunctionalButton";
import CheckedIcon from "../icons/CheckedIcon";
function ElementDistributionComponent(props) {
    const [isDistributable, setIsDistributable] = useState(props.isDistributable);
    useEffect(() => {
        setIsDistributable(props.isDistributable);
    }, [props.isDistributable]);
    return (_jsxs("div", Object.assign({ className: "flex text-white text-center flex-col ", style: {
            minHeight: "77px",
            minWidth: "208px",
            width: "100%",
            padding: "10px 0px",
            backgroundColor: "rgba(0, 0, 0, 0.50)",
            borderRadius: "4px",
        } }, { children: [_jsx("div", Object.assign({ className: "flex justify-center items-center pb-2 font-roboto", style: {
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "19px",
                } }, { children: props.textToDistribute })), !isDistributable &&
                _jsx("div", Object.assign({ className: " flex justify-center", style: { zIndex: 100 } }, { children: _jsx(FunctionalButton, { text: "Distribuer", onClick: props.handleClick }) })), isDistributable &&
                _jsx("div", Object.assign({ className: "flex justify-center items-center self-center", style: {
                        borderRadius: "50%",
                        width: "24px",
                        height: "24px",
                        border: "1px solid black",
                        padding: "3px",
                        backgroundColor: "rgba(71, 185, 105, 1)"
                    } }, { children: _jsx(CheckedIcon, {}) }))] })));
}
export default ElementDistributionComponent;
