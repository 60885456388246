var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CustomRadioButton from "../../CustomRadioButton";
import FunctionalButtonBlueFound from "../../FonctionnalButtonBlueFoundComponent";
import HandleTeamDiceThrow from "./HandleTeamDiceThrow";
import ETeam from "cresus-common/dist/dto/ETeam";
import TeamsSelectionInGameTag from "./TeamsSelectionInGameTag";
import useSessionContext from "../../../tools/useSessionContext";
function DiceHandlerComponent(props) {
    const { sendRequest, websocketPeer, gameState } = useSessionContext();
    const [isActive, setIsActive] = useState(props.isActive);
    const [mustDicesBeLunched, setMustDicesBeLunched] = useState(gameState.isThereThrownEvent);
    const [teamsActiveInGame, setTeamsActiveInGame] = useState([false, false, false, false]);
    const [teamsSelected, setTeamsSelected] = useState([false, false, false, false].map((bool, index) => {
        if (props.gameState.teams.includes(props.gameState.teams[index]) && props.gameState.teams[index].dicesEvent.ShouldDiceBeRolled === true) {
            return true;
        }
        else {
            return false;
        }
    }));
    const [dices, setDices] = useState([0, 0]);
    const [makeNewThrow, setMakeNewThrow] = useState(false);
    function checkIfReadyToRethrow() {
        setMakeNewThrow(true);
        props.gameState.teams.forEach((team) => {
            if (team.dicesEvent.AreDicesRolled === false && team.dicesEvent.ShouldDiceBeRolled === true) {
                setMakeNewThrow(false);
            }
        });
        // console.log("isReady", makeNewThrow, props.gameState.teams);
    }
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "makeTeamsThrowDicesEvent") {
                console.log("makeTeamsThrowDicesEvent", notification);
                props.setInsideGameStates(notification.data);
                const buffTeamSelected = [false, false, false, false];
                notification.data.teams.forEach((team) => {
                    if (team.dicesEvent.ShouldDiceBeRolled === true) {
                        buffTeamSelected[team.teamColor] = true;
                    }
                });
                setTeamsSelected(buffTeamSelected);
            }
            if (notification.method === "makeOneTeamThrowDicesEvent") {
                console.log("makeOneTeamThrowDicesEvent", notification);
                props.setInsideGameStates(notification.data);
            }
            if (notification.method === "makeNewEventThrow") {
                props.setInsideGameStates(notification.data);
                setTeamsSelected([false, false, false, false]);
            }
        });
    }, []);
    function handleMakeTeamsThrowDicesEvent() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("makeTeamsThrowDicesEvent", {
                teams: props.gameState.teams.filter((team) => teamsSelected[team.teamColor]).map((team) => team.teamColor),
                dices: dices
            }).catch((error) => { console.log("makeTeamsThrowDicesEvent", error); });
            if (result !== undefined) {
                // props.setInsideGameStates(result);
                console.log("dice throwed", result);
                // setMustDicesBeLunched(true);
                // setMakeNewThrow(false);
            }
        });
    }
    function handleDiceEventThrow(teamToThrow) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("makeOneTeamThrowDicesEvent", {
                teamToThrow: teamToThrow,
            }).catch((error) => { console.log("makeOneTeamThrowDicesEvent", error); });
            if (result !== undefined) {
                console.log("dice throwed once", result);
                //    props.setInsideGameStates(result);
            }
        });
    }
    function handleNewEventThrow() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("makeNewEventThrow").catch((error) => { console.error("makeNewEventThrow", error); });
            if (result !== undefined) {
                console.log("dice throwed", result);
                //    props.setInsideGameStates(result);
                //    setTeamsSelected([false, false, false, false]);
            }
        });
    }
    function handleSelectNumberEventDices(numberOfDices) {
        if (numberOfDices === 1) {
            setDices([0, -1]);
        }
        else if (numberOfDices === 2) {
            setDices([0, 0]);
        }
        else {
            console.log("error");
            setDices([-1, -1]);
        }
    }
    function handleSelectionTeamToLunchDices(team) {
        const buffTeamsSelected = [...teamsSelected];
        if (team === ETeam.RED) {
            buffTeamsSelected[0] = !buffTeamsSelected[0];
        }
        else if (team === ETeam.BLUE) {
            buffTeamsSelected[1] = !buffTeamsSelected[1];
        }
        else if (team === ETeam.YELLOW) {
            buffTeamsSelected[2] = !buffTeamsSelected[2];
        }
        else if (team === ETeam.GREEN) {
            buffTeamsSelected[3] = !buffTeamsSelected[3];
        }
        setTeamsSelected(buffTeamsSelected);
        console.log(teamsSelected);
    }
    useEffect(() => {
        const buffTeamActive = [...teamsActiveInGame];
        props.gameState.teams.forEach((team) => {
            if (team.teamColor === ETeam.RED) {
                buffTeamActive[0] = true;
            }
            else if (team.teamColor === ETeam.BLUE) {
                buffTeamActive[1] = true;
            }
            else if (team.teamColor === ETeam.YELLOW) {
                buffTeamActive[2] = true;
            }
            else if (team.teamColor === ETeam.GREEN) {
                buffTeamActive[3] = true;
            }
        });
        setTeamsActiveInGame(buffTeamActive);
        setMustDicesBeLunched(gameState.isThereThrownEvent);
        checkIfReadyToRethrow();
    }, [props.gameState.teams]);
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    return (_jsxs("div", Object.assign({ 
        // hidden={true}
        className: `absolute bg-dilemmeDeepPurpple w-full justify-start  font-roboto flex-col gap-[20px]`, style: {
            display: isActive ? "flex" : "none",
            padding: "20px 10px",
            minWidth: 748,
            maxWidth: 950,
            height: "auto",
            maxHeight: "100%",
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            color: isActive ? "white" : "transparent",
            zIndex: isActive ? "1" : "0",
        } }, { children: [!props.gameState.isThereThrownEvent &&
                _jsxs("div", Object.assign({ className: "flex flex-col gap-[20px] " }, { children: [_jsx("span", Object.assign({ className: "flex self-center font-roboto", style: {
                                textTransform: "uppercase",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "19px",
                                color: "#FFFFFF",
                            } }, { children: "configurer le lancer de d\u00E9s pour les \u00E9quipes" })), _jsxs("div", Object.assign({ className: "flex flex-col gap-[10px] items-center" }, { children: [_jsx("span", Object.assign({ className: "flex self-center font-roboto", style: {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "16px",
                                        color: "#FFFFFF",
                                    } }, { children: "\u00C9quipes qui lancent les d\u00E9s" })), _jsx(TeamsSelectionInGameTag, { handleTeamInactive: handleSelectionTeamToLunchDices, teamsActiveInGame: teamsActiveInGame, teamsSelected: teamsSelected })] })), _jsxs("div", Object.assign({ className: "flex flex-col gap-[10px]" }, { children: [_jsx("span", Object.assign({ className: "flex self-center font-roboto", style: {
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "16px",
                                        color: "#FFFFFF",
                                    } }, { children: "Nombre de d\u00E9s" })), _jsxs("div", Object.assign({ className: "flex gap-[20px] self-center" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [_jsx(CustomRadioButton, { mode: 1, isActivated: true, handleRadioButtonCLick: () => handleSelectNumberEventDices(1), isSelected: dices.filter(dice => dice >= 0).length === 1 }), _jsx("span", Object.assign({ style: { fontWeight: false ? 600 : 500 } }, { children: "1" }))] })), _jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [_jsx(CustomRadioButton, { mode: 1, isActivated: true, handleRadioButtonCLick: () => handleSelectNumberEventDices(2), isSelected: dices.filter(dice => dice >= 0).length === 2 }), _jsx("span", Object.assign({ style: { fontWeight: true ? 600 : 500 } }, { children: "2" }))] }))] }))] })), _jsx("div", Object.assign({ className: "flex items-center self-center" }, { children: _jsx(FunctionalButtonBlueFound, { text: "Faire lancer les d\u00E9s", enabled: true, colorMode: 0, onClick: handleMakeTeamsThrowDicesEvent }) }))] })), props.gameState.isThereThrownEvent &&
                _jsxs("div", Object.assign({ className: "flex flex-col gap-[20px] " }, { children: [_jsx("div", Object.assign({ className: "flex gap-[20px] items-center justify-center" }, { children: props.gameState.teams.map((team) => {
                                return (_jsx(HandleTeamDiceThrow, { team: team.teamColor, AreDicesThrown: team.dicesEvent.AreDicesRolled, dices: team.dicesEvent.dices, handleClickRollDice: handleDiceEventThrow, isChoosen: teamsSelected[team.teamColor] }));
                            }) })), _jsx("div", Object.assign({ className: "flex items-center self-center" }, { children: _jsx(FunctionalButtonBlueFound, { text: "Faire un autre lancer", enabled: makeNewThrow, colorMode: 0, onClick: handleNewEventThrow }) }))] }))] })));
}
export default DiceHandlerComponent;
