var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import FunctionalButtonBlueFound from "../../FonctionnalButtonBlueFoundComponent";
import CardsTypeIcon from "../../icons/CardTypeIcon";
import ECategoriesCards from "cresus-common/dist/dto/ECategoriesCards";
import { DropdownList } from "react-widgets/cjs";
import DropdownArrowIcon from "../../icons/DropdownArrowIcon";
import CardComponent, { cardCategoryToText, textToCardCategory } from "./CardComponent";
import { switchTypeToColor, switchTypeToTextSingular } from "../../../tools/switchToCards";
import ESlotType from "cresus-common/dist/dto/ESlotType";
import ECardHandler from "cresus-common/dist/dto/ECardHandler";
import switchToTeamName from "../../../tools/switchColorToNameTeam";
import useSessionContext from "../../../tools/useSessionContext";
function getAvailableThemes(typeOfCard) {
    switch (typeOfCard) {
        case ETypeCards.QUESTION:
            return [
                ECategoriesCards.ASSURANCE,
                ECategoriesCards.Q_ECONOMIE,
                ECategoriesCards.BANQUE,
                ECategoriesCards.CREDIT,
                ECategoriesCards.EPARGNE,
                ECategoriesCards.DROIT,
                ECategoriesCards.BUDGET,
                ECategoriesCards.JEU,
            ];
        case ETypeCards.PLAISIR:
            return [];
        case ETypeCards.EVENT:
            return [
                ECategoriesCards.CHANCE,
                ECategoriesCards.DEPENSE,
                ECategoriesCards.DILEMME,
                ECategoriesCards.ECONOMIE,
            ];
        default:
            return [];
    }
}
// TODO ranger fonction utilitaire ailleurs
function switchSlotTypeToTypeOfCard(slotType) {
    switch (slotType) {
        case ESlotType.DILEMME:
            return ETypeCards.EVENT;
        case ESlotType.ACCES_SOINS:
            return ETypeCards.QUESTION;
        case ESlotType.ETUDES:
            return ETypeCards.QUESTION;
        case ESlotType.QUESTION:
            return ETypeCards.QUESTION;
        case ESlotType.HUISSIER:
            return ETypeCards.QUESTION;
        case ESlotType.PLAISIR:
            return ETypeCards.PLAISIR;
        case ESlotType.SHOPPING:
            return ETypeCards.QUESTION;
        case ESlotType.TAXES:
            return ETypeCards.QUESTION;
        case ESlotType.TRANSPORT:
            return ETypeCards.QUESTION;
        default:
            console.log("error not a slotType");
            return ETypeCards.QUESTION;
    }
}
const defaultCardsProposed = [{
        isSelected: false,
        isAnswerCorrect: false,
        id: 0,
        reference: "DECK-BASE",
        title: "Lave-linge en panne",
        type: ETypeCards.EVENT,
        category: ECategoriesCards.DEPENSE,
        difficulty: 0,
        textPlayer: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
        textAnimator: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
        version: 1,
        short_description: "Remboursement Lave-linge - 220€",
        is_kept_card: true,
        typeSpecificContent: "test",
        comment: "test"
    },
    {
        isSelected: false,
        isAnswerCorrect: false,
        id: 0,
        reference: "DECK-BASE",
        title: "Lave-linge en panne",
        type: ETypeCards.EVENT,
        category: ECategoriesCards.DEPENSE,
        difficulty: 0,
        textPlayer: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
        textAnimator: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
        version: 1,
        short_description: "Remboursement Lave-linge - 220€",
        is_kept_card: true,
        typeSpecificContent: "test",
        comment: "test"
    },
    {
        isSelected: false,
        isAnswerCorrect: false,
        id: 0,
        reference: "DECK-BASE",
        title: "Lave-linge en panne",
        type: ETypeCards.EVENT,
        category: ECategoriesCards.DEPENSE,
        difficulty: 0,
        textPlayer: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
        textAnimator: "Votre lave-linge tombe en panne !\r\nPerdez 1 Point Plaisir et payez 200€ pour le faire réparer.\r\nSi vous n'avez pas les moyens, vous appelez votre banque qui vous autorise un découvert de 200€. Ces 200€ seront prévelevés de votre prochain salaire avec 20€ de frais. Concervez cette carte jusqu'à la fin du mois. ",
        version: 1,
        short_description: "Remboursement Lave-linge - 220€",
        is_kept_card: true,
        typeSpecificContent: "test",
        comment: "test"
    }];
// type CardChoosable = {
//     isSelected: boolean;
//     isAnswereCorrect: boolean;
//     card: CardDTO;
// }
function CardsHandlerComponent(props) {
    const { sendRequest, websocketPeer } = useSessionContext();
    const [team, setTeam] = useState(props.teamToPlay.teamColor);
    const [slotType, setSlotType] = useState(props.teamToPlay.slotTeam.slotType);
    const [isActive, setIsActive] = useState(props.isActive);
    const [cardHandlerState, setCardHandlerState] = useState(props.teamToPlay.cardHandlerEnum);
    const [availableThemes, setAvailableThemes] = useState([]);
    //  const [cardSelected, setCardSelected] = useState<CardChoiceDTO>(props.cardSelected);
    const [cardSelected, setCardSelected] = useState(props.teamToPlay.cardSelected);
    const [categoryChoosenDropdown, setCategoryChoosenDropdown] = useState("");
    const [cardsProposed, setCardsProposed] = useState(defaultCardsProposed);
    const refTheme = useRef();
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    useEffect(() => {
        // setTurnState(props.teamToPlay.turnState);
        setCardHandlerState(props.teamToPlay.cardHandlerEnum);
        // setCardState(props.teamToPlay.cardState);
        setTeam(props.teamToPlay.teamColor);
        setSlotType(props.teamToPlay.slotTeam.slotType);
        setCardsProposed(props.teamToPlay.cardProposed);
        setCardSelected(props.teamToPlay.cardSelected);
        const availableThemes = getAvailableThemes(switchSlotTypeToTypeOfCard(props.teamToPlay.slotTeam.slotType));
        setAvailableThemes(availableThemes.map((theme) => cardCategoryToText(theme)));
        console.log("props.teamToPlay ", props.teamToPlay);
    }, [props.teamToPlay]);
    useEffect(() => {
        if (props.teamToPlay.cardHandlerEnum !== ECardHandler.MUST_CHOOSE_CARD) {
            refTheme.current = "";
            setCategoryChoosenDropdown("");
        }
    }, [props.teamToPlay]);
    useEffect(() => {
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            if (notification.method === "pickNewCards") {
                props.setInsideGameState(notification.data);
            }
            if (notification.method === "chooseCard") {
                props.setInsideGameState(notification.data);
            }
            if (notification.method === "correctQuestionCard") {
                props.setInsideGameState(notification.data);
            }
        });
    }, []);
    function handlePickNewCards(typeOfCard, category, teamColor) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("pickNewCards", {
                teamColor: teamColor,
                typeOfCard: typeOfCard,
                category: textToCardCategory(category)
            }).catch((error) => { console.log("pickNewCards", error); });
            if (result !== undefined) {
                // props.setInsideGameState(result)
                console.log("result ", result);
            }
        });
    }
    function handleChooseCard(cardChoosen, teamColor) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("chooseCard", {
                teamColor: teamColor,
                cardChoosen: cardChoosen
            }).catch((error) => { console.log("chooseCard", error); });
            if (result !== undefined) {
                // props.setInsideGameState(result as GameStateDTO)
                console.log("result i got the choose card", result);
            }
        });
    }
    function handleChangeOfSelectedCategory(value) {
        refTheme.current = value;
        setCategoryChoosenDropdown(value);
    }
    function handleRadioButtonCLick(card, index) {
        console.log("handleRadioButtonCLick ", card);
        const buff = Object.assign({}, card);
        buff.isSelected = true;
        setCardSelected(buff);
        const newCardsProposed = [...cardsProposed];
        newCardsProposed.forEach((card, indexOfCard) => {
            if (indexOfCard !== index) {
                card.isSelected = false;
            }
            else {
                card.isSelected = true;
            }
        });
        setCardsProposed(newCardsProposed);
    }
    function handleCorrection(isCorrect, teamColorOfTeam) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("correctQuestionCard", {
                teamColor: teamColorOfTeam,
                correctedAnswer: isCorrect
            }).catch((error) => { console.log("correctQuestionCard", error); });
            if (result !== undefined) {
                // props.setInsideGameState(result as GameStateDTO)
                console.log("result i got the correction card", result);
            }
            // buff.isAnswereCorrect = isCorrect ? 1 : 0
        });
    }
    function handleSlotTypeComponent(slotType) {
        let component = _jsx("div", {});
        if (slotType === ESlotType.QUESTION || slotType === ESlotType.DILEMME || slotType === ESlotType.PLAISIR) {
            component = _jsxs("div", Object.assign({ className: "flex gap-[10px] items-center" }, { children: [_jsx(CardsTypeIcon, { color: switchTypeToColor(switchSlotTypeToTypeOfCard(slotType)) }), _jsxs("div", { children: ["L'\u00E9quipe ", switchToTeamName(team), " a tir\u00E9 une carte ", _jsx("b", { children: switchTypeToTextSingular(switchSlotTypeToTypeOfCard(slotType)) }), "."] })] }));
        }
        else {
            component = _jsxs("div", Object.assign({ className: "flex gap-[10px] items-center" }, { children: [_jsx(CardsTypeIcon, { color: switchTypeToColor(switchSlotTypeToTypeOfCard(slotType)) }), _jsxs("div", { children: ["Faire tirer une carte question \u00E0 l'\u00E9quipe ", switchToTeamName(team), "."] })] }));
        }
        return component;
    }
    return (_jsxs("div", Object.assign({ className: `absolute bg-dilemmeDeepPurpple w-full  font-roboto items-center`, style: {
            display: isActive ? "flex" : "none",
            padding: "20px 10px",
            minWidth: 748,
            maxWidth: 950,
            height: "auto",
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            color: isActive ? "white" : "transparent",
            zIndex: isActive ? "1" : "0",
        } }, { children: [
            // cardState === ECardStateGame.NO_CARDS_CHOOSABLE
            cardHandlerState === ECardHandler.NO_ACTION_REQUIRED &&
                _jsx("div", Object.assign({ className: "flex w-full justify-center items-center text-center self-center" }, { children: "Aucune carte n'a \u00E9t\u00E9 tir\u00E9e pour le moment" })), 
            //cardState === ECardStateGame.CARDS_CHOOSABLE && 
            cardHandlerState === ECardHandler.MUST_CHOOSE_CARD && cardsProposed.length > 0 &&
                //TODO variante avec case pas carte mais doit pouvoir piocher une question 
                // ((slotType === ESlotType.QUESTION) || (slotType === ESlotType.DILEMME) || (slotType === ESlotType.PLAISIR) ) && 
                _jsxs("div", Object.assign({ className: "flex w-full flex-col gap-[20px]", style: {
                    // backgroundColor: isActive ? "transparent" : "white",
                    } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [handleSlotTypeComponent(slotType), _jsx(FunctionalButtonBlueFound, { text: "Choisir", fontSize: "12px", enabled: cardsProposed.filter(cards => cards.isSelected === true).length > 0, onClick: () => { handleChooseCard(cardSelected, team); } })] })), _jsxs("div", Object.assign({ className: "flex gap-[20px] items-center" }, { children: [_jsx(DropdownList, { data: availableThemes, onChange: (value) => { handleChangeOfSelectedCategory(value); }, placeholder: "", selectIcon: _jsx(DropdownArrowIcon, { color: "white" }), containerClassName: "dropdown_customized" }), _jsx(FunctionalButtonBlueFound, { enabled: cardsProposed[0].type === ETypeCards.PLAISIR ? true : categoryChoosenDropdown !== "", onClick: () => handlePickNewCards(cardsProposed[0].type, refTheme.current, team), text: "Tirer 3 autres cartes", colorMode: 1, fontSize: "12px" })] })), _jsxs("div", Object.assign({ className: "flex flex-col w-full gap-2" }, { children: [_jsx(CardComponent, { handleRadioButtonClick: () => { handleRadioButtonCLick(cardsProposed[0], 0); }, isChoosen: false, card: cardsProposed[0], isSelected: cardsProposed[0].isSelected, isActive: props.isActive }), _jsx(CardComponent, { handleRadioButtonClick: () => { handleRadioButtonCLick(cardsProposed[1], 1); }, isChoosen: false, card: cardsProposed[1], isSelected: cardsProposed[1].isSelected, isActive: props.isActive }), _jsx(CardComponent, { handleRadioButtonClick: () => { handleRadioButtonCLick(cardsProposed[2], 2); }, isChoosen: false, card: cardsProposed[2], isSelected: cardsProposed[2].isSelected, isActive: props.isActive })] }))] })), 
            // cardState === ECardStateGame.CARDS_CHOSEN 
            // && cardSelected.isAnswereCorrect === -1 
            cardHandlerState === ECardHandler.MUST_CORRECT_CARD && cardSelected
                &&
                    _jsxs("div", Object.assign({ className: "flex p-[20px] gap-[20px] flex-col w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-[10px] items-center" }, { children: [_jsx(CardsTypeIcon, { color: switchTypeToColor(switchSlotTypeToTypeOfCard(slotType)) }), _jsxs("div", { children: ["L'\u00E9quipe ", switchToTeamName(team), " a tir\u00E9 une carte ", _jsx("b", { children: switchTypeToTextSingular(switchSlotTypeToTypeOfCard(slotType)) }), "."] })] })), _jsx("div", Object.assign({ className: "flex gap-[10px]" }, { children: _jsx(CardComponent, { handleRadioButtonClick: () => { }, isChoosen: true, card: cardSelected, isSelected: true, isActive: props.isActive }) })), cardSelected.type === ETypeCards.QUESTION &&
                                _jsxs("div", Object.assign({ className: "flex items-center gap-[10px] flex-col font-roboto", style: {
                                        fontSize: "14px",
                                    } }, { children: ["La r\u00E9ponse de l'\u00E9quipe est :", _jsxs("div", Object.assign({ className: "flex items-center gap-[15px]" }, { children: [_jsx(FunctionalButtonBlueFound, { text: "Bonne", colorMode: 1, fontSize: "12px", onClick: () => { handleCorrection(true, team); } }), _jsx(FunctionalButtonBlueFound, { text: "Mauvaise", colorMode: 1, fontSize: "12px", onClick: () => { handleCorrection(false, team); } })] }))] }))] })), 
            //cardState === ECardStateGame.CARDS_CHOSEN && cardSelected.isAnswereCorrect !== -1
            cardHandlerState === ECardHandler.CARD_HANDLED && cardSelected && cardSelected.type === ETypeCards.QUESTION
                &&
                    _jsxs("div", Object.assign({ className: "flex p-[20px] gap-[20px] flex-col w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-[10px] items-center" }, { children: [_jsx(CardsTypeIcon, { color: switchTypeToColor(switchSlotTypeToTypeOfCard(slotType)) }), _jsxs("div", { children: ["L'\u00E9quipe ", switchToTeamName(team), " a tir\u00E9 une carte ", _jsx("b", { children: switchTypeToTextSingular(switchSlotTypeToTypeOfCard(slotType)) }), " et a donn\u00E9 la ", cardSelected.isAnswerCorrect ? "bonne" : "mauvaise", " r\u00E9ponse."] })] })), _jsx("div", Object.assign({ className: "flex gap-[10px] w-full" }, { children: _jsx(CardComponent, { handleRadioButtonClick: () => { }, isChoosen: true, card: cardSelected, isSelected: true, isActive: props.isActive }) }))] })), cardHandlerState === ECardHandler.CARD_HANDLED && cardSelected && (cardSelected.type !== ETypeCards.QUESTION)
                &&
                    _jsxs("div", Object.assign({ className: "flex p-[20px] gap-[20px] flex-col w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-[10px] items-center" }, { children: [_jsx(CardsTypeIcon, { color: switchTypeToColor(switchSlotTypeToTypeOfCard(slotType)) }), _jsxs("div", { children: ["L'\u00E9quipe ", switchToTeamName(team), " a tir\u00E9 une carte ", _jsx("b", { children: switchTypeToTextSingular(switchSlotTypeToTypeOfCard(slotType)) }), "."] })] })), _jsx("div", Object.assign({ className: "flex gap-[10px] w-full" }, { children: _jsx(CardComponent, { handleRadioButtonClick: () => { }, isChoosen: true, card: cardSelected, isSelected: true, isActive: props.isActive }) }))] }))] })));
}
export default CardsHandlerComponent;
