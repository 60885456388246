import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
function ProjectProposalsComponent(props) {
    var _a, _b, _c, _d, _e, _f;
    function testIfUndefined() {
        let isUndefined = false;
        props.projects.forEach((project) => {
            if (project.content === undefined) {
                isUndefined = true;
            }
        });
        return isUndefined;
    }
    return (_jsx(_Fragment, { children: !testIfUndefined() &&
            _jsxs("div", Object.assign({ className: "flex fixed w-screen h-screen flex-row  justify-center z-0", style: {
                    padding: "15%",
                    zIndex: 80,
                } }, { children: [_jsx("div", Object.assign({ className: "flex relative justify-center self-center items-center postItWaitingRoom", style: {
                            minHeight: 232,
                            minWidth: 232,
                            maxHeight: 232 * 2,
                            maxWidth: 232 * 2,
                            // padding: 30,
                            zIndex: 1,
                            rotate: "3deg",
                        } }, { children: _jsx("div", Object.assign({ className: "flex absolute flex-col postItWaitingRoom positionPostItOne", style: {
                                minHeight: 232,
                                minWidth: 232,
                                maxHeight: 232 * 2,
                                maxWidth: 232 * 2,
                                // marginRight: "45cqw",
                                rotate: "3deg",
                                backgroundImage: "url(./assets/UI/post_its/post_it.png)",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                zIndex: 0,
                            } }, { children: _jsxs("div", Object.assign({ className: "w-full h-full flex relative font-kalam text-center justify-center flex-col postItProposal", style: {
                                // paddingTop: "27cqw",
                                // paddingBottom: "23cqw",
                                // paddingLeft: "12cqw",
                                // paddingRight: "12cqw",
                                } }, { children: [_jsx("div", Object.assign({ className: "w-full h-full flex relative font-kalam text-center justify-center", style: {
                                            // fontSize: "6.9cqw",
                                            // lineHeight: "6.9cqw",
                                            fontWeight: 400,
                                            top: 0,
                                            rotate: "-3deg",
                                        } }, { children: (_a = props.projects[0]) === null || _a === void 0 ? void 0 : _a.content })), _jsxs("div", Object.assign({ className: "w-full h-full flex relative justify-center items-end font-kalam fontSizePricePostit", style: {
                                            // fontSize: "13cqw",
                                            // lineHeight: "13cqw",
                                            fontWeight: 700,
                                            rotate: "-3deg",
                                            bottom: 0,
                                        } }, { children: [(_b = props.projects[0]) === null || _b === void 0 ? void 0 : _b.price, " \u20AC"] }))] })) })) })), _jsx("div", Object.assign({ className: "flex relative justify-center self-center items-center postItWaitingRoom", style: {
                            minHeight: 232,
                            minWidth: 232,
                            maxHeight: 232 * 2,
                            maxWidth: 232 * 2,
                            // padding: 30,
                            zIndex: 1,
                            rotate: "0deg",
                        } }, { children: _jsx("div", Object.assign({ className: "flex absolute flex-col postItWaitingRoom", style: {
                                minHeight: 232,
                                minWidth: 232,
                                maxHeight: 232 * 2,
                                maxWidth: 232 * 2,
                                rotate: "-10deg",
                                transform: "scaleX(-1)",
                                backgroundImage: "url(./assets/UI/post_its/post_it.png)",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                zIndex: 0,
                            } }, { children: _jsxs("div", Object.assign({ className: "w-full h-full flex relative font-kalam text-center justify-center flex-col postItProposal", style: {
                                    // paddingTop: "27cqw",
                                    // paddingBottom: "23cqw",
                                    // paddingLeft: "12cqw",
                                    // paddingRight: "12cqw",
                                    transform: "scaleX(-1)",
                                } }, { children: [_jsx("div", Object.assign({ className: "w-full h-full flex relative font-kalam text-center justify-center", style: {
                                            // fontSize: "6.9cqw",
                                            // lineHeight: "6.9cqw",
                                            fontWeight: 400,
                                            top: 0,
                                            rotate: "10deg",
                                        } }, { children: (_c = props.projects[1]) === null || _c === void 0 ? void 0 : _c.content })), _jsxs("div", Object.assign({ className: "w-full h-full flex relative justify-center items-end font-kalam fontSizePricePostit", style: {
                                            // fontSize: "13cqw",
                                            // lineHeight: "13cqw",
                                            fontWeight: 700,
                                            rotate: "10deg",
                                            bottom: 0,
                                        } }, { children: [(_d = props.projects[1]) === null || _d === void 0 ? void 0 : _d.price, " \u20AC"] }))] })) })) })), _jsx("div", Object.assign({ className: "flex relative justify-center self-center items-center postItWaitingRoom", style: {
                            minHeight: 232,
                            minWidth: 232,
                            maxHeight: 232 * 2,
                            maxWidth: 232 * 2,
                            // padding: 30,
                            zIndex: 1,
                            rotate: "3deg",
                        } }, { children: _jsx("div", Object.assign({ className: "flex absolute flex-col postItWaitingRoom positionPostItTwo", style: {
                                minHeight: 232,
                                minWidth: 232,
                                maxHeight: 232 * 2,
                                maxWidth: 232 * 2,
                                rotate: "10deg",
                                // marginLeft: "45cqw",
                                backgroundImage: "url(./assets/UI/post_its/post_it.png)",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                zIndex: 0,
                            } }, { children: _jsxs("div", Object.assign({ className: "w-full h-full flex relative font-kalam text-center justify-center flex-col postItProposal", style: {
                                // paddingTop: "27cqw",
                                // paddingBottom: "23cqw",
                                // paddingLeft: "12cqw",
                                // paddingRight: "12cqw",
                                } }, { children: [_jsx("div", Object.assign({ className: "w-full h-full flex relative font-kalam text-center justify-center", style: {
                                            // fontSize: "6.9cqw",
                                            // lineHeight: "6.9cqw",
                                            fontWeight: 400,
                                            top: 0,
                                            rotate: "-10deg",
                                        } }, { children: (_e = props.projects[2]) === null || _e === void 0 ? void 0 : _e.content })), _jsxs("div", Object.assign({ className: "w-full h-full flex relative justify-center items-end font-kalam fontSizePricePostit", style: {
                                            // fontSize: "13cqw",
                                            // lineHeight: "13cqw",
                                            fontWeight: 700,
                                            rotate: "-10deg",
                                            bottom: 0,
                                        } }, { children: [(_f = props.projects[2]) === null || _f === void 0 ? void 0 : _f.price, " \u20AC"] }))] })) })) }))] })) }));
}
export default ProjectProposalsComponent;
