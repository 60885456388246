import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETurn from "cresus-common/dist/dto/ETurn";
import { useEffect, useState } from "react";
import { useBoardGame } from "../../contexts/BoardGameProvider";
function ChargesFixedSlots(props) {
    const { playSound } = useBoardGame();
    const [isPayed, setIsPayed] = useState(props.isPaid);
    const [isOnHover, setIsOnHover] = useState(false);
    const [turnState, setTurnState] = useState(props.turn);
    const [isCaptain, setIsCaptain] = useState(props.isCaptain);
    const pleasurePointsPenalty = [];
    function handleClick() {
        playSound("PayCharges");
        props.payChargeByPlayer();
    }
    for (let i = 0; i < props.pleasurePointsPenalty; i++) {
        pleasurePointsPenalty.push(i);
    }
    useEffect(() => {
        setIsPayed(props.isPaid);
        setTurnState(props.turn);
    }, [props.isPaid, props.turn]);
    useEffect(() => {
        setIsCaptain(props.isCaptain);
    }, [props.isCaptain]);
    return (_jsxs("button", Object.assign({ className: "flex relative font-kalam flex-col noteBookFixedCharges", disabled: isPayed || turnState !== ETurn.MUST_ROLL_DICE || !isCaptain || props.isDoubleOn, onClick: handleClick, onMouseEnter: () => setIsOnHover(true), onMouseLeave: () => setIsOnHover(false), style: {
            minWidth: 140,
            maxWidth: 140 * 2,
            minHeight: 63,
            maxHeight: 63 * 2,
            fontSize: "1.6cqw",
            fontStyle: "normal",
            lineHeight: "1.6cqw",
            fontWeight: "400",
            backgroundColor: "rgba(255, 255, 255, 1)",
            border: "1px solid transparent",
            opacity: isPayed ? 0.3 : 1,
        } }, { children: [isOnHover && turnState === ETurn.MUST_ROLL_DICE &&
                _jsx("div", { className: "flex h-full w-full absolute", style: {
                        position: "absolute",
                        backgroundImage: "url(./assets/UI/game_elements_images/isOnHoverCircle.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } }), _jsx("div", Object.assign({ className: "flex fontFixedCharges", style: {
                    backgroundImage: isPayed ? "url(./assets/UI/game_elements_images/crossed.png)" : "none",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                } }, { children: props.name })), _jsxs("div", Object.assign({ className: "flex items-center gap-1", style: { fontWeight: 700 } }, { children: [isPayed ? "" : "Payer", _jsxs("div", Object.assign({ className: "flex fontFixedCharges", style: {
                            // fontSize: 16,
                            lineHeight: "normal",
                            backgroundImage: isPayed ? "url(./assets/UI/game_elements_images/crossed.png)" : "none",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        } }, { children: [props.cost, " \u20AC"] }))] })), _jsx("div", { className: "flex relative", style: {
                    paddingTop: "4px",
                    width: "100%",
                    height: "2px",
                    minHeight: "2px",
                    backgroundImage: "url(./assets/UI/line.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                } }), _jsxs("div", Object.assign({ className: "flex gap-[5px]" }, { children: [props.bailiff &&
                        _jsx("div", { className: "flex noteBookBailAndPleasure", style: {
                                minWidth: 16,
                                minHeight: 16,
                                maxWidth: 16 * 2,
                                maxHeight: 16 * 2,
                                backgroundImage: "url(./assets/UI/game_elements_images/bailiff_picto.png)",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                            } }), pleasurePointsPenalty.map((pleasurePointPenalty, index) => {
                        return (_jsx("div", { className: "flex noteBookBailAndPleasure", style: {
                                minWidth: 16,
                                minHeight: 16,
                                maxWidth: 16 * 2,
                                maxHeight: 16 * 2,
                                backgroundImage: "url(./assets/UI/game_elements_images/pleasure_points_picto.png)",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                            } }));
                    })] }))] })));
}
export default ChargesFixedSlots;
