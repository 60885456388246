var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CardInfoActiveIcon from "../../icons/CardInfoActiveIcon";
import CardInfoInactiveIcon from "../../icons/CardInfoInactiveIcon";
import ChargesComponent from "./ChargesComponent";
import CardTeamHandlerComponent from "./CardTeamHandlerComponent";
import InformationTeamComponent from "./InformationTeamComponent";
import ETeam from "cresus-common/dist/dto/ETeam";
import useSessionContext from "../../../tools/useSessionContext";
export function getTeamColor(team) {
    switch (team) {
        case ETeam.BLUE:
            return "BLUE";
        case ETeam.GREEN:
            return "GREEN";
        case ETeam.YELLOW:
            return "YELLOW";
        case ETeam.RED:
            return "RED";
        default:
            console.log("error not a team");
            return "NONE";
    }
}
function switchToTeamName(team) {
    switch (team) {
        case ETeam.BLUE:
            return "bleue";
        case ETeam.GREEN:
            return "verte";
        case ETeam.YELLOW:
            return "jaune";
        case ETeam.RED:
            return "rouge";
        default:
            console.log("error not a team");
            return "error";
    }
}
function TeamsHandlerComponent(props) {
    const { sendRequest, websocketPeer } = useSessionContext();
    const [isActive, setIsActive] = useState(props.isActive);
    // const [teamsDTODemo, setTeamsDTODemo] = useState(props.teamsInGame);
    const [AreInfoCardShowed, setAreInfoCardShowed] = useState(props.teamsInGame.map(team => false));
    function handleDeleteCharge(charge, team) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("deleteCharge", {
                chargeToDelete: charge,
                team: team,
            }).catch((error) => { console.log("deleteCharge", error); });
            if (result !== undefined) {
                // props.setInsideGameState(result);
                console.log("result ", result);
            }
        });
    }
    function handleDeleteCards(card, teamColor) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("deleteCard", {
                cardToDelete: card,
                teamColor: teamColor,
            }).catch((error) => { console.log("deleteCard", error); });
            if (result !== undefined) {
                // props.setInsideGameState(result);
                console.log("result ", result);
            }
        });
    }
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    // useEffect(() => {
    //     setTeamsDTODemo([...props.teamsInGame]);
    //     console.log(props.teamsInGame, ":isThereModifs", teamsDTODemo)
    // }, [props.teamsInGame]);
    function clickedOnCardInfo(index) {
        const newAreInfoCardShowed = [...AreInfoCardShowed];
        newAreInfoCardShowed[index] = !newAreInfoCardShowed[index];
        setAreInfoCardShowed(newAreInfoCardShowed);
    }
    function getTotalsIncomes(team) {
        let totalIncome = 0;
        team.incomes.forEach(income => {
            totalIncome += income.amount;
            totalIncome += income.bonusMalusAmout;
        });
        return totalIncome;
    }
    function handlePayCharge(charge, team) {
        console.log("pay charge", charge, team);
        props.handlePayCharge(charge, team);
    }
    //TODO mettre moin que 100 de la height mettre de la marge bot
    return (_jsx("div", Object.assign({ 
        // hidden={true}
        className: `absolute bg-dilemmeDeepPurpple w-full justify-start  font-roboto flex-col gap-[20px] overflow-auto`, style: {
            display: isActive ? "flex" : "none",
            padding: "20px 10px",
            minWidth: 748,
            maxWidth: 950,
            height: "auto",
            maxHeight: "100%",
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            color: isActive ? "white" : "transparent",
            zIndex: isActive ? "1" : "0",
        } }, { children: props.teamsInGame !== undefined && props.teamsInGame.map((team, index) => {
            return (_jsxs("div", Object.assign({ className: "flex justify-start w-full flex-col", style: {
                    backgroundColor: "#121E46",
                    borderRadius: "4px",
                } }, { children: [_jsxs("div", Object.assign({ className: "flex justify-start w-full items-center" }, { children: [_jsx("div", { className: "flex", style: {
                                    marginTop: "14px",
                                    marginRight: "10px",
                                    width: 80,
                                    minWidth: 80,
                                    height: 80,
                                    backgroundImage: `url('/assets/UI/cigognes_teams_images/cigogne_with_team_${getTeamColor(team.teamColor)}.png')`,
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    transform: "scaleX(-1)",
                                } }), _jsxs("div", Object.assign({ className: "flex flex-col justify-center w-full self-start", style: {
                                    marginLeft: "10px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full font-roboto", style: {
                                            marginBottom: "10px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            color: "white",
                                            textTransform: "uppercase",
                                        } }, { children: ["\u00E9quipe ", switchToTeamName(team.teamColor)] })), team.money && team.charges && team.savings && team.pleasurePoints &&
                                        _jsx(InformationTeamComponent, { cards: team.cards, cash: team.money.amount, chargesToPay: team.charges.filter(charge => !charge.isPaid).length, income: getTotalsIncomes(team), piggyBank: team.savings.amout, piggyBankPercent: team.savings.percentageOfBonus, pleasurePoints: team.pleasurePoints.amount, project: team.project })] })), _jsx("button", Object.assign({ className: "flex", onClick: () => { clickedOnCardInfo(index); }, style: {
                                    height: 30,
                                    width: 30,
                                    minWidth: 30,
                                    marginLeft: "30px",
                                    marginRight: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    borderRadius: "15px",
                                } }, { children: AreInfoCardShowed[index] ? _jsx(CardInfoActiveIcon, {}) : _jsx(CardInfoInactiveIcon, {}) }))] })), AreInfoCardShowed[index] &&
                        _jsxs("div", Object.assign({ className: "flex w-full flex-col gap-[10px]", style: {
                                borderBottomLeftRadius: "4px",
                                borderBottomRightRadius: "4px",
                                borderTop: "1px solid #8699C1",
                                padding: "10px",
                                fontSize: "16px",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "19px",
                            } }, { children: ["Charges", _jsx("div", Object.assign({ className: "flex flex-grow flex-wrap w-full gap-[10px] items-center justify-start" }, { children: team.charges.map((charge, index) => {
                                        return (_jsx(ChargesComponent, { handleDelete: () => handleDeleteCharge(charge, team), isCreated: charge.createdCharge, handlePay: () => { handlePayCharge(charge, team); }, name: charge.name, payed: charge.isPaid, price: charge.amount + charge.bonusMalusAmout }));
                                    }) })), _jsxs("div", Object.assign({ className: "flex items-start gap-[10px] flex-col" }, { children: ["Cartes", _jsx("div", Object.assign({ className: "flex flex-grow flex-wrap w-full gap-[10px] items-center justify-start" }, { children: team.cards && team.cards.length > 0 &&
                                                team.cards.map((card, index) => {
                                                    return (_jsx(CardTeamHandlerComponent, { handleDelete: () => handleDeleteCards(card, team.teamColor), name: card.title, slotType: card.type, text: card.short_description }));
                                                }) }))] }))] }))] })));
        }) })));
}
export default TeamsHandlerComponent;
