import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
// import logo from './logo.svg';
import './App.css';
import './App.tailwind.css';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import WaitingRoomPage from './pages/animatorPages/WaitingRoomPage';
import TeamSelectionPage from './pages/animatorPages/TeamSelectionPage';
import DistibutionProjectPage from './pages/animatorPages/DistributionProjectPage';
import SessionContextProvider from './contexts/SessionContextProvider';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import IntroductionRoomPage from './pages/animatorPages/IntroductionRoomPage';
import TrainWithoutDynamic from './TrainWithoutDynamic';
import WaitingRoomPlayerPage from './pages/playerPages/WaitingRoomPlayerPage';
import LoadingPlayerPage from './pages/playerPages/LoadingPlayerPage';
import TooLateToJoinPage from './pages/playerPages/TooLateToJoinPage';
import IntroductionPlayerPage from './pages/playerPages/IntroductionPlayerPage';
import PlayerDistributionProjectPage from './pages/playerPages/PlayerDistributionProjectPage';
import ChooseNamePage from './pages/playerPages/ChooseNamePage';
import GameInProgressPage from './pages/playerPages/GameInProgressPage';
import AuthContextProvider from './contexts/AuthContextProvider';
import AvatarCompositionPage from './pages/playerPages/AvatarCompositionPage';
import BoardGameProvider from './contexts/BoardGameProvider';
import DefaultNavigation from './contexts/DefaultNavigation';
import MediaContextProvider from './contexts/MediaContextProvider';
import AnimatorGamePage from './pages/animatorPages/AnimatorGamePage';
import ReconnectionPage from './pages/playerPages/ReconnectionPage';
import 'animate.css';
function App() {
    if (window.location.pathname.startsWith("/passwordmodificationpage")) {
        const url = `${process.env.REACT_APP_BACKOFFICE_URL}/${window.location.pathname}/${window.location.search}`;
        const cleanedURL = url.replace(/([^:])(\/\/+)/g, '$1/');
        window.location.href = cleanedURL;
        console.warn("Redirecting to backoffice for password modification");
    }
    // To disable the back button
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
    });
    // Détection du navigateur
    const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
    if (isFirefox) {
        document.body.classList.add('firefox');
    }
    else {
        document.body.classList.add('other-browser');
    }
    return (_jsx(BoardGameProvider, { children: _jsx(AuthContextProvider, { children: _jsx(SessionContextProvider, { children: _jsx(MediaContextProvider, { children: _jsx(React.StrictMode, { children: _jsx(Router, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(DefaultNavigation, {}) }), _jsx(Route, { path: '/ForgottenPasswordPage', element: _jsx(ForgottenPasswordPage, {}) }), _jsx(Route, { path: '/WaitingRoomPage', element: _jsx(WaitingRoomPage, {}) }), _jsx(Route, { path: '/IntroductionRoomPage', element: _jsx(IntroductionRoomPage, {}) }), _jsx(Route, { path: '/TeamSelectionPage', element: _jsx(TeamSelectionPage, {}) }), _jsx(Route, { path: '/DistributionProjectPage', element: _jsx(DistibutionProjectPage, {}) }), _jsx(Route, { path: '/AnimatorGamePage', element: _jsx(AnimatorGamePage, {}) }), _jsx(Route, { path: '/ChooseNamePage', element: _jsx(ChooseNamePage, {}) }), _jsx(Route, { path: '/TooLateToJoinPage', element: _jsx(TooLateToJoinPage, {}) }), _jsx(Route, { path: '/WaitingRoomPlayerPage', element: _jsx(WaitingRoomPlayerPage, {}) }), _jsx(Route, { path: '/IntroductionPlayerPage', element: _jsx(IntroductionPlayerPage, {}) }), _jsx(Route, { path: '/AvatarCompositionPage', element: _jsx(AvatarCompositionPage, {}) }), _jsx(Route, { path: '/PlayerDistributionProjectPage', element: _jsx(PlayerDistributionProjectPage, {}) }), _jsx(Route, { path: '/GameInProgressPage', element: _jsx(GameInProgressPage, {}) }), _jsx(Route, { path: '/TrainWithoutDynamic', element: _jsx(TrainWithoutDynamic, {}) }), _jsx(Route, { path: '/LoadingPlayerPage', element: _jsx(LoadingPlayerPage, {}) }), _jsx(Route, { path: '/ReconnectionPage', element: _jsx(ReconnectionPage, {}) })] }) }) }) }) }) }) }));
}
export default App;
