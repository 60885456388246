"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceNewLines = exports.generateNDigitString = exports.truncateString = exports.limitString = exports.generateRandomString = void 0;
function generateNDigitString(n) {
    let result = '';
    for (let i = 0; i < n; i++) {
        result += Math.floor(Math.random() * 10); // generates a random digit between 0 and 9
    }
    return result;
}
exports.generateNDigitString = generateNDigitString;
function generateRandomString(length) {
    return generateNDigitString(length);
}
exports.generateRandomString = generateRandomString;
function limitString(s, length) {
    return s.length > length ? s.substring(0, length) + "..." : s;
}
exports.limitString = limitString;
function truncateString(s, length) {
    return s.length > length ? s.substring(0, length) : s;
}
exports.truncateString = truncateString;
// Replaces all occurences of "\\r\\n" by "\n"
function replaceNewLines(text) {
    return text.replaceAll("\\r\\n", "\n");
}
exports.replaceNewLines = replaceNewLines;
