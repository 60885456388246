import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function TooLateToJoinPage() {
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-start items-center h-screen w-screen", style: {
            // backgroundImage: "url(./assets/UI/fond_temporaire_connexion.png)",
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            paddingTop: "50px",
            paddingBottom: "60px",
        } }, { children: _jsxs("div", Object.assign({ className: "flex gap-[50px] flex-col h-full items-center relative" }, { children: [_jsx("div", { className: "flex", style: {
                        backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        height: "216px",
                        width: "420px",
                        zIndex: 1,
                    } }), _jsxs("div", Object.assign({ className: "flex font-kalam text-white self-center text-center", style: {
                        fontSize: "40px",
                        fontStyle: "normal",
                        lineHeight: "50px",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                        textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347, 3px 3px 0px #353347, 3px -3px 0px #353347, -3px 3px 0px #353347, -3px -3px 0px #353347, 4px 0px 0px #353347, 0px 4px 0px #353347, 4px 1px 0px #353347, 1px 4px 0px #353347, 4px 2px 0px #353347, 2px 4px 0px #353347, 4px 3px 0px #353347, 3px 4px 0px #353347, -4px 0px 0px #353347, 0px -4px 0px #353347, -4px 1px 0px #353347, 1px -4px 0px #353347, -4px 2px 0px #353347, 2px -4px 0px #353347, -4px 3px 0px #353347, 3px -4px 0px #353347, 4px -1px 0px #353347, -1px 4px 0px #353347, 4px -2px 0px #353347, -2px 4px 0px #353347, 4px -3px 0px #353347, -3px 4px 0px #353347, -4px -1px 0px #353347, -1px -4px 0px #353347, -4px -2px 0px #353347, -2px -4px 0px #353347, -4px -3px 0px #353347, -3px -4px 0px #353347, -5px 5px 0px #353347, -6px 5px 0px #353347, -5px 6px 0px #353347, -4px 6px 0px #353347, -3px 6px 0px #353347, -2px 6px 0px #353347, -1px 6px 0px #353347, -0px 6px 0px #353347, -6px 4px 0px #353347, -6px 3px 0px #353347, -6px 2px 0px #353347, -6px 1px 0px #353347, -6px 0px 0px #353347, -6px -1px 0px #353347",
                        // textShadow:" -5px 5px 0px #352247"
                        // textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, -5px 5px 0px #352247"
                    } }, { children: ["La session a d\u00E9j\u00E0 commenc\u00E9, ", _jsx("br", {}), " vous ne pouvez pas la rejoindre."] })), _jsxs("div", Object.assign({ className: "flex absolute  flex-col font-kalam text-black self-center text-center", style: {
                        width: "480px",
                        bottom: "0px",
                        padding: "10px",
                        fontSize: "16px",
                        fontStyle: "normal",
                        lineHeight: "20px",
                        backgroundColor: "#FFFFFF",
                        border: "2px solid #000000",
                    } }, { children: ["Ne vous en faites pas, vous pouvez reprogrammer une session en nous contactant \u00E0 l'adresse suivante :", _jsx("br", {}), " ", _jsx("b", { children: "contact@dilemme.com" })] }))] })) })));
}
export default TooLateToJoinPage;
