import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ESlotType from "cresus-common/dist/dto/ESlotType";
import { useEffect, useState } from "react";
import WhiteSunIcon from "../../icons/WhiteSunIcon";
function getNameOfWaitingDeck(slotTypeToPass) {
    switch (slotTypeToPass) {
        case ESlotType.DILEMME:
            return "event";
        case ESlotType.QUESTION:
            return "question";
        case ESlotType.PLAISIR:
            return "pleasure";
        default:
            return "waiting_decks_";
    }
}
function WaitingDrawCardComponent(props) {
    const [slotType, setSlotType] = useState(props.slotType);
    const [nameOfWaitingDeck, setNameOfWaitingDeck] = useState("");
    useEffect(() => {
        setSlotType(props.slotType);
        setNameOfWaitingDeck(getNameOfWaitingDeck(props.slotType));
    }, [props.slotType]);
    return (_jsx("div", Object.assign({ className: "flex absolute justify-center items-center h-full w-full", style: {
            minHeight: "100vh",
            backgroundColor: "rgba(12, 21, 51, 0.80)",
            zIndex: 2,
            top: 0,
        } }, { children: _jsxs("div", Object.assign({ className: "relative flex justify-center self-center" }, { children: [_jsx("div", Object.assign({ className: "absolute flex justify-center items-center self-center whiteSun", style: {
                        maxWidth: 400 * 2,
                        maxHeight: 400 * 2,
                        minHeight: 400,
                        minWidth: 400,
                        // right: -200,
                        //  backgroundColor: "rgba(0, 0, 0, 1)", 
                        zIndex: 0,
                    } }, { children: _jsx("div", Object.assign({ className: "w-full h-full flex absolute", style: {
                            right: "0%",
                            top: "0cqw",
                            animation: "spin 10s linear infinite",
                        } }, { children: _jsx(WhiteSunIcon, {}) })) })), _jsx("div", Object.assign({ className: "absolute flex justify-center items-center self-center cardToDrawDeck", style: {
                        maxWidth: 340 * 2,
                        maxHeight: 278 * 2,
                        minHeight: 278,
                        minWidth: 340,
                    } }, { children: _jsx("div", { className: "flex relative w-full h-full", style: {
                            right: "-45%",
                            top: "calc(-10%*1.22)",
                            backgroundImage: `url(./assets/UI/game_elements_images/waiting_decks/waiting_deck_${nameOfWaitingDeck}.png)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        } }) })), _jsx("div", Object.assign({ className: "flex absolute justify-center items-center self-center drawCardBoard", style: {
                        maxWidth: 314 * 2,
                        maxHeight: 134 * 2,
                        minHeight: 134,
                        minWidth: 314,
                    } }, { children: _jsx("div", Object.assign({ className: "flex absolute w-full h-full items-center justify-center", style: {
                            right: "0%",
                            borderRadius: 15,
                            border: "2px solid #000000",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            zIndex: 0,
                            padding: "2.25%",
                            boxShadow: "-10px 10px 0px 0px rgba(0, 0, 0, 0.25)",
                        } }, { children: _jsx("div", Object.assign({ className: "flex justify-center relative w-full h-full", style: {
                                padding: "10%",
                                borderRadius: 10,
                                backgroundColor: "rgba(111, 130, 192, 1)",
                            } }, { children: _jsx("div", Object.assign({ className: "flex justify-center items-center text-center flex-col text-white font-kalam gap-[10px] fontThrowDice", style: {
                                    // fontSize: "6.5cqw",
                                    fontWeight: 700,
                                    // lineHeight: "6.5cqw",
                                    textTransform: "uppercase",
                                } }, { children: "Vous allez piocher une carte !" })) })) })) }))] })) })));
}
export default WaitingDrawCardComponent;
