var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DropdownList } from "react-widgets/cjs";
import DropdownArrowIcon from "../../icons/DropdownArrowIcon";
import FunctionalButtonBlueFound from "../../FonctionnalButtonBlueFoundComponent";
import axios from "axios";
import isNullOrUndefined from "../../../tools/isNullOrUndefined";
import useSessionContext from "../../../tools/useSessionContext";
import React from "react";
function VideoPlayer(props) {
    const refVideo = React.useRef(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    function handleVideoLoadedData() {
        setIsVideoLoaded(true);
        console.log("handleVideoLoadedData");
    }
    const HAVE_ENOUGH_DATA = 40; // https://html.spec.whatwg.org/multipage/media.html#the-ready-states
    return (_jsxs("div", Object.assign({ className: " relative w-full h-full flex justify-center" }, { children: [
            // refVideo.current?.readyState !== HAVE_ENOUGH_DATA 
            !isVideoLoaded
                &&
                    _jsx("div", Object.assign({ className: "bg-black flex w-full h-full items-center justify-center absolute" }, { children: _jsx("span", { className: "loaderReconnection flex" }) })), _jsx("video", Object.assign({ ref: refVideo, controls: true, onLoadedData: handleVideoLoadedData, style: {
                    display: isVideoLoaded ? "flex" : "none",
                } }, { children: _jsx("source", { src: props.url, type: "video/mp4" }) }))] })));
}
function ProjectionContentComponent(props) {
    var _a, _b, _c;
    const { sendRequest, websocketPeer } = useSessionContext();
    const [isActive, setIsActive] = useState(props.isActive);
    const [isActiveContent, setIsActiveContent] = useState(false);
    const [contents, setContents] = useState([]);
    const [contentModel, setContentModel] = useState(undefined);
    function getContentWithSAS(contentModel) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isNullOrUndefined(contentModel)) {
                const reponse = yield axios.get(`${process.env.REACT_APP_API_URL}/contents/content/?path=${encodeURIComponent(contentModel.path)}`);
                if (!isNullOrUndefined(reponse))
                    setContentModel(reponse.data);
                else {
                    console.log("no content strange", reponse);
                }
            }
            else {
                console.log("no content");
            }
        });
    }
    function getContentList() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(`${process.env.REACT_APP_API_URL}/contents`);
            return response.data;
        });
    }
    function getContentShown() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield sendRequest("getContentDiffused")
                .catch((error) => { console.log("getContentDiffused", error); });
            if (response && response.content)
                setContentModel(response.content);
            else
                setContentModel(undefined);
            console.log("getContentShown", response);
        });
    }
    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);
    useEffect(() => {
        getContentList().then((data) => {
            setContents(data);
        }).catch((error) => {
            console.log("error", error);
        });
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", (notification) => {
            console.log("ProjectionComponent RECEIVED NOTIFICATION", notification);
            try {
                if (notification.method === "startDiffusion") {
                    setContentModel(notification.data);
                    setIsActiveContent(true);
                }
                if (notification.method === "stopDiffusion") {
                    setContentModel(undefined);
                    setIsActiveContent(false);
                }
            }
            catch (e) {
                console.log("error", e);
            }
        });
        getContentShown();
        console.log("content model", contentModel);
    }, []);
    function startDiffusion() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("startDiffusion", contentModel);
            const result = yield sendRequest("startDiffusion", { content: contentModel })
                .catch((error) => { console.log("startDiffusion", error); });
        });
    }
    function stopDiffusion() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("stopDiffusion")
                .catch((error) => { console.log("stopDiffusion", error); });
        });
    }
    const handleButtonClick = () => {
        if (isActiveContent) {
            stopDiffusion();
        }
        else {
            startDiffusion();
        }
        setIsActiveContent(!isActiveContent);
    };
    return (_jsxs("div", Object.assign({ className: `absolute bg-dilemmeDeepPurpple w-full justify-start font-roboto flex-col`, style: {
            display: isActive ? "flex" : "none",
            padding: "20px 10px",
            minWidth: 748,
            maxWidth: 950,
            height: "auto",
            maxHeight: "100%",
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            color: isActive ? "white" : "transparent",
            zIndex: isActive ? "1" : "0",
        } }, { children: [_jsx("div", Object.assign({ className: "font-semibold text-sm mb-2" }, { children: "Contenu \u00E0 projeter" })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(DropdownList, { containerClassName: "dropdown_content_projection test", data: contents, textField: "name", value: contentModel, onChange: (value) => { getContentWithSAS(value); }, renderValue: ({ item }) => {
                            console.log("item ", item);
                            if (item === undefined)
                                item = { path: "", type: 0, name: "", filename: "", size: "" };
                            return (_jsxs("div", Object.assign({ className: "flex text-white text-sm items-center" }, { children: [_jsx("span", Object.assign({ className: "bg-dilemmeSimplePurple font-medium mr-3", style: { padding: "1px 5px", borderRadius: "2px" } }, { children: item.type === 0 ? "Image" : "Vidéo" })), " " + item.name] })));
                        }, renderListItem: ({ item }) => (_jsxs("div", Object.assign({ className: "flex  text-sm items-center" }, { children: [_jsx("span", Object.assign({ className: "bg-dilemmeSimplePurple font-medium mr-3 text-white", style: { padding: "1px 5px", borderRadius: "2px" } }, { children: item.type === 0 ? "Image" : "Vidéo" })), " " + item.name] }))), selectIcon: _jsx(DropdownArrowIcon, { color: "white" }) }), _jsx("div", Object.assign({ className: "ml-5" }, { children: _jsx(FunctionalButtonBlueFound, { text: `${isActiveContent ? "Arrêter" : "Commencer"} la projection`, enabled: contentModel ? true : false, colorMode: 0, onClick: handleButtonClick }) }))] })), contentModel !== undefined &&
                _jsxs("div", Object.assign({ className: "bg-white w-full mt-5", style: {
                        display: contentModel !== undefined ? "flex" : "none",
                        height: "410px"
                    } }, { children: [contentModel !== undefined && contentModel.path && ((_a = contentModel.path) === null || _a === void 0 ? void 0 : _a.includes('?sv')) && contentModel.type === 1 &&
                            _jsx(VideoPlayer, { url: contentModel.path }), contentModel !== undefined && ((_b = contentModel.path) === null || _b === void 0 ? void 0 : _b.includes('?sv')) && contentModel.type === 0 &&
                            _jsx("div", { className: "flex", style: {
                                    width: "100%",
                                    height: "100%",
                                    backgroundImage: `url(${contentModel.path})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                } })] }), (_c = contentModel.path) !== null && _c !== void 0 ? _c : "content")] })));
}
export default ProjectionContentComponent;
