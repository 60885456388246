import React from "react";
import SessionContext from "../contexts/SessionContext";
const useSessionContext = () => {
    const context = React.useContext(SessionContext);
    // console.log("useSessionContext context: ", context, SessionContext) ;
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};
export default useSessionContext;
