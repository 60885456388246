var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import ChatMessageComponent from "./ChatMessageComponent";
import ChatOpenButtonComponent from "./player_buttons/ChatOpenButtonComponent";
import useSessionContext from "../tools/useSessionContext";
import React from "react";
function ChatComponent(props) {
    const { sendRequest, websocketPeer } = useSessionContext();
    const [isShown, setIsShown] = useState(props.isChatShown);
    const [chat, setChat] = useState([]);
    const refMessageToSend = useRef(null);
    function toggleChat() {
        props.toggleChat();
        props.newMessageAlert(false);
    }
    function handleChangeMessage(e) {
        if (refMessageToSend.current) {
            refMessageToSend.current.value = e.target.value;
        }
    }
    function handleSendMessage() {
        // console.log("handleSendMessage in chat component", refMessageToSend.current);
        if (refMessageToSend.current) {
            console.log("data to send", props.myPlayer);
            const messageToSend = {
                content: refMessageToSend.current.value,
                date: new Date(),
                person: props.myPlayer,
            };
            sendMessage(messageToSend);
        }
    }
    function getChat() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("getChat", {})
                .catch((error) => { console.log("getChat", error); });
            console.log("getChat result", result);
            if (result) {
                setChat(result);
                // if(result.length > 0)
                // {
                //     props.newMessageAlert(true);
                // }
            }
        });
    }
    function sendMessage(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield sendRequest("sendMessage", message)
                .catch((error) => { console.log("sendMessage", error); });
            console.log("result", result);
            if (refMessageToSend.current) {
                refMessageToSend.current.value = "";
            }
            // if (result) {
            //      setChat((prevChat) => [...prevChat, result]);
            // }
        });
    }
    useEffect(() => {
        getChat();
        const callbackNotification = (notification) => {
            try {
                if (notification.method === "sendMessage") {
                    setChat((prevChat) => {
                        return ([...prevChat, notification.data]);
                    });
                    // const isMyMessageAsPlayer = (notification.data.person as PlayerDTO).name === props.myPlayer.name;
                    // const isMyMessageAsAnimator = (notification.data.person as AnimatorMessage).animatorName === props.myPlayer.name;
                    const messageName = notification.data.person.name ? notification.data.person.name : notification.data.person.animatorName;
                    // if(messageName !== props.myPlayer.name)
                    //    {
                    //      props.newMessageAlert(true);
                    //     }
                }
            }
            catch (e) {
                console.error(e);
            }
        };
        // This has to be done in the useEffect to avoid the event to be subscribed multiple times
        websocketPeer === null || websocketPeer === void 0 ? void 0 : websocketPeer.on("notification", callbackNotification);
        return () => {
            var _a;
            const websocketPeerAny = websocketPeer;
            const foundIndex = (_a = websocketPeerAny._events.notification) === null || _a === void 0 ? void 0 : _a.findIndex(el => el === callbackNotification);
            if (foundIndex !== -1 && websocketPeer._events.notification) {
                websocketPeer._events.notification.splice(foundIndex, 1);
                console.log("callback removed from the array", foundIndex);
            }
            else
                console.error("callback not found in the array", websocketPeer);
        };
    }, []);
    useEffect(() => {
        console.log("chat quand update", chat);
        if (props.isChatShown) {
            props.newMessageAlert(false);
        }
        if (chat.length > 0 && props.isChatShown === false) {
            props.newMessageAlert(true);
        }
    }, [chat]);
    console.log('REREENDER CHAT', chat);
    useEffect(() => {
        setIsShown(props.isChatShown);
        console.log("isShown", isShown);
    }, [props.isChatShown]);
    return (_jsx("div", Object.assign({ className: "fixed flex flex-col justify-start chat gap-2", style: {
            minHeight: 456,
            minWidth: 255,
            maxHeight: 456 * 2,
            maxWidth: 255 * 2,
            zIndex: isShown ? 100 : 0,
            bottom: 0,
            pointerEvents: "none",
        } }, { children: _jsxs("div", Object.assign({ className: `flex relative h-full w-full flex-col justify-start  ${isShown ? "positionChatShown" : "positionChatHidden"}`, style: {
                pointerEvents: "auto",
                // bottom: `${isShown ? "2cqw" : "-100cqh"}`,
                transition: "all 1.5s ease-out",
                // paddingTop: "12.55cqw",
                // paddingLeft: "7.84cqw",
                // paddingRight: "3.53cqw",
                zIndex: 4,
                backgroundImage: "url(./assets/UI/chat_images/chat_holder_image.png)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
            } }, { children: [_jsxs("div", Object.assign({ className: "flex absolute flex-col gap-[10px] justify-between items-start dimensionChat", style: {
                        // width: "86.63cqw",
                        // height: "72.77cqh",
                        // paddingRight: "4.31cqw",
                        zIndex: -1,
                    } }, { children: [_jsx("div", { className: "flex relative", style: {
                                width: "100%",
                                height: 2,
                                backgroundImage: "url(./assets/UI/line.png)",
                                backgroundSize: "cover",
                                backgroundRepeat: "repeat-x",
                            } }), _jsx("div", { className: "flex relative", style: {
                                width: "100%",
                                height: 2,
                                backgroundImage: "url(./assets/UI/line.png)",
                                backgroundSize: "cover",
                                backgroundRepeat: "repeat-x",
                            } })] })), _jsx("div", Object.assign({ className: "flex relative flex-col justify-start items-start dimmensionInnerChat ", style: {
                    // width: "86.63cqw",
                    // height: "72.77cqh",
                    // paddingRight: "4.31cqw",
                    // paddingTop: "4.31cqw",
                    } }, { children: _jsx("div", Object.assign({ className: "flex font-kalam flex-col flex-noWrap overflow-y-auto scrollbar-black fontSizeMessages", style: {
                            width: "100%",
                            height: "100%",
                            // fontSize: "4.71cqw",
                            // lineHeight: "5.49cqw",
                        } }, { children: chat.map((message, index) => {
                            return _jsx(ChatMessageComponent, { myPlayerName: props.myPlayer.name, message: message }, "ChatMessageComponent" + Math.random());
                        }) })) })), _jsxs("div", Object.assign({ className: "flex relative font-kalam messageInput", style: {
                    // width: "84.31cqw",
                    // height: "15.67cqw",
                    // fontSize: "4.71cqw",
                    // lineHeight: "5.49cqw",
                    } }, { children: [_jsx("textarea", { className: "flex relative font-kalam flex-wrap scroll-auto messageInputTextArea", id: "message", name: "Message", required: true, placeholder: "Message...", readOnly: false, ref: refMessageToSend, onChange: (e) => handleChangeMessage(e), style: {
                                // width: "84.31cqw",
                                // height: "15.67cqw",
                                // padding: "1.96cqw",
                                // paddingRight: "11.76cqw",
                                border: "2px solid rgba(0, 0, 0, 0.1)",
                                resize: "none",
                                scrollbarColor: "transparent transparent",
                                scrollbarWidth: "thin",
                            } }), _jsx("button", { className: "flex absolute buttonSendMessage", onClick: handleSendMessage, style: {
                                backgroundImage: "url(./assets/UI/buttons/chat_buttons/send_message_image.png)",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                // width: "7.84cqw",
                                // height: "6.27cqw",
                                // bottom: "1.96cqw",
                                // right: "1.96cqw",
                            } })] })), _jsx("div", Object.assign({ className: "flex absolute chatCloseComponentPosition", style: {
                    // top: "20.39cqw",
                    // right: "-17.84cqw",
                    } }, { children: _jsx(ChatOpenButtonComponent, { closeChat: toggleChat }) }))] })) })));
}
export default ChatComponent;
