import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import AvatarCompositionComponent from "../avatar_creation_component/AvatarCompositionComponent";
function TeamDisplayComponent(props) {
    const hasElements = props.avatarPlayers.length > 0;
    return (_jsxs("div", Object.assign({ className: `flex flex-col h-full ${hasElements ? "justify-between" : "justify-center items-center"} ` }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [hasElements ? (props.avatars.map((avatarUrl, index) => (_jsxs("div", Object.assign({ className: "relative flex dimmentionOtherTeamsAvatars", style: {
                            backgroundImage: `url(./assets/UI/teams/${avatarUrl}.png)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            // height: "15.625cqw",
                            // width: "15.625cqw",
                            zIndex: "10",
                            background: "rgba(0, 0, 0, 0.10)",
                            borderRadius: "1.56cqw",
                        } }, { children: [_jsx(AvatarCompositionComponent, { toRight: true, avatarComposition: Object.assign(Object.assign({}, avatarUrl), { teamColor: props.teamColor }) }), _jsx("div", { className: "absolute dimmentionOtherTeamsAvatars", style: {
                                    top: 0,
                                    backgroundImage: "url(./assets/UI/teams/player_frame.png)",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    // height: "15.625cqw",
                                    // width: "15.625cqw",
                                } })] }), index)))) :
                        _jsx("div", Object.assign({ className: "font-kalam flex justify-center items-center" }, { children: "Aucun joueur dans l'\u00E9quipe" })), hasElements ? (Array(4 - props.avatarPlayers.length).fill(null).map((_, emptyIndex) => (_jsx("div", { className: "relative flex dimmentionOtherTeamsAvatars", style: {
                            backgroundImage: "url(./assets/UI/teams/no_player.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            // height: "15.625cqw",
                            // width: "15.625cqw",
                        } }, `empty-${emptyIndex}`)))) : null] })), hasElements ? (_jsxs("div", Object.assign({ className: "flex justify-between text-center items-center font-kalam text-[4.38cqw]" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: ["\u00C9quipe ", _jsx("div", Object.assign({ className: "ml-[1.25cqw] font-bold" }, { children: props.teamName }))] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsxs("div", Object.assign({ className: "flex mr-[3.125cqw] items-center" }, { children: [_jsxs("div", Object.assign({ className: "font-bold mr-[1.25cqw]" }, { children: [props.totalCash, "\u20AC"] })), _jsx("div", { className: "dimmensionOtherTeamsCash", style: {
                                            backgroundImage: "url(./assets/UI/teams/cash.png)",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            // height: "5cqw",
                                            // width: "7.5cqw",
                                            zIndex: 1,
                                        } })] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "font-bold mr-[1.25cqw]" }, { children: props.pleasureCoins })), _jsx("div", { className: "dimmensionOtherTeamsPleasure", style: {
                                            backgroundImage: "url(./assets/UI/teams/pleasure_coin.png)",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            // height: "5cqw",
                                            // width: "5cqw",
                                            zIndex: 1,
                                        } })] }))] }))] }))) : null] })));
}
export default TeamDisplayComponent;
