import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETeam from "cresus-common/dist/dto/ETeam";
const getTeamColor = (teamColor) => {
    console.log("teamColor", teamColor);
    switch (teamColor) {
        case ETeam.BLUE:
            return "4";
        case ETeam.GREEN:
            return "5";
        case ETeam.YELLOW:
            return "3";
        case ETeam.RED:
            return "2";
        case ETeam.NONE:
            return "1";
        default:
            console.log("error not a team");
            return "error";
    }
};
function AvatarCompositionComponent(props) {
    const zIndex = ["100", "140", "110", "130", "120", "160"];
    function transformAvatarCompositionToLinks() {
        let avatarHair = props.avatarComposition.hair === 5 ? `hair0${props.avatarComposition.hair + 1}` : `hair0${props.avatarComposition.hair + 1}-0${props.avatarComposition.hair_color + 1}`;
        let avatarLinks = [
            `skin0${props.avatarComposition.skin + 1}`,
            avatarHair,
            `eyes0${props.avatarComposition.eyes + 1}`,
            `nose0${props.avatarComposition.nose + 1}`,
            `mouth0${props.avatarComposition.mouth + 1}`,
            `bonus0${props.avatarComposition.bonus + 1}`
        ];
        return avatarLinks;
    }
    const avatarLinks = transformAvatarCompositionToLinks();
    return (_jsxs("div", Object.assign({ className: "w-full h-full absolute", style: {
            transform: `${props.toRight ? "scaleX(-1)" : ""}`,
        } }, { children: [avatarLinks.map((el, index) => (_jsx("div", { className: "h-full w-full flex absolute", style: {
                    backgroundImage: `url(./assets/UI/avatar_composition/composition/${el}.png)`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    zIndex: zIndex[index]
                } }, "avatarLinks" + index))), _jsx("div", { className: "h-full w-full flex absolute", style: {
                    backgroundImage: `url(./assets/UI/avatar_composition/composition/body0${getTeamColor(props.avatarComposition.teamColor)}.png)`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    zIndex: 150
                } })] })));
}
export default AvatarCompositionComponent;
