import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AvatarCompositionComponent from "../avatar_creation_component/AvatarCompositionComponent";
import useSession from "../../contexts/useSession";
import { limitString } from "cresus-common/dist/utils/StringUtils";
import FunctionalButton from "../FunctionalButton";
function PlayerToReconnect(props) {
    const { websocketPeer } = useSession();
    return (_jsxs("div", Object.assign({ className: "flex justify-around text-white font-roboto", style: {
            background: "rgba(18, 30, 70, 1)",
            minHeight: "45px",
            width: "100%",
            height: 77.5,
            borderRadius: "3px",
        } }, { children: [_jsx("div", Object.assign({ className: "flex relative h-full", style: {
                    aspectRatio: 1
                } }, { children: _jsx(AvatarCompositionComponent, { avatarComposition: props.player.avatarComposition, toRight: true }, Math.random().toString()) })), _jsxs("div", Object.assign({ className: "flex w-full flex-col gap-[5px] justify-center", style: { margin: 10 } }, { children: [_jsx("div", Object.assign({ className: "flex font-white font-roboto flex-nowrap", style: {
                            fontSize: "15px",
                            fontWeight: "400",
                        } }, { children: limitString(props.player.name, 10) })), !props.player.isConnected && _jsx(FunctionalButton, { text: "Rejoindre", onClick: () => { props.joinAsPlayer(props.player); } })] }))] })));
}
export default PlayerToReconnect;
