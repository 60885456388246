import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function LoadingPlayerPage() {
    return (_jsxs("div", Object.assign({ className: "fixed flex w-screen h-screen bg-dilemmeSimplePurple justify-center items-center p-[50px]", style: { zIndex: 150 } }, { children: [_jsx("div", { className: "flex absolute ", style: {
                    width: "420px",
                    height: "166px",
                    backgroundImage: "url(./assets/UI/logos/dilemme_education.png)",
                    backgroundSize: "contain",
                } }), _jsxs("div", Object.assign({ className: "flex absolute flex-col justify-center", style: {
                    bottom: "50px",
                    width: "500px",
                    height: "80px",
                } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-kalam text-white itemps-center justify-center", style: {
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "50px",
                            letterSpacing: "0.2px",
                            textAlign: "center",
                            textShadow: "-1px 0px 0px #352247, 0px -1px 0px #352247, 0px 1px 0px #352247, 1px 0px 0px #352247, -1px -1px 0px #352247, 1px 1px 0px #352247, -2px 0px 0px #352247, -2px 1px 0px #352247, -2px 2px 0px #352247, -1px 2px 0px #352247, 0px 2px 0px #352247, 1px 2px 0px #352247, 2px 2px 0px #352247, 2px 1px 0px #352247, 2px -1px 0px #352247, 2px -2px 0px #352247, 1px -2px 0px #352247, 0px -2px 0px #352247, -1px -2px 0px #352247, -2px -2px 0px #352247, -2px -1px 0px #352247, 3px 0px 0px #353347, 3px 1px 0px #353347, 3px 2px 0px #353347,-3px 0px 0px #353347, -3px 1px 0px #353347, -3px 2px 0px #353347, -3px -1px 0px #353347, -3px -2px 0px #353347, 3px -1px 0px #353347, 3px -2px 0px #353347",
                        } }, { children: "Chargement en cours ..." })), _jsx("div", Object.assign({ className: "flex relative w-full bg-dilemmeDeepPurpple", style: {
                            overflow: "hidden",
                            height: "30px",
                            padding: "3px",
                            borderRadius: "15px",
                            border: "3px solid #000000",
                        } }, { children: _jsx("div", { className: "flex relative self-center bg-white overflow-clip", style: {
                                overflow: "hidden",
                                animation: " 3s linear 0s infinite running slidein",
                                width: 200,
                                height: "20px",
                                borderRadius: "15px",
                                border: "3px solid #000000",
                            } }) }))] }))] })));
}
export default LoadingPlayerPage;
