import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EEffects from "cresus-common/dist/dto/EEffects";
import { useEffect } from "react";
import { DropdownList } from "react-widgets/cjs";
import DropdownArrowIcon from "../../icons/DropdownArrowIcon";
function EffectInputDropDownComponent(props) {
    var _a;
    function handleInputEffect(e) {
        //setNameOfEffect(e.target.value);
        if (props.handlePassInformationOnEffect) {
            props.handlePassInformationOnEffect(e.target.value);
        }
        console.log('handleInputEffect', e.target.value);
    }
    useEffect(() => {
        //  setNameOfEffect(props.nameOfEffect);
        console.log("hello i should not come last", props.nameOfEffect);
    }, [props.nameOfEffect]);
    return (_jsxs("div", Object.assign({ className: "flex h-full items-center", style: {
            width: (props.enumEffect === EEffects.DEPLACEMENT && !props.isEffectDropdown) ? "97.8px" : ((props.enumEffect === EEffects.TRANSFERT_EPARGNE) ? "300px" : "250px"),
        } }, { children: [props.isEffectDropdown &&
                _jsx(DropdownList, { data: props.availableEffects, defaultValue: (_a = props.nameOfEffect) !== null && _a !== void 0 ? _a : props.availableEffects[0], onChange: (value) => {
                        if (props.handlePassInformationOnEffect)
                            props.handlePassInformationOnEffect(value);
                    }, selectIcon: _jsx(DropdownArrowIcon, { color: "white" }), containerClassName: "dropdown_effects_customized dropdown_effects_customized.rw-popup dropdown_effects_customized.rw-dropdown-list-input" }), !props.isEffectDropdown && props.enumEffect !== EEffects.DEPLACEMENT &&
                _jsx("input", { className: "flex h-full p-[10px] font-roboto w-full items-center", defaultValue: props.nameOfEffect, onChange: (e) => handleInputEffect(e), style: {
                        padding: "10px",
                        fontSize: "14px",
                        fontWeight: 400,
                        fontStyle: "normal",
                        lineHeight: "normal",
                        color: "#FFFFFF",
                        backgroundColor: "#384983",
                        borderRadius: "4px",
                    } }, "hello")] })));
}
export default EffectInputDropDownComponent;
