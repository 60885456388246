var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ShaderMaterial, Color, TextureLoader } from "three";
import { loadTextFile } from "../../../utils/loadTextFile";
import * as THREE from 'three';
export function createWaterMaterial(depthBuffer) {
    return __awaiter(this, void 0, void 0, function* () {
        const textureLoader = new TextureLoader(); // TODO replace by useLoader
        const tex360 = textureLoader.load("./assets/360/limpopo_golf_course_4k.jpg");
        tex360.wrapS = THREE.RepeatWrapping;
        tex360.wrapT = THREE.RepeatWrapping;
        tex360.generateMipmaps = false;
        const noiseTex = textureLoader.load("./assets/3D/Noise.png");
        noiseTex.wrapS = THREE.RepeatWrapping;
        noiseTex.wrapT = THREE.RepeatWrapping;
        noiseTex.generateMipmaps = false;
        const WaterVertexShader = yield loadTextFile("./assets/shaders/Water.vs");
        const WaterFragmentShader = yield loadTextFile("./assets/shaders/Water.fs");
        const material = new ShaderMaterial({
            uniforms: {
                time: { value: 0 },
                color: { value: new Color(0.2, 0.0, 0.1) },
                environmentTex: { value: tex360 },
                noiseTex: { value: noiseTex },
                depthTex: { value: depthBuffer },
                // ...THREE.UniformsLib['lights']                     // lights: true // TODO injecting light not working as expected
            },
            transparent: false,
            vertexShader: WaterVertexShader,
            fragmentShader: WaterFragmentShader,
            // lights: true // TODO injecting light not working as expected
        });
        return material;
    });
}
